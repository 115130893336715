/* for the edit */

.comment_add_wrapper {
    width: 100%;
    height: fit-content;
    min-height: 65px;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;

    margin: 5px 0;
    padding: 10px 0;
}

.comment_text_wrapper {
    flex: 1;
    height: fit-content;
    min-height: 30px;
}

.comment_text_wrapper .cancel_edit {
    cursor: pointer;
}

.comment_text_wrapper .cancel_edit:hover {
    text-decoration: underline;
}
