@use "../shared.module.scss";

.data_section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 40px;
}

.align_left {
	justify-content: left;
}

.loading_container {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.data_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.data_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.data_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.data_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.data_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.data_section {
		grid-template-columns: repeat(1, 1fr);
	}
}
