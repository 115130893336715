.items_wrapper {
	width: 100%;
	height: fit-content;
	margin-top: 15px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;

	gap: 19px;
}

/* .items_wrapper_keypeople {

    width: 100%;
    height: fit-content;
    margin-top: 15px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 40px;
} */

.key_profile {
	flex-basis: 33.333333%;
}

.key_special_profiles {
	width: 100%;
	height: fit-content;
	margin-top: 20px;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	row-gap: 40px;
}

.key_special_profiles.align_left {
	justify-content: left;
}

.items_wrapper_tags {
	width: 100%;
	height: fit-content;
	margin-top: 15px;

	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.manage_company {
	width: 100%;
	display: flex;
	justify-content: center;
}

.custom_btn {
	width: 330px;
}

/* .manage_company button {
    width: 293px;
    height: 35px;
    padding: 8px 14px;
    background-color: #dbdfe4;
    margin-bottom: 30px;
    border-radius: 5px;


    font-size: 14px;
    font-weight: var(--font-weight-bold);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: var(--gray80);
} */

.employees_container {
	margin-top: 20px;
}

.employees_container .pending_btn {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: var(--gaawk);
	margin-bottom: 20px;
}

.employees_container .pending_btn:hover {
	text-decoration: underline;
}

.employee_item:last-of-type {
	border: none;
	padding: 0;
	margin: 0;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.key_profile {
		flex-basis: 50%;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.key_profile {
		flex-basis: 50%;
	}
}

@media screen and (max-width: 1165.98px) {
	.key_profile {
		flex-basis: 100%;
	}
}
