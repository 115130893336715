.popover_link {
	color: unset;
}

.popover_link:hover,
.popover_link:focus {
	text-decoration: none;
	color: unset;
}

.option_icon {
	width: 22px;
	height: 22px;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
}
.option_icon img {
	width: auto;
	height: auto;
}
