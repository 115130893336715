.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	.setting_item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;

		.details {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
}

@mixin mobile-layout {
	.container {
		.setting_item {
			flex-direction: column;
			align-items: flex-start;
		}
		.manage_btn {
			width: 100%;
		}
	}
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	@include mobile-layout;
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (max-width: 639px) {
	@include mobile-layout;
}
