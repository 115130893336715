.leftside_container {
	display: flex;
	flex-direction: column;
}

.button_container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
}

.btn {
	width: 100%;
}

.type_container {
	padding: 20px;
}

.type_container.no_bottom_padding {
	padding-bottom: 0px;
}
