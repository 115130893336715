@use "variables" as *;

.fileviewer_container {
	width: 100%;
	/* height: fit-content; */
	height: calc(100dvh - $topNavBarHeight);
	padding-left: 1px;
}

.mobile_post_container {
	display: none;
}

.header_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.post_actions_wrapper {
	display: flex;
	align-items: center;
	gap: 30px;
}

@media screen and (max-width: 973.98px) {
	.post_actions_wrapper {
		gap: 20px;
	}
}

@media screen and (max-width: 747.98px) {
	.fileviewer_container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}

	.mobile_post_container {
		display: block;
	}
}
