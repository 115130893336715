.item_container {
	margin: 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid #dbdfe4;
}

.item_container:last-of-type {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}

.controls_wrapper {
	padding: 10px;
	border-radius: 6px;
	background-color: var(--gray20);
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.left_side {
	display: flex;
	flex-direction: column;
	font-weight: var(--font-weight-semibold);

}

.dates_wrapper {
	font-size: 12px;
	color: #a6b1bc;
	text-transform: uppercase;
}

.role_wrapper {
	font-size: 14px;
	color: var(--gray80);
}

.right_side {
	display: flex;
	gap: 20px;
}

.right_side button img {
	width: 24px;
	height: 24px;
}

.no_border {
	border-bottom: none;
	padding: 0px;
	margin: 0px;
}
