.header {
	font-size: 18px;
	font-weight: var(--font-weight-bold);

	display: flex;
	align-items: center;
	gap: 20px;
	max-width: 60%;

	p {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.container {
	padding: 20px;
	background-color: #fff;
	.project_card {
		width: 100%;
		display: flex;
		gap: 10px;
		margin-top: 20px;

		.img_wrapper {
			width: 263px;
			height: 131px;

			background-color: var(--gray20);
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;

			flex-shrink: 0;
			img {
				border-radius: 8px;
				object-fit: cover;
			}
		}

		.project_details {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 15px;

			.title_wrapper {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				gap: 20px;

				h3.title {
					display: -webkit-box;
					line-clamp: 2;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.project_more_info {
				display: flex;
				flex-direction: column;
				gap: 10px;

				.tags_container {
					display: flex;
					align-items: center;
					gap: 10px;
					flex-wrap: wrap;
				}

				.date_container {
					font-size: 10px;
					p span {
						font-weight: var(--font-weight-semibold);
					}
				}
			}
		}
	}

	.menu {
		margin: 25px 0;

		.menu_button {
			color: #000;
			font-weight: var(--font-weight-semibold);
			font-size: 12px;
		}
	}

	.project_description_wrapper {
		display: flex;
		flex-direction: column;
		gap: 30px;

		.company_stats p {
			font-weight: var(--font-weight-semibold);
		}
	}
}

.title {
	font-size: 16px;
	margin: 0px;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.container {
		.project_card {
			flex-direction: column;

			.img_wrapper {
				width: 100%;
				height: 152px;
			}
		}
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	.container {
		.project_card {
			flex-direction: column;

			.img_wrapper {
				width: 100%;
				height: 152px;
			}
		}
	}
}

@media screen and (max-width: 319px) {
	.container {
		.project_card {
			flex-direction: column;

			.img_wrapper {
				width: 100%;
				height: 152px;
			}
		}
	}
}
