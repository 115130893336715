.form_wrapper {
	padding: 40px;

	.arrow_btn {
		width: fit-content;
		height: 20px;
		display: flex;
		align-items: center;
		img {
			width: 20px;
		}
	}

	h3 {
		margin: 0 0 20px;
		font-size: 18px;
	}

	.description {
		margin-bottom: 20px;
	}

	.submit_btn {
		margin-top: 25px;
		width: 100%;
	}

	.success_info {
		margin-top: 20px;
		color: green;
	}
}
