.container {
	padding: 20px;
}

.info {
	display: flex;
	gap: 20px;

	.board_icon {
		flex-shrink: 0;
		width: 40px;
	}

	p {
		font-weight: var(--font-weight-semibold);
	}
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

@media screen and (max-width: 639px) {
	.button_container {
		.save_btn {
			width: 100%;
		}
	}
}
