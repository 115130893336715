.fullscreen_controls_wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}

.fullscreen_controls {
    width: 112px;
    text-align: center;
    margin: 20px auto 0px;

    border-radius: 18px;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #000;
    padding: 6px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    opacity: 0.3;

    /* animation: fade 0.3s linear; */
}

/* @keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
} */

.fullscreen_controls img {
    cursor: pointer;
}

.option_icon {
    width: 4px;
    transform: rotate(90deg);
}

.fullscreen_exit_icon {
    width: 24px;
}
