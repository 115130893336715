.reply_container {
	min-width: 210px;
	height: 50px;
	border-radius: 10px;
	border: solid 1px var(--gray70);
	margin: 10px;

	display: flex;
	gap: 10px;
	align-items: center;
}

.reply_container.is_replying {
	margin: 10px 0px;
}

.contact_container {
	padding: 0px 10px;
}

.reply_image {
	width: 50px;
	height: 50px;
	flex-shrink: 0;
}

.reply_image img {
	border-radius: 10px;
	border: solid 1px var(--gray70);
	margin-left: -1px;
	object-fit: cover;
}

.reply_image_file {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-around;

	border-radius: 10px;
	border: solid 1px var(--gray70);
	margin-left: -1px;
	flex-shrink: 0;
}

.reply_image_file img {
	width: 25px;
	height: 25px;
}

.reply_details_container {
	text-align: left;
	color: #000;
	padding: 10px 10px 10px 0;
	width: 100%;
	overflow: hidden;
}

.reply_title {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/* max-width: 140px; */
}

.reply_subtitle {
	font-size: 10px;

	display: flex;
	align-items: center;
	gap: 5px;
}

.reply_subtitle img {
	height: 11px;
	width: auto;
}
