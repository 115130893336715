.react-date-picker {
	display: inline-flex;
	position: relative;
	width: 100%;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-date-picker--disabled {
	background-color: #f0f0f0;
	color: #6d6d6d;
}

.react-date-picker__wrapper {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;

	height: 35px;
	border-radius: 5px;
	/* border: solid 1px var(--gray50); */
	outline: solid 1px var(--gray50);
}

.react-date-picker--open .react-date-picker__wrapper {
	outline: #6cc5d1 solid 2px !important;
}

.react-date-picker.error .react-date-picker__wrapper {
	outline: 2px solid red;
}

.react-date-picker__inputGroup {
	/* min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2); */
	min-width: fit-content;
	flex-grow: 1;
	/* padding: 0 2px; */
	padding: 7px;
	box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
	padding: 1px 0;
	white-space: pre;
}
.react-date-picker__inputGroup__input {
	min-width: 0.54em;
	height: 100%;
	position: relative;
	padding: 0 1px;
	border: 0;
	background: none;
	font: inherit;
	box-sizing: content-box;
	-moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
	background: none;
	/* background: rgba(255, 0, 0, 0.1); */
}
.react-date-picker__inputGroup__input--hasLeadingZero {
	margin-left: -0.54em;
	padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
	border: 0;
	background: transparent;
	padding: 4px 6px;
}
.react-date-picker__button:enabled {
	cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
	stroke: var(--gaawk);
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
	stroke: #6d6d6d;
}
.react-date-picker__button svg {
	display: inherit;
}
.react-date-picker__calendar {
	width: 350px;
	max-width: 100vw;
	z-index: 1;
	top: 40px !important;
}
.react-date-picker__calendar--closed {
	display: none;
}
.react-date-picker__calendar .react-calendar {
	border-width: thin;
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.form-end-date .react-date-picker__calendar {
		left: -112px !important;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.form-start-date .react-date-picker__calendar {
		left: -25px !important;
	}

	.form-end-date .react-date-picker__calendar {
		left: -185px !important;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.form-end-date .react-date-picker__calendar {
		left: -112px !important;
	}
}

@media screen and (max-width: 639.98px) {
	.form-start-date .react-date-picker__calendar {
		left: -25px !important;
	}

	.form-end-date .react-date-picker__calendar {
		left: -185px !important;
	}
}
