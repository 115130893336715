@use "variables" as *;

/*.empty_container {*/
/*    width: 100%;*/
/*    height: calc(100dvh - 70px);*/

/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    justify-content: center;*/
/*    align-content: center;*/
/*}*/

/*.empty_container p {*/
/*    font-weight: var(--font-weight-semibold);*/
/*    font-size: 35px;*/
/*    font-stretch: normal;*/
/*    font-style: normal;*/
/*    line-height: normal;*/
/*    letter-spacing: normal;*/
/*    text-align: center;*/
/*    color: #ff3ae5;*/
/*}*/

.msg_loading_spinner {
	background-color: transparent;
}

.loading_container {
	background-color: rgba(255, 255, 255, 0.8);

	width: 100%;
	height: 100%;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* .media_container {
    position: absolute;
    top: 5px;
    right: 0;
    left: 0;
    bottom: 0;

    height: calc(100dvh - 60px - 60px);

    background-color: var(--gray20);

    z-index: 9000;
} */

.media_wrapper {
	width: 100%;
	height: 100%;
	position: relative;
}

.container {
	/* width: 100%;
    height: fit-content; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: absolute;
	right: 0;
	left: 0;
	top: 65px; /* which is the height of <ConvoHeader /> */
	height: calc(100% - 65px);
}

.container.info_opened {
	width: 1200px;
	left: 50%;
	transform: translate(-50%);
}

.wrapper {
	gap: 15px;
	overflow: auto;
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
}

.wrapper > div:first-child {
	margin-bottom: 20px;
}

.wrapper > div:nth-last-child(2) {
	margin-top: 20px;
}

.msg_wrapper_focused {
	/* background-color: red; */
	-webkit-animation: replyMessageFocused 1.3s ease-in-out;
	-moz-animation: replyMessageFocused 1.3s ease-in-out;
	-o-animation: replyMessageFocused 1.3s ease-in-out;
	animation: replyMessageFocused 1.3s ease-in-out;
}

@keyframes replyMessageFocused {
	0% {
		background-color: rgba(166, 177, 188, 0);
	}

	40% {
		background-color: rgba(166, 177, 188, 0);
	}

	50% {
		background-color: rgba(166, 177, 188, 0.7);
	}

	100% {
		background-color: rgba(166, 177, 188, 0);
	}
}

.start_message_container {
	width: 100%;
	height: 20px;
	margin-bottom: 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.start_message_wrapper {
	width: 250px;
	height: 100%;

	background-color: var(--gaawk);
	border-radius: 5px;
}

.gaawkchat_logo_container {
	width: 100%;
	height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	position: relative;
}

.gaawkchat_logo_container img {
	width: 190px;
	height: auto;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

/* MESSAGE INFO MODAL */

.messageInfo_container {
	width: 100%;
	height: fit-content;
	padding-bottom: 40px;
}

.messageInfo_top {
	padding: 20px 20px 0px;
}

.messageInfo_content {
	margin: 30px 0 10px 0;
	display: flex;
	justify-content: flex-end;
}

.messageInfo_status {
	font-weight: var(--font-weight-semibold);
	margin-top: 30px;
}

.gray_container {
	background-color: #dbdfe4;
	/* min-height: 50px; */
	height: fit-content;
	padding: 10px 20px;
}

.messageInfo_status_wrapper {
	display: flex;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray80);
}

.messageInfo_status_wrapper:first-child {
	padding-top: 0px;
}
.messageInfo_status_wrapper:last-child {
	border-bottom: none;
	padding-bottom: 0px;
}

.loadingContainer {
	padding: 20px 20px 0px;
}

.messageInfo_status_content {
	flex-grow: 1;
}

.messageInfo_status_content img {
	width: auto;
	height: 10px;
	margin-right: 10px;
}

.messageInfo_status_time {
	font-size: 12px;
	/* display: flex;
    align-items: center; */
}

.group_header_status {
	padding: 0 20px;
	margin: 10px 0px;
}

.group_header_status img {
	width: 25px;
	height: auto;
	margin-right: 10px;
}

/* reply feature css */

/* .reply_container {
    width: 100%;
    padding: 0 10px;
    position: sticky;
    bottom: 0;
} */

/*2000*/
@media screen and (min-width: 2000px) {
	.content_wrapper {
		width: 1200px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.content_wrapper {
		width: 1200px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.container.info_opened {
		width: 992px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.container.info_opened {
		width: 768px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.container.info_opened {
		width: 576px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.container.info_opened {
		width: 360px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.container.info_opened {
		width: 620px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.container.info_opened {
		width: 360px;
	}
}

@media screen and (max-width: 359px) {
	.container.info_opened {
		width: 100%;
	}
}
