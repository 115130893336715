.item {
	width: 96px;
	height: fit-content;
	border-radius: 12px;
	position: relative;
}

.item_icon {
	width: 96px;
	height: 96px;

	border-radius: inherit;

	background-color: var(--gray20);

	/* border: solid 1px var(--gray70); */

	position: relative;
}

.item_icon.active {
	/* border: solid 1px var(--gaawk); */
	outline: solid 2px var(--gaawk);
	border-radius: 12px;
}

.item_list.active {
	/* border: solid 1px var(--gaawk); */
	outline: solid 2px var(--gaawk);
	border-radius: 6px;
}

.small_icon {
	width: 36px;
	height: auto;

	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	/* cursor: pointer; */
}

.item_icon.gaawkFolder {
	display: flex;
	justify-content: center;
	align-items: center;
}

.gaawkfolder_icon_container {
	display: flex;
	gap: 7px;
	align-items: center;
	cursor: pointer;
}

.gaawkfolder_icon_container img {
	height: 29px;
	width: auto;
}

.media_wrapper .file_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 12px;
	cursor: pointer;
}

.item_name {
	margin-top: 10px;

	width: 100%;
	height: fit-content;
	max-height: 30px;

	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 14px;
	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.item_name p {
	text-overflow: ellipsis;
	overflow-wrap: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.item_type {
	/* width: 100%; */
	/* height: 17px; */
	font-weight: var(--font-weight-extralight);
	font-size: 12px;
	/* font-weight: 300; */
	/* font-stretch: normal; */
	/* font-style: normal; */
	/* line-height: 13px; */
	/* letter-spacing: normal; */
	/* text-align: left; */
	color: var(--gray80);

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
}

.options {
	width: 25px;
	height: 25px;

	position: absolute;
	top: 4px;
	right: 8px;
	z-index: 20;
}

.options img {
	width: 17px;
	height: 4px;
}

.tickbox {
	position: absolute;
	top: 6px;
	left: 5px;
	z-index: 100;
}

.tickbox_container {
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.share {
	width: 16px;
	height: 14px;

	position: absolute;
	bottom: 8px;
	right: 8px;
	cursor: pointer;
	z-index: 10;
}

.share img {
	cursor: inherit;
}

.download {
	width: 13px;
	height: 15px;

	position: absolute;
	bottom: 8px;
	right: 32px;
	cursor: pointer;
	z-index: 10;
}

.star {
	width: 16px;
	height: 15px;

	position: absolute;
	bottom: 8px;
	left: 8px;

	z-index: 10;
}

.modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;

	padding: 20px 40px;
}

.option_icon {
	margin-left: 4px;
}

/* LIST VERSION */

.item_list {
	width: 100%;
	height: 56px;
	/* margin-top: 6px; */
	border-radius: 6px;
	/* border: solid 1px var(--gray70); */
	background-color: var(--gray20);
	padding: 6px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.item_list:first-child {
	margin-top: 0px;
}

.item_name_list p {
	font-weight: var(--font-weight-semibold);
	max-width: 950px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.item_type_list {
	font-weight: 300;
}

.left_side {
	display: flex;
	align-items: center;
}

.icon_wrapper {
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	/* margin-left: -1px; */
}

.folder_wrapper,
.document_wrapper,
.media_wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	/* margin-left: -1px; */
}

.play_icon {
	position: absolute;
	width: 40px;
	z-index: 2;
}

.folder_wrapper img,
.document_wrapper img {
	width: 30px;
	height: auto;
	border: none;
	border-radius: 0;
	cursor: pointer;
}

.media_wrapper img {
	border-radius: 6px;
	object-fit: cover;
	cursor: pointer;
}

.item_details {
	/* margin-left: 40px; */

	font-size: 12px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	line-height: 1.4;
	color: #000;
}

.item_buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;

	gap: 20px;
	margin-right: 15px;
}

.item_buttons button {
	/* margin-left: 20px; */
	display: flex;
	align-items: center;
	width: 20px;
	height: 20px;
}

.item_buttons button img {
	width: 100%;
	height: auto;
}

/* .item_buttons button:first-child {
	margin-left: 0px;
} */

.item_list .gaawkfolder_icon_container img {
	height: 15px;
	width: auto;
}

.options {
	position: absolute;
	top: 4px;
	right: 8px;
	z-index: 20;
}

.options img {
	width: 17px;
	height: 4px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .item {
		width: 98px;
	} */

	/* .item_list:first-child {
		margin-top: 30px;
	} */

	.item_name_list p {
		max-width: 750px;
	}

	/* .item_icon {
		height: 98px;
		width: 98px;
	} */

	.item_name {
		margin-top: 7px;
	}

	.gaawkfolder_icon_container {
		gap: 5px;
	}

	.gaawkfolder_icon_container img {
		height: 23px;
	}
	/* .small_icon {
		width: 45px;
	} */

	.options {
		top: 2px;
		right: 6px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .item {
		width: 72px;
	} */

	/* .item_icon {
		height: 72px;
		width: 72px;
	} */

	.item_name {
		margin-top: 5px;
	}

	.item_name_list p {
		max-width: 530px;
	}

	.options {
		top: -2px;
		right: 5px;
	}

	.share {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 5px;
	}

	.download {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 24px;
	}

	.star {
		width: 12px;
		height: 12px;

		bottom: 10px;
		left: 5px;
	}

	.gaawkfolder_icon_container {
		gap: 4px;
	}

	.gaawkfolder_icon_container img {
		height: 18px;
	}

	/* .small_icon {
		width: 26px;
	} */
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .item {
		width: 72px;
	} */

	/* .item_icon {
		height: 72px;
		width: 72px;
	} */

	.item_name {
		margin-top: 5px;
	}

	.item_name_list p {
		max-width: 330px;
	}

	.options {
		top: -2px;
		right: 3px;
	}

	.share {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 5px;
	}

	.download {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 24px;
	}

	.star {
		width: 12px;
		height: 12px;

		bottom: 10px;
		left: 5px;
	}

	.gaawkfolder_icon_container {
		gap: 4px;
	}

	.gaawkfolder_icon_container img {
		height: 18px;
	}
	/* .small_icon {
		width: 26px;
	} */
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .item {
		width: 96px;
	} */

	/* .item_list:first-child {
		margin-top: 25px;
	} */

	.item_name_list p {
		max-width: 190px;
	}

	.item_buttons .quick_action {
		display: none;
	}

	/* .star_list,
	.download_list,
	.share_list {
		display: none;
	} */

	/* .item_icon {
		height: 96px;
		width: 96px;
	} */

	.item_name {
		margin-top: 9px;
	}

	.options {
		top: -2px;
		right: 5px;
	}

	.share {
		width: 13px;
		height: 12px;

		bottom: 10px;
		right: 5px;
	}

	.download {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 24px;
	}

	.star {
		width: 12px;
		height: 12px;

		bottom: 10px;
		left: 5px;
	}

	.gaawkfolder_icon_container {
		gap: 5px;
	}

	.gaawkfolder_icon_container img {
		height: 23px;
	}
	/* .small_icon {
		width: 45px;
	} */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	/* .item {
		width: 72px;
	} */

	.item_name_list p {
		max-width: 390px;
	}

	/* .item_icon {
		height: 72px;
		width: 72px;
	} */

	.item_name {
		margin-top: 5px;
		/*height: 30px;*/
	}

	.options {
		top: -2px;
		right: 3px;
	}

	.share {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 5px;
	}

	.download {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 24px;
	}

	.star {
		width: 12px;
		height: 12px;

		bottom: 10px;
		left: 5px;
	}

	.gaawkfolder_icon_container {
		gap: 4px;
	}

	.gaawkfolder_icon_container img {
		height: 18px;
	}
	/* .small_icon {
		width: 26px;
	} */
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .item {
		width: 96px;
	} */

	/* .item_list:first-child {
		margin-top: 25px;
	} */

	.item_name_list p {
		max-width: 190px;
	}

	.item_buttons .quick_action {
		display: none;
	}

	/* .item_icon {
		height: 96px;
		width: 96px;
	} */

	.item_name {
		margin-top: 9px;
	}

	.options {
		top: -2px;
		right: 3px;
	}

	.share {
		width: 13px;
		height: 12px;

		bottom: 10px;
		right: 5px;
	}

	.download {
		width: 13px;
		height: 11px;

		bottom: 10px;
		right: 24px;
	}

	.star {
		width: 12px;
		height: 12px;

		bottom: 10px;
		left: 5px;
	}
	.gaawkfolder_icon_container {
		gap: 5px;
	}

	.gaawkfolder_icon_container img {
		height: 23px;
	}
	/* .small_icon {
		width: 45px;
	} */
}
