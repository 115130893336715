.wrapper {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
}

.container {
	width: 100%;
	padding: 20px;
	background-color: #ffffff;
}

.modal_container {
	padding: 20px;
}

.form_input_wrapper,
.react_select_wrapper {
	margin-top: 15px;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper input {
	height: 35px;
	cursor: text;
}

.form_input_wrapper label,
.react_select_wrapper label,
.custom_upload_wrapper label {
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: inline-block;
	margin-bottom: 7px;
}
.form_input_wrapper p.error_message,
.custom_upload_wrapper p.error_message,
.react_select_wrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

/* CUSTOM UPLOADER */

.custom_upload_wrapper {
	margin-top: 15px;
}

.info_text {
	object-fit: contain;

	font-size: 12px;
	font-style: italic;
	color: var(--gray80);
	margin-top: 5px;
	display: inline-block;
}

.img_option button {
	position: relative;

	width: 100%;
	height: 35px;
	background-color: var(--light-gaawk);
	border-radius: 5px;

	font-weight: var(--font-weight-bold);
	font-size: 14px;

	text-align: center;
	color: var(--gaawk);
	margin-top: 10px;
}

.img_option button.error {
	border: 2px solid red;
}

.custom_upload_wrapper input[type="file"] {
	opacity: 0;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 100%;

	cursor: inherit;
}

.thumbs_container {
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0px;

	width: 100%;
	gap: 20px;
}

.thumb {
	display: inline-flex;
	border-radius: 5px;
	width: 80px;
	height: 80px;

	margin-right: 10px;

	background-color: var(--gray20);

	position: relative;
}

.thumb_inner {
	display: flex;
	justify-content: center;
	align-items: center;
	/* min-width: 0; */
	overflow: hidden;
	width: 100%;
	border-radius: 5px;

	position: relative;
}

.thumb_inner .close {
	position: absolute;
	top: 4px;
	right: 4px;

	width: 24px;
	height: 24px;
}

.thumb_inner img {
	display: block;

	object-fit: cover;
}

/* END OF CUSTOM UPLOADER */

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.separator {
	width: 100%;
	height: 6px;
	background-color: var(--gray20);
}

.link {
	color: var(--gaawk);
}

.link:hover {
	cursor: pointer;
	text-decoration: underline;
}

.radio_item {
	margin-bottom: 20px;
}

.label_title {
	margin: 0 0 5px;
	font-weight: var(--font-weight-semibold);
}

.label_text {
	font-style: italic;
}

.container > .label_title {
	margin-bottom: 20px;
}

@media screen and (max-width: 639px) {
	.save_btn {
		width: 100%;
	}
}
