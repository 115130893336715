.outer_wrapper,
.container {
	position: relative;
}

.option_wrapper {
	position: absolute;
	top: 5px;
	right: 10px;
	z-index: 1;
	transform: rotate(90deg);
}

.base_container {
	background-color: var(--gray20);
}

.item_container {
	width: 150px;
	height: 150px;
	border-radius: 12px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 15px;
}

.item_container img:first-of-type:not(.file_icon) {
	object-fit: cover;
	border-radius: 12px;
}

.border {
	border: solid 1px var(--gray70);
}

.relative {
	position: relative;
}

.play_icon {
	position: absolute;
	z-index: 99;
	width: 66px;
	height: auto;
}

.file_icon {
	width: 66px;
	height: auto;
}

.file_name {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	text-align: center;
	color: #000;

	max-width: 80%;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: anywhere;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.option_white_style {
	width: 14px;
	height: 14px;
	rotate: 90deg;
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.item_container {
		width: 130px;
		height: 130px;
	}
}
