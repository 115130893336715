.container {
	position: relative;
}

.image_container {
	width: 320px;
	height: 180px;
	background-color: #dbdfe4;
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.image_container.photo img {
	object-fit: cover;
	border-radius: 8px;
}

.video_container {
	position: relative;
	width: 100%;
	height: 100%;
}

.video_container .icon {
	position: absolute;
	width: 72px;
	height: 72px;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.icon_container {
	width: 72px;
	height: 72px;

	margin-bottom: 20px;
}

.filename_container {
	position: absolute;
	bottom: 0;

	width: 100%;
	height: 30px;
	border-radius: 0 0 8px 8px;
	background-color: #a6b1bc;

	font-size: 12px;
	font-weight: var(--font-weight-semibold);
	color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;
}

.remove_button {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 10px;
	right: 10px;
	z-index: 1;
	background-color: var(--gray20);
	border-radius: 50%;
}

.edit_button {
	position: absolute;
	z-index: 1;
	top: 10px;
	left: 10px;
}
