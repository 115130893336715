.container {
	width: calc(100vw - 360px);
	background-color: #000;
	padding: 20px;
	height: 220px;

	display: flex;
	justify-content: center;

	h1 {
		font-weight: var(--font-weight-extralight);
		font-size: 26px;
		text-align: center;
		color: #fff;
	}

	.inner_wrapper {
		// display: flex;
		// flex-direction: column;
		// justify-content: center;
		// align-items: center;
		width: 100%;
		max-width: 580px;
	}
}

.advanced_container {
	margin-top: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		color: #fff;
		&:hover {
			text-decoration: underline;
		}
	}

	.location_button {
		display: flex;
		align-items: center;
		gap: 10px;
		img {
			width: 20px;
		}
	}
}

.find_section {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	text-align: center;

	margin-top: 20px;
	font-weight: var(--font-weight-bold);
	font-size: 12px;
	color: #fff;
	cursor: pointer;

	p:not(:last-of-type) {
		position: relative;
		padding-right: 20px;

		span:after {
			content: "";
			background: var(--gray80);
			position: absolute;
			bottom: 0;
			right: 0;
			height: 100%;
			width: 1px;
		}
	}
}

// .banner {
// 	background-image: url("images/jobs-banner.webp");
// 	height: 340px;
// 	background-size: cover;
// 	background-position: center;

// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// }

// .search_wrapper {
// 	width: 504px;
// 	height: 200px;
// 	background-color: rgba(55, 59, 67, 0.8);
// 	border-radius: 12px;
// 	padding: 20px;
// }

// .header {
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	margin-bottom: 20px;
// }

.header img {
	width: 137px;
	height: auto;
}

.header span {
	font-size: 12px;
	font-weight: var(--font-weight-semibold);
	text-align: left;
	color: #fff;
	cursor: pointer;
}

.header span:hover {
	text-decoration: underline;
}

// .input_wrapper {
// 	position: relative;
// }

// .search_icon {
// 	width: 19px;
// 	height: 19px;

// 	margin: auto;
// 	position: absolute;
// 	top: 0;
// 	left: 10px;
// 	bottom: 0;
// }

.input_wrapper input {
	width: 100%;
	height: 35px;
	border-radius: 5px;
	opacity: 1;
	padding-left: 40px;
}

.input_wrapper input:focus {
	outline: 2px solid #6cc5d1;
	outline-offset: -1px;
}

// .close_icon {
// 	width: 14px;
// 	height: 14px;

// 	position: absolute;
// 	margin: auto;
// 	top: 0;
// 	bottom: 0;
// 	right: 10px;
// 	cursor: pointer;
// }

// .separator {
// 	width: 1px;
// 	height: 25px;
// 	background-color: #a6b1bc;

// 	position: absolute;
// 	margin: auto;
// 	top: 0;
// 	bottom: 0;
// 	right: 33px;
// }

// .location_input {
// 	display: flex;
// 	align-items: center;
// 	gap: 10px;
// }

// .location_input > div {
// 	width: 100%;
// }

// .search_button {
// 	width: 80px;
// 	height: 35px;
// 	padding: 0px 10px;
// 	font-size: 14px;
// }

.job_modal_container {
	padding: 20px;

	font-size: 14px;
	color: #000;
}

.job_modal_container:nth-child(2) {
	padding-top: 0;
}

// .btn_container {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// 	gap: 20px;
// 	padding-bottom: 20px;
// }

// .btn {
// 	height: 35px;
// 	font-size: 14px;
// }

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	// .banner {
	// 	height: 328px;
	// }
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	// .banner {
	// 	height: 260px;
	// }
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	// .banner {
	// 	height: 258px;
	// }
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	// .banner {
	// 	height: 300px;
	// }
}

@media screen and (max-width: 639.98px) {
	// .banner {
	// 	height: 258px;
	// }
}

@media screen and (max-width: 747px) {
	.container {
		width: 100vw;
	}
	// .banner {
	// 	height: 258px;
	// }
}
