.container {
	padding: 20px;
}

.label_title {
	margin: 0 0 5px;
	font-weight: var(--font-weight-semibold);
}

.label_text {
	font-size: 12px;
	color: #373b43;
}

.radio_item {
	margin-bottom: 10px;
}

.reasons_wrapper {
	margin: 20px 0;
}

.reason_input {
	margin-top: 0;
}

.button {
	width: 100%;
	margin-top: 20px;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

.file_wrapper {
	margin-top: 20px;
	& > div {
		width: 100%;
	}
}

.success_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	h3 {
		font-size: 18px;
		font-weight: var(--font-weight-bold);
	}
}

.success_icon {
	width: 46px;
	height: auto;
}
