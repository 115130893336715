.container {
}

.notification_prompt {
	display: flex;
	gap: 30px;
	.button_container {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-top: 20px;
		& > button {
			width: 100%;
		}
	}

	.logo_container {
		display: flex;
		align-items: center;
	}
}

.gaawk_modal_backdrop {
	opacity: 0.8 !important;
	z-index: 1900;
}

.gaawk_modal_backdrop.modal_on_top {
	z-index: 1900;
	opacity: 0.8 !important;
}

.gaawk_modal_dialog {
	height: fit-content;
	border: none !important;
	margin: 0 auto;
	z-index: 2000;
	width: 450px;
	top: 55px;
}

.gaawk_modal_dialog.default {
	width: 300px !important;
	/* margin-top: 185px !important; */
	margin: 185px auto;
}

.gaawk_modal_dialog.default.sm {
	width: 250px !important;
}

.gaawk_modal_dialog.black {
	width: 420px !important;
	/* margin-top: 90px !important; */
	/* margin: 90px auto; */
}
.gaawk_modal_dialog_fullwidth {
	width: 740px !important;
	/* margin-top: 90px !important; */
	margin: 90px auto;
}

.gaawk_modal_content {
	border-radius: 16px;
	border: none !important;
	padding: 20px;
	justify-content: center !important;
	justify-items: center !important;

	// height: 280px;
}

/*
Header
 */

.gaawk_modal_header {
	height: 56px;
	border-radius: 10px 10px 0 0;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	/*align-content: center !important;*/
	/*align-items: center !important;*/

	/*padding: 0 1rem 0 0 !important;*/
}

/*.gaawk-modal-header.default {*/
/*    background-color: var(--gaawk);;*/
/*}*/

.gaawk_modal_header.black {
	background-color: #000000;
}

.gaawk_modal_header.black .gaawk_modal_header_title {
	padding-right: 4rem;
}

.gaawk_modal_header_title {
	flex: 1;

	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #fff;

	padding-left: 2rem;
}

.gaawk_modal_header button.close {
	width: 14px;
	height: 14px;

	margin-bottom: 5px;
}

.gaawk_modal_header .close_x:after {
	content: "×";
	/*font-size: 16px;*/
	/*width: 16px;*/

	/*width: 1em;*/
	/*height: 1em;*/

	width: 20px;
	height: 100%;

	font-size: 30px;
	font-weight: 900;
}

/*
body
 */

.gaawk_modal_body {
	padding: 0 !important;
	border-radius: 16px;
}

.hide {
	display: none !important;
}
@media screen and (max-width: 720px) {
	.gaawk_modal_dialog_fullwidth {
		width: 100% !important;
		/* margin-top: 90px !important; */
		margin: 90px auto;
	}
}
/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.gaawk_modal_dialog {
		margin: 30px auto !important;
	}

	/*!*this is for chat !!*!*/
	/*.gaawk_modal_dialog.black {*/
	/*    width: 100% !important;*/
	/*    max-width: 100% !important;*/
	/*    margin: 0 !important;*/
	/*}*/
	/*.gaawk_modal_dialog.black .gaawk_modal_content {*/
	/*    border-radius: 0 !important;*/
	/*}*/
	/*.gaawk_modal_dialog.black .gaawk_modal_header {*/
	/*    justify-content: flex-start !important;*/
	/*    border-radius: 0 !important;*/
	/*}*/
	/*.gaawk_modal_header.black .close_x:after {*/
	/*    content: '←';*/
	/*}*/
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.gaawk_modal_dialog.black {
		width: 340px !important;
		/* width: 360px !important; */
		/* margin-top: 30px !important; */
		margin: 30px auto;
	}

	.gaawk_modal_dialog {
		/*width: 300px !important;*/
		margin: 30px auto;
	}

	/*.gaawk_modal_dialog {*/
	/*    margin: 1.75rem auto;*/
	/*}*/
	/*.gaawk_modal_dialog.black {*/
	/*    !*width: 100% !important;*!*/
	/*    !*max-width: 100% !important;*!*/
	/*    !*margin: 0 !important;*!*/
	/*    min-width: 360px !important;*/
	/*    overflow-x: auto !important;*/
	/*}*/
	/*.gaawk_modal_dialog.black .gaawk_modal_content {*/
	/*    border-radius: 0 !important;*/
	/*}*/
	/*.gaawk_modal_dialog.black .gaawk_modal_header {*/
	/*    justify-content: flex-start !important;*/
	/*    border-radius: 0 !important;*/
	/*}*/
	/*.gaawk_modal_header.black .close_x:after {*/
	/*    content: '←';*/
	/*}*/
}
