.container {
	background-color: var(--gray20);
	border-radius: 12px;
	padding: 15px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;

	.left_side {
		display: flex;
		align-items: center;
		gap: 10px;
		overflow: hidden;

		.details {
			text-align: left;
			overflow: hidden;
		}
	}

	.delete_btn {
		flex-shrink: 0;
	}

	h4 {
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}

	span {
		font-size: 12px;
	}

	.icon {
		width: 28px;
	}
}

.container.shared {
	background-color: #fff;
}
