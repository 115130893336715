.container {
	padding: 20px;

	h3 {
		margin: 0 0 15px;
	}
}

.search_input_wrapper {
	padding: 20px 20px 0;
	// background-color: #dbdfe4;
}

.items_wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	gap: 50px;
	row-gap: 20px;

	text-align: center;
	background-color: #fff;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper {
		gap: 50px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper {
		gap: 42px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		gap: 43px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		gap: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		gap: 65px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.items_wrapper {
		gap: 20px;
	}
}

@media screen and (max-width: 367px) {
	.items_wrapper {
		grid-template-columns: repeat(auto-fill, 150px);
		gap: 5px;
		justify-content: space-between;
	}
}
