@use "../../shared.module.scss";

.dialCode_input {
	margin: 0;
}

.contact_section {
	width: 100%;
	height: fit-content;
}

/*
item from EducationItem.jsx
 */

.item_container {
	width: 100%;
	overflow: hidden;

	padding: 20px 0px;

	border-bottom: var(--gray30) 1px solid;
}

.item_container:last-child {
	border-bottom: none;
}

.description {
	display: flex;
	align-items: flex-start;
	gap: 10px;
}

.contact_icon {
	width: 24px;
	height: 24px;
	flex-shrink: 0;

	/* margin-right: 10px; */
}

.phone_header {
	display: flex;
	align-items: center;
	gap: 10px;
}

.phone_header p {
	font-size: 16px;
	color: #000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.visibility {
	display: flex;
	/* align-items: flex-start; */
	flex-shrink: 0;
	gap: 5px;
	/* height: 19px; */
}
/*
.visibility img {
	margin-left: 5px;
	height: 19px;
} */

.phone_wrapper {
	width: 100%;
	display: flex;
	align-items: flex-start;
	gap: 20px;
}

.phonenumber_wrapper {
	font-size: 16px;
	margin-top: 6px;
}

.phonenumber_wrapper a {
	color: var(--gaawk);
}

.phone_dial {
	width: 45%;
	display: flex;
	flex-direction: column;
}

#phone_number {
	width: 100%;
	height: 35px;

	border-radius: 5px;
	border: solid 1px var(--gray50);
	padding: 7px;

	font-size: 14px;
}

#phone_number:focus {
	border: #6cc5d1 solid 2px;
}

.phone_wrapper img {
	width: 17px;
	margin-right: 5px;
}

/*
Add From
 */

.visibility_wrapper {
	margin-top: 15px;
	width: 20%;
	height: fit-content;
}

.visibility_label {
	display: inline-block;
	margin-bottom: 7px;
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.add_form {
	width: 100%;
	height: fit-content;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;

	position: relative;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper input[type="text"] {
	width: 100%;
	height: 35px;

	border-radius: 5px;
	padding: 7px;

	font-size: 14px;
}

/* .form_input_wrapper input[type="text"]:focus {
    border: #6cc5d1 solid 2px;
} */

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	margin-bottom: 7px;
	display: block;
}

.description_wrapper {
	width: 100%;
	height: 35px;
	margin: 7px 0 0;
	border: solid 1px var(--gray50);
	border-radius: 5px;
}

.proficiency_wrapper {
	margin-top: 7px;

	width: 100%;
	height: 35px;
	/*background-color: #1a8897;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.proficiency_wrapper div {
	flex: 1;
	height: 100%;
	border-radius: 5px;
	margin-right: 3px;
	background-color: var(--gray50);

	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;

	cursor: pointer;

	font-weight: var(--font-weight-semibold);
	font-size: 16px;

	text-align: center;
	color: #ffffff;
}

.proficiency_wrapper div:last-child {
	margin-right: 0;
}

.proficiency_wrapper div.selected {
	background-color: #067c8d;
}

.react_select_wrapper {
	margin-top: 15px;

	width: 100%;
	height: fit-content;
}

.react_select_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	display: inline-block;
	margin-bottom: 7px;
}

/* .dropdown_item {
  width: 190px;
  height: 35px;
  margin-right: 10px;
} */

.actively_looking_wrapper {
	margin-top: 15px;

	width: 100%;
	height: 35px;

	position: relative;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.actively_looking_wrapper label {
	padding-top: 2px;
	height: 19px;

	font-size: 14px;
}

/* .phone_controls {
	display: flex;
	column-gap: 20px;
}

.phone_controls img {
	height: 24px;
	cursor: pointer;
} */

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.react_select_wrapper p.error_message,
.form_input_wrapper p.error_message {
	/* margin-left: auto; */
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

/*2000*/
@media screen and (min-width: 2000px) {
	/* .delete_button {
        margin-right: 40px;
    } */
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	/* .delete_button {
        margin-right: 40px;
    }

    .delete_button {
        margin-right: 40px;
    } */
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .container {
        padding: 20px 20px 30px;
    } */

	.dropdown_item {
		margin-right: 20px;
	}

	/* .delete_button {
        margin-right: 30px;
    } */
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .container {
        margin-top: 15px;
        padding: 20px 20px 30px;
    } */

	/* .item_container {
        padding: 0 15px;
    } */

	.visibility_wrapper {
		width: 25%;
	}

	/* .delete_button {
        margin-right: 30px;
    } */
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .container {
        margin-top: 15px;
        padding: 20px 15px 30px;
    } */
	/* .item_container {
        padding: 0 15px;
    } */

	.visibility_wrapper {
		width: 35%;
	}

	/* .delete_button {
        margin-right: 30px;
    } */
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .container {
        margin-top: 10px;
        padding: 10px 10px 40px;
    } */

	/* .header button {
        width: 24px;
        height: 24px;

        justify-content: center;
    }

    .header label {
        height: 20px;
        font-size: 15px;
    }

    .header button label {
        display: none !important;
    } */

	.item_container {
		/* padding: 0 10px 0 10px; */
		/* height: 93px; */

		align-content: flex-start;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.visibility_wrapper {
		width: 100%;
	}

	/* .description {
        font-size: 14px;
    } */

	.visibility {
		font-size: 13px;
	}

	.dropdown_item {
		width: 145px;
	}

	/* .save_button_wrapper {
        justify-content: space-between;
    } */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	/* .container {
        margin-top: 15px;
        padding: 22px 20px 30px;
    } */
	/* .item_container {
        padding: 0 20px 0 10px;
    } */

	/* .header label {
        height: 20px;
        font-size: 15px;
    } */

	.visibility_wrapper {
		width: 35%;
	}
	/* .description {
        font-size: 14px;
    } */

	.visibility {
		font-size: 13px;
	}

	/* .save_button_wrapper {
        justify-content: space-between;
    } */
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .container {
        margin-top: 10px;
        padding: 10px 10px 40px;
    } */

	/* .header button {
        width: 24px;
        height: 24px;

        justify-content: center;
    }

    .header button label {
        display: none !important;
    }

    .header label {
        height: 20px;
        font-size: 15px;
    } */

	.item_container {
		/* padding: 0 10px 0 10px; */
		/* height: 93px; */

		align-content: flex-start;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.visibility_wrapper {
		width: 100%;
	}

	/* .description {
        font-size: 14px;
    } */

	.visibility {
		font-size: 13px;
	}

	.dropdown_item {
		width: 145px;
	}

	.button_container button {
		width: 100%;
	}

	/* .save_button_wrapper {
        justify-content: space-between;
    } */
}
