.container {
	padding: 20px;

	.info_text {
		margin-bottom: 10px;
		font-style: italic;
		font-size: 12px;
	}

	.items_wrapper {
		margin-top: 20px;
		overflow: auto;
		max-height: 500px;
	}

	.contact_item {
		padding: 5px 10px 10px 0;
	}
}
