.items_container {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.directMessageBtn {
	font-size: 10px;
	color: var(--gaawk);
	flex-shrink: 0;
}

.section_container {
	margin-top: 6px;
	min-height: fit-content;
	padding: 15px;

	&.no_padding {
		padding: 0;
	}
}
