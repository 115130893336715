.container {
	padding-bottom: 20px;
	border-bottom: 2px solid var(--gray20);
	margin-top: 20px;
}

.container:last-child {
	border-bottom: none;
}

.container h4 {
	margin-top: 0px;
}

.items_container {
	width: 100%;
	height: fit-content;
	margin-top: 15px;

	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}
