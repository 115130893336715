.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
}

.swiper-slide img.other-icon {
	width: 100px;
}

.swiper-slide .other-file {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	width: 140px;
}

.swiper-slide .other-file p {
	font-size: 14px;
}

.swiper-slide img.file-icon {
	width: 50px;
	height: auto;
	position: absolute;
	border-radius: 0 !important;
}

.thumb_wrapper {
	width: 100% !important;
	height: 100% !important;
	background-color: transparent !important;
}

.container_wrapper {
	width: 100% !important;
	height: 100% !important;
	border-radius: 5px !important;
}

.swiper-delete-btn {
	position: absolute;
	z-index: 9999;
	top: 0px;
	right: 0px;
	opacity: 1 !important;
}

.noRadius {
	border-radius: 0 !important;
}

.swiper {
	width: 100%;
	height: 300px;
	margin-left: auto;
	margin-right: auto;
}

.swiper-slide {
	background-size: cover;
	background-position: center;
}

.mySwiper2 {
	/* height: calc(100% - 136px); */
	width: 100%;
}

/* .mySwiper2.fullHeight {
    height: 100%;
} */

.mySwiper {
	height: 100%;
	box-sizing: border-box;
	padding-bottom: 20px;
}

.mySwiper2 .swiper-slide {
	background-color: #000;
}
.mySwiper .swiper-slide {
	/* width: 25%; */
	width: 96px !important;

	height: 100%;
	opacity: 0.4;
	border-radius: 5px;
}

/* .mySwiper .swiper-slide img {
    object-fit: cover;
    border-radius: 12px;
} */

.mySwiper .swiper-slide-thumb-active {
	opacity: 1;
}
.swiper-wrapper {
	display: flex;
	align-items: center;
}

.thumb_container {
	border-radius: 16px 16px 0 0;
	height: 136px;
	background-color: var(--gray20);
	padding: 10px;
}

/* scrollbar customization */

.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
	left: 0;
	width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
	color: #fff;
}

.loading_container {
	width: 100%;
	height: 100%;
	text-align: center;
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2000;
	background-color: #fff;
	opacity: 0.8;
}

.media_text {

	font-size: 16px;

	text-align: center;
	color: #000;
	padding: 10px;
}

.fullscreen {
	background-color: #fff;
}

/* .fs_disabled {
    height: inherit;
} */

/* .fullscreen_controls_wrapper {
    width: 100%;
    height: 110px;
    position: absolute;
    z-index: 2;
}

.fullscreen_controls {
    width: 112px;
    text-align: center;
    margin: 20px auto 0px;

    border-radius: 18px;
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #000;
    padding: 6px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    animation: fade 0.3s linear;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fullscreen_controls img {
    cursor: pointer;
}

.option_icon {
    width: 4px;
    transform: rotate(90deg);
}

.fullscreen_exit_icon {
    width: 24px;
} */

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .mySwiper2 {
        height: calc(100% - 112px);
    } */
	.mySwiper .swiper-slide {
		width: 72px !important;
	}
	.thumb_container {
		height: 112px;
	}
}

@media screen and (max-width: 639.98px) {
	/* .mySwiper2 {
        height: calc(100% - 112px);
    } */
	.mySwiper .swiper-slide {
		width: 72px !important;
	}
	.thumb_container {
		height: 112px;
	}
}
