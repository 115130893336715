.dropzone {
	width: 100%;
	min-height: 60px;
	padding: 17px;
	display: flex;
	align-items: center;
	gap: 17px;
	cursor: pointer;
	border-radius: 12px;
	border: dashed 2px #a6b1bc;
}

.dropzone p {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: var(--gray80);
}

.dropzone img {
	width: 20px;
	height: auto;
}

img.status_icon {
	width: 20px;
	margin-right: 10px;
}

.rejected_container {
	margin-top: 20px;
	padding: 20px;
	background-color: var(--error100);
	border-radius: 12px;
}

.rejected_container h4 {
	margin: 0 0 10px;
}

.rejected_container ul {
	margin-bottom: 0;
}
