@use "../../shared.module.scss";

.no_margin {
	margin-top: 0;
}

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

/*
item from EducationItem.jsx
 */

.item_container {
	width: 100%;
	overflow: hidden;
	padding: 20px 0;

	border-bottom: var(--gray30) 1px solid;
}

.item_container:last-child {
	border-bottom: none;
}

.description {
	display: flex;
	align-items: flex-start;
}

.item_icon {
	flex: 0 0 30px;
	width: 30px;
	height: 30px;
	margin-right: 10px;
}

.item_icon img {
	width: 24px;
	height: 24px;
}

.item_icon.empty {
	border: dashed 1px #6cc5d1;
	border-radius: 50%;
}

.visibility {
	display: flex;
}

.visibility img {
	margin-left: 10px;
}

.visibility_wrapper {
	margin-top: 15px;
	width: 20%;
	height: fit-content;
}

.visibility_label {
	display: inline-block;
	margin-bottom: 7px;
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.site_header {
	display: flex;
	align-items: center;
}

.site_header p {
	font-size: 16px;
}

.site_wrapper {
	font-size: 16px;
	margin-top: 6px;
}

.site_wrapper a {
	color: var(--gaawk);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

/*
Add From
 */

.add_form {
	width: 100%;
	height: fit-content;
}

.form_input_wrapper {
	margin-top: 15px;

	width: 100%;
	height: fit-content;

	position: relative;
}

.form_input_wrapper input[type="text"] {
	width: 100%;
	height: 35px;
	margin: 7px 0 0;
	border-radius: 5px;
	/* border: solid 1px var(--gray50); */
	padding: 7px;

	font-size: 14px;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.react_select_wrapper {
	margin-top: 15px;

	width: 100%;
	height: fit-content;
}

.react_select_wrapper:first-child {
	margin-top: 0px;
}

.react_select_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: inline-block;
	margin-bottom: 7px !important;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.react_select_wrapper p.error_message,
.form_input_wrapper p.error_message {
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.actively_looking_wrapper {
		margin-top: 0;
		width: fit-content;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.actively_looking_wrapper {
		margin-top: 0;
		width: fit-content;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.dropdown_item {
		margin-right: 20px;
	}

	.actively_looking_wrapper {
		margin-top: 0;
		width: fit-content;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.visibility_wrapper {
		width: 25%;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.visibility_wrapper {
		width: 35%;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.header label {
		height: 20px;
		font-size: 15px;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.description {
		font-size: 14px;
	}

	.visibility {
		font-size: 14px;
	}

	.dropdown_item {
		width: 145px;
	}

	.visibility_wrapper {
		width: 100%;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.header label {
		height: 20px;
		font-size: 15px;
	}

	.description {
		font-size: 14px;
	}

	.visibility {
		font-size: 14px;
	}

	.visibility_wrapper {
		width: 35%;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.header label {
		height: 20px;
		font-size: 15px;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.description {
		font-size: 14px;
	}

	.visibility {
		font-size: 14px;
	}

	.dropdown_item {
		width: 145px;
	}

	.visibility_wrapper {
		width: 100%;
	}

	.button_container button {
		width: 100%;
	}
}
