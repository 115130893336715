.form {
	padding: 40px;

	.arrow_btn {
		width: fit-content;
		height: 20px;
		display: flex;
		align-items: center;
		img {
			width: 20px;
		}
	}

	h2 {
		margin: 20px 0;
		font-size: 18px;
	}

	.description {
		margin: 20px 0 30px 0;
		color: #000;
	}

	.radio_container {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.radio_item {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}

	.next_btn {
		width: 100%;
		margin-top: 20px;
	}

	.hide {
		display: none;
	}

	.error_message {
		text-align: right;
		font-style: italic;
		color: red;
	}
}
