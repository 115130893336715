.container {
	width: 100%;
	overflow: hidden;
}

.title {
	font-weight: var(--font-weight-bold);
	font-size: 18px;
	margin: 0 0 20px 0;
}

.paragraph {
	font-size: 10px;
}

.companies_container {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.companies_container.owned {
	display: grid;
	grid-template-columns: repeat(3, 373px);
	grid-gap: 20px;
}

.button_wrapper {
	display: flex;
	justify-content: center;
	margin: 60px 0 50px;
}

.add_button {
	width: 320px;
}

.company_tagLine,
.company_location {
	width: 100%;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
}

.company_tagLine {
	font-size: 12px;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
	width: 100%;
}

.company_location {
	font-size: 10px;
	color: #a6b1bc;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
	.companies_container.owned {
		grid-template-columns: repeat(2, 466px);
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
	.companies_container.owned {
		grid-template-columns: repeat(2, 354px);
	}
}

@media screen and (max-width: 1165px) {
	.companies_container.owned {
		grid-template-columns: 1fr;
		gap: 10px;
	}
}
