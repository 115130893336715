.album_header {
	background-color: var(--gray20);
	padding: 20px 15px;
}

.title_wrapper {
	font-weight: var(--font-weight-bold);
	font-size: 18px;
	color: #000;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.edit_btn {
	font-size: 14px;
}

.visibility_wrapper {
	display: flex;
}

.title {
	display: flex;
	align-items: center;
	gap: 10px;
}

.album_owner {
	font-size: 10px;
}

.album_owner .owner_url {
	font-size: 10px;
	font-weight: var(--font-weight-semibold);
	color: #000;
}

.counters_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 5px;
}

.counter_item {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--gray70);

	display: flex;
	align-items: center;
	gap: 5px;
}

.counter_item img {
	width: 18px;
	height: 18px;
}

.location_date_wrapper {
	margin-top: 20px;
}

.location_wrapper,
.date_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;

	font-weight: var(--font-weight-semibold);
	font-size: 12px;

	color: #000;
}

.location_wrapper img,
.date_wrapper img {
	width: 13px;
	height: auto;
}

.description_wrapper {
	margin: 20px 0px;

	font-size: 14px;
	color: #000;
}

.people_wrapper {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
}

.people_wrapper > div {
	width: 50%;
}

.people_wrapper > div:not(:only-child):first-child {
	border-right: 1px solid var(--gray70);
	margin-right: 15px;
}

.people_wrapper span {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	margin-bottom: 10px;
	display: block;
}

.tags_container {
	display: flex;
	align-items: center;
	gap: 5px;
	flex-wrap: wrap;
}

.album_wrapper {
	padding: 25px 20px;
	background-color: #fff;
}

.info_wrapper {
	font-size: 14px;
	font-style: italic;
	text-align: center;
	color: #000;
	margin-bottom: 40px;
}

.button_container,
.emptyIcon_wrapper {
	display: flex;
	justify-content: center;
}

.add_button {
	width: 320px;
	margin-bottom: 20px;
}

.edit_button_container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.edit_button {
	background-color: transparent;
	border-radius: 4px;
	border: solid 1px var(--gaawk);

	color: var(--gaawk);
}

.emptyIcon {
	width: 169px;
	height: auto;
	margin-top: 85px;
}

.loading_container {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin: 30px 0;
}

.viewer_header {
	font-weight: var(--font-weight-bold);
	font-size: 18px;
	text-align: left;
	color: #fff;
}

.fullscreen_icon {
	height: 20px;
	width: auto;
}

.people_modal {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 24px;
	height: 24px;
	background-color: #d3d3d3;
	color: #fff;

	border-radius: 100%;

	font-weight: var(--font-weight-bold);
	font-size: 12px;
	text-align: center;
	cursor: pointer;
}

.btn_container {
	margin-top: 20px;
	text-align: center;
}

/* .gallery_container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 52px;
} */

/* @media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.gallery_container {
		gap: 50px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.gallery_container {
		gap: 42px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.gallery_container {
		gap: 43px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.gallery_container {
		gap: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.gallery_container {
		gap: 65px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.gallery_container {
		gap: 20px;
	}
}

@media screen and (max-width: 367px) {
	.gallery_container {
		gap: 12px;
		justify-content: space-between;
	}
} */
