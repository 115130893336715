.group_search_wrapper {
	padding: 12px 20px;
	background-color: #dbdfe3;
}

.add_group_container {
	width: 100%;
	height: fit-content;
	padding: 20px 20px 40px;
}

.contact_list_wrapper {
	height: 463px;
	overflow: auto;
}

.contact_item {
	width: 100%;
	border-bottom: 1px var(--gray30) solid;
	padding: 5px 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.contact_item:last-of-type {
	border-bottom: none;
}

.contact_item:hover {
	background-color: var(--gray30);
	cursor: pointer;
}
