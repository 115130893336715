.multiselect_commands {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.multiselect_commands_item {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: var(--gray80);

	display: flex;
	align-items: center;
	gap: 5px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.multiselect_commands_item span {
		display: none;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

/*360*/
@media screen and (max-width: 639.98px) {
	.multiselect_commands_item span {
		display: none;
	}
}
