.profile_item {
	width: 100%;
	justify-content: space-between;
	// height: 62px;
	display: flex;
	align-items: center;
	padding: 10px;
	overflow: hidden;
	gap: 20px;
}

.profile_item:hover {
	background-color: var(--gray20);
	border-radius: 8px;
}

.custom_style {
	align-items: center;
	flex-shrink: 1;
}

.subdata {
	color: var(--gray70);
}
