.post_comments_section {
	display: none;
	width: 100%;

	transition: all 0.5s ease-in-out;
	height: fit-content;

	border-top: var(--gray30) 0.5px solid;
}

.post_comments_section_visible {
	display: block;
}

.comments_wrapper {
	width: 100%;
	height: fit-content;
}

/* TODO: START FROM HERE */

/* .comment_item {
    width: 100%;
    height: fit-content;
    padding: 10px 0 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
} */

/* .profile_pic {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 2px;
    background-color: #454545;
    cursor: pointer;
}

.profile_pic.company {
    border-radius: 2px;
}

.profile_pic.user {
    border-radius: 50%;
}

.profile_pic.user img {
    border-radius: 50%;
    object-fit: cover;
}

.profile_pic.border .profile_pic.user img {
    border: 1px #fff solid;
} */

/* .comment_flex {
    flex: 1;
    height: fit-content;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
} */

/* .comment {
    width: 100%;
    height: fit-content;
    min-height: 55px;
    padding: 10px 10px 15px;
    border-radius: 5px;
    background-color: var(--gray20);

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}

.comment .owner {

    width: fit-content;
    height: 22px;
    font-weight: var(--font-weight-semibold);
    font-size: 13px;
    font-weight: var(--font-weight-semibold);
    line-height: 16px;
    color: #000;
    cursor: pointer;
}

.comment .owner:hover {

    text-decoration: underline;
}

.comment .time {
    width: fit-content;
    height: 22px;

    margin-left: 5px;


    font-size: 11px;

    color: #000;
}

.comment_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.reply_text {
    width: 100%;
    height: fit-content;


    font-size: 12px;
    line-height: 16px;
    color: #000;
    overflow-wrap: anywhere;
}

.comment_reactions_wrapper {
    width: 100%;
    height: 16px;

    margin-top: 5px;



    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.comment_reactions_wrapper label {
    width: fit-content;
    height: fit-content;

    margin: 0 5px 0 0;

    font-weight: var(--font-weight-semibold);
    font-size: 13px;
    font-weight: var(--font-weight-semibold);
    color: #000;

    cursor: pointer;
}

.comment_reactions_wrapper button {
    font-weight: var(--font-weight-semibold);
    font-size: 13px;
    font-weight: var(--font-weight-semibold);
    color: #000;
}

.seperator {
    width: 1px;
    height: 70%;
    margin: 0 10px;
    background-color: var(--gray30);
}

.comment_reactions_wrapper img {


}

.reply_icon {
    width: 12px !important;
    height: 12px !important;
    margin-right: 5px;
    margin-top: 2px;
} */

.load_more_button {
	width: 100%;
	height: fit-content;

	display: flex;
	justify-content: flex-end;
	align-content: center;
	align-items: center;
}

.load_more_button button {
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	/*line-height: 22px;*/
	color: #000;
	margin-bottom: 10px;
}

.comment_add_wrapper {
	width: 100%;
	height: fit-content;
	min-height: 65px;

	/* border-top: var(--gray30) 0.3px solid; */

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: flex-start;
	/* position: relative; */
	margin: 5px 0;
	padding: 10px 0;
}

/* .comment_add_wrapper > div:first-of-type {
    position: absolute; */
/* top: 0; */
/* top: 11px;
} */

.comment_text_wrapper {
	flex: 1;
	height: fit-content;
	min-height: 30px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
}
