.container h3 {
	font-size: 14px;
}

.subcontainer {
	padding-bottom: 20px;
	border-bottom: 2px solid var(--gray20);
}

.subcontainer:last-child {
	border-bottom: none;
	padding-bottom: 0;
}

.skills_container {
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 10px;
	flex-wrap: wrap;
}
