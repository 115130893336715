.container {
    /* display: flex;
    flex-direction: column; */
    position: relative;
}

.clickable {
    cursor: pointer;
}

.remove_icon {
    position: absolute;
    width: 20px;
    height: auto;
    right: -5px;
    top: -3px;

    background-color: #fff;
    border-radius: 100%;

    z-index: 1;
    cursor: pointer;
}
