.container {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.blocked_container {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.contact_item {
			padding-bottom: 12px;
			border-bottom: 1px solid var(--gray20);

			&:last-child {
				border-bottom: none;
			}
		}
	}
}
