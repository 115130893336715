.container {
	padding: 20px;
	background-color: #ffffff;
	margin-top: 10px;

	/* min-height: calc(100dvh - 553px); */
	/* overflow: auto; */

	position: relative;
}

.container:last-of-type {
	flex-grow: 1;
}

.container.no_margin {
	margin-top: 0px;
}

/* .container.noMinHeight {
	min-height: fit-content;
} */

.container[disabled] {
	pointer-events: none;
	opacity: 0.4;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .container {
		min-height: calc(100dvh - 494px);
	} */
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .container {
		min-height: calc(100dvh - 483px);
	} */
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .container {
		min-height: calc(100dvh - 423px);
	} */
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .container {
		min-height: calc(100dvh - 322px);
	} */
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	/* .container {
		min-height: calc(100dvh - 440px);
	} */
}

@media screen and (max-width: 639.98px) {
	/* .container {
		min-height: calc(100dvh - 319px);
	} */
}
