.tag_wrapper {
	background-color: var(--gray20);
	border-radius: 5px;
	padding: 5px 10px;

	display: flex;
	align-items: center;
	gap: 10px;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: var(--gray80);
	/* border: 1px solid #a6b1bc; */
	white-space: nowrap;
	overflow: hidden;
	flex-shrink: 0;
}

.tag_wrapper p {
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.tag_wrapper button {
	width: 16px;
	height: 16px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.tag_wrapper img {
	cursor: pointer;
}
