.header {
	font-weight: var(--font-weight-bold);
	font-size: 18px;

	display: flex;
	align-items: center;
	gap: 20px;
}

.filter_tag {
	padding: 5px;
	font-size: 12px;
	background-color: #fff;
}

.item_link {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray20);
}

.item_link:hover,
.item_link:focus {
	text-decoration: none;
}

.item_link:last-of-type {
	margin-bottom: 0px;
	padding-bottom: 0px;
	border-bottom: none;
}

.item_tagLine,
.item_location {
	width: 100%;
}

.item_tagLine {
	font-size: 12px;
	color: #000;
}

.item_location {
	font-size: 10px;
	color: var(--gray70);
}

.clickable {
	cursor: pointer;
}
