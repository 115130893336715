.container {
	width: 100vw;
	height: 100dvh;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #ffffff;
	// position: relative;
	display: flex;
	flex-direction: column;

	.body_container {
		height: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
	}
}
