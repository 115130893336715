.container {
	padding: 20px 0px;
	border-bottom: 1px solid var(--gray20);
}

.container:first-child {
	padding-top: 0;
}

.container:last-child {
	border-bottom: none;
}

.title {
	display: flex;
	align-items: center;
	gap: 10px;
}

.title h4 {

	font-size: 16px;
	font-weight: var(--font-weight-semibold);
	color: #000;
	margin: 0;
}

.date_container,
.label_container {

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: #000;
}

.date_container {
	margin-top: 3px;
}

.label_container {
	margin: 20px 0 10px;
}

.notes_container {

	font-size: 14px;
	color: #000;
}

.button_container {
	margin-top: 20px;
	display: flex;
	align-items: center;
	gap: 20px;
}

.button {
	background-color: transparent;
	font-size: 14px;

	text-transform: capitalize;

	display: flex;
	align-items: center;
	gap: 5px;
}

.button.edit {
	border: 1px solid var(--gaawk);
	color: var(--gaawk);
}

.button.delete {
	border: 1px solid #d20707;
	color: #d20707;
}

.button img {
	width: auto;
	height: 14px;
}

.color_pin {
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
}
