.container {
	background-color: #fff;
	padding: 20px;
	display: flex;
	justify-content: center;
}

.job_container {
	/* background-color: var(--gray20); */
	margin-bottom: 20px;
}

.project_wrapper {
	margin-top: 10px;
}

.project_tag {
	font-size: 12px;
	padding: 1px 5px;
	background-color: #1a8897;
	border: none;
	color: #fff;
	width: fit-content;
}

.job_footer {
	margin-top: 10px;
	font-size: 12px;
	color: var(--gray80);
}

.tab_menu {
	padding: 20px;
	border-bottom: 6px solid var(--gray20);
}

.hidden {
	display: none;
}

.applicants_container {
	padding: 20px;
}

.applicant_item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid #a6b1bc;
	cursor: pointer;
}

.applicant_item:last-of-type {
	border: none;
	padding-bottom: 0;
	margin-bottom: 0;
}

.turndown_icon {
	transform: scaleX(-1) scaleY(-1);
}

.info_text {
	font-style: italic;
	text-align: center;

	color: var(--gray80);
}

.hidden {
	display: none;
}

.button_container {
	display: flex;
	justify-content: center;
	/* margin-top: 20px; */
}

.btn {
	padding: 0 10px;
	font-size: 12px;
	height: 35px;
}

.option_icon {
	transform: rotate(90deg);
}
