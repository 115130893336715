// .items_wrapper {
// 	padding: 20px 15px;
// 	display: grid;
// 	grid-template-columns: repeat(auto-fill, 150px);
// 	gap: 20px;
// 	background-color: #fff;
// 	min-height: 100%;
// }

.container {
	padding: 20px;
}

// @media screen and (min-width: 2000px) {
// }

// @media screen and (min-width: 1608px) and (max-width: 1999.98px) {
// }

// @media screen and (min-width: 1400px) and (max-width: 1607.98px) {
// 	.items_wrapper {
// 		gap: 12px;
// 		row-gap: 20px;
// 	}
// }

// @media screen and (min-width: 1166px) and (max-width: 1399.98px) {
// 	.items_wrapper {
// 		gap: 46px;
// 		row-gap: 20px;
// 	}
// }

// @media screen and (min-width: 974px) and (max-width: 1165.98px) {
// 	.items_wrapper {
// 		gap: 48px;
// 		row-gap: 20px;
// 	}
// }

// @media screen and (min-width: 748px) and (max-width: 973.98px) {
// 	.items_wrapper {
// 		gap: 30px;
// 		row-gap: 20px;
// 	}
// }

// @media screen and (min-width: 640px) and (max-width: 747.98px) {
// 	.items_wrapper {
// 		gap: 70px;
// 		row-gap: 20px;
// 	}
// }

// @media screen and (max-width: 639.98px) {
// 	.items_wrapper {
// 		gap: 18px;
// 		row-gap: 20px;
// 		justify-content: space-between;
// 		grid-template-columns: repeat(auto-fill, 130px);
// 	}
// }
