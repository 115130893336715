@use "variables" as *;

.section {
	// &:last-of-type {
	//     padding: 0;
	// }
	border-top: 6px solid var(--gray20);
	padding: 20px;
	&.hidden {
		display: none;
	}

	& > h3 {
		margin: 0 0 20px;
	}
}
.header_wrapper {
	background-color: #fff;
}
.items_wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	gap: 54px;
	row-gap: 20px;
}

.option_wrapper {
	position: absolute;
	top: 5px;
	right: 10px;
	z-index: 1;
}

.products_medias_wrapper {
	background-color: #fff;

	min-height: fit-content;
}
.fileviewer_container {
	height: calc(100dvh - $topNavBarHeight);
}
.content_wrapper {
	display: flex;
	flex-direction: column;
	padding: 10px;
	margin-left: 12px;
	width: 50%;
	align-items: flex-start;
}
.content_wrapper_vertical {
	display: flex;
	flex-direction: column;
	padding: 20px;

	width: 100%;
	align-items: flex-start;
}
.service_name {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #232323;
}
.forward_icon {
	width: 12px;
	height: 12px;
	margin-left: 12px;
}
.container_link {
	color: unset;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}

.container_link:hover,
.container_link:focus {
	text-decoration: none;
	color: unset;
}
.update_btn {
	width: 335px;
}

.horizontal_line {
	border-top: 6px solid var(--gray20);
	width: 100%;
	margin: 0 auto;
	margin-top: 25px;
	margin-bottom: 17px;
}
.buttons_horizontal_line {
	border-top: 1px solid var(--gray20);
	margin-top: 20;
	margin-bottom: 20;
	width: 95%;
	margin: 0 auto;
}
.tag {
	background-color: var(--gray20);
	border-radius: 5px;
	padding: 4px 8px;
	color: #494949;
	font-size: 12px;
	font-weight: var(--font-weight-semibold);
}
.tag_container {
	margin-top: 8px;
	margin-bottom: 20px;
}
.breadcrumb_container {
	font-weight: var(--font-weight-semibold);
	background-color: var(--gray20);
	font-size: 12px;
	color: var(--gray80);
	margin-bottom: 10px;
}
.enquire_btn_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
	gap: 20px;
}
.breadcrumb_BG {
	background-color: var(--gray20);
	padding: 8px;
}
.service_image {
	height: 243px;
}
.header_container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}
.image_container {
	width: 50%;
}
.services_container {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
}

@media screen and (min-width: 1608px) {
	.services_container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 16px;
	}
}
.service_full_view_item {
	color: unset;
	display: flex;
}

.service_full_view_item:focus,
.service_full_view_item:hover {
	text-decoration: unset;
	color: unset;
}

.button_wrapper {
	display: flex;
	justify-content: center;
	margin-top: 20px;

	.view_all_button {
		font-size: 14px;
		height: 35px;
		width: 160px;
		padding: 0;
	}
}
.container {
	background-color: #fff;
	height: 100%;
}
.no_padding {
	padding: 0px;
}
.description {
	width: 100%;
}

.locations_wrapper {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: var(--gray80);
	margin-top: 20px;
}

.sub_services_txt {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #000;
}
.view_all {
	font-weight: var(--font-weight-bold);
	font-size: 14px;
	color: var(--gray80);
}
@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) {
	.service_image {
		width: 600px;
		height: 300px;
		object-fit: fill;
	}
	.service_full_view_item {
		padding: 14px;
	}
}
@media screen and (max-width: 1607px) {
	.service_full_view_item {
		border-top: 6px solid var(--gray20);
	}
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper {
		gap: 53px;
	}
	.service_image {
		width: 486px;
		height: 243px;
		object-fit: fill;
	}
}
@media screen and (min-width: 1166px) and (max-width: 1399px) {
	.service_image {
		object-fit: fill;
	}
	.items_wrapper {
		gap: 46px;
	}
}

@media screen and (min-width: 1400px) {
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		gap: 49px;
	}
	.service_image {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		gap: 32px;
	}
	.header_container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.image_container {
		width: 100%;
	}
	.content_wrapper {
		width: 100%;
	}
	.service_image {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		gap: 69px;
	}
	.header_container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.image_container {
		width: 100%;
	}

	.content_wrapper {
		width: 100%;
	}
	.service_image {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
}
@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.items_wrapper {
		gap: 32px;
	}
}

@media screen and (max-width: 367px) {
	.items_wrapper {
		grid-template-columns: repeat(auto-fill, 130px);
		gap: 5px;
		justify-content: space-between;
	}
}
@media screen and (max-width: 639.98px) {
	.header_container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.image_container {
		width: 100%;
	}
	.content_wrapper {
		width: 100%;
	}
	.service_image {
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
}
