.company_item {
	background-color: var(--gray20);
	padding: 20px;
}

.container {
	padding: 20px;

	font-size: 14px;
	color: var(--black);
	background-color: #fff;
}

.container.modal {
	background-color: unset;
}

.terms_wrapper {
	margin: 40px 0;
}

.button_container {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.terms {
	font-weight: var(--font-weight-semibold);
	color: var(--gaawk);
}

.terms:hover {
	text-decoration: underline;
	cursor: pointer;
}

.payment_container {
	margin-top: 20px;
	text-align: center;
	padding: 20px;
	background-color: antiquewhite;
}

/* MODAL */

.congrats_message {
	margin-bottom: 20px;
	text-align: center;
	color: var(--gaawk);
}

.congrats_message h2 {
	font-weight: var(--font-weight-bold);
	margin: 0px;
	font-size: 18px;
}

.congrats_message h4 {
	font-weight: var(--font-weight-semibold);
	margin: 0px;
	font-size: 14px;
}

.company_wrapper {
	padding: 20px;
	border-radius: 12px;
	border: solid 1px #dbdfe4;
	margin-bottom: 20px;
}

.info {
	font-size: 14px;
	text-align: center;
	color: var(--black);
	margin-bottom: 20px;
}
