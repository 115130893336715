.map_button {

	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: var(--gaawk);
}

.map_button:hover {
	text-decoration: underline;
}
