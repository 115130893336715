.container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 10px 0;
	color: var(--gray70);
	font-size: 10px;
	flex-grow: 1;
	min-height: 35px;
	// max-height: 35px;
	// background-color: #fff;
}
