.container {
	.nav_group {
		padding: 10px 20px;
		h3 {
			margin-bottom: 20px;
		}
		.navigation {
			flex: 1;
			display: flex;
			flex-direction: column;

			.label {
				font-weight: var(--font-weight-semibold);
			}
		}
	}
}
