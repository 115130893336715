.container {
	padding: 20px;
	border-bottom: 6px solid var(--gray20);
}

.container h4 {
	margin: 0px;

	font-size: 18px;
	font-weight: var(--font-weight-bold);
	color: var(--black);
}

.container p {
	margin: 10px 0px 20px;

	font-size: 14px;
	color: var(--black);
}

.btn_wrapper {
	display: flex;
	justify-content: center;
}

.btn {
	width: 310px;
}
