@use "variables" as *;

$profileContainerHeight: 65px;

.left_side_container {
	width: 100%;
	position: relative;
}

/* .left_side_header {
    margin-top: 5px;

    width: 100%;
    height: 55px;
    padding: 10px;
    background-color: var(--gray80);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
} */

/* .profile_thumb_container {
    max-width: 235px;
    height: 55px;
    display: flex;
    align-items: flex-end;
    flex-shrink: 1;
    overflow-x: auto;
} */

.img_container {
	width: 34px;
	height: 34px;
	border: solid 1px var(--gray20);
	background-color: lightgray;
	border-radius: 50%;
}

.img_container img {
	object-fit: cover;
	border-radius: 50%;
}

/* .profile_thumb_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 50px;
    padding: 0px 7px 7px;
    cursor: pointer;
}

.profile_thumb_item.active {
    background-color: #dbdfe4;
    border-radius: 8px 8px 0 0;
}

 .separator {
    height: auto;
    width: 8px;
    margin-left: 17px;
} */

.profile_container {
	height: $profileContainerHeight;
	padding: 8px 15px;
}

.warning_wrapper {
	padding: 0 10px 10px;
}

.search_container {
	padding: 10px;
}

.conversation_list_container {
	width: 100%;

	background-color: #ffffff;
	height: calc(
		100dvh - $topNavBarHeight - $profileContainerHeight - 55px
	); /* 55px being the conversation search bar*/

	// position: absolute;
	// top: 120px;
	// left: 0;
	// bottom: 56px;

	overflow-y: auto;
}

.conversation_list_container.mobile {
	height: calc(100dvh - $topNavBarHeight - $profileContainerHeight - 55px - $bottomNavbarHeight);
}

.start_conversation_container {
	width: 100%;
	background-color: #ffffff;
	height: calc(100dvh - $topNavBarHeight - $profileContainerHeight - 55px);
	/* padding: 10px 0; */
	overflow-y: auto;
}

.start_conversation_container.mobile {
	height: calc(100dvh - $topNavBarHeight - $profileContainerHeight - 55px - $bottomNavbarHeight);
}

.start_conversation_header {
	width: 100%;
	display: flex;
	align-items: flex-end;
	padding: 20px 10px 10px;

	font-weight: var(--font-weight-semibold);
	font-size: 18px;
	color: var(--gray70);
}

.add_container {
	width: 100%;
	height: fit-content;

	padding-bottom: 10px;
}

/* .add_search_container {
    width: 100%;
    height: 60px;
    margin: 5px 0 0 0;
    padding: 0 12px;
    background-color: #a6b1bc;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
} */

/* .add_search_text_wrapper {
    width: 290px;
    height: 35px;
} */

/* .invite_container {
    width: 100%;
    height: 45px;
    margin: 5px 0 0 0;
    background-color: var(--gaawk);;
} */

/* .contacts_container {
    width: 100%;
    height: calc(100dvh - 400px);



    padding: 0 15px;

    overflow-y: auto;
} */
/*
.contact_list_wrapper {
    max-height: 322px;
    overflow: auto;

} */

.contact_item {
	width: 100%;
	padding: 0 10px;
	height: 62px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.contact_item:hover {
	background-color: var(--gray30);
	cursor: pointer;
}

.contact_item:last-child {
	border-bottom: none;
}

.contact_item_wrapper {
	width: 100%;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	padding: 5px 0;
	border-bottom: 1px var(--gray30) solid;
}

.contact_item:last-of-type .contact_item_wrapper {
	border-bottom: none;
}

/* .contact_item:last-of-type {
    border-bottom: none;
} */

.start_conversation_container .contact_item:last-of-type .contact_item_wrapper {
	border-bottom: none;
}

.conversation_icon {
	width: 50px;
	height: auto;
}

/* .profile_info_wrapper {
    flex: 1;
    height: 100%;

    margin-left: 10px;
    padding: 4px 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    align-items: center;
}

.profile_info_wrapper label.name {
    width: 100%;


    font-size: 14px;
     font-weight: var(--font-weight-semibold);
    color: #000;
    cursor: inherit;
}

.profile_info_wrapper label.description {
    width: 100%;


    font-size: 10px;
    font-weight: var(--font-weight-semibold);
    color: #a6b1bc;
    cursor: inherit;
} */

/* .profile_icons {
    width: 50px;
    height: 25px;

    margin-left: 5px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
}

.profile_icons img {
    width: 25px;
    height: 25px;
} */

.bulletList_container {
	padding: 10px 20px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.search_container {
		width: 100%;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .left_side_header {
        justify-content: space-between;
        min-width: 360px;
    } */

	.search_container {
		width: 100%;
	}

	/* .conversation_list_container {
        min-width: 360px;
    } */
}
