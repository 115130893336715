/* RENAME MODAL */

.modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
	padding: 20px 30px;
}

.rename_input_wrapper {
	width: 100%;
}

.rename_input_wrapper label {
	display: block;
	margin-bottom: 5px;

	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: left;
	color: var(--black);
}

.rename_input_wrapper input {
	height: 32px;
	margin-bottom: 25px;
}

.movecopy_modal_buttons,
.rename_modal_buttons {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

@media screen and (max-width: 639px) {
	.rename_modal_buttons {
		flex-direction: column-reverse;
		gap: 10px;
	}

	.rename_modal_buttons button {
		width: 100%;
	}
}
