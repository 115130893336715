.container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	margin: 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray30);

	&:hover {
		text-decoration: none;
	}

	&:last-of-type {
		border-bottom: none;
	}

	&.disabled {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
}

.board_details {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 96px;
	overflow: hidden;

	&.sidebar {
		min-height: unset;
		gap: 5px;

		h3 {
			font-size: 14px;
		}

		.owner_wrapper {
			display: none;
		}

		.counter_wrapper {
			font-size: 12px;
		}
	}

	h3 {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.counter_wrapper {
	margin-bottom: 5px;
	display: flex;
	gap: 10px;
	align-items: center;

	.counter_item:not(.hideDecoration) p:hover {
		text-decoration: underline;
	}

	.counter_item:first-of-type {
		position: relative;
		padding-right: 12px;

		p:after {
			content: "";
			background: var(--gray30);
			position: absolute;
			bottom: 0;
			right: 0;
			height: 100%;
			width: 1px;
		}
	}

	.privacy_icon {
		width: 12px;
	}
}

.owner_wrapper {
	display: flex;
	gap: 8px;
	align-items: center;
	&:not(.hideDecoration):hover :not(span) {
		text-decoration: underline;
	}

	.author_span {
		color: #a6b1bc;
	}

	& p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

// .counter_item:after {
// 	content: "";
// 	width: 0;
// 	height: 100%;
// 	position: absolute;
// 	border: 1px solid black;
// 	top: 0;
// 	left: 100px;
// }

// .counter_item:after {
// 	display: none;
// }
