.services_container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 10px;
}

.service_wrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	justify-content: flex-start;
	border-bottom: 1px solid #dbdfe4;
	padding-bottom: 10px;
	color: unset;

	&:last-of-type {
		border-bottom: none;
		padding-bottom: 0;
	}
}

.companyDTO {
	display: flex;
	align-items: center;
	gap: 6px;

	h5 {
		margin: 0;
		color: #a6b1bc;
		font-size: 10px;
	}
}

.serviceDTO {
	display: flex;
	align-items: flex-start;
	gap: 10px;

	& > div {
		h3 {
			margin: 0 0 5px;
			text-transform: uppercase;
		}

		p {
			color: #000;
			font-size: 12px;

			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.service_image_wrapper {
		width: 104px;
		height: 79px;
		flex-shrink: 0;
		img {
			object-fit: cover;
		}
	}
}

.section_container {
	margin-top: 6px;
	min-height: fit-content;
	padding: 15px;

	&.no_padding {
		padding: 0;
	}
}
