.container {
	width: 100%;
	margin: 20px 0 0 0;
	// padding: 40px 40px 30px;
	padding: 20px;
	border-radius: 10px;
	background-color: #ffffff;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
}

.img_canvas {
	width: 300px;
	height: 300px;
	border-radius: 50%;
	background: var(--gray50);
}

.calendar {
	z-index: 2000 !important;
}

// .img_canvas img {
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// 	border-radius: 50%;
// }

// .until_section {
// 	width: 100%;
// 	height: 30px;
// 	margin-top: 38px;
// 	border-radius: 5px;
// 	background-color: #080808;

// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: center;
// 	align-content: center;
// 	align-items: center;
// }

// .until_section label {
// 	height: 20px;
// 	font-weight: var(--font-weight-semibold);
// 	font-size: 15px;
// 	font-weight: var(--font-weight-semibold);
// 	font-stretch: normal;
// 	font-style: normal;
// 	/*line-height: normal;*/
// 	letter-spacing: normal;
// 	text-align: center;
// 	line-height: 18px;
// 	color: #ffffff;
// }

.temp_options_section {
	margin-top: 30px;

	width: fit-content;
	height: fit-content;

	display: block;

	/*display: flex;*/
	/*flex-wrap: wrap;*/
	/*justify-content: center;*/
	/*align-content: flex-start;*/
}

.temp_option {
	/*width: 300px;*/
	/*width: 100%;*/
	width: fit-content;
	height: 35px;

	/*background-color: #cfc6a3;*/

	/*padding: 0 0 0 20px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;

	position: relative;

	margin-bottom: 10px;
	padding: 10px;
}

.temp_option:hover {
	cursor: pointer;
	border-radius: 5px;
	background-color: var(--gray30);
}

.temp_option div {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.temp_option img {
	width: 25px;
	height: 25px;

	margin: 0 30px 0 0;

	object-fit: contain;
}

.temp_option label {
	width: 195px;
	/*height: 19px;*/
	margin: 0 56px 0 0;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	/*line-height: 16px;*/
	letter-spacing: normal;
	text-align: left;
	color: #000000;

	cursor: inherit;
}

.button_wrapper {
	width: 100%;
	margin-top: 15px;

	display: flex;
	justify-content: center;
}

.save_button {
	width: 130px;
	height: 35px;
	padding: 8px 25px;
	border-radius: 5px;
	border: solid 1px #6cc5d1;
	background-color: var(--gaawk);

	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 1.3px;
	text-align: center;
	color: #ffffff;
}

.custom_date_section {
	margin-top: 5px;

	width: 320px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.custom_date_section label {
	font-weight: var(--font-weight-semibold);
	margin-bottom: 7px;
}

// .custom_date_wrapper {
// 	width: 330px;
// 	/*height: 200px;*/

// 	// padding: 0 0 0 20px;

// 	// display: flex;
// 	// flex-wrap: wrap;
// 	// justify-content: flex-start;
// 	// align-content: flex-start;
// }

// .custom_date_wrapper label {
// 	height: 20px;
// 	font-weight: var(--font-weight-semibold);
// 	font-size: 13px;
// 	font-weight: var(--font-weight-semibold);
// 	font-stretch: normal;
// 	font-style: normal;
// 	line-height: 16px;
// 	letter-spacing: normal;
// 	text-align: left;
// 	color: #000000;
// }

.select_section {
	margin-top: 5px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-content: flex-start;

	/*background-color: #1a8897;*/
}

// .styles.custom_select_width {
// 	width: 140px;
// }

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	// .container {
	// 	padding: 40px 20px 30px;
	// }
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	// .container {
	// 	padding: 16px 30px 30px;
	// }

	// .img_canvas {
	// 	width: 200px;
	// 	height: 200px;
	// }

	// .until_section {
	// 	margin-top: 60px;
	// }

	.temp_options_section {
		margin-top: 20px;
	}

	.temp_option label {
		margin: 0 0 0 0;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	// .container {
	// 	padding: 16px 30px 30px;
	// 	margin: 0 0 0 0;
	// 	height: calc(100dvh - 100px);
	// }

	// .img_canvas {
	// 	width: 200px;
	// 	height: 200px;
	// }

	// .until_section {
	// 	margin-top: 60px;
	// }

	.temp_options_section {
		margin-top: 20px;
	}

	.temp_option label {
		margin: 0 0 0 0;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	// .container {
	// 	padding: 20px 30px 30px;
	// 	margin: 0 0 0 0;
	// 	height: calc(100dvh - 100px);
	// }

	// .img_canvas {
	// 	width: 200px;
	// 	height: 200px;
	// }

	// .until_section {
	// 	margin-top: 60px;
	// }

	.temp_options_section {
		margin-top: 20px;
	}

	.temp_option label {
		margin: 0 0 0 0;
	}
}
