.container {
	position: relative;
}

.input {
	width: 100%;
	height: 26px;

	color: #383c3e;
	font-family: "Poppins";
	font-size: 16px;
	letter-spacing: 0.5px;

	padding-bottom: 12px;
	border-bottom: #dfdfdf 1px solid;

	&:focus {
		border-bottom: var(--gaawk) 1px solid;
	}
	&.error {
		border-bottom: #d40d0d 1px solid;
	}
}

.errorMessage {
	position: absolute;
	bottom: -16px;
	left: 0;

	color: #d40d0d;
	font-family: "Poppins-Regular";
	font-size: 10px;
	letter-spacing: 0.5px;
}
