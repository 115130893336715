.react_crop {
	max-height: 400px;
}

.btn_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.control {
	display: flex;
	align-items: center;
	gap: 20px;
}

@media screen and (max-width: 639px) {
	.btn_container .btn {
		width: 100%;
	}
}
