.modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
	padding: 20px 30px;
}

.loadingContainer {
	padding: 10px;
}

.movecopy_body h3 {
	font-size: 12px;
	color: var(--gray70);
}

.movecopy_folder_container {
	border-radius: 6px;
	border: 1px solid #a6b1bc;
	margin-bottom: 20px;
	/* padding: 10px; */
}

.current_folder {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	/* margin-bottom: 10px; */
	padding: 10px;
}

.current_folder img {
	width: 20px;
	height: auto;
	margin-right: 10px;
	cursor: pointer;
}

.current_folder span {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.folders_list {
	/* height: 230px; */
	height: 200px;
	margin-top: 10px;
	overflow: auto;
}

.info_text {
	font-size: 14px;

	display: block;
	margin: 0px 10px 10px;
}

.folder_item:first-of-type {
	margin-top: 0px;
}

.folder_item:last-of-type {
	margin-bottom: 0px;
}

.folder_item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
}
.folder_item.disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.folder_item:hover:not(.disabled) {
	background-color: lightgray;
	cursor: pointer;
}

.folder_item img {
	width: 24px;
	height: auto;
	margin-right: 10px;
}

.folder_item span {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: var(--gray80);
}

.movecopy_modal_buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

.separator {
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 639px) {
	.movecopy_modal_buttons {
		flex-direction: column-reverse;
		gap: 10px;
	}

	.movecopy_modal_buttons button {
		width: 100%;
	}
}
