.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

#waveform ::part(cursor) {
	display: none;
}

// #waveform ::part(cursor) {
// 	height: 10px;
// 	width: 10px;
// 	top: 50%;
// 	right: 50%;
// 	transform: translate(-50%, -50%);
// 	border-radius: 50%;

// 	cursor: pointer;
// }

#waveform ::part(scroll) {
	overflow-x: unset !important;
	overflow-y: unset !important;
}

.audio_control_icon {
	width: 14px;
}

.mic_icon {
	height: 20px;
}

.right_side {
	display: flex;
	align-items: center;
	gap: 10px;
	color: var(--gray80);
}

@media screen and (min-width: 2000px) {
	#waveform ::part(wrapper) {
		min-width: 590px;
	}
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
	#waveform ::part(wrapper) {
		min-width: 590px;
	}
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
	#waveform ::part(wrapper) {
		min-width: 465px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
	#waveform ::part(wrapper) {
		min-width: 330px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
	#waveform ::part(wrapper) {
		min-width: 270px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	#waveform ::part(wrapper) {
		min-width: 200px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
	#waveform ::part(wrapper) {
		min-width: 270px;
	}
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	#waveform ::part(wrapper) {
		min-width: 170px;
	}
}

@media screen and (max-width: 319px) {
	#waveform ::part(wrapper) {
		min-width: 45vw;
	}
}
