.reaction_icon {
	width: 35px;
}

.reaction_icon.full_screen_post {
	width: 25px;
}

.reaction_counter_wrapper {
	display: flex;
	/* gap: 10px; */
	width: 100%;
	justify-content: space-between;
}

.reaction_counter_wrapper.flex_end {
	justify-content: flex-end;
}

.emoji_container {
	display: flex;
}

.left_side {
	display: flex;
	align-items: center;
	gap: 10px;
	white-space: nowrap;
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
}

.emoji_icon {
	width: 19px;
}

.reaction_counter {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-weight: var(--font-weight-semibold);

	font-size: 12px;
	width: 100%;
	cursor: pointer;
}

.reaction_counter .right_side button:not(:last-child):after {
	content: ".";
	display: inline-block;
	margin: 0 8px;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.reaction_icon {
		width: 30px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.reaction_icon {
		width: 25px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

@media screen and (max-width: 639.98px) {
	.reaction_icon {
		width: 25px;
	}
}
