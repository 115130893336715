.container {
  width: 100vw;
  height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  position: relative;
}

.titleWrapper {
  margin: 0 auto;
  width: 1047px;
  height: 642px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.imgContainer {
  flex: 1;
  height: 100%;
  position: relative;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  position: absolute;
  top: -118px;
  left: 0;
  right: 0;
}

.titleContainer {
  width: 537px;
  height: 183px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;

}

.title {
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.titleText1 {
  width: 100%;
  color: #000;
  font-family: "Poppins";
  font-size: 68px;
  line-height: 68px
}

.titleText2 {
  color: #000;

  margin-right: 5px;

  font-family: "Poppins-Thin";
  font-size: 68px;
  font-style: italic;
  line-height: 68px
}

.titleText3 {
  color: #000;
  font-family: "Poppins";
  font-size: 68px;
  line-height: 68px;

}

.subTitle{
  margin-top: 6px;
  flex: 1;
  width: 100%;
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
  line-height: 20px;
}

.contentWrapper {
  margin: 10px auto 45px;
  width: 800px;
  text-align: center;
}

.contentTitle {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 38px;
}

.faqWrapper {
  margin-top: 40px;
  width: 100%;
  height: auto;
  //border: #1a8897 1px solid;
  border-top: #F1F1F1 3px solid;
}

/*others*/
@media screen and (max-width: 1279.98px) {
  .container {
    //padding: 0 23px;
  }

  .titleWrapper {
    margin-top: 55px;
    width: 100%;
    height: 216px;

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .imgContainer {
    display: none;
  }

  .titleContainer {
    margin: 0 auto;
    width: 300px;
    flex: 1;
  }

  .title {

    flex-direction: column;
    flex-wrap: nowrap;
  }

  .titleText1 {
    font-size: 48px;
    line-height: 48px
  }

  .titleText2 {
    font-size: 48px;
    line-height: 48px
  }

  .titleText3 {
    font-size: 48px;
    line-height: 48px

  }

  .subTitle{
    margin-top: 12px;
    font-size: 12px;
    line-height: 16px;
  }

  .contentWrapper {
    margin: 54px auto 68px;
    width: 300px;
    text-align: center;
  }

  .contentTitle {
    text-align: left;
    font-size: 24px;
  }

}
