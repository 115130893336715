.container {
	display: flex;
	flex-direction: column;
	gap: 7px;
	justify-content: space-between;
	margin-top: 20px;
	position: relative;

	label {
		font-weight: var(--font-weight-semibold);
		font-size: 14px;
		margin-bottom: 0 !important;
	}

	.error_message {
		font-size: 12px;
		color: red;
		font-style: italic;
		position: absolute;
		bottom: -20px;
		right: 0px;
		text-align: right;
	}
}

.label {
	display: flex;
	flex-direction: column;
	.info {
		font-style: italic;
		font-size: 12px;
	}
}
