/* Customize the label (the container) */

.container {
	display: block;
	position: relative;
	padding-left: 24px;
	cursor: pointer;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 3px;
	left: 0;
	height: 14px;
	width: 14px;
	background-color: #eee;
	border-radius: 3px;

	vertical-align: middle;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a cyan background */
.container input:checked ~ .checkmark {
	background-color: var(--gaawk);
}

.container input:focus ~ .checkmark {
	outline: 2px solid var(--gaawk);
	outline-offset: -2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 5px;
	top: 1px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;

	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.unclickable_label {
	display: block;
	padding-left: 24px;
}
