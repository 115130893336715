@use "variables" as *;

.container {
	padding: 20px;

	& > div:first-child {
		margin-top: 0;
	}
}

.separator_container {
	border-top: 6px solid var(--gray20);
	border-bottom: 6px solid var(--gray20);
}

.separator_container {
	& > div > div:last-child {
		margin-bottom: 0;
	}
}

.multicheckbox_container,
.field_wrapper {
	margin-bottom: 20px;
}

.multicheckbox_container:last-of-type {
	margin-bottom: 0px;
}

.button_container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
	// padding: 20px;
	.btn {
		width: 100%;
	}
}

.loading_spinner {
	height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	align-items: center;
}

.datePickerContainer {
	margin-bottom: 20px;
	label {
		font-weight: var(--font-weight-semibold);
		font-size: 14px;
		color: #000000;
		margin-bottom: 8px;
		display: block;
	}
}

.datePickerWrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
}

.datePickerItem {
	width: 100%;
	// width: 240px;
	display: flex;
	flex-direction: column;
}

.agency_wrapper {
	margin-bottom: 20px;
	h3 {
		font-size: 14px;
		margin-bottom: 8px;
		display: block;
	}
	.agency_checkbox {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.no_margin {
	margin: 0;
}

.no_top_padding {
	padding-top: 0;
}

.margin_top {
	margin-top: 10px;
}

.inline_input_wrapper {
	width: 100%;
	display: flex;
	gap: 30px;
	margin: 20px 0;

	.item {
		width: 100%;

		label {
			font-weight: var(--font-weight-semibold);
			font-size: 14px;
			color: #000000;
			display: inline-block;
			margin-bottom: 7px;
		}
	}
}
