.container {
	h4 {
		margin: 0 0 8px;
	}
}

.switch_wrapper {
	display: flex;
	align-items: center;
	gap: 20px;


	font-size: 14px;
	color: var(--black);
	margin-bottom: 8px;

	span {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	img {
		width: 15px;
	}
}
