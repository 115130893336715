.container {
	color: unset;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
}

.container:hover,
.container:focus {
	text-decoration: none;
	color: unset;
}

.image_wrapper {
	position: relative;
}

.product_img {
	border-radius: 8px;
	width: 150px;
	height: 150px;
	object-fit: cover;
}

.option_icon {
	position: absolute;
	transform: rotate(90deg);
	top: 0px;
	right: 5px;
	height: 30px;
	width: 30px;
	img {
		width: unset;
		height: unset;
	}
}

.product_name {
	font-weight: var(--font-weight-semibold);
	margin-top: 5px;
	font-size: 12px;
	color: #000;
	height: 30px;
}

.profile_wrapper {
	display: flex;
	align-items: center;
	margin-top: 5px;
	background-color: #fff;

	span {
		font-size: 10px;
	}
}

.productOwner {
	font-size: 10px;
}
.image_wrapper {
	height: unset;
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.product_img {
		width: 130px;
		height: 130px;
	}
}
