.container {
	display: flex;
	align-items: flex-start;
	gap: 10px;
}

.profile_pic {
	width: 36px;
	height: 36px;
	// margin-right: 10px;
	border-radius: 2px;
	background-color: lightgray;
	flex-shrink: 0;

	position: relative;
}

.profile_pic.company {
	border-radius: 2px;
}

.profile_pic.user {
	border-radius: 50%;
}

.profile_pic.company img {
	border-radius: 2px;
	object-fit: cover;
}

.profile_pic.user img {
	border-radius: 50%;
	object-fit: cover;
}

.notification_body {

	font-size: 14px;

	h4 {
		margin: 0 0 5px;
	}
}
