.card {
	/* width: fit-content; */
	height: fit-content;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	position: relative;
	display: flex;
	flex-direction: column;

	/* display: relative; */
}

.card.isReply {
	background-color: var(--gray20) !important;
	margin: 15px 15px 0;
}

.card.receiver {
	background-color: #ffffff;
	float: left;
	border-radius: 15px 15px 15px 0;
}

.card.sender {
	background-color: var(--light-gaawk);
	float: right;
	border-radius: 15px 15px 0 15px;
}

.card.isInfoModal {
	float: none;
	margin: auto;
}

.participant_name {
	/* padding: 10px 15px 0px; */

	font-weight: var(--font-weight-semibold);
	font-size: 16px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.bubble_header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 10px 15px 0px;
	gap: 10px;
}

.bubble_header.with_name {
	justify-content: space-between;
}

.forwarded {
	font-weight: var(--font-weight-semibold);
	font-size: 10px;

	color: var(--gray70);

	display: flex;
	align-items: center;
	gap: 5px;
}

.forwardIcon {
	fill: var(--gray70);
	width: 20px;
}

/* .forwarded img {
	width: 14px;
	height: auto;
} */

.media_container {
	/* width: 468px;
    height: 258px; */
	/* width: 100%; */

	/* border: solid 5px #fff; */
	padding: 5px;

	border-radius: inherit;
	cursor: pointer;
}

.warning_storage {
	background-color: var(--gray80);
	max-width: 300px;
	height: 300px;
	border-radius: 10px;
	margin: 5px;
	padding: 20px;

	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: var(--font-weight-semibold);
	text-align: center;
}

.warning_storage img {
	width: 48px;
	height: 48px;
}

.map_container {
	width: 307px;
	padding: 10px;

	cursor: pointer;
}

.map_container img {
	height: 150px;
	border-radius: 10px;
	border: solid 1px var(--gray70);
}

.location_title {
	font-size: 14px;
	line-height: 20px;
	color: var(--gaawk);

	margin-top: 10px;
}

.location_title:hover {
	text-decoration: underline;
	cursor: pointer;
}

/* .media_container img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    border-radius: inherit;
} */

.card.sender video {
	border-radius: 15px 15px 0 15px;
}

.card.receiver video {
	border-radius: 15px 15px 15px 0;
}

.message_text_container {
	width: 100%;
	height: fit-content;

	position: relative;

	padding: 10px 15px;
	overflow-wrap: normal;
}

.deleted_msg {
	display: flex;
	align-items: center;
	gap: 5px;
	font-style: italic;
	color: var(--gray70);
}

.message_text {
	width: 100%;
	max-width: 100%;

	font-size: 14px;
	color: #000;

	/* overflow-wrap: normal; */
	word-wrap: normal;
	text-align: justify;
}

.message_text.emoji_only {
	font-size: 25px !important;
}

.bottom_info {
	width: auto;
	height: 18px;
	margin-top: 2px;

	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	justify-content: flex-end;
}

.bottom_info label {
	margin-left: 4px;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #a6b1bc;
}

.bottom_info img {
	width: auto;
	height: 10px;
	object-fit: contain;
}

/* ===== POPOVER CSS ===== */
.option_icon {
	/* margin-left: 4px; */
	width: 24px;
	height: 24px;
	z-index: 1000;
	position: absolute;

	top: 10px;
	right: 10px;
}

.option_icon.align_left {
	margin-left: 0px;
	right: 0px;
	left: 10px;
}

.loading_container {
	padding: 10px;
	text-align: center;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.card {
		max-width: 720px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.card {
		max-width: 720px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.card {
		max-width: 595px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.card {
		max-width: 460px;
	}

	/* .media_container {
        width: 460px;
        height: 274px;
    } */
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.card {
		max-width: 400px;
	}

	/* .media_container {
        width: 317px;
        height: 253px;
    } */
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.card {
		max-width: 334px;
	}

	/* .media_container {
        width: 317px;
        height: 253px;
    } */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.card {
		max-width: 400px;
	}

	/* .media_container {
        width: 317px;
        height: 253px;
    } */
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.card {
		max-width: 315px;
	}

	/* .media_container {
        width: 317px;
        height: 253px;
    } */

	.message_text {
		font-size: 13px;
		/* line-height: 16px; */
	}

	.bottom_info label {
		font-size: 12px;
	}
}
