.otp_wrapper {
	display: flex;
	justify-content: center;
	width: 50%;
	margin: auto;
}

.button_container {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	align-items: center;

	button {
		width: 320px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.otp_wrapper {
		width: 70%;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.otp_wrapper {
		width: 70%;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.otp_wrapper {
		width: 100%;
	}
}

@media screen and (max-width: 639.98px) {
	.otp_wrapper {
		width: 100%;
	}
}
