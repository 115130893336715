.location_modal_container {
	width: 100%;
	height: fit-content;

	/*background-color: #1a8897;*/

	padding: 20px;
}

.location_list_wrapper {
	margin-top: 20px;
	width: 100%;
	height: 500px;
	overflow-y: auto;
}

.location_item {
	width: 100%;
	height: 50px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.location_item:hover {
	background-color: var(--gray20);
	cursor: pointer;
}

.active_location {
	margin-top: 20px;
}

.tag {
	width: 100%;
	justify-content: space-between;
}

.location_item img {
	width: 30px;
	height: 30px;
}

.location_item div {
	margin-left: 15px;
	flex: 1;
	height: 100%;

	padding: 5px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
}

.location_item .name {
	width: 100%;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	line-height: 19px;
	color: #000;
	cursor: inherit;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.location_item .location {
	width: 100%;

	font-weight: var(--font-weight-extralight);
	font-size: 12px;

	color: #000;
	cursor: inherit;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}
