.experience_item {
	width: 100%;
	border-bottom: 1px solid var(--gray30);
}

.experience_item:last-of-type {
	border-bottom: none;
}

.experience_header {
	display: flex;
	align-items: center;
}

.company_details {
	display: flex;
	flex-direction: column;
}

.company_position {
	font-weight: var(--font-weight-semibold);
	font-size: 18px;
	color: #000;
}

.position_details {
	margin: 20px auto;
	padding-left: 17px;
}

.position_details .outer {
	border-left: 2px solid #a6b1bc;
}

.position_details .outer:last-of-type {
	border-left: 2px solid transparent;
}

.position_details .outer:only-of-type {
	border-left: none;
	padding-left: 2px;
}

.experience_card {
	display: flex;
	flex-direction: column;

	margin-left: 27px;
	padding-bottom: 20px;
}

.experience_card p {
	font-size: 14px;
	color: #000;
	margin-top: 10px;
}

.outer:last-of-type .experience_card {
	padding-bottom: 0px;
}

.position_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.position_info {
	display: flex;
	flex: 1;
	flex-direction: column;
	max-width: 1020px;
}

.verified_icon {
	height: 24px;
	width: auto;
	margin-left: 20px;
}

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

.position_title {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	color: var(--gray80);
	position: relative;
}

.position_title::before {
	content: "";
	position: absolute;
	/*   margin: 10px; */
	width: 30px;
	height: 30px;
	background-color: #a6b1bc;
	border-radius: 999px;
	left: -43px;
	top: -4px;
	border: 9px solid white;
}

.subData {
	font-weight: var(--font-weight-semibold);
	font-size: 10px;
	color: var(--gray70);
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.position_info {
		max-width: 800px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.position_info {
		max-width: 590px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.position_info {
		max-width: 400px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.position_info {
		max-width: 190px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.position_info {
		max-width: 450px;
	}
}

@media screen and (max-width: 639.98px) {
	.position_info {
		max-width: 190px;
	}
}
