@use "../../shared.module.scss";

.item_icon {
	height: fit-content;
	margin-right: 10px;
}

.item_icon img {
	width: 24px;
	height: 24px;
}

.data_section {
	flex: 1;
}

.item_description {
	font-size: 16px;

	display: flex;
	align-content: center;
}

.item_description_wrapper {
	display: flex;
	align-items: center;
}

.item_description_wrapper label {
	margin-right: 10px;
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
}

.item_description_wrapper .visibility_icon {
	display: flex;
	align-items: center;
}

.item_description_wrapper .visibility_icon span {
	display: flex;
	align-items: center;
}

.item_description_wrapper .visibility_icon span img {
	width: 18px;
	height: 18px;
	margin-right: 8px;
}

.item_description_wrapper .visibility_icon span:last-child img {
	margin-right: 0px;
}

.item_data {
	margin-top: 10px;
	color: var(--gray70);
}

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

.filler {
	flex-grow: 1;
}

.other_section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	margin-top: 15px;
}

/*
item from EducationItem.jsx
 */

.item_container {
	width: 100%;
	height: fit-content;

	padding: 20px;

	border-bottom: #dbdfe4 1px solid;

	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	justify-content: space-between;
}

.item_container:first-child {
	border-top: #dbdfe4 1px solid;
}

.visibility {
	height: 19px;

	font-size: 14px;

	display: flex;
}

.visibility img {
	margin-left: 5px;
}

.visibility_wrapper {
	margin-top: 15px;
	width: 20%;
	height: fit-content;
}

.visibility_label {
	display: inline-block;
	margin-bottom: 7px;
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.info_wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

/*
Add From
 */

.add_form {
	padding: 20px;
}

.form_input_wrapper {
	/* margin-top: 15px; */

	width: 100%;
	height: fit-content;

	position: relative;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	margin-bottom: 7px;
	display: block;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

/* .textarea_container {
	margin-top: 20px;
}

.textarea_container label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.textarea_wrapper {
	width: 100%;
	height: 250px;

	margin-top: 7px;
	padding: 11px 6px 32px 10px;

	border: solid 1px var(--gray50);
	border-radius: 5px;

	position: relative;
}

.textarea_wrapper.focused {
	border: solid 2px #6cc5d1;
}

.textarea_wrapper.error {
	border: solid 2px red;
}

.textarea_wrapper textarea {
	resize: none;

	width: 100%;
	height: 100%;

	padding: 0 10px 0 0;

	border: none;

	font-size: 14px;

	overflow-y: scroll;

	scrollbar-color: #a6b1bc var(--gray20);
	scrollbar-width: thin;
}

.textarea_wrapper textarea::-webkit-scrollbar {
	width: 5px;
	cursor: pointer;
	border-radius: 5px !important;
}

.textarea_wrapper textarea::-webkit-scrollbar-track {
	background-color: var(--gray20);
}

.textarea_wrapper textarea::-webkit-scrollbar-thumb {
	background-color: #a6b1bc;
	cursor: pointer !important;
} */

/* .word_count_wrapper {
	width: fit-content;
	height: fit-content;

	position: absolute;
	right: 13px;
	bottom: 9px;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
}

.text_count {
	width: fit-content;
	height: 15px;
	font-weight: var(--font-weight-semibold);
	font-size: 11px;

	text-align: left;
	color: #1a8897;
}

.text_count.danger {
	color: red;

	font-weight: var(--font-weight-semibold);
} */

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.form_input_wrapper p.error_message {
	font-size: 12px;
	color: red;
	font-style: italic;
	position: absolute;
	bottom: -20px;
	right: 0px;
	text-align: right;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.delete_button {
		margin-right: 40px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.delete_button {
		margin-right: 40px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.other_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.item_container {
		padding: 15px;
	}

	.delete_button {
		margin-right: 30px;
	}
	.visibility_wrapper {
		width: 25%;
	}

	.other_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.item_container {
		padding: 15px;
	}

	.delete_button {
		margin-right: 30px;
	}
	.visibility_wrapper {
		width: 35%;
	}

	.other_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.item_container {
		padding: 10px;
		height: fit-content;

		align-content: flex-start;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.visibility_wrapper {
		width: 100%;
	}

	.other_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.item_container {
		padding: 10px;
	}

	.visibility_wrapper {
		width: 35%;
	}

	.other_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.item_container {
		padding: 10px;
		height: fit-content;

		align-content: flex-start;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.visibility_wrapper {
		width: 100%;
	}

	.other_section {
		grid-template-columns: repeat(1, 1fr);
	}

	.button_container button {
		width: 100%;
	}
}
