.container {
	height: 100%;

	.mb_10 {
		margin-bottom: 10px;
	}

	.loading_container {
		height: 100%;
	}

	h4 {
		margin: 0;
	}

	.info {
		margin-top: 6px;
		font-weight: var(--font-weight-semibold);
		color: var(--gray80);
		font-size: 12px;
	}

	.bar_container {
		margin: 20px 0;
	}

	.spaces_container {
		margin-top: 15px;

		.bordered_container {
			margin-top: 15px;
			padding: 15px;
			border: 1px solid #dbdfe4;
			border-radius: 12px;

			.fullwidth {
				width: 100%;
			}

			.section_container {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin: 12px 0 23px 0;

				.radio_button_wrapper {
					display: flex;
					align-items: center;
					gap: 10px;
					font-weight: var(--font-weight-semibold);
					font-size: 14px;

					label {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
				}
			}
		}
	}
}
