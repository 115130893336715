.container {
	margin-top: 130px;

	width: 100%;

	display: flex;
	flex-direction: column;

	justify-content: center;
	align-items: center;

	text-align: center;
}

.subtitle {
	color: var(--gaawk);
	text-align: center;
	font-family: "Poppins";
	font-size: 16px;
	font-style: normal;
	line-height: 53.398px;
}

.title {
	color: #131723;
	text-align: center;
	font-family: "Poppins-SemiBold";
	font-size: 45px;
	line-height: 50px;
}

.description {
	margin-top: 16px;
	width: 653px;
	color: #5d6579;
	text-align: center;
	font-family: "Poppins";
	font-size: 16px;
	font-style: normal;
	line-height: 24px;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
	.container {
		margin-top: 100px;
	}
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
	.container {
		margin-top: 60px;
	}
	.subtitle {
		font-size: 12px;
		line-height: 48.885px;
	}

	.description {
		margin-top: 4px;
		width: 376px;
	}
}

/*mobile*/
@media screen and (min-width: 360px) and (max-width: 743.98px) {
	.container {
		margin-top: 20px;
	}

	.subtitle {
		font-size: 12px;
		line-height: 48.885px;
	}

	.description {
		margin-top: 4px;
		width: 304px;
	}
}
