.container {
    margin-top: 6px;
    padding: 20px 20px 60px;
    width: 100%;
    height: fit-content;

    background-color: #ffffff;

    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    gap: 70px;
}

.media_container {
    margin-top: 6px;
    background-color: #ffffff;
}

.option_container {
    width: 100%;
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
    /* .container {
        gap: 70px;
    } */
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
    /* .container {
        gap: 70px;
    } */
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
    /* .container {
        gap: 70px;
    } */

    .media_files_container {
        margin-right: 6px;
        padding: 0px 10px 0px 7px;
    }
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
    .container
    /* ,.media_container  */ {
        padding: 20px 15px;
    }
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
    .container
    /* ,.media_container  */ {
        padding: 20px;
    }
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
    .container
    /* ,.media_container  */ {
        padding: 20px 10px;
    }
}

/*360*/
@media screen and (max-width: 639.98px) {
    .container
    /* ,.media_container  */ {
        padding: 20px;
    }
}
