.form_input_wrapper {
	margin-top: 15px;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: inline-block;
	margin-bottom: 7px;
}

/* .form_input_wrapper input {
	width: 100%;
	height: 35px;
	padding: 7px;

	font-size: 14px;
} */

.form_input_wrapper p.error_message {
	font-size: 12px;
	color: red;
	font-style: italic;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

p.error_message.available {
	color: var(--gaawk);
	margin-top: 5px;
	gap: 5px;
}

p.error_message.taken {
	margin-top: 5px;
	gap: 5px;
}

.warning_icon {
	width: 15px;
	height: 15px;
}

.left_side_item {
	position: absolute;
	left: 2px;
	top: 50%;
	display: flex;
	align-items: center;
	transform: translateY(-50%);
	padding: 0 5px;
	border-radius: 4px 0 0 4px;
	height: 31px;
	background-color: #d9edf1;
	font-weight: var(--font-weight-semibold);
}
