.map_container {
	display: flex;
	gap: 20px;
}

.title {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	margin: 0;
}

.address {
	margin-top: 10px;
}

.map {
	width: 263px;
	height: 131px;
	cursor: pointer;
	border-radius: 8px;
	/* margin-top: 20px; */
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.map_container {
		flex-direction: column-reverse;
		gap: 20px;
	}

	.map {
		width: 100%;
		height: 360px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	.map_container {
		flex-direction: column-reverse;
		gap: 20px;
	}

	.map {
		width: 100%;
		height: 360px;
	}
}

@media screen and (max-width: 319px) {
	.map_container {
		flex-direction: column-reverse;
		gap: 20px;
	}

	.map {
		width: 100%;
		height: 360px;
	}
}
