.title {
	text-align: center;
	margin-top: 5px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

.item_cover {
	width: 150px;
	height: 150px;
	position: relative;
	background-color: #dbdfe4;

	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
}

.item_cover img {
	object-fit: cover;
	border-radius: 12px;
}

.item_cover .gallery_icon {
	width: 60px;
	height: auto;
	border-radius: 0;
}

.album_item_wrapper {
	position: relative;
}

.option_wrapper {
	position: absolute;
	top: 5px;
	right: 10px;
	z-index: 1;
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.item_cover {
		width: 130px;
		height: 130px;
	}
}
