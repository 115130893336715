.contact_container {
	display: flex;
	justify-content: flex-start;
	/* align-items: center; */

	flex-direction: column;
}

.contact_container .link {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--gaawk);
	margin-bottom: 5px;
}

.contact_container .link:hover {
	text-decoration: underline;
	cursor: pointer;
}

.sublabel {
	font-size: 10px;
}

.sublabel img {
	width: 12px;
	height: 12px;
	margin-right: 5px;
}

.profile_pic_container {
	display: flex;
	align-items: center;
}

hr {
	/* width: 100%; */
	margin: 5px 0px;
	border: 0.5px solid #a6b1bc;
}
