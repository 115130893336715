/* pdf-container */
.pdf_container {
	display: flex;
	flex-direction: column;
	position: relative;
	/* height: 100%; */
	width: auto;
}

.page_controls {
	position: absolute;
	bottom: 5%;
	left: 50%;
	background: white;
	opacity: 0;
	transform: translateX(-50%);
	transition: opacity ease-in-out 0.2s;
	box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
	border-radius: 4px;
}

.pdf_container:hover .page_controls {
	opacity: 1;
}

.page_controls button {
	width: 44px;
	height: 44px;
	background: white;
	border: 0;
	font: inherit;
	font-size: 0.8em;
	border-radius: 4px;
}

.page_controls button:hover {
	background: lightgray;
}

.page_controls button:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.page_controls button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.password_container {
	color: #fff;
}

.password_container p {
	margin-bottom: 20px;
}
