.section {
	border-top: 6px solid var(--gray20);
	background-color: #fff;
}

.section.noBorder {
	border-top: none;
}

.container {
	padding: 20px;
}

.item_header {
	flex: auto;
	display: flex;
	margin-bottom: 10px;
}

.item_header img {
	width: 20px;
	cursor: pointer;
}

.header_title {
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	margin: 0;
}

.emptyContainer {
	width: 100%;
	background-color: #ffffff;
}

.emptyText {
	font-size: 14px;
	margin: 20px 0px;
}

.emptyBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.cta_button {
	width: 320px;
}

.hide {
	display: none;
}

.disabled {
	opacity: 0.4;
	background-color: #fff;
}
