.container {
	border-bottom: 6px solid var(--gray20);
}

.padding_container {
	padding: 20px;
}

.filter_item,
.multicheckbox_container {
	margin-bottom: 20px;
}
.filter_item:last-of-type,
.multicheckbox_container:last-of-type {
	margin-bottom: 0px;
}

.filter_item label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--black);
	display: block;
	margin-bottom: 10px;
}

.inline_input {
	display: flex;
	align-items: flex-start;
	gap: 30px;
	height: 35px;
}

.see_more_btn {
	margin-top: 8px;
}
