.link {
	&.no_decoration {
		text-decoration: none;
	}

	color: var(--gaawk);

	// &:visited {
	// 	color: unset;
	// }

	&:hover :global(#initial_letter) {
		// display: inline-block;
		// display: block;
		text-decoration: none;
	}
}
