.header {
	font-size: 18px;
	font-weight: var(--font-weight-bold);

	display: flex;
	align-items: center;
	gap: 20px;
}

.sub_container {
	background-color: #fff;
	padding: 20px;
}

.review_container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;
}

.review_container .tag {
	padding: 2px 6px;
}

.job_wrapper {
	background-color: var(--gray20);
	padding: 20px;
}

.no_padding {
	padding: 0;
}

.tags_container {
	margin-top: 10px;
}

.tag {
	padding: 0 5px;
	border: none;
	width: fit-content;
}

.input_wrapper {
	margin-bottom: 20px;
	background-color: #fff;
}

.input_wrapper label {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: var(--black);
}

.textarea_wrapper {
	margin-top: 20px;
}
/* .input_wrapper textarea {

	margin-top: 20px;
	border: 1px solid var(--gray50);
	border-radius: 5px;
	min-height: 140px;
	resize: vertical;
	padding: 10px;
} */

.text_info {
	font-style: italic;
	color: var(--gray80);
	font-size: 12px;
	margin: 10px 0;
}

.text_info.error {
	color: red;
}

.text_info span {
	font-weight: var(--font-weight-bold);
}

.files_wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 10px;
}

.loaded_item {
	margin-top: 10px;
}

.file_item {
	padding: 10px 20px;
	width: 100%;
	border-radius: 8px;
	border: solid 1px #dbdfe4;

	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: space-between;

	font-size: 14px;
	color: var(--black);
}

.file_item .delete_button {
	display: flex;
	align-items: center;
	justify-content: center;
}

.file_item_info {
	display: flex;
	align-items: center;
	gap: 10px;
	overflow: hidden;
}

.file_item_info div {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.custom_upload_button button {
	position: relative;

	width: 100%;
	height: 35px;
	background-color: var(--light-gaawk);
	border-radius: 5px;

	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;

	letter-spacing: 0.7px;
	text-align: center;
	color: var(--gaawk);
	margin-top: 10px;
}

.custom_upload_button input[type="file"] {
	opacity: 0;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 100%;

	cursor: inherit;
}

.file_wrapper {
	width: 80px;
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	align-items: center;
}

.filename {
	margin-top: 5px;

	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: anywhere;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.thumbnail {
	width: 80px;
	height: 80px;
	border-radius: 5px;
	border: 1px solid #a6b1bc;
	display: flex;
	justify-content: center;
	align-items: center;
}

.thumbnail img {
	width: 70%;
}

.react_select_wrapper {
	width: 100%;
	height: fit-content;
	/* padding: 20px; */
	background-color: #fff;
}

.sub_title {
	object-fit: contain;

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: var(--black);
	display: block;
	margin-bottom: 10px;
}

.member_item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.member_item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.dropdown_item {
	display: flex;
	align-items: center;
	width: 100%;
	height: 35px;
	margin-left: 10px;
	/* padding: 0 10px; */
}

.dropdown_item img {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	object-fit: cover;
}

.button_container {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.review_btn {
		width: 100%;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	.review_btn {
		width: 100%;
	}
}

@media screen and (max-width: 319px) {
	.review_btn {
		width: 100%;
	}
}
