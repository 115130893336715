.container {
	width: 100%;
	height: 16px;

	margin-top: 5px;

	/*background-color: #1a8897;*/

	display: flex;
	/* flex-wrap: wrap; */
	justify-content: space-between;
	align-content: center;
	align-items: center;

	position: relative;

	font-weight: var(--font-weight-semibold);
	font-size: 10px;

	color: #a6b1bc;
}

.reactions_container {
	position: absolute;
	left: 0px;
	bottom: 25px;
}

.reactions_container.reply {
	left: -50px;
}

.reactions_container > div {
	justify-content: space-around;
}

.reply_icon {
	width: 12px !important;
	height: 12px !important;
	margin-right: 5px;
	margin-top: 2px;
}

.react_btn {
	font-weight: var(--font-weight-semibold);
	font-size: 10px;
}

.react_btn,
.reply_btn,
.time_container {
	white-space: nowrap;
}

.react_btn.active {
	color: var(--gaawk);
}

.seperator {
	margin: 0 5px;
	color: #000;
}

.reply_btn {
	color: var(--gaawk);
}

.left_side {
	display: flex;
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.reactions_container > div {
		width: 415px !important;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.reactions_container {
		left: -50px;
	}

	.reactions_container.reply {
		left: -100px;
	}

	.reactions_container > div {
		width: 340px !important;
	}
}

@media screen and (max-width: 639.98px) {
	.reactions_container {
		left: -50px;
	}

	.reactions_container.reply {
		left: -100px;
	}
	.reactions_container > div {
		width: 340px !important;
	}
}
