.container {
	width: 14px !important;
	height: 14px !important;
	padding: 3px;
	border-radius: 3px;
	border: solid 1px var(--gray70);

	cursor: pointer;

	position: relative;
	flex-shrink: 0;
}

.input {
	position: absolute;

	left: 0;
	top: 0;

	width: 100%;
	height: 100%;

	opacity: 0;

	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 3px;
	z-index: 100;
}

.checkmark {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 1px;

	width: 8px !important;
	height: 8px !important;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: var(--gray30);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: var(--gaawk);
}
