@use "../shared.module.scss";

.software_section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

.software_header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.controls {
	margin-top: 15px;
	display: flex;
	gap: 20px;
}

.software_item {
	width: 367px;
	margin-bottom: 7px;
}

.software_icon {
	width: 36px;
	height: 36px;
	margin-right: 10px;
}

.software_name label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--gray80);
}

.software_time label {
	font-weight: var(--font-weight-semibold);
	font-size: 10px;
	color: var(--black);
}

.software_slider_wrapper {
	margin-top: 8px;
	height: 10px;
}

/*
Add From
 */

.add_form {
	width: 100%;
	height: fit-content;
}

.no_margin_top {
	margin-top: 0;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;

	margin-bottom: 15px;
}

.form_input_wrapper label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
	display: block;
	margin-bottom: 7px;
}

.form_input_wrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.proficiency_wrapper {
	margin-top: 7px;

	width: 100%;
	height: 35px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.proficiency_wrapper.error {
	outline: 2px solid red;
	outline-offset: -1px;
	border-radius: 5px;
}

.proficiency_wrapper button {
	flex: 1;
	height: 100%;
	border-radius: 5px;
	margin-right: 3px;
	background-color: #000000;

	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;

	font-weight: var(--font-weight-semibold);

	text-align: center;
	color: #ffffff;
}

.proficiency_wrapper button:last-child {
	margin-right: 0;
}

.proficiency_wrapper button.selected {
	background-color: #067c8d;
}

.dropdown_container {
	width: 100%;
	height: fit-content;

	margin: 7px 0 0 0;

	display: flex;
	align-items: center;
	gap: 20px;
}

.dropdown_item {
	width: 190px;
	height: 35px;
}

.actively_looking_wrapper {
	margin-top: 15px;

	width: 100%;
	height: 35px;

	position: relative;

	display: flex;
	align-items: center;
	gap: 10px;
}

.actively_looking_wrapper label {
	padding-top: 2px;
	height: 19px;

	font-size: 14px;
	color: #000000;

	margin-left: 10px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.actively_looking_wrapper {
		width: fit-content;
	}

	.delete_button {
		margin-right: 40px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.actively_looking_wrapper {
		width: fit-content;
	}

	.delete_button {
		margin-right: 40px;
	}

	.delete_button {
		margin-right: 40px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.dropdown_item {
		margin-right: 20px;
	}

	.actively_looking_wrapper {
		width: fit-content;
	}

	.delete_button {
		margin-right: 30px;
	}

	.software_section {
		grid-template-columns: repeat(2, 1fr);
	}

	.software_item {
		width: 466px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.item_container {
		padding: 0 15px;
	}

	.slider_wrapper {
		width: 400px;
		margin-left: auto;
	}

	.delete_button {
		margin-right: 30px;
	}

	.software_section {
		grid-template-columns: repeat(2, 1fr);
	}

	.software_item {
		width: 354px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.item_container {
		padding: 0 15px;
	}

	.slider_wrapper {
		width: 260px;
		margin-left: 190px;
	}

	.delete_button {
		margin-right: 30px;
	}

	.software_section {
		grid-template-columns: repeat(1, 1fr);
	}

	.software_item {
		width: 100%;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.item_container {
		padding: 0 10px 0 10px;
		height: 93px;

		align-content: flex-start;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.slider_wrapper {
		width: 260px;
		top: 65px;

		left: 0;
		right: 0;

		margin-left: 10px;
	}

	.name {
		font-size: 14px;
	}

	.experience_time {
		font-size: 13px;
	}
	.dropdown_item {
		width: 145px;
	}

	.actively_looking_wrapper label {
		flex: 1;
		padding-top: 2px;
		height: fit-content;
		font-size: 12px;
	}

	.save_button_wrapper {
		justify-content: space-between;
	}

	.software_section {
		grid-template-columns: repeat(1, 1fr);
	}

	.software_item {
		width: 100%;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.item_container {
		padding: 0 20px 0 10px;
	}

	.slider_wrapper {
		width: 320px;
	}

	.name {
		font-size: 14px;
	}

	.experience_time {
		font-size: 13px;
	}

	.save_button_wrapper {
		justify-content: space-between;
	}

	.software_section {
		grid-template-columns: repeat(2, 1fr);
	}

	.software_item {
		width: 100%;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.save_btn {
		width: 100%;
	}

	.wrapper {
		padding: 0 10px;
	}

	.item_container {
		padding: 0 10px 0 10px;
		height: 93px;

		align-content: flex-start;
	}

	.text_wrapper {
		padding-top: 10px;
	}

	.slider_wrapper {
		width: 260px;
		top: 65px;

		left: 0;
		right: 0;

		margin-left: 10px;
	}

	.name {
		font-size: 14px;
	}

	.experience_time {
		font-size: 13px;
	}

	.dropdown_item {
		width: 145px;
	}

	.actively_looking_wrapper label {
		flex: 1;
		padding-top: 2px;
		height: fit-content;
		font-size: 12px;
	}

	.save_button_wrapper {
		justify-content: space-between;
	}

	.software_section {
		grid-template-columns: repeat(1, 1fr);
	}
	.software_item {
		width: 100%;
	}
}
