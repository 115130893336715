.container {
	width: 100%;

	background-color: #ffffff;

	padding: 0 20px;

	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.deleted_container {
	background-color: #ffffff;

	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	color: var(--gray80);
	text-align: center;
}

.cover_container {
	position: relative;
}

.cover_wrapper {
	width: 100%;
	height: 327px;
	border-radius: 0 0 10px 10px;
	background-color: var(--gray50);

	position: relative;

	z-index: 10;
}

.cover_wrapper .disabled {
	cursor: default;
}

.cover_wrapper:hover {
	cursor: pointer;
}

.cover_wrapper img {
	width: 100%;
	height: 100%;
	border-radius: 0 0 10px 10px;
	object-fit: cover;
	z-index: 10;
}

.profile_pic_wrapper {
	width: 140px;
	height: 140px;
	flex-shrink: 0;
	bottom: -85px;
	left: 25px;

	border-radius: 50%;
	border: 5px #ffffff solid;

	background-color: var(--gray50);

	position: absolute;
	z-index: 10;
}

.profile_pic_wrapper img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}

.profile_pic_wrapper.company {
	/* border-radius: 13px; */
	border-radius: 22%;
}

.profile_pic_wrapper .AAA {
	width: 47px;
	height: 20px;
	padding: 5px 5px 5px 5px;
	border-radius: 5px;
	background-color: #cfc6a3;

	position: absolute;
	right: -5px;
	bottom: 0;
}

.info_container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 10px;
	margin-left: 180px;
	margin-bottom: 40px;
	gap: 10px;
}

.info_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.info_wrapper .name_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.info_wrapper .name_wrapper .tag {
	padding: 3px 5px;
}

.info_wrapper .name_wrapper img {
	width: 25px;
}

.info_wrapper p.name {
	font-weight: var(--font-weight-semibold);
	font-size: 22px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.info_wrapper p.position {
	font-size: 14px;
}

.info_wrapper p.location {
	width: 100%;
	font-weight: var(--font-weight-extralight);
	font-size: 14px;
	font-weight: 300;
}

.info_actions {
	display: flex;
	margin-top: 27px;
}

.info_actions button:not(.edit_button) {
	width: 40px;
	height: 35px;
	margin-left: 10px;
	cursor: pointer;
}

.info_actions button.chat_btn {
	background-color: #000000;
	border-radius: 5px;
}

.info_actions button.chat_btn img {
	padding: 5px;
}

.edit_button img {
	width: 12px !important;
	height: auto !important;
	margin-right: 10px;
}

.info_actions button img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.option_button {
	transform: rotate(90deg);
}

label.switch {
	color: black;
	display: flex;
	align-items: center;
}

label.switch span {
	margin-right: 5px;
}

.custom_popover_item {
	padding: 5px 15px;
}

.custom_popover_item span {
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom_popover_item span img {
	width: 15px;
	height: auto;
}

.scrollableMenu_wrapper {
	width: 100%;
	border-top: solid 1px var(--gray30);
}

/*2000*/
@media screen and (min-width: 2000px) {
	.info_wrapper p.name {
		max-width: 500px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.info_wrapper p.name {
		max-width: 500px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.cover_wrapper {
		height: 268px;
	}

	.info_wrapper p.name {
		max-width: 300px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.info_wrapper p.name {
		word-break: break-word;
		white-space: unset;
	}
	.container {
		padding: 0 15px;
	}

	.info_container {
		flex-direction: column;
		align-items: center;
		margin: 70px 0 20px;
		gap: 10px;
	}

	.profile_pic_wrapper {
		bottom: -65px;
		left: 50%;
		transform: translateX(-50%);
	}

	.cover_wrapper {
		height: 208px;
	}

	.info_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		text-align: center;
	}

	.info_actions {
		margin: 0;
	}

	.info_wrapper .name_wrapper {
		justify-content: center;
	}

	.info_wrapper p.name {
		font-size: 20px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.info_wrapper p.name {
		word-break: break-word;
		white-space: unset;
	}
	.container {
		padding: 0 10px;
	}

	.info_container {
		flex-direction: column;
		align-items: center;
		margin: 0;
		gap: 10px;
		margin: 70px 0 20px;
	}

	.profile_pic_wrapper {
		bottom: -65px;
		left: 50%;
		transform: translateX(-50%);
	}

	.cover_wrapper {
		height: 156px;
	}

	.info_actions {
		margin: 0;
	}

	.info_wrapper .name_wrapper {
		justify-content: center;
	}

	.info_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		text-align: center;
	}

	.info_wrapper p.name {
		font-size: 18px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.info_wrapper p.name {
		word-break: break-word;
		white-space: unset;
	}

	.container {
		padding: 0 10px;
	}

	.info_container {
		flex-direction: column;
		align-items: center;
		margin: 0;
		gap: 10px;
		margin: 70px 0 20px;
	}

	.profile_pic_wrapper {
		bottom: -65px;
		left: 50%;
		transform: translateX(-50%);
	}

	.cover_wrapper {
		height: 95px;
	}

	.info_actions {
		margin: 0;
	}

	.info_wrapper .name_wrapper {
		justify-content: center;
	}

	.info_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		text-align: center;
	}

	.info_wrapper p.name {
		font-size: 16px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.info_wrapper p.name {
		word-break: break-word;
		white-space: unset;
	}

	.container {
		padding: 0 10px;
	}

	.info_container {
		flex-direction: column;
		align-items: center;
		margin: 0;
		gap: 10px;
		margin: 70px 0 20px;
	}

	.profile_pic_wrapper {
		bottom: -65px;
		left: 50%;
		transform: translateX(-50%);
	}

	.cover_wrapper {
		height: 168px;
	}

	.info_wrapper .name_wrapper {
		justify-content: center;
	}

	.info_actions {
		margin: 0;
	}

	.info_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		text-align: center;
	}

	.info_wrapper p.name {
		font-size: 18px;
	}
}

/*!*360*!*/
@media screen and (min-width: 360px) and (max-width: 639.98px) {
	.info_wrapper p.name {
		word-break: break-word;
		white-space: unset;
	}

	.container {
		padding: 0 10px;
	}

	.info_container {
		flex-direction: column;
		align-items: center;
		margin: 0;
		gap: 10px;
		margin: 70px 0 20px;
	}

	.profile_pic_wrapper {
		bottom: -65px;
		left: 50%;
		transform: translateX(-50%);
	}

	.cover_wrapper {
		height: 95px;
	}

	.info_wrapper .name_wrapper {
		justify-content: center;
	}

	.info_actions {
		margin: 0;
	}
	.info_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		text-align: center;
	}

	.info_wrapper p.name {
		font-size: 16px;
	}
}

@media screen and (max-width: 359px) {
	.info_wrapper p.name {
		word-break: break-word;
		white-space: unset;
	}

	.container {
		padding: 0 10px;
	}

	.info_container {
		flex-direction: column;
		align-items: center;
		margin: 0;
		gap: 10px;
		margin: 70px 0 20px;
	}

	.profile_pic_wrapper {
		bottom: -65px;
		left: 50%;
		transform: translateX(-50%);
	}

	.cover_wrapper {
		height: 95px;
	}

	.info_wrapper .name_wrapper {
		justify-content: center;
	}

	.info_actions {
		margin: 0;
	}
	.info_wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		text-align: center;
	}

	.info_wrapper p.name {
		font-size: 16px;
	}
}
