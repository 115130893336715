.notification_item {
	padding: 10px;
	width: 100%;
	background-color: var(--light-gaawk);
	// margin-bottom: 5px;
	border-radius: 8px;
	text-align: left;

	&.read {
		background-color: var(--gray20);
	}

	.notification_details {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.notification_body {

			font-size: 14px;

			h4 {
				margin: 0 0 5px;
			}

			p {
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 5;
				word-break: break-word;
			}
		}
	}

	.notification_time {
		display: flex;
		justify-content: flex-end;
		font-size: 10px;
		color: var(--gray80);
		margin-top: 5px;
	}

	.more_button {
		height: 20px;
		width: 20px;
		flex-shrink: 0;
		display: flex;
		justify-content: flex-end;
		img {
			width: 3px;
			// margin-right: 5px;
		}
	}

	.left_side {
		display: flex;
		align-items: flex-start;
	}
}

.icon_wrapper {
	display: flex;
	align-items: center;
}

.menu_item {
	display: flex;
	align-items: center;
	gap: 10px;
}
