.container {
	margin: 15px 0px;
	position: relative;

	&.disabled label {
		color: #a8abae;
		cursor: not-allowed;
	}
}

.container.no_margin {
	margin: 0px;
}

.container label {
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
	display: inline-block;
	margin-bottom: 7px;
}

.error_message {
	font-size: 12px;
	color: red;
	font-style: italic;
	position: absolute;
	bottom: -20px;
	right: 0px;
	text-align: right;
}
