.container {
	border-bottom: 6px solid var(--gray20);
}

.container:last-of-type {
	border-bottom: none;
}

.title_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 20px;

	.filter_button {
		width: 28px;
		height: 28px;
		background-color: #a6b1bc;
		border-radius: 4px;
		padding: 4px;

		img {
			display: flex;
			align-items: center;
		}
	}
}

.section_wrapper {
	padding: 20px;
}

.section_wrapper {
	& > h3 {
		margin: 0 0 20px;
		color: #000;
	}
}

.contact_item {
	border-bottom: 1px solid #dbdfe4;
	padding: 10px 0px;

	&:first-of-type {
		padding-top: 0px;
	}

	&:last-of-type {
		border-bottom: none;
		padding-bottom: 0px;
	}
}

.button_wrapper {
	display: flex;
	justify-content: center;
}

.no_padding {
	padding: 0px;
}

.active_filters {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	margin-bottom: 20px;
}
