.header_content_contanier {
	width: 100%;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-content: center;
	align-items: center;
}

.container {
	padding: 20px;
	flex-grow: 1;
	background-color: #fff;
	height: 100%;
}

.upload_header {
	font-weight: var(--font-weight-semibold);
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	gap: 20px;
}

.upload_header h4 {
	margin: 0 0 5px;
}
.upload_header p {
	font-size: 12px;
	color: var(--gray80);
}

.items_container {
	/* position: absolute;

    top: 145px;
    left: 0;
    right: 0; */

	width: 100%;
	/*height: calc(100dvh - 200px);*/
	/* height: calc(100dvh - 145px - 65px); */

	/* overflow-y: auto;
    overflow-x: hidden; */
	/*background-color: #72cccc;*/

	padding: 20px 0px 10px;
}

.items_wrapper {
	width: 100%;
	height: fit-content;

	/*background-color: #cfc6a3;*/

	/*gap: 10%;*/
	padding: 20px;
	gap: 40px 80px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper {
		width: 710px;
		margin: auto;
		gap: 40px 0;
		justify-content: space-between;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper {
		width: 710px;
		margin: auto;
		gap: 40px 0;
		justify-content: space-between;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		gap: 20px 28px;
		justify-content: center;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		gap: 20px 36px;
		justify-content: center;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		gap: 20px 37px;
		justify-content: center;
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	.items_wrapper {
		gap: 20px 36px;
		justify-content: center;
	}
}
