.hide {
	display: none;
}

.header_container {
	position: absolute;
	height: 65px;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 20px;
	border-left: var(--gray20) 1px solid;
	/* width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    gap: 20px; */
}

.profile {
	/* width: 100%; */
	/* height: 35px; */

	display: flex;
	/* flex-wrap: wrap; */
	justify-content: flex-start;
	align-content: center;

	min-width: 0;
}

.profile_info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* margin-top: 3px; */
	/* width: fit-content; */
	font-weight: var(--font-weight-semibold);
	min-width: 0;

	/* height: 35px; */

	/* display: flex;
    flex-direction: column; */
	/* flex-wrap: wrap; */
	/* justify-content: flex-start; */
	/* align-content: center; */
	/* align-items: center; */
}

.profile_info .name {
	/*height: 22px;
     width: 100%;*/
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	font-size: 16px;
	line-height: 19px;
	/* display: block; */
}

.profile_info .name:hover {
	text-decoration: underline;
	cursor: pointer;
}

.profile_info .status {
	/* width: 100%; */
	/*height: 19px;*/
	/*margin: 22px 0 0;*/
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	/* display: block; */

	margin-top: 5px;
	font-size: 14px;
	line-height: 14px;
}

.header_leftside {
	/* width: fit-content; */
	/* height: 25px; */

	display: flex;
	/* flex-wrap: wrap; */
	/* justify-content: flex-end; */
	align-items: center;
	align-content: center;
	gap: 10px;
	min-width: 0;
}

.header_leftside > img:first-of-type {
	width: 30px;
	height: auto;
	margin-right: 20px;
	cursor: pointer;
}

.header_options {
	/* width: fit-content;
    height: 25px; */

	display: flex;
	/* flex-wrap: wrap; */
	justify-content: flex-end;
	align-items: center;
	align-content: center;
}

.header_options img {
	width: auto;
	height: 25px;
	margin-left: 30px;
}

.header_options button img {
	width: auto;
	height: 25px;
	margin-left: 30px;
	/* position: relative; */
}

.header_options img:first-child {
	margin-left: 0;
}

.option_icon {
	width: 25px;
	height: 25px;
	/* margin-left: 30px; */
}

.option_icon svg {
	fill: #000;
	width: 25px;
	height: 25px;
	transform: rotate(90deg);
}

/* ===== POPOVER CSS ===== */

.sys_popover_items {
	/*min-width: ;*/
	min-width: 95px;
	background-color: #ffffff;
	border-radius: 10px;
	padding: 10px 0;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
	margin-top: 5px;
	/* position: absolute;
    top: 10px;
    right: 0px; */
}

.sys_popover_item {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-start;

	padding: 7px 15px;
}

.sys_popover_item:hover {
	background-color: #e7e7e8;
	cursor: pointer;
}

.sys_popover_item img {
	width: 13px;
	height: 13px;
	margin-right: 10px;
	line-height: 1;
}

.sys_popover_item button {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	position: relative;
}

/* ===== END POPOVER CSS ===== */

.chat_footer {
	width: 100%;
	min-height: 56px;
	height: 100%;
	padding: 10px 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	position: relative;
}

.chat_footer.disabled {
	height: 56px;
	background-color: #a6b1bc;
	text-align: center;
	justify-content: center;
	color: #fff;
	font-style: italic;
	font-weight: var(--font-weight-bold);
}

.chat_footer.select_active {
	background-color: #000;
	flex-wrap: nowrap;
	justify-content: space-between;
}

.selected_counter {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);

	color: #fff;
	white-space: nowrap;

	display: flex;
	align-items: center;
	gap: 20px;
}

.select_icons {
	height: 24px;
	width: auto;
	cursor: pointer;
}

.closeIcon {
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.reply_container {
	/* width: 100%; */
	/* height: auto; */
	position: absolute;
	bottom: 60px;
	left: 166px;
	/* bottom: 50px; */
	/* padding: 0 10px; */
	/* position: sticky; */
	/* bottom: 0; */
}

.footer_button {
	width: auto;
	height: 24px;
	margin-right: 10px;
}

.attach_button {
	position: relative;
}

.text_container {
	flex: 1;
	height: 100%;
}

.preview_text_container {
	padding: 10px;
	background-color: #000;
}

.send_button,
.mic_button {
	width: 35px;
	height: 35px;
	margin: 0 0 0 20px;
	background-color: var(--gaawk);
	border-radius: 50%;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.send_button.media_modal {
	margin: 0px;
}

.send_button img {
	width: 20px;
	height: 100%;
	margin-left: 2px;
}

.mic_button img {
	width: 25px;
	height: 100%;
}

.footer_modal .send_button {
	width: 25px;
	height: 25px;
	margin: 0 0 0 10px;
}

.footer_modal .send_button img {
	width: 13px;
	height: 100%;
	margin-left: 2px;
}

.emoji_container {
	position: absolute;
	/*bottom: 0;*/
	/*right: ;*/

	width: 100%;

	left: 0px;
	top: -320px;

	z-index: 9000;
}

.emoji_container > [class~="emoji-picker-react"] {
	box-shadow: none;
}

.recording_controls {
	position: absolute;
	width: 206px;
	height: 50px;
	bottom: 50px;
	right: -10px;
	background-color: #000000;
	padding: 5px 10px;
	border-radius: 8px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-content: center;
}

.recording_controls .stopwatch {
	font-size: 14px;
	color: var(--gray70);
}

.recording_controls .send_button {
	width: 35px;
	height: 35px;
	background-color: var(--gaawk);
	border-radius: 50%;
	margin: 0;
}

.recording_controls .cancel_button {
	width: 35px;
	height: 35px;
}

.recording_controls .mic_icon {
	width: 25px;
	height: 25px;
}

/*.attachment_wrapper {*/
/*    position: absolute;*/

/*    !*top: -155px;*!*/
/*    bottom: 50px;*/
/*    left: 20px;*/

/*    width: 40px;*/
/*    height: 200px;*/

/*    border-radius: 15px;*/

/*    background-color: #4cd964;*/

/*    z-index: 9000;*/

/*}*/

.media_modal_wrapper {
	width: 100%;
	height: fit-content;
}
.media_modal_wrapper > div {
	border-radius: 0 0 12px 12px;
}
.media_modal_wrapper > div > div:not(:only-child):last-child {
	border-radius: 0 0 12px 12px;
}

.media_modal_container {
	width: 100%;
	height: fit-content;
	max-height: calc(100dvh - 400px);

	padding: 5px 5px 0;

	overflow-y: auto;
}

.media_modal_footer {
	width: 100%;
	height: 40px;
	border-radius: 0 0 10px 10px;
	background-color: #000;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	position: relative;

	padding: 6px 10px;
}

.media_item {
	width: 100%;
	height: fit-content;

	margin-bottom: 10px;

	position: relative;
}

.media_item_controllers {
	position: absolute;
	top: 10px;
	left: 0;

	width: 100%;
	height: 30px;

	z-index: 2;

	padding: 0 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.media_item_controllers button {
	width: 30px;
	height: 100%;
	border-radius: 5px;
	background-color: #fff;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
}

.media_item_controllers button img {
	width: 10px;
	height: 10px;
}

/* POPOVER FOOTER CSS */

.sys_popover_items {
	/* min-width: 123px; */
	background-color: #ffffff;
	border-radius: 10px;
	padding: 10px 0;
	box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
	/* margin-top: 5px; */
}

.sys_popover_item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;

	padding: 4px 8px;
}

.sys_popover_item:hover {
	background-color: #e7e7e8;
	cursor: pointer;
}

.sys_popover_item img {
	width: 14px;
	height: auto;
	margin-right: 10px;
	line-height: 1;
}

.sys_popover_item button {
	font-size: 12px;
	font-weight: var(--font-weight-semibold);

	position: relative;
}

.contact_item {
	width: 100%;
	/* height: 65px; */
	/* border-bottom: 1px var(--gray30) solid; */
	padding: 0 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.contact_item:hover {
	background-color: var(--gray30);
	cursor: pointer;
}

.contact_item_wrapper {
	width: 100%;
	/* height: 45px; */

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	padding: 5px 0;
	border-bottom: 1px var(--gray30) solid;
}

.contact_item:last-of-type .contact_item_wrapper {
	border-bottom: none;
}

.filepreview_container {
	/* display: flex;
    align-items: center;
    justify-content: space-around; */

	padding: 20px;
	border: 2px solid black;
	border-radius: 10px 10px 0 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}

.filepreview_container img {
	width: 70px;
	height: auto;
}

.filepreview_container p {
	width: 100%;
	font-size: 14px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
}

.location_item {
	width: 100%;
	height: 50px;
	/*padding: 0 15px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.location_item:hover {
	background-color: var(--gray20);
	cursor: pointer;
}

.location_item img {
	width: 30px;
	height: 30px;
}

.location_item div {
	margin-left: 15px;
	flex: 1;
	height: 100%;
	/*background-color: #1a8897;*/
	padding: 5px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
}

.location_item .name {
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	/* font-weight: var(--font-weight-semibold);
	line-height: 19px;
	color: #000;
	cursor: inherit; */

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.location_item .location {
	width: 100%;

	font-weight: var(--font-weight-extralight);
	font-size: 12px;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	-webkit-box-orient: vertical;
}

.back_btn {
	display: none;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.reply_container {
		left: 75px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.reply_container {
		left: 45px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.reply_container {
		left: 35px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .profile_info {
        display: none;
    } */
	.reply_container {
		left: 30px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .profile_info {
        display: none;
    } */
	.reply_container {
		left: 10px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	/* .profile_info {
        display: none;
    } */

	.back_btn {
		display: block;
	}

	.reply_container {
		left: 25px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.profile_info .name {
		font-size: 14px;
	}
	.profile_info .status {
		font-size: 12px;
	}

	.header_leftside > img:first-of-type {
		width: 20px;
	}

	.header_options button img {
		height: 19px;
	}
	.reply_container {
		left: 10px;
	}

	.back_btn {
		display: block;
	}
}
