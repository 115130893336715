.container {
	/* margin-top: 10px; */
	width: 100%;
	height: fit-content;
	/*background-color: #1a8897;*/

	/*display: none;*/
	transition: all 0.5s ease-in-out;

	padding: 0 10px 0 50px;
}

.visible {
	display: block;
}

.reply_item {
	margin-top: 10px;
	width: 100%;
	height: fit-content;
	/*background-color: #cfc6a3;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	/*align-items: center;*/

	/*border-left: var(--gray80) 2px solid;*/
}

.reply_section {
	flex: 1;
	height: fit-content;
}

.reply_wrapper {
	padding: 5px 10px;

	width: fit-content;
	height: fit-content;
	min-height: 50px;

	background-color: var(--gray20);

	border-radius: 5px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

.owner {
	width: fit-content;
	height: 22px;
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	line-height: 16px;
	color: #000;
	cursor: pointer;
}

.owner:hover {
	text-decoration: underline;
}

.time {
	width: fit-content;
	height: 22px;

	margin-left: 5px;

	font-size: 11px;

	color: #000;
}

.reply_text {
	width: 100%;
	height: fit-content;

	font-size: 12px;
	line-height: 16px;
	color: #000;
}

.reply_reactions_wrapper {
	width: 100%;
	height: 16px;

	margin-top: 5px;
	padding: 0 0 0 34px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.reply_reactions_wrapper label {
	width: fit-content;
	height: fit-content;

	margin: 0 5px 0 0;

	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	color: #000;

	cursor: pointer;
}

.reply_reactions_wrapper button {
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	color: #000;
}

.seperator {
	width: 1px;
	height: 70%;
	margin: 0 10px;
	background-color: var(--gray30);
}

.load_more_button {
	width: 100%;
	height: fit-content;

	padding: 5px 0 0 34px;

	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.load_more_button button {
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	/*line-height: 22px;*/
	color: #000;
}

.relpy_add_wrapper {
	width: 100%;
	height: 50px;

	/*border-top: var(--gray30) 0.3px solid;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.reply_text_wrapper {
	font-size: 12px;
	flex: 1;
	height: 30px;

	/*background-color: #1a8897;*/
}
