.container {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 72px 76px 0;
  position: relative;
  z-index: 2;
}

.gaawkIcon {
  width: 115px;
  height: 36px;
}

.buttonView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  height: 100%;
}

.loginText {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 21.182px;
  font-style: normal;
  font-weight: 500;
  //line-height: 59.627px;

  //   cursor: pointer;
}

.button {
  margin-left: 30px;
  width: 195px;
  height: 46px;

  border-radius: 8px;
  background: #6CC5D1;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.buttonText {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 21.182px;
  font-style: normal;
  font-weight: 500;
}

.menuImg {
  width: 16px;
  height: 12px;
  cursor: pointer;
  display: none;
}

.menuContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 219px;
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.25);
  z-index: 4;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.menuWrapper {
  width: 320px;
  height: 100%;
  //border: #f6db71 2px solid;
  padding: 48px 42px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.menuIcons {
  width: 100%;
  height: 25px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 25px;
}

.menuIconsGaawk {
  width: 78px;
  height: 25px;
}

.menuIconsClose {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.menuItem {
  width: 100%;
  height: 34px;
  border-bottom: #e9e9e9 1px solid;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
}

.menuItemText {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
}

/*others*/
@media screen and (max-width: 1279.98px) {
  .container {
    padding: 48px 42px 0;
  }
  .gaawkIcon {
    width: 78px;
    height: 25px;
  }

  .loginText {
    display: none;
  }

  .button {
    display: none;
  }

  .menuImg {
    display: block;
  }
}