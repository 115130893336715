.container {
	width: 100%;
	max-height: 450px;
	padding: 0 20px 20px;
	overflow-y: auto;
}

.search_input_container {
	padding: 20px;
	/* background-color: #dbdfe4; */
}

.contact_item {
	width: 100%;
	padding: 0 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.contact_item_wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	padding: 5px 0;
	border-bottom: 1px var(--gray30) solid;
}

.contact_item:last-of-type .contact_item_wrapper {
	border-bottom: none;
}
