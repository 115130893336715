.padd_lr_10 {
	padding: 0;
}

.hide_post_controls {
	display: none;
}

.create_post_section {
	margin-bottom: 20px;
	padding: 15px;

	width: 100%;
	height: fit-content;

	border-radius: 10px;
	background-color: #000;

	display: flex;
	gap: 15px;

	align-items: center;
}

/* .create_post_profile_img {
	flex: 0 0 70px;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
} */

.profile_img {
	width: 50px;
	height: 50px;

	border-radius: 50%;

	border: #ffffff 2px solid;

	background-color: var(--gray50);
}

.profile_img.company {
	/* border-radius: 2px; */
	border-radius: 23%;
}

.profile_img img {
	object-fit: fill;
	border-radius: 50%;
}

.profile_arrow {
	width: 16px;
	height: 16px;
}

.create_post_text_reaction_section {
	flex: 1;

	/* height: fit-content; */

	/* padding: 0 0 0 11px; */
}

.create_post_input {
	/* width: 100%; */
	height: 30px;
	padding: 0 12px;
	/*padding: 0 16px 2px !important;*/
	border-radius: 4px;

	background-color: #ffffff;

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.create_post_input label {
	font-size: 14px;
}

/* .create_post_icons_icon img {
	width: 25px;
	height: 25px;
} */
.icon {
	width: 24px;
	height: auto;
	fill: #000;
}

.create_post_modal_container {
	width: 100%;
	height: fit-content;

	/*background-color: #1a8897;*/

	padding: 20px 30px 30px;
}

.scrollable_modal {
	max-height: calc(100dvh - 250px);
	overflow-y: auto;
}

.user_wrapper {
	width: 100%;
	height: 45px;
	/*background-color: #1a8897;*/
	border-radius: 50%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.user_wrapper img {
	/* width: 45px; */
	height: 100%;

	border-radius: 50%;
}

.user_wrapper label {
	margin-left: 15px;
	font-weight: var(--font-weight-semibold);
	font-size: 15px;
	font-weight: var(--font-weight-semibold);
	line-height: 19px;
	text-align: left;
	color: #000;
}

.title_input {
	margin-top: 20px;
}

.title_input label {
	margin-bottom: 6px;
	display: block;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

.title_input input[type="text"] {
	height: 35px;
}

.create_body_wrapper {
	margin-top: 20px;
	width: 100%;
	height: fit-content;
}

.post_text_container {
	width: 100%;
	height: 177px;

	/*padding: 10px;*/

	/*padding: 9px 5px 10px 14px;*/
	/*border-radius: 5px;*/
	/*border: solid 1px var(--gray50);*/

	/*background-color: #4cd964;*/
}

.post_location_map_container {
	margin-top: 10px;
	width: 100%;
	height: 177px;

	border-radius: 5px;

	position: relative;
}

.location_clear_button {
	position: absolute;
	right: 5px;
	top: 5px;

	width: 25px;
	height: 25px;
	background-color: var(--gray80);
	border-radius: 2px;
}

.location_clear_button img {
	width: 10px;
	height: 10px;
	padding-bottom: 2px;
}

.create_post_media_container {
	margin-top: 15px;
	width: 100%;
	/* height: 210px; */
	border-radius: 10px;
	position: relative;
}

.create_post_media_container video {
	border-radius: 10px;
	min-height: unset;
	max-height: unset;
}

.create_post_media_controllers {
	position: absolute;
	top: 10px;
	left: 0;

	width: 100%;
	height: 30px;

	z-index: 2;

	padding: 0 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

/* .create_post_media_controllers.video {
	justify-content: flex-end;
} */

.create_post_media_controllers button {
	height: 100%;
	border-radius: 5px;
	background-color: #fff;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
}

.create_post_media_controllers button label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.create_post_media_controllers button img {
	width: 10px;
	height: 10px;
}

.edit_post_media_button {
	width: 70px;
}

.edit_post_media_button img {
	width: 15px !important;
	height: 15px !important;
}

.add_post_media_button {
	width: 177px;
}

.remove_post_media_button {
	width: 30px;
}

.post_buttons_wrapper {
	margin: 15px 0px;

	/* padding: 0 20px; */

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.post_buttons_wrapper button {
	display: flex;
	align-items: center;
}

.post_visibility_row {
	display: flex;
	justify-content: space-between;
	align-items: center;

	/*background-color: #cfc6a3;*/

	margin: 20px 0;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

/* .post_visibility_row label {
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    font-weight: var(--font-weight-semibold);

    text-align: left;
    color: #000;
} */

.board_visibility {
	display: flex;
	align-items: center;
	gap: 10px;
}

.board_visibility img {
	width: 18px;
	height: 18px;
}

.visibility_wrapper {
	/*flex: 1;*/
	width: 160px;
	height: 30px;
}

.post_button_wrapper {
	margin-top: 20px;
	width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.info {
	margin-top: 10px;
	font-size: 12px;
	font-style: italic;
}

/*Tags modal*/
/* .search_input {
    width: 100%;
    height: 35px;


    font-size: 14px;
    line-height: 16px;
    color: #000;
} */

/*
.lables {
    margin-top: 15px;
    width: 100%;
    height: 17px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    font-size: 12px;
    line-height: 19px;
    text-transform: uppercase;
}

.lables label {

    color: #a6b1bc;
    text-transform: inherit;
}

.lables button {
    font-weight: var(--font-weight-semibold);
    color: var(--gaawk);;
    text-transform: inherit;
}

.selected_profiles_wrapper {
    width: 100%;
    height: fit-content;
    margin-bottom: 10px;
    margin-top: 10px;
}

.selected_profiles_wrapper label {

    font-size: 12px;
    line-height: 19px;
    color: #a6b1bc;
    text-transform: uppercase;
}

.selected_profiles {
    margin-top: 5px;

    width: 100%;

    height: fit-content;
    max-height: 120px;
    overflow-y: auto;

    border-radius: 5px;
    border: solid 1px var(--gray30);

    padding: 8px;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
}

.selected_profiles div.item {
    width: fit-content;
    height: 25px;

    padding: 4px 5px;
    border-radius: 5px;
    background-color: var(--gaawk);;

    margin: 0 10px 10px 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.selected_profiles div.item label {
    font-weight: var(--font-weight-semibold);
    font-size: 12px;
    font-weight: var(--font-weight-semibold);
    line-height: 16px;
    text-align: left;
    color: #fff;
    text-transform: none;
}

.selected_profiles div.item img {
    margin-left: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.profile_list_wrapper {
    margin-top: 15px;
    width: 100%;
    height: 300px;
    overflow-y: auto;
}

.profile_item {
    width: 100%;
    height: 50px;
    border-top: var(--gray30) 1px solid;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.profile_item:last-child {
    border-bottom: var(--gray30) 1px solid;
}

.profile_item:hover {
    cursor: pointer;
    background-color: var(--gray30);
}

.profile_icon {
    width: 30px;
    height: 30px;
}

.profile_icon.no_img {
    background-color: #000;
    border: var(--gray30) 2px solid;
}

.profile_icon.no_img.user {
    border-radius: 50%;
}

.profile_icon.no_img.company {
    border-radius: 5px;
}

.profile_icon img.user {
    border-radius: 50%;
}

.profile_icon img.company {
    border-radius: 5px;
}

.profile_icon img {
    border: var(--gray30) 2px solid;
}

.profile_name {
    margin: 0 0 0 10px;
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    line-height: 16px;
    color: #000;
    cursor: inherit;
} */

/*
emotion modal
 */

/* .emotion_list_wrapper {
    margin-top: 20px;
    width: 100%;
    height: 500px;
    overflow-y: auto;
}

.emotion_item {
    width: 100%;
    height: 50px;
    padding: 0 15px;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.emotion_item:hover {
    background-color: var(--gray20);
    cursor: pointer;
}

.emotion_item img {
    width: 40px;
    height: 40px;
}

.emotion_item label {
    margin-left: 22px;

    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    line-height: 19px;
    color: #000;
    cursor: inherit;
} */

/*.media_viewer_contaienr {*/
/*    width: 100%;*/
/*    height: 400px;*/

/*    !*max-height: calc(100dvh - 65px);*!*/
/*    overflow-y: auto;*/
/*}*/

.media_viewer_item {
	width: 100%;
	height: fit-content;

	position: relative;
	margin-top: 20px;
}

/*.media_viewer_item_controller {*/
/*    position: absolute;*/

/*    top: 10px;*/
/*    left: 0;*/

/*    width: 100%;*/
/*    height: 30px;*/
/*    padding: 0 10px;*/

/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    justify-content: space-between;*/
/*    align-content: center;*/
/*    align-items: center;*/
/*}*/

.media_viewer_item:first-child {
	margin-top: 0;
}

.media_viewer_item img {
	width: 100%;
	height: auto;
	border-radius: 5px;
	object-fit: cover;
}

.media_viewer_item .doc_icon_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;
}

.media_viewer_item .doc_icon {
	width: 60px;
}

.media_caption_container {
	width: 100%;
	height: 98px;

	/*padding: 9px 5px 9px 14px;*/
	border-radius: 5px;
	border: solid 1px var(--gray50);

	margin-top: 10px;
}

.loader_wrapper {
	text-align: center;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;
	/* flex: auto; */

	position: relative;
}

.form_input_wrapper.media_edit {
	margin-bottom: 20px;
}

.form_input_wrapper label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	margin-bottom: 8px;
	display: block;
}

.form_input_wrapper textarea {
	width: 100%;
	min-height: 96px;
	margin: 10px 0 20px 0px;
	padding: 5px 10px;
	object-fit: contain;
	border-radius: 5px;
	border: solid 1px var(--gray50);
	resize: vertical;
	overflow: hidden;

	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
}

/* .form_input_wrapper textarea:focus {
	border: #6cc5d1 solid 2px !important;
} */

.media_data {
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
	text-align: left;
	color: var(--black);
	margin-top: 10px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.media_data > div {
	display: flex;
	align-items: center;
	gap: 5px;
}

.media_data img {
	width: auto;
	height: 20px;
	margin-right: 10px;
}

.media_data .delete_data {
	width: 17px;
	cursor: pointer;
}

.taggedProfiles_container {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	align-items: center;
	margin-top: 10px;
}

.taggedItem {
	position: relative;
}

.taggedItem .close_btn {
	width: 18px;
	height: 18px;
	position: absolute;
	top: -2px;
	right: -5px;
	cursor: pointer;
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	align-items: center;
}

.button_container {
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
}

/* .btn.cancel {
    background-color: var(--error200);
    color: #ffffff;
    border: none;
} */

.selected_tags_container {
	margin-top: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.profile_img {
		width: 40px;
		height: 40px;
	}

	/* .create_post_input {
		height: 30px;
	}

	.create_post_input label {
		font-size: 15px;
	} */

	/* .create_post_icons_icon img {
		width: 22px;
		height: 22px;
	} */

	.post_item {
		padding: 15px 15px 0;
	}

	.post_text {
		margin-top: 10px;

		font-size: 14px;
		line-height: 16px;
	}

	.post_media_container {
		margin-top: 10px;
		height: fit-content;
		max-height: 453px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 225px;
		max-height: 453px;
	}

	.comment_count {
		font-size: 13px;
	}

	.reactions_count label {
		font-size: 13px;
	}

	.post_footer {
		/*height: 90px;*/
		padding: 8px 0 0;
	}

	.reactions button {
		width: 20px;
		height: 20px;
	}

	.counts_text {
		font-size: 13px;
		letter-spacing: -0.26px;
	}

	.reaction_button_wrapper {
		height: 50px;
		margin-top: 10px;
	}

	.reaction_button_icon {
		height: 18px;
	}

	.reaction_button_icon img {
		width: 18px;
	}

	.reaction_button_icon label {
		margin: 0 0 0 8px;
		font-size: 14px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.profile_img {
		width: 40px;
		height: 40px;
	}

	/* .create_post_input {
		height: 30px;
	}

	.create_post_input label {
		font-size: 15px;
	} */

	/* .create_post_icons_icon img {
		width: 22px;
		height: 22px;
	} */
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .create_post_profile_img {
		flex: 0 0 50px;
	} */

	.profile_img {
		width: 30px;
		height: 30px;
	}

	.profile_arrow {
		width: 12px;
		height: 12px;

		margin-left: 2px;
	}

	.profile_img {
		width: 30px;
		height: 30px;
	}

	/* .create_post_input {
		height: 25px;
	}

	.create_post_input label {
		font-size: 13px;
	} */

	/* .create_post_icons_icon img {
		width: 18px;
		height: 18px;
	} */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.profile_img {
		width: 40px;
		height: 40px;
	}

	/* .create_post_icons_icon img {
		width: 22px;
		height: 22px;
	} */
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .create_post_profile_img {
		flex: 0 0 50px;
	} */

	.profile_img {
		width: 30px;
		height: 30px;
	}

	.profile_arrow {
		width: 12px;
		height: 12px;

		margin-left: 2px;
	}

	/* .create_post_input {
		height: 25px;
	}

	.create_post_input label {
		font-size: 13px;
	} */

	/* .create_post_icons_icon img {
		width: 18px;
		height: 18px;
	} */

	.post_visibility_row label {
		font-size: 12px;
	}

	.visibility_wrapper {
		width: 130px;
	}

	/* .post_buttons_wrapper {
		padding: 0 5px;
	} */
}
