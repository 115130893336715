.header {
	font-size: 18px;
	font-weight: var(--font-weight-bold);

	display: flex;
	align-items: center;
	gap: 20px;
	max-width: 60%;

	p {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.container {
	padding: 20px;
	background-color: #fff;

	.important_info {
		flex-shrink: 0;
		span {
			font-weight: var(--font-weight-semibold);
		}
	}

	.company_wrapper {
		margin: 20px 0;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.date_container {
		font-size: 10px;
		margin: 20px 0;
		display: flex;
		align-items: center;
		gap: 25px;

		hr {
			width: 100%;
			border-top: 0.5px solid #dbdfe4;
		}
	}

	.button_container {
		display: flex;
		justify-content: center;
		.cta_btn,
		.apply_btn {
			width: 320px;
		}

		.application_info {
			width: 100%;
			font-weight: var(--font-weight-semibold);
			& > div {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}
	}

	.other_info_container {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.tags_container {
			display: flex;
			align-items: center;
			gap: 10px;
			flex-wrap: wrap;
			overflow: hidden;
		}

		.company_stats p {
			font-weight: var(--font-weight-semibold);
		}
	}

	.jobs_wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;

		& > div {
			padding-bottom: 20px;
			border-bottom: 1px solid var(--gray30);
		}
		& > div:last-of-type {
			padding-bottom: 0px;
			border-bottom: none;
		}
	}
}
