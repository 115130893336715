@use "variables" as *;

.container {
	width: 100%;
	// height: calc(100dvh - $topNavBarHeight);
	flex-grow: 1;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.gaawk_logo {
		width: 139px;
		height: auto;
		margin-bottom: 21px;
	}

	& > p {
		text-align: center;
		font-size: 12px;
		color: #000;
		margin-bottom: 16px;
	}

	.search_input {
		background-color: var(--gray20);
		width: 400px;
	}

	.location_container {
		display: block;
		margin: 20px auto;
	}

	.location_wrapper {
		display: flex;
		align-items: center;
		gap: 20px;

		& > span {
			flex-shrink: 0;
		}

		& > p {
			font-weight: var(--font-weight-bold);
			font-size: 12px;
		}

		.forward_icon {
			fill: #686e76;
			width: 15px;
			transform: rotate(90deg);
		}
	}
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.container {
		.search_input {
			width: 350px;
		}
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
	.container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight - 35px);
	}
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	.container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight - 35px);
		.search_input {
			width: 90vw;
		}
	}
}

@media screen and (max-width: 319px) {
	.container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight - 35px);
		.search_input {
			width: 90vw;
		}
	}
}
