.title {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	margin-bottom: 10px;
	display: block;
}

.options_container {
	display: grid;
	grid-template-columns: repeat(4, auto);
	gap: 10px;
	align-items: flex-start;
}

.info_text {
	font-size: 12px;
	color: #000000;
	/* margin: 10px 0px; */
	margin-top: 10px;
	display: block;
}

p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.input_wrapper {
	display: flex;
	/* align-items: center; */
	gap: 15px;
}

.checkbox {
	margin-top: 3px;
	margin-right: 0px !important;
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.options_container {
		grid-template-columns: repeat(2, auto);
	}
}

@media screen and (max-width: 639.98px) {
	.options_container {
		grid-template-columns: repeat(2, auto);
	}
}
