.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	.loading {
		height: 100%;
	}

	.info p {
		font-size: 12px;
	}

	.main_container {
		margin: 30px 0;
		.feature_item {
			padding: 12px 0;
			border-bottom: 1px solid var(--gray30);
			display: flex;
			align-items: center;
			gap: 10px;
			font-weight: var(--font-weight-semibold);
			font-size: 12px;

			.bold {
				font-weight: var(--font-weight-bold);
			}

			.warning {
				color: var(--error200);
				font-size: 10px;
			}

			&:last-of-type {
				border-bottom: none;
			}

			.icon_wrapper {
				background-color: #000;
				width: 16px;
				height: 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				padding: 2px;
			}
		}
	}

	.disclaimer {
		display: flex;
		align-items: flex-start;
		gap: 10px;
		font-size: 12px;
	}

	.button_container {
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}
}
