/*.container {*/
/*    width: 100%;*/
/*    height: 100%;*/

/*    */
/*}*/

.select_container {
	width: 100%;
	height: 30px;
	padding: 5px 8px 5px 6px;
	border-radius: 5px;
	background-color: var(--gray20);

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;

	cursor: pointer;

	position: relative;
}

.selected_value {
	width: 100%;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.selected_value div {
	width: fit-content;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.selected_value div img {
	width: 20px;
	height: 20px;
	margin-right: 8px;
}

.selected_value img.arrow {
	width: 12px;
	height: 7px;
}

.selected_value label {
	font-size: 13px;
	cursor: inherit;
	/*font-weight: var(--font-weight-semibold);*/
	/*line-height: 1.46;*/
}

.option_list_container {
	position: absolute;
	top: 32px;
	left: 0;
	width: 100%;
	height: fit-content;
	border-radius: 5px;
	z-index: 9999;

	background-color: var(--gray20);
}

.option_list {
	width: 100%;
	height: fit-content;

	padding: 5px 10px 2px;
	margin: 0;
	/*border-radius: 50%;*/
}

.option_list li {
	width: 100%;
	height: 30px;
	/*padding: 2px 0;*/

	border-bottom: var(--gray30) 1px solid;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.option_list li:last-child {
	border-bottom: none;
}

.option_list li label {
	font-size: 12px;
	cursor: inherit;
}

.option_list li span {
	width: 18px;
	height: 18px;
	/*background-color: #1a8897;*/
	border-radius: 50%;
}

.no_padding {
	display: flex;
}

.no_padding input + span {
	padding-left: 0px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

/*360*/
@media screen and (max-width: 639.98px) {
	.selected_value div img {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}

	.selected_value img.arrow {
		width: 8px;
		height: 7px;
	}

	.selected_value label {
		font-size: 11px !important;
		cursor: inherit;
		/*font-weight: var(--font-weight-semibold);*/
		/*line-height: 1.46;*/
	}
}
