.container {
	width: 100%;

	padding: 20px;

	background-color: #ffffff;
}

.form {
	width: 100%;
	height: fit-content;
}

/* NORMAL INPUT (TITLE) */

/* to remove margin top of the first div (title) */
.form div:first-child {
	margin-top: 0px;
}

.form_input_wrapper {
	margin-top: 20px;
	width: 100%;
	height: fit-content;
	position: relative;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000000;
	display: inline-block;
}
.form_input_wrapper input {
	width: 100%;
	height: 35px;
	margin: 7px 0 0;
	border-radius: 5px;
	/* border: solid 1px var(--gray50) !important; */
	padding: 7px;

	font-size: 14px;
}
/*
.form_input_wrapper input:focus {
    border: #6cc5d1 solid 2px !important;
} */

/* REACT-SELECT INPUT */

.react_select_wrapper {
	margin: 15px 0;

	width: 100%;
	height: fit-content;

	display: flex;
	flex-direction: column;
}

.react_select_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000000;
	margin-bottom: 7px;
}

.react_select_wrapper p.error_message,
.datePickerWrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

/* DATEPICKER */

.datePickerWrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 20px;
	gap: 40px;
}

.datePickerItem {
	width: 240px;
	display: flex;
	flex-direction: column;
}

/* .datePickerItem:first-of-type {
    margin-right: 40px;
} */

.datePickerItem label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000000;
	display: inline-block;

	margin-bottom: 7px;
}

/* REACT SWITCH */

.react_switch {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.react_switch span {
	font-size: 14px;

	color: #000000;

	display: block;
	margin-left: 20px;
}

/* DETAILS */

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

/* CURRENT POSITIONS */

.current_positions {
	margin-top: 20px;
}

.current_positions label {
	font-size: 14px;

	color: var(--black);
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.info_text {
	margin-top: 10px;

	font-size: 12px;
	font-style: italic;
	color: var(--black);
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.datePickerWrapper {
		gap: 20px;
	}
}

@media screen and (max-width: 639.98px) {
	.datePickerWrapper {
		gap: 20px;
	}

	.button_container button {
		width: 100%;
	}
}
