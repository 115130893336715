.container {
	background-color: white;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.boards_wrapper {
	background-color: #fff;
	flex-grow: 1;
}
