.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.container label {
	overflow-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

	font-size: 12px;
	color: #000;
	font-weight: var(--font-weight-bold);

	width: 80px;
}

.thumb_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background-color: var(--gray20);
	border-radius: 5px;
	position: relative;

	width: 80px;
	height: 80px;
}

.thumb_wrapper .close {
	position: absolute;
	top: 4px;
	right: 4px;
	width: 24px;
	height: 24px;
	z-index: 2;
	border-radius: 100%;
}

.thumb_wrapper img {
	display: block;
	object-fit: cover;
}

.thumb_wrapper .icon {
	object-fit: fill;
	width: 50px;
	height: 50px;
	border-radius: 0px;
}

.thumb_wrapper .play_icon {
	position: absolute;
	width: 40px;
	height: 40px;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
