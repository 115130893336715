.container {
	display: flex;
	flex-direction: column;
	gap: 20px;

	button {
		display: flex;
		align-items: center;

		h3 {
			font-weight: var(--font-weight-bold);
		}

		.forward_icon {
			width: 20px;
			fill: #000;
			transform: rotate(90deg);
			transition: transform 0.3s ease;
		}

		.forward_icon.rotate {
			transform: rotate(-90deg);
			transition: transform 0.3s ease;
		}
	}
}
