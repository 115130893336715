.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	gap: 10px;
}

.icon {
	fill: #000;
}

.conversation_controls {
	display: flex;
	align-items: center;
	gap: 10px;
	height: 100%;
	border-left: 1px solid #dbdfe4;
	padding-left: 10px;
}
