input.input[type="radio"] {
	/* remove standard background appearance */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* create custom radiobutton appearance */
	/*display: inline-block;*/
	width: 15px;
	height: 15px;
	padding: 2px;
	/* background-color only for content */
	background-clip: content-box;
	border: 2px solid #bbbbbb;
	/*background-color: #e7e6e7;*/
	border-radius: 50%;
	flex-shrink: 0;
	cursor: pointer;
}

/* appearance for checked radiobutton */
input.input[type="radio"]:focus {
	/* background-color: var(--gaawk);; */
	border: 2px solid #6cc5d1;
	/* padding: 2px; */
}

input.input[type="radio"]:checked {
	background-color: var(--gaawk);
	border: 2px solid #6cc5d1;
	padding: 2px;
}
