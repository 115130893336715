.filter_item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon {
    width: 11px;
    height: 11px;
}
