@use "variables" as *;

.main {
	display: flex;
	flex-direction: column;
	height: calc(100vh - $topNavBarHeight);
}

.container {
	display: flex;
	justify-content: space-between;
	position: relative;
	// max-width: 2000px;
}

.bottomNavBar {
	display: none;
}

@media screen and (max-width: 747.98px) {
	.bottomNavBar {
		display: flex;
		flex-grow: 1;
	}
}
