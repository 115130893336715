.item_container {
	width: 100%;
	padding: 20px;
}

.products_wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
}
.item_image {
	width: 334px;
	height: 268px;
	object-fit: cover;
	margin-right: 16px;
}
.forward_icon {
	width: 10px;
	height: 13px;
	position: relative;
	top: 4px;
}
.products_image {
	height: 79px;
	width: 79px;
	border-radius: 6px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	cursor: grab;
}
.item_content_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.item_service_name {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	text-align: left;
	color: #000;
	text-transform: uppercase;
}
.content_wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
}
.products_text {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}
.learn_more_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.explore_text {
	font-weight: var(--font-weight-bold);
	font-size: 14px;
	color: var(--gray80);
	margin-right: 10px;
}
.horizontal_line {
	border-top: 6px solid var(--gray20);
	width: 100%;
	margin: 0 auto;
	margin-bottom: 20px;
}
.description {
	/* padding-top: 2px; */
	margin-top: 20px;

	font-size: 14px;
	color: #000;
	word-break: break-word;

	height: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.flex_row_between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.products_container {
	margin-top: 10px;
	width: 100%;
}

.header_wrapper.min_height_50 {
	min-height: 50px;
}
.scroll_container {
	width: 200px;
	gap: 10px;
}

.intro_title_wrapper {
	background-color: rgba(114, 122, 130, 0.8);
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 5px 20px;
	color: white;
	text-transform: uppercase;
	font-weight: var(--font-weight-bold);
}

.intro_title_wrapper span {
	font-size: 10px;
}

.intro_title_wrapper h3 {
	font-size: 14px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.breadcrumb_wrapper {
	background-color: var(--gray20);
	padding: 5px 20px;

	font-weight: var(--font-weight-bold);
	color: #000;
	font-size: 12px;

	display: flex;
	align-items: center;
	gap: 5px;
}

.breadcrumb_wrapper span {
	display: flex;
	align-items: center;
	gap: 5px;
}

.breadcrumb_wrapper img {
	width: 14px;
}

.description_wrapper {
	padding: 20px;
	background-color: #fff;
}

.button_container {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	gap: 20px;
}

.profile_wrapper {
	display: flex;
	align-items: center;
	padding: 20px 20px 0;
	background-color: #fff;
}
.scroll_wrapper {
	padding: 0px;
}
@media screen and (max-width: 1165px) {
	.item_image {
		width: 100%;
		height: 100%;
		margin-right: 0px;
		margin-bottom: 8px;
	}

	.learn_more_container {
		justify-content: center;
	}
}

@media screen and (min-width: 1166px) {
	.item_content_container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		height: 100%;
	}
	.learn_more_container {
		justify-content: end;
	}
}

@media screen and (min-width: 1608px) {
	.forward_icon {
		top: 1px;
	}

	/* .products_wrapper {
		height: auto;
	} */

	.image_container {
		height: 205px;
	}

	.item_image {
		width: 256px;
		height: 205px;
	}
	.horizontal_line {
		display: none;
	}

	.products_image {
		height: 58px;
		width: 58px;
	}
}
