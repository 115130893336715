.company_item {
	display: flex;
	gap: 20px;
	align-items: center;
	/* width: 300px; */
	cursor: default;
	overflow: hidden;
}

.isClickable {
	cursor: pointer;
}

.company_item.border {
	width: 100%;
	padding-bottom: 10px;
	border-bottom: 1px solid #dbdfe4;
}

.company_item.border:last-child {
	border: none;
}

.logo_container {
	width: 48px;
	height: 48px;
	border-radius: 3px;
	border: 1px solid #dbdfe4;
}

/* .logo_container img {
    object-fit: contain;
    border-radius: 5px;
} */

.company_details h4 {

	margin: 0;
}

.company_details span {

	font-size: 10px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: var(--black);
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.company_item {
		width: 100%;
		padding-bottom: 10px;
	}

	.company_item:last-child {
		border: none;
		padding-bottom: 0px;
	}

	.company_item.border {
		border-bottom: 1px solid #dbdfe4;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.company_item {
		width: 100%;
		padding-bottom: 10px;
	}

	.company_item:last-child {
		border: none;
		padding-bottom: 0px;
	}
	.company_item.border {
		border-bottom: 1px solid #dbdfe4;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.company_item {
		width: 100%;
		padding-bottom: 10px;
	}

	.company_item:last-child {
		border: none;
	}
	.company_item.border {
		border-bottom: 1px solid #dbdfe4;
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	.company_item {
		width: 100%;
		padding-bottom: 10px;
	}

	.company_item:last-child {
		border: none;
		padding-bottom: 0px;
	}
	.company_item.border {
		border-bottom: 1px solid #dbdfe4;
	}
}
