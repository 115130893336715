.form_container {
	padding: 20px;
	background-color: #fff;
}

.form_input_wrapper {
	margin-bottom: 20px;
	width: 100%;
	height: fit-content;

	display: flex;
	flex-direction: column;
}

.text_area {
	height: 200px;
}

.input_wrapper {
	margin-top: 0;
	margin-bottom: 20px;
}

.input_label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	margin-bottom: 8px;
}

.form_input_wrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
}

.form_input_wrapper input {
	width: 100%;
	height: 35px;
	border-radius: 5px;
	/* border: solid 1px var(--gray50); */
}

.create_button_wrapper {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	margin-top: 20px;
}

/* .button {
	width: 145px;
	height: 35px;
	font-weight: var(--font-weight-bold);
	font-size: 14px;
	text-align: center;
	color: #fff;
	white-space: nowrap;
}
.button.save {
	background-color: var(--gaawk);
}

.button.delete {
	background-color: var(--error200);
	border: none;
} */

/* .form_input_wrapper textarea {
	width: 100%;
	min-height: 140px;
	padding: 5px 10px;
	border-radius: 5px;
	border: solid 1px var(--gray50);
	resize: vertical;


	font-size: 14px;
}

.form_input_wrapper textarea:focus {
	border: #6cc5d1 solid 2px !important;
} */

.form_input_wrapper .custom_select_wrapper {
	width: 100%;
	height: 35px;
}

.visibility_wrapper {
	width: 173px;
}

.visibility_wrapper input {
	height: auto;
}

.visibility_wrapper label:last-child {
	margin: 0;
}

.datepicker {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.profile_item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.profile_item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.contributors_container {
	margin-top: 15px;
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.modal_container {
	padding: 20px;
}

.info_text {
	font-size: 14px;
}

.btn_container {
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}

.member_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.shrink {
	flex-shrink: 1;
}

.member_item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

@media screen and (max-width: 639px) {
	.btn_container {
		flex-direction: column-reverse;
		gap: 10px;
	}
	.btn_container button {
		width: 100%;
	}
}
