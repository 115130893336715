.media_viewer_item {
	width: 100%;
	height: fit-content;

	position: relative;
	margin-top: 20px;
}

.media_viewer_item:first-child {
	margin-top: 0;
}

.media_viewer_item img {
	width: 100%;
	height: auto;
	border-radius: 5px;
	object-fit: cover;
}

.media_viewer_item .doc_icon_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;
}

.media_viewer_item .doc_icon {
	width: 60px;
}

.media_viewer_item .play_icon {
	position: absolute;
	width: 40px;
	height: 40px;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.media_caption_container {
	width: 100%;
	height: 98px;

	/*padding: 9px 5px 9px 14px;*/
	border-radius: 5px;
	border: solid 1px var(--gray50);

	margin-top: 10px;
}

.create_post_media_controllers {
	position: absolute;
	top: 10px;
	left: 0;

	width: 100%;
	height: 30px;

	z-index: 2;

	padding: 0 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.create_post_media_controllers button {
	height: 100%;
	border-radius: 5px;
	background-color: #fff;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
}

.create_post_media_controllers button label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	line-height: 16px;
	color: #000;
	cursor: inherit;
}

.create_post_media_controllers button img {
	width: 10px;
	height: 10px;
}

.remove_post_media_button {
	width: 30px;
}

.edit_post_media_button {
	width: 70px;
}

.edit_post_media_button img {
	width: 15px !important;
	height: 15px !important;
}

.lazy_img {
	max-height: 400px;
}
