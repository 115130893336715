.user_item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #dbdfe4;
}

.user_item.canSelect:hover {
    cursor: pointer;
    background-color: #e3e6e9;
}

.user_item:last-of-type {
    border-bottom: none;
}

.user_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.user_name {
    font-size: 14px;
    flex-grow: 1;
}

.messageInfo_status_time {
    font-size: 12px;
}

@media screen and (max-width: 639.98px) {
    .user_details {
        display: block;
    }
}
