.scroll_container {
	height: 100%;
	overflow: hidden !important;
	overflow-x: scroll !important;

	padding: 20px 0;
	/* margin-top: 24px; */
}

.scroll_container::-webkit-scrollbar {
	width: 8px;
	background-color: transparent;
}

/**  STYLE 11 */
.scroll_container::-webkit-scrollbar-track {
	border-radius: 8px;
	background: transparent;
}

.scroll_container::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background: transparent;
}

.scroll_container::-webkit-scrollbar-thumb:hover {
	background: transparent;
}

.scroll_container::-webkit-scrollbar-thumb:active {
	background: transparent;
}

.scroll_container .tiles {
	padding: 0;
	margin: 0;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.col {
	width: fit-content;
	/* height: 20px; */
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	cursor: grab;
	white-space: nowrap;
	margin-right: 35px;
}
.col button {
	font-weight: var(--font-weight-semibold);
	font-size: 15px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.75px;
	color: #000;
	text-transform: uppercase;
	cursor: pointer;
	text-align: left;

	width: 100%;
	white-space: nowrap; /* Chrome */
}

.col .active {
	height: 25px;
	padding: 2px 7px 3px 6px;
	background-color: var(--gaawk);

	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */

	border-radius: 5px;

	font-weight: var(--font-weight-semibold);
	color: white;
}

.col button:hover:not(.active) {
	cursor: pointer;
	color: var(--gaawk);
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.scroll_container {
		padding: 15px 0;
		/* margin-top: 10px; */
	}

	.col button {
		font-size: 13px;
		letter-spacing: 0.65px;
	}

	.col .active {
		height: 23px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.scroll_container {
		padding: 15px 0;
		/* margin-top: 10px; */
	}

	.col button {
		font-size: 13px;
		letter-spacing: 0.65px;
	}

	.col .active {
		height: 23px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.scroll_container {
		padding: 10px 0;
		/* margin-top: 10px; */
	}

	.col button {
		font-size: 12px;
		letter-spacing: 0.6px;
	}

	.col .active {
		height: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.scroll_container {
		padding: 15px 0;
		/* margin-top: 10px; */
	}

	.col button {
		font-size: 13px;
		letter-spacing: 0.65px;
	}

	.col .active {
		height: 23px;
	}
}

@media screen and (max-width: 639.98px) {
	.scroll_container {
		padding: 10px 0;
		/* margin-top: 10px; */
	}

	.col button {
		font-size: 12px;
		letter-spacing: 0.6px;
	}

	.col .active {
		height: 20px;
	}
}
