.post_media_container {
	/*margin-top: 15px;*/

	width: 100%;
	/* width: 80%; */ /* ~ a implement sur les large screen ou il y a 2 columns seulement */
	margin: auto; /* ~ a implement sur les large screen ou il y a 2 columns seulement */
	height: fit-content;
	min-height: 250px;

	display: flex;
	gap: 3px;
	/* flex-wrap: wrap; */
	/* justify-content: space-around; */
	justify-content: center;
	/* align-content: space-between; */
	align-items: center;
}

.profile_pic {
	border-radius: 50%;
}

.post_media_container.preview {
	height: 100%;
	border-radius: 10px;
	/* background-color: #dbdfe4; */
}

.post_media_container.preview.chat {
	background-color: transparent;
}

.post_media_container .preview.chat {
	width: 100%;
}

.post_media_container:hover {
	cursor: pointer;
}

.post_media_container img {
	/*max-height: 100%;*/
	object-fit: cover !important;
}

.post_media_container video {
	/* min-height: 100% !important; */
	/* min-width: 100% !important; */
	/* height: auto; */
	object-fit: cover;
	/* min-height: 225px; //* commented because causing issues   */
	/* max-height: 452px; */
	aspect-ratio: 1;
}

/**
1 image
 */

.post_media {
	width: 100%;
	height: fit-content;
	position: relative;
}

.post_media img {
	aspect-ratio: 1;
}

.post_media.preview {
	width: 100%;
	height: 100%;
}
.post_media.preview.chat {
	height: 258px;
}

.post_media.preview img {
	border-radius: 10px;
	min-height: 100%;
	max-height: 100%;
}

/**
two images
 */

.post_media_2 {
	display: grid;
	grid-template-columns: calc(50% - 1.5px) calc(50% - 1.5px);
	gap: 3px;
	width: 100%;
}

.post_media_2_1 {
	aspect-ratio: 1;
	display: flex;
	align-items: center;
	width: 100%;
}

.post_media_2_1.preview:first-child img,
.post_media_2_1.preview:first-child video {
	border-radius: 10px 0 0 10px;
}

.post_media_2_1.preview:last-child img,
.post_media_2_1.preview:last-child video {
	border-radius: 0 10px 10px 0;
}

/**
post media 3 images
 */

.post_media_3 {
	display: grid;
	grid-template-columns: calc(50% - 1.5px) calc(50% - 1.5px);
	gap: 3px;
	width: 100%;
}

.post_media_3_1 {
	aspect-ratio: 1/2;
	display: flex;
	align-items: center;
}

.post_media_3_1.preview img,
.post_media_3_1.preview video {
	border-radius: 10px 0 0 10px;
}

.post_media_3_2 {
	display: grid;
	gap: 3px;
	width: calc(50% - 1.5px);
}

.post_media_3_2_1 {
	aspect-ratio: 1;
	display: flex;
	align-items: center;
}

.post_media_3_2_1.preview:first-child img,
.post_media_3_2_1.preview:first-child video {
	border-radius: 0 10px 0 0;
}

.post_media_3_2_1.preview:last-child img,
.post_media_3_2_1.preview:last-child video {
	border-radius: 0 0 10px 0;
}

/**
Post media 4
 */

.post_media_4 {
	display: grid;
	grid-template-columns: calc(50% - 1.5px) calc(50% - 1.5px);
	gap: 3px;
	width: 100%;
}

.post_media_4_1 {
	aspect-ratio: 1;
	display: flex;
	align-items: center;
}

.post_media_4_1.preview:first-child img,
.post_media_4_1.preview:first-child video {
	border-radius: 10px 0 0 0;
}

.post_media_4_1.preview:nth-child(2) img,
.post_media_4_1.preview:nth-child(2) video {
	border-radius: 0 10px 0 0;
}

.post_media_4_1.preview:nth-child(3) img,
.post_media_4_1.preview:nth-child(3) video {
	border-radius: 0 0 0 10px;
}

.post_media_4_1.preview:nth-child(4) img,
.post_media_4_1.preview:nth-child(4) video {
	border-radius: 0 0 10px 0;
}

/*
Post media 5
 */

.post_media_5 {
	display: grid;
	gap: 3px;
	aspect-ratio: 2/1;
	width: 100%;
}

.row {
	display: flex;
	gap: 3px;
}

.post_media_5_1 {
	aspect-ratio: 1;
	width: 50%;
	display: flex;
	align-items: center;
}

.post_media_5_1.preview:nth-child(1) img,
.post_media_5_1.preview:nth-child(1) video {
	border-radius: 10px 0 0 0;
}

.post_media_5_1.preview:nth-child(2) img,
.post_media_5_1.preview:nth-child(2) video {
	border-radius: 0 10px 0 0;
}

.post_media_5_2 {
	aspect-ratio: 1;
	width: calc(100% / 3);
	position: relative; /* Needed for media_5_more UI */
	display: flex;
	align-items: center;
}

.post_media_5_2.preview:nth-child(1) img,
.post_media_5_2.preview:nth-child(1) video {
	border-radius: 0 0 0 10px;
}

.post_media_5_2.preview:nth-child(3) img,
.post_media_5_2.preview:nth-child(3) video,
.post_media_5_2.preview:nth-child(3) .shades {
	border-radius: 0 0 10px 0;
}

.media_5_more {
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.shades {
	position: absolute;

	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0.5;
	background-color: #000;
}

.shades.create_post {
	border-radius: 0 0 10px 0;
}

/* .media_5_more .shades {
} */

.media_5_more span {
	-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
	font-weight: var(--font-weight-semibold);
	font-size: 36px;
	font-weight: var(--font-weight-bold);
	text-align: center;
	color: #fff;
	z-index: 3;
}

.video_container {
	width: 100%;
	height: 100%;
	border-radius: inherit;
	position: relative;
}

.video_container .video_play_icon {
	position: absolute;
	width: 40px;
	height: 40px;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video_play_icon .play_icon {
	min-height: unset;
	min-width: unset;
}

.file_container {
	border: solid 1px var(--gray70);
	border-radius: 10px;
	height: 50px;
	/* min-width: 294px; */
	display: flex;
	align-items: center;
	/* width: 50px; */
	/* display: flex; */
	/* justify-content: flex-start; */
	gap: 10px;
}

.icon_container {
	width: 50px;
	height: 50px;
	border: solid 1px var(--gray70);
	border-left: 0;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: -1px;
}

.doc_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	text-align: center;
}

.doc_container p {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	/* UNCOMMENT BELOW TO HAVE MULTILINE ELLIPSIS */
	/* display: -webkit-box; */
	/* -webkit-box-orient: vertical; */
	/* -webkit-line-clamp: 1; */
	/* word-break: break-all; */
}

.file_icon {
	width: 50px;
	height: 50px;
	/* border: none !important; */
	border-radius: 0px !important;
}

.file_icon.chat {
	width: 25px;
	height: 25px;
}

.file_details {
	text-align: left;
	color: #000;
	margin-right: 10px;

	display: flex;
	flex-direction: column;
	gap: 2px;
}

.file_title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 220px;

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
}

.file_extension_container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
}

.file_extension_container span {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 200px;

	font-size: 10px;
}

.file_extension_container img {
	height: 14px;
	width: auto;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.post_media_container.twoColumns:not(.preview) {
		width: 60%;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.post_media_container.twoColumns:not(.preview) {
		width: 60%;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.post_media_container.twoColumns:not(.preview) {
		width: 60%;
	}

	.media_5_more span {
		font-size: 29px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .post_media img {
		width: 60%;
	} */

	.media_5_more span {
		font-size: 29px;
	}

	.video_play_icon {
		width: 80px;
		height: 80px;
	}

	.video_play_icon .play_icon {
		width: 32px;
		height: 32px;
		margin-left: 6px;
	}

	.post_media.preview.chat {
		height: 274px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .post_media img {
		aspect-ratio: 1;
	} */

	.media_5_more span {
		font-size: 25px;
	}

	.video_play_icon {
		width: 80px;
		height: 80px;
	}

	.video_play_icon .play_icon {
		width: 32px;
		height: 32px;
		margin-left: 6px;
	}

	.post_media.preview.chat {
		height: 253px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .post_media img {
		aspect-ratio: 1;
	} */

	.media_5_more span {
		font-size: 22px;
	}

	.video_play_icon {
		width: 80px;
		height: 80px;
	}

	.video_play_icon .play_icon {
		width: 32px;
		height: 32px;
		margin-left: 6px;
	}

	.post_media.preview.chat {
		height: 253px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.post_media img {
		aspect-ratio: 1;
	}

	.media_5_more span {
		font-size: 29px;
	}

	.post_media.preview.chat {
		height: 253px;
	}
}

/*!*360*!*/
@media screen and (min-width: 360px) and (max-width: 639.98px) {
	/* .post_media img {
		aspect-ratio: 1;
	} */

	.media_5_more span {
		font-size: 22px;
	}

	.video_play_icon {
		width: 80px;
		height: 80px;
	}

	.video_play_icon .play_icon {
		width: 32px;
		height: 32px;
		margin-left: 6px;
	}

	.post_media.preview.chat {
		height: 253px;
	}
}

@media screen and (max-width: 359px) {
	/* .post_media {
		width: 100%;
		aspect-ratio: 1/1;
		height: auto;
	} */
}
