button img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

div img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.container {
	width: 100%;
	height: 100%;

	/*height: 1200px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;

	/* padding: 5px 0 0 0; */
}

.right_section {
	/*height: fit-content;*/
	height: 700px;

	background-color: goldenrod;
}

.slider_status_section {
	margin-top: 15px;

	width: 100%;
	height: 180px;

	background-color: darkgray;
}

/*
Posts
 */

.feed_posts_section {
	margin-top: 10px;
}

.feed_posts_section.board_feed {
	margin-top: 0px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.board_no_results {
	background-color: #fff;
	flex-grow: 1;
}

.search_input_wrapper {
	padding: 20px;
	background-color: #fff;
	flex-grow: 1;
}

/* No Results */

.no_results_container {
	background-color: #fff;
	padding: 20px;
	flex-grow: 1;
	border-top: 10px solid var(--gray20);
}

.no_results_container.board {
	border-top: none;
}

.no_post {
	font-weight: var(--font-weight-bold);
}

/*
post item
 */

.post_item {
	margin-bottom: 12px;
	padding: 15px 20px 20px;

	width: 100%;
	height: fit-content;

	background-color: #ffffff;
}

.post_item:last-child {
	margin-bottom: 0px;
}

.post_item.inShareModal {
	/* padding: 10px; */
	margin-top: 12px;
	padding: 20px;
}

.view_all {
	margin: 15px auto 0;
}

.unavailable {
	padding: 20px;
	text-align: center;
}
.unavailable img {
	width: 25px;
	margin-bottom: 5px;
}

.unavailable h4 {
	margin: 0;
}

.share_post {
	background-color: var(--gray20);
	margin-bottom: 0px;
	border-radius: 12px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.post_item.tips {
	background-color: #000000;
}

.post_header {
	width: 100%;
	height: fit-content;
	min-height: 45px;

	display: flex;
	gap: 10px;

	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
}

.header_profile_img {
	flex: 0 0 45px;
	height: 45px;

	margin-right: 15px;

	border-radius: 50%;
	/*border: solid 2px #000000;*/
	background-color: transparent;
}

.header_profile_img img {
	width: 100%;
	height: 100%;

	object-fit: cover;

	border-radius: 50%;
}

.header_profile_info_wrapper {
	flex: 1;
	height: 100%;

	/*margin-right: 10px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: flex-start;
}

.header_text_wrapper {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	flex-direction: row;
	/*word-break: break-all;*/
}

.post_owner_name {
	height: fit-content;
	min-height: 20px;

	font-size: 14px;
}

.post_owner_name .bold {
	font-weight: var(--font-weight-semibold);
}

/* .tagged_names {
    color: var(--gaawk);
}

.tagged_names:hover {
    text-decoration: underline;
    cursor: pointer;
} */

.post_header_info {
	width: 100%;
	height: 20px;
	/*background-color: var(--gaawk);;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

.post_header_info span {
	width: 16px;
	height: 16px;
}

.post_header_info_date {
	/*height: 100%;*/
	font-weight: var(--font-weight-extralight);
	font-size: 13px;
	line-height: 20px;
	color: #000000;

	margin-right: 5px;
}

.post_header_info_icons {
	width: 20px;
	height: 100%;
}

/* .post_header_actions {
    width: 6px;
    height: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
} */

.post_container {
	width: 100%;
	height: fit-content;

	margin-top: 15px;
}

.board_post_title {
	margin: 0;
}

.tags_title {
	font-size: 12px;
	font-weight: var(--font-weight-semibold);
	margin: 0;
}

.date_container {
	display: flex;
	align-items: center;
	gap: 5px;
}

.services_tags_container {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	margin: 10px 0 20px 0;
}

.tag_item {
	font-size: 12px;
	width: fit-content;
}

.post_text {
	width: 100%;
	height: fit-content;

	font-size: 16px;

	margin-bottom: 10px;
}

/* .hashtag_text {
    color: var(--gaawk);
}

.hashtag_text:hover {
    cursor: pointer;
} */

.post_location_wrapper {
	margin-top: 15px;

	width: 100%;
	height: 358px;
	cursor: pointer;
}

.post_footer {
	width: 100%;
	/*height: 102px;*/
	height: fit-content;
	padding: 9px 0 0 0;
	/*margin-top: 10px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

.reaction_wrapper {
	width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	justify-items: flex-start;
	align-content: center;
	align-items: center;
}

.reactions {
	width: fit-content;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.reactions button {
	width: 22px;
	/* height: 22px; */
	border-radius: 50%;
	margin-right: 5px;
}

.reactions button.small {
	width: 14px;
	/* height: 14px; */

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.counts_wrapper {
	width: fit-content;
	height: fit-content;

	/*margin-left: 2px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

.counts_text {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.reaction_button_wrapper {
	width: 100%;
	height: 55px;

	border-top: var(--gray30) 0.5px solid;
	margin-top: 14px;

	display: flex;
	/* flex-wrap: wrap; */
	justify-content: space-evenly;
	align-content: center;
	align-items: center;
	padding: 5px 0;
	gap: 5px;
	position: relative;
	z-index: 7;
}

.react_button_wrapper {
	width: 100%;
	height: 100%;
}

.reactions_container {
	position: absolute;
	left: 0;
	bottom: 49px;
}

.extended_reaction_button {
	border-radius: 5px;
	background-color: var(--gray30);
}

.reaction_button_icon {
	width: 100%;
	height: 100%;

	display: flex;

	justify-content: center;
	align-content: center;
	align-items: center;

	cursor: inherit;
}

.reaction_button_icon:hover {
	border-radius: 5px;
	background-color: var(--gray30);
	cursor: pointer;
}

.reaction_button_icon.selected {
	border-radius: 5px;
	background-color: var(--gray30);
}

.reaction_button_icon img {
	width: 24px;
	height: 100%;
	cursor: inherit;
	margin-right: 10px;

	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
}

.reaction_button_icon label {
	height: 100%;
	/*margin: 0 0 0 10px;*/
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	text-align: center;
	cursor: inherit;

	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;

	text-transform: capitalize !important;
}

.sm {
	padding: 10px 10px 0 10px !important;
}

/* .sm .post_header_actions {
    width: 6px;
    height: 20px;
} */

.sm .post_footer {
	/*height: 70px;*/
	/*height: 70px;*/
	padding: 6px 0 0 0;
}

.sm .reaction_button_wrapper {
	height: 43px;
	margin-top: 6px;
}

/* .sm .reaction_button_icon {
    height: 15px;
} */

.sm .reaction_button_icon img {
	width: 15px;
}

.sm .reaction_button_icon label {
	height: 100%;
	margin: 0 0 0 6px;
	font-size: 13px;
}

.sm .post_text {
	margin-top: 10px;

	font-size: 13px;
	line-height: 15px;
}

.sm .counts_text {
	font-size: 11px;
	letter-spacing: -0.22px;
}

.sm .reactions button {
	width: 15px;
	/* height: 15px; */
	margin-right: 2px;
}

.hide {
	display: none;
}

/*.button_hover_reaction:hover .reactions_list_section{*/
/*    display: block;*/

/*    -webkit-animation: fadeInFromNone 1s ease-out;*/
/*    -moz-animation: fadeInFromNone 1s ease-out;*/
/*    -o-animation: fadeInFromNone 1s ease-out;*/
/*    animation: fadeInFromNone 1s ease-out;*/
/*}*/

/*.extended_reaction_button {*/
/*    */
/*}*/

.reactions_list_section {
	/*display: none;*/

	position: absolute;

	top: -60px;
	left: 0;

	width: 250px;
	height: 60px;

	z-index: 10;

	/*background-color: #ff00e6;*/

	-webkit-animation: fadeInFromNone 1s ease-out;
	-moz-animation: fadeInFromNone 1s ease-out;
	-o-animation: fadeInFromNone 1s ease-out;
	animation: fadeInFromNone 1s ease-out;
}

.reactions_list_wrapper {
	width: 100%;
	height: 50px;

	background-color: #000;
	border-radius: 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-content: center;
	align-items: center;

	/*padding: 2px 0;*/
}

.reactions_list_wrapper button {
	width: 40px;
	height: 40px;
}

.reactions_list_wrapper button img {
	width: 30px;
	height: 30px;
}

.reactions_list_wrapper button img:hover {
	width: 40px;
	height: 40px;
}

.reactions_container.full_screen_post > div {
	width: 340px !important;
}

.tagged_container {
	padding: 20px;
	/* max-height: 480px; */
	/* overflow: auto; */
}

.tagged_container .overflow_container {
	max-height: 480px;
	overflow: auto;
	padding-right: 10px;
}

.modal_container {
	padding: 20px;
}

.modal_button_wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	gap: 20px;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.right_section {
		width: 422px;
	}

	.post_media_container {
		margin-top: 15px;
		height: fit-content;
		max-height: 718px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 358px;
		max-height: 718px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.right_section {
		width: 422px;
	}

	.post_media_container {
		margin-top: 15px;
		height: fit-content;
		max-height: 718px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 358px;
		max-height: 718px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.right_section {
		width: 347px;
	}

	.post_text {
		margin-top: 15px;

		font-size: 15px;
		line-height: 16px;
	}

	.post_media_container {
		margin-top: 15px;
		height: fit-content;
		min-height: 292px;
		max-height: 580px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 292px;
		max-height: 580px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.right_section {
		width: 269px;
	}

	.slider_status_section {
		margin-top: 0;
	}

	.create_post_section {
		padding: 15px 15px 10px;
	}

	.profile_img {
		width: 40px;
		height: 40px;
	}

	.create_post_input {
		height: 30px;
	}

	.create_post_input label {
		font-size: 15px;
	}

	.create_post_icons_section {
		height: 35px;
	}

	.create_post_icons_icon {
		width: 22px;
		height: 22px;
	}

	.post_item {
		padding: 15px;
	}

	.post_text {
		margin-top: 10px;

		font-size: 14px;
		line-height: 16px;
	}

	.post_media_container {
		margin-top: 10px;
		height: fit-content;
		max-height: 453px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 225px;
		max-height: 453px;
	}

	.comment_count {
		font-size: 13px;
	}

	.reactions_count label {
		font-size: 13px;
	}

	.post_footer {
		/*height: 90px;*/
		padding: 8px 0 0;
	}

	.reactions button {
		width: 20px;
		/* height: 20px; */
	}

	.counts_text {
		font-size: 13px;
		letter-spacing: -0.26px;
	}

	.reaction_button_wrapper {
		height: 50px;
		margin-top: 10px;
	}

	/* .reaction_button_icon {
        height: 18px;
    } */

	.reaction_button_icon img {
		width: 18px;
	}

	.reaction_button_icon label {
		margin: 0 0 0 8px;
		font-size: 14px;
	}

	.reactions_container > div {
		width: 450px !important;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.right_section {
		display: none !important;
	}

	.slider_status_section {
		margin-top: 0;
	}

	.create_post_section {
		padding: 15px 15px 10px;
	}

	.profile_img {
		width: 40px;
		height: 40px;
	}

	.create_post_input {
		height: 30px;
	}

	.create_post_input label {
		font-size: 15px;
	}

	.create_post_icons_section {
		height: 35px;
	}

	.create_post_icons_icon {
		width: 22px;
		height: 22px;
	}

	.post_media_container {
		margin-top: 10px;
		height: fit-content;
		max-height: 563px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 267px;
		max-height: 563px;
	}

	.post_text {
		margin-top: 15px;

		font-size: 15px;
		line-height: 16px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.right_section {
		display: none !important;
	}

	.slider_status_section {
		margin-top: 0;
	}

	.create_post_section {
		padding: 10px;
	}

	.create_post_profile_img {
		flex: 0 0 50px;
	}

	.profile_img {
		width: 30px;
		height: 30px;
	}

	.profile_arrow {
		width: 12px;
		height: 12px;

		margin-left: 2px;
	}

	.profile_img {
		width: 30px;
		height: 30px;
	}

	.create_post_input {
		height: 25px;
	}

	.create_post_input label {
		font-size: 13px;
	}

	.create_post_icons_section {
		height: 30px;
	}

	.create_post_icons_icon {
		width: 18px;
		height: 18px;
	}

	.post_item {
		padding: 15px;
	}

	/* .post_header_actions {
        width: 6px;
    } */

	.post_text {
		margin-top: 7px;

		font-size: 13px;
		line-height: 15px;
	}

	.post_owner_name {
		min-height: 15px;
		height: fit-content;
		font-weight: var(--font-weight-semibold);
		font-size: 13px;
		line-height: 1;
		padding: 0 !important;
		/*line-height: 15px;*/
	}

	.post_header_info_date {
		font-size: 11px;
	}

	.post_media_container {
		margin-top: 10px;
		height: fit-content;
		max-height: 340px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 170px;
		max-height: 340px;
	}

	.reactions_count_section {
		margin-top: 5px;
		height: 15px;
	}

	.reactions_count button {
		width: 15px;
	}

	.comment_count {
		font-size: 10px;
	}

	.reactions_count label {
		font-size: 10px;
	}

	.reactions_count div {
		margin-right: 8px;
	}

	.reactions_count label {
		margin-left: 3px;
	}

	.post_footer {
		/*height: 70px;*/
		padding: 6px 0 0 0;
	}

	.reactions button {
		width: 15px;
		/* height: 15px; */
		margin-right: 2px;
	}

	.counts_text {
		font-size: 11px;
		letter-spacing: -0.22px;
	}

	.reaction_button_wrapper {
		height: 43px;
		margin-top: 6px;
	}

	/* .reaction_button_icon {
        height: 15px;
    } */

	.reaction_button_icon img {
		width: 15px;
	}

	.reaction_button_icon label {
		height: 100%;
		margin: 0 0 0 6px;
		font-size: 13px;
	}

	.reactions_container > div {
		width: 340px !important;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.right_section {
		display: none !important;
	}

	.padd_lr_10 {
		padding: 0 10px;
	}

	.slider_status_section {
		margin-top: 0;
	}

	.create_post_section {
		padding: 15px 15px 10px;
	}

	.profile_img {
		width: 40px;
		height: 40px;
	}

	.create_post_icons_section {
		height: 35px;
	}

	.create_post_icons_icon {
		width: 22px;
		height: 22px;
	}

	.post_text {
		margin-top: 15px;

		font-size: 15px;
		line-height: 16px;
	}

	/*.post_media_container {*/
	/*    margin-top: 18px;*/
	/*    height: 329px;*/
	/*}*/
	.post_media_container {
		margin-top: 15px;
		height: fit-content;
		max-height: 580px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 292px;
		max-height: 580px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.right_section {
		display: none !important;
	}

	.padd_lr_10 {
		padding: 0 10px;
	}

	.slider_status_section {
		margin-top: 5px;
	}

	.create_post_section {
		padding: 15px 10px 5px;
	}

	.create_post_profile_img {
		flex: 0 0 50px;
	}

	.profile_img {
		width: 30px;
		height: 30px;
	}

	.profile_arrow {
		width: 12px;
		height: 12px;

		margin-left: 2px;
	}

	.create_post_input {
		height: 25px;
	}

	.create_post_input label {
		font-size: 13px;
	}

	.create_post_text_reaction_section {
		padding: 0;
	}

	.create_post_icons_section {
		height: 30px;
	}

	.create_post_icons_icon {
		width: 18px;
		height: 18px;
	}

	.post_item {
		padding: 15px;
	}

	.post_header {
		min-height: 30px;
		height: fit-content;
	}

	.header_profile_img {
		flex: 0 0 30px;
		height: 30px;
		margin-right: 10px;
	}

	.post_owner_name {
		min-height: 15px;
		height: fit-content;
		font-weight: var(--font-weight-semibold);
		font-size: 13px;
		line-height: 1;
		padding: 0 !important;
		/*line-height: 15px;*/
	}

	.post_header_info {
		height: 15px;
	}

	.post_header_info_date {
		font-size: 11px;
	}

	/* .post_header_actions {
        width: 6px;
        height: 20px;
    } */

	.post_text {
		margin-top: 10px;

		font-size: 13px;
		line-height: 15px;
	}

	.post_media_container {
		margin-top: 10px;
		height: fit-content;
		max-height: 340px;
	}

	.post_media_container img {
		width: 100%;
		height: fit-content;
		min-height: 170px;
		max-height: 340px;
	}

	.reactions_count_section {
		margin-top: 5px;
		height: 15px;
	}

	.reactions_count button {
		width: 15px;
	}

	.comment_count {
		font-size: 10px;
	}

	.reactions_count label {
		font-size: 10px;
	}

	.reactions_count div {
		margin-right: 8px;
	}

	.reactions_count label {
		margin-left: 3px;
	}

	.post_footer {
		/*height: 70px;*/
		padding: 6px 0 0 0;
	}

	.reactions button {
		width: 15px;
		/* height: 15px; */
		margin-right: 2px;
	}

	.counts_text {
		font-size: 11px;
		letter-spacing: -0.22px;
	}

	.reaction_button_wrapper {
		height: 43px;
		margin-top: 6px;
	}

	/* .reaction_button_icon {
        height: 15px;
    } */

	.reaction_button_icon img {
		width: 15px;
	}

	.reaction_button_icon label {
		height: 100%;
		margin: 0 0 0 6px;
		font-size: 13px;
	}

	.reactions_container > div {
		width: 340px !important;
	}

	.post_item.inShareModal {
		padding: 10px;
	}

	.modal_button_wrapper {
		flex-direction: column-reverse;
		gap: 10px;

		button {
			width: 100%;
		}
	}
}

@-webkit-keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@-moz-keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@-o-keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}
