@mixin section-title {
	margin: 0 0 10px;
}

.container {
	background-color: var(--gray20);

	& > div:first-child {
		margin-top: 0px;
	}

	& > div h4 {
		@include section-title;
	}
	& > div p {
		font-size: 10px;
	}
}
