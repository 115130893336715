.container {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.button_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		button {
			width: 335px;
		}
	}
}
