.container {
	background-color: white;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.search_input_wrapper {
	display: flex;
	align-items: center;
	gap: 20px;
	padding: 20px 20px 0;
}

.boards_wrapper {
	background-color: #fff;
	flex-grow: 1;
}

.suggest_container {
	background-color: var(--gray20);
	padding: 20px;

	button {
		display: block;
		color: var(--gaawk);
		font-weight: var(--font-weight-semibold);
	}
}
