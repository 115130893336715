.login_form {
	padding: 40px;

	.login_btn {
		width: 100%;
		height: 35px;
		font-size: 14px;
		padding: 0;
	}
}

.logo_wrapper {
	display: flex;
	justify-content: center;
	margin: 53px 0 75px 0;
	img {
		width: 110px;
	}
}

.input_wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
}
