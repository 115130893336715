.modal_container {
	padding: 20px;
}

.content_wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
}

.text {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--gray80);
}

.btn_container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
}

/* .modal_btn {
	background-color: var(--gaawk);
	font-size: 14px;


	height: 35px;
}

.modal_btn.red {
	background-color: var(--error200);
} */

/* .cancel_btn {
	font-size: 14px;


	height: 35px;
	color: #a6b1bc;
	outline: 1px solid #a6b1bc;
	outline-offset: -1px;
} */

@media screen and (max-width: 639.98px) {
	.btn_container {
		flex-direction: column-reverse;
		gap: 10px;
	}
	.btn_container button {
		width: 100%;
	}
}
