.see_more {
	display: flex;
	align-items: center;
}

.see_more label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #686e76;
	cursor: inherit;
}

.forward_icon {
	width: 14px;
	/* margin-top: 2px; */
}
