@use "variables" as *;

.container {
	width: 100%;
	background-color: #fff;
	border-left: 1px solid var(--gray20);
	display: flex;
	justify-content: center;
	overflow: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&.container::-webkit-scrollbar {
		display: none;
	}
}

.center_wrapper {
	height: calc(100dvh - $topNavBarHeight);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer_container {
	display: flex;
	flex-grow: 1;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.center_wrapper {
		width: 840px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.center_wrapper {
		width: 840px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.center_wrapper {
		width: 360px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.center_wrapper {
		width: 620px;
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.center_wrapper {
		min-width: 300px;
		width: 360px;
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
}

@media screen and (max-width: 359px) {
	.center_wrapper {
		width: 100vw;
		min-width: auto;
	}
}
