.container {

  width: 160px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  text-align: left;

}

.imgContainer {
  justify-content: flex-start;
}

.img {
  width: 128px;
  height: 128px;
  object-fit: contain;
}

.title {
  margin-top: 22px;
  color: #000000;

  font-family: "Poppins-SemiBold";
  font-size: 14px;
}

.details {
  color: #43B7C7;

  font-family: "Poppins-Regular";
  font-size: 13px;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {

  .container {
    width: 90px;
  }

  .img {
    width: 73px;
    height: 73px;
  }

  .title {
    margin-top: 14px;
    font-size: 8px;
  }

  .details {
    font-size: 7px;
  }

}

/*mobile*/
@media screen and (min-width: 320px) and (max-width: 743.98px) {

  .container {
    width: 90px;
  }

  .img {
    width: 73px;
    height: 73px;
  }

  .title {
    margin-top: 14px;
    font-size: 8px;
  }

  .details {
    font-size: 7px;
  }
}