.additional_form {
	padding: 20px;
	background-color: #fff;
}

.additional_form h3 {
	margin: 0 0 10px;
}

.section > div {
	margin-bottom: 15px;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;

	margin-bottom: 15px;
}

.form_input_wrapper label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: block;
	margin-bottom: 7px;
}

.form_input_wrapper textarea {
	border: 1px solid var(--gray50);
	border-radius: 5px;
	min-height: 140px;
	padding: 10px;
	resize: vertical;
}
.form_input_wrapper textarea:focus {
	border: 2px solid #6cc5d1;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	background-color: #fff;
}
