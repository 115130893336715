@use "variables" as *;

.right_side_wrapper {
	width: calc(100% - 360px);
	height: calc(100dvh - $topNavBarHeight);
	border-left: 1px solid var(--gray20);
	/* flex: 1; */
	/* width: 100vw; */
	position: relative;

	background-color: #fff;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;

	z-index: 3;
}

.section_wrapper {
	width: 100%;

	/* margin-top: 55px; */
	/* margin-bottom: 5px; */

	height: calc(100dvh - $topNavBarHeight);

	overflow-y: auto;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
	/* //* scrollbar-gutter prevent from design shifts when scroll appears */
	scrollbar-gutter: stable both-edges;
}

.section_wrapper.disableScrollbar {
	scrollbar-gutter: unset;
}

.section_wrapper.chat {
	/* height: calc(
        100dvh - 55px - 56px
    );  */
	/* TODO >> this needs to be dynamic based on the height of the input */
	/*margin-bottom: 55px !important;*/

	/* flex-direction causing profile page not to show to the end when opened in convo */
	/* flex-direction: column-reverse; */

	position: relative;
	/*align-content: flex-end !important;*/
	/*flex-direction: row-reverse;*/
	scrollbar-gutter: unset;
}

.section_wrapper.chat.infoOpened,
.container.infoOpened,
.content_wrapper.infoOpened {
	height: calc(100dvh - $topNavBarHeight) !important;
	scrollbar-width: none;
}

.container {
	/*margin-top: 55px;*/
	/*margin-bottom: 5px;*/

	width: 100%;
	// max-width: 1200px;
	height: fit-content;

	height: calc(100dvh - $topNavBarHeight);

	/*overflow-y: auto;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
}

// .container.fullWidth {
// 	max-width: none;
// }

/* .container.chat { */
/* height: calc(
        100dvh - 55px - 56px
    );  */
/* TODO >> this needs to be dynamic based on the height of the input */
/* } */

.content_wrapper {
	height: calc(100dvh - $topNavBarHeight);
	display: flex;
	flex-direction: column;
}

.content_wrapper.fullWidth {
	width: 100%;
	max-width: 2000px;
}

/* .content_wrapper.chat {
    height: calc(100dvh - 55px - 56px);
} */

.footer {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	width: 100%;
	/* height: 56px; */
	min-height: $bottomNavbarHeight;
	/*margin: 5px 0 0;*/
	/* padding: 10px 20px; */
	background-color: #000;

	border-left: #fff 1px solid;
}

.footer.infoOpened {
	display: none;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.content_wrapper {
		width: 1200px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.content_wrapper {
		width: 1200px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.content_wrapper {
		width: 992px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.content_wrapper {
		width: 768px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.content_wrapper {
		width: 576px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.content_wrapper {
		width: 360px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.container {
		/*    margin-bottom: 61px;*/
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}

	/* .right_side_wrapper.chat {
        display: none;
    } */

	.right_side_wrapper {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
		width: 100%;
	}

	.right_side_wrapper.chat {
		height: calc(100dvh - $topNavBarHeight);
	}

	.right_side_wrapper.hide {
		display: none;
	}

	.right_side_wrapper.show {
		display: block;
	}

	// .right_proiority {
	// 	display: block !important;
	// 	flex: 1 !important;
	// 	width: 100% !important;
	// }

	.content_wrapper {
		width: 620px;
		/* margin-bottom: 61px; */
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
	.footer {
		border: none;
	}
	.section_wrapper {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .right_side_wrapper.chat {
        display: none;
    } */

	.container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}

	.right_side_wrapper {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
		width: 100%;
	}

	.right_side_wrapper.chat {
		height: calc(100dvh - $topNavBarHeight);
	}

	.right_side_wrapper.hide {
		display: none;
	}

	.right_side_wrapper.show {
		display: block;
	}

	// .right_proiority {
	// 	display: block !important;
	// 	flex: 1 !important;
	// 	width: 100% !important;
	// }

	.content_wrapper {
		/* min-width: 312px; */
		width: 360px;

		/* margin-bottom: 61px; */
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
	.footer {
		border: none;
	}
	.section_wrapper {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
}

@media screen and (max-width: 359px) {
	.content_wrapper {
		width: 100%;
	}
}
