.create_post_modal_container {
	width: 100%;
	height: fit-content;

	/*background-color: #1a8897;*/

	padding: 20px;
}

.emotion_list_wrapper {
	margin-top: 20px;
	width: 100%;
	height: 500px;
	overflow-y: auto;

	display: flex;
	flex-direction: column;
	gap: 5px;
}

.emotion_item {
	width: 100%;
	height: 50px;
	padding: 15px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.emotion_item:hover,
.emotion_item.active {
	background-color: var(--gray20);
	cursor: pointer;
}

.emotion_item img {
	width: 40px;
	height: 40px;
}

.emotion_item .remove_icon {
	width: 17px;
}

.emotion_item label {
	margin-left: 22px;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	line-height: 19px;
	color: #000;
	cursor: inherit;
}
