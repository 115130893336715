.container {
  width: 100vw;
  height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #ffffff;
  position: relative;
}

.titleContainer {
  width: 800px;
  height: 160px;

  margin: 110px auto 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
  text-align: left;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.titleText1 {
  color: #000;

  font-family: "Poppins";
  font-size: 68px;
  line-height: 50px;
}

.titleText2 {
  color: #000;

  font-family: "Poppins-Thin";
  font-size: 68px;
  font-style: italic;
  line-height: 80px;
}

.subTitle {
  flex: 1;
  padding: 0 20px;
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
  line-height: 20.664px;
}

.contentWrapper {
  margin: 80px auto 45px;
  width: 800px;
  text-align: center;
}

.contentTitle {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 38px;
  line-height: 105.258px;
}

.content{
  width: 100%;
  height: auto;
  text-align: left;
}

.content p{
  margin-top: 25px;
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
  line-height: 22px;
}

/*others*/
@media screen and (max-width: 1279.98px) {
  .container {
    //padding: 0 23px;
  }

  .titleContainer {
    width: 100%;
    height: auto;

    margin: 82px auto 0;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 31px;
    text-align: center;
  }

  .titleText1 {
    font-size: 45px;
    line-height: 50px;
  }

  .titleText2 {
    font-size: 45px;
    line-height: 40px;
  }

  .subTitle {
    width: 300px;
    padding: 0;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 16px;
  }

  .contentWrapper {
    margin: 20px auto 68px;
    width: 300px;
    text-align: left;
  }

  .contentTitle {
    text-align: left;
    font-size: 24px;
  }

  .content p{
    margin-top: 30px;
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    line-height: 16px;
  }
}
