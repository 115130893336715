.container {
	padding: 20px;
}

.warning_container {
	display: flex;
	align-items: flex-start;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

.button_container {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

@media screen and (max-width: 639px) {
	.button_container {
		flex-direction: column-reverse;
		gap: 10px;
	}
	.button_container button {
		width: 100%;
	}
}
