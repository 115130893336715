.container {
	padding: 20px;
}

.button_wrapper {
	/* width: 320px; */
	margin-top: 28px;
}

.empty_image {
	width: 61px;
	height: 61px;
}

.no_padding {
	padding: 0;
}

.text {
	margin-top: 26px;

	font-size: 14px;
	color: var(--gray80);
	white-space: pre-line;
	text-align: center;
}

.no_results_container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.no_results_container span {
	text-align: center;
	font-weight: var(--font-weight-bold);
	font-size: 16px;
}

.is_search {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
