@mixin mobile-wrapper-hidden {
	border-left: none;
	display: none;
}

@mixin mobile-show {
	display: flex;
	flex: auto;
	padding-left: 0;
}

@mixin mobile-left-header-show {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 0;
}

.header {
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	background-color: #fff;
	// max-width: 2000px;
	margin: auto;
	width: 100vw;
}

.left_header_wrapper {
	padding-right: 20px;
	flex: 0 0 340px;
	height: 100%;

	display: flex;
	// flex-wrap: wrap;

	justify-content: space-between;
	align-items: center;
	align-content: center;

	&.hide {
		display: none;
	}
}

.logos_wrapper {
	display: flex;
	align-items: center;
	gap: 15px;
}

.actions_wrapper {
	display: flex;
	align-items: center;
	gap: 20px;

	button {
		width: 24px;
		height: 24px;
	}
}

.right_header_wrapper {
	width: calc(100% - 340px);
	height: 100%;
	border-left: 1px var(--gray20) solid;

	padding-left: 20px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;

	&.hide_separator {
		border-left: none;
	}

	&.full_width {
		width: 100%;
		padding-left: 0;
	}
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.left_header_wrapper {
		display: none;
	}

	.right_header_wrapper {
		@include mobile-wrapper-hidden;
	}

	.show {
		@include mobile-show;
	}

	.left_header_wrapper.show {
		@include mobile-left-header-show;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.header {
		width: 100%;
		min-width: 300px;
	}

	.left_header_wrapper {
		display: none;
	}

	.right_header_wrapper {
		@include mobile-wrapper-hidden;
	}

	.show {
		@include mobile-show;
	}

	.left_header_wrapper.show {
		@include mobile-left-header-show;
	}
}

@media screen and (max-width: 359px) {
	.header {
		min-width: auto;
	}
	.left_header_wrapper {
		display: none;
	}

	.right_header_wrapper {
		@include mobile-wrapper-hidden;
	}

	.show {
		@include mobile-show;
	}

	.left_header_wrapper.show {
		@include mobile-left-header-show;
	}
}

@media screen and (min-width: 748px) {
	.actions_wrapper {
		display: none;
	}
}
