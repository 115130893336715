.container {
	background-color: var(--gray20);
	border-radius: 8px;
	padding: 8px;
	width: 100%;

	display: flex;
	justify-content: space-between;
	gap: 10px;

	.img_wrapper {
		width: 263px;
		height: 131px;
		background-color: #fff;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;

		flex-shrink: 0;
		img {
			border-radius: 8px;
			object-fit: cover;
		}
	}

	.project_details {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&.closed {
			color: var(--gray80);
		}

		.project_header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.tag {
				background-color: var(--gray50);
				font-weight: var(--font-weight-bold);
				color: #fff;
				border: none;
				padding: 2px 6px;
				font-size: 12px;
				width: fit-content;
			}

			.option_btn {
				width: 32px;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					transform: rotate(90deg);
					width: auto;
				}
			}
		}

		.title {
			font-size: 16px;
			margin: 10px 0 0;

			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.job_count_container {
			font-weight: var(--font-weight-bold);
			font-size: 12px;
			margin-bottom: 8px;
		}

		.project_footer {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.companyName {
				font-size: 10px;
			}

			.date_wrapper {
				p {
					font-size: 10px;
					font-weight: var(--font-weight-bold);

					span {
					}
				}
			}
		}
	}
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.container {
		flex-direction: column;
		width: 100%;
		height: 308px;
		justify-content: unset;

		.img_wrapper {
			width: 100%;
			height: 152px;
		}
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	.container {
		flex-direction: column;
		width: 100%;
		height: 308px;
		justify-content: unset;

		.img_wrapper {
			width: 100%;
			height: 152px;
		}
	}
}

@media screen and (max-width: 319px) {
	.container {
		flex-direction: column;
		width: 100%;
		height: 308px;
		justify-content: unset;

		.img_wrapper {
			width: 100%;
			height: 152px;
		}
	}
}
