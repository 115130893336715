.general_details_container {
	font-size: 14px;
}

.subsection_wrapper {
	margin-top: 20px;
}

.subsection_wrapper h3 {
	width: 100%;
}

.subsection {
	margin-top: 20px;
}

.bio {
	word-break: break-all;
}
