.form_input_wrapper {
	margin-bottom: 20px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: center;

	label {
		width: 100%;
		margin-bottom: 7px;
		font-weight: var(--font-weight-semibold);
		font-size: 14px;
		color: #000;
	}

	p {
		margin-left: auto;
		text-align: right;
		font-style: italic;
		color: red;
	}
}

.button_container {
	display: flex;
	justify-content: flex-end;

	button {
		height: 35px;
		padding: 0 15px;
		font-size: 14px;
	}
}

.info {
	margin-bottom: 20px;
}
