.container {
	padding: 8px;
	border-radius: 8px;
	border: solid 1px #dbdfe4;
}

.profile_container {
	display: flex;
	align-items: center;
	overflow: hidden;

	span {
		font-size: 14px;
	}
}

.details_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
}

.overflow_hidden {
	overflow: hidden;
	color: #000;
	&:hover {
		text-decoration: none;
	}
}

.subData {
	color: #a6b1bc;
}

.buttons_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	gap: 10px;

	.button {
		min-width: unset;
		width: 140px;
	}
}
