.container {
	border-bottom: 6px solid var(--gray20);

	&:last-of-type {
		border-bottom: none;
	}
}

.section_wrapper {
	padding: 20px;

	&.sidebar {
		padding: 0;
	}

	.title_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 15px;

		button {
			white-space: nowrap;
			height: 100%;
			color: var(--gaawk);
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.new_board_container {
		width: 100%;
		padding: 20px;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 20px;
		color: var(--gray80);
	}
}
