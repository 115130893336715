.conversation_item {
	width: 100%;
	height: 62px;

	padding: 0 10px;

	/*padding: 5px 10px;*/
}

.conversation_item.active {
	background-color: var(--light-gaawk);
}

.conversation_item:hover {
	background-color: var(--gray30);
	cursor: pointer;
}

.conversation_item_wrapper {
	width: 100%;
	height: 100%;

	padding: 5px 0;

	border-bottom: 1px var(--gray30) solid;

	display: flex;
	/* flex-wrap: wrap; */

	justify-content: flex-start;
	align-content: center;
	align-items: center;
	cursor: inherit;
	overflow: hidden;
}

.conversion_info {
	flex: 1;
	/*height: 100%;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-end;
	overflow: hidden;
	/*background-color: yellow;*/
}

.name_container {
	width: 100%;
	height: 18px;
	margin: 0 0 2px 0;

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.profile_name {
	/*width: 100%;*/
	/*height: 18px;*/
	/*margin: 0 0 1px 0;*/
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000;
	max-width: 215px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	cursor: inherit;
}

.time {
	/*width: 100%;*/
	/*height: 15px;*/
	/*margin: 3px 0 1px 255px;*/

	font-size: 10px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #a6b1bc;

	cursor: inherit;
}

.message_container {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

.message_wrapper {
	/*width: 100%;*/
	/*height: 15px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	/*background-color: #1a8897;*/
}

.message_wrapper img {
	width: 10px;
	height: 100%;
	/*padding: 2px 0;*/
}

.message_indicators {
	display: flex;
	align-items: center;
	gap: 10px;
}

.muteIcon {
	width: 19px;
	height: auto;
}

/* .message_text {
    flex: 1;
    height: 100%;
    max-width: 230px;
    margin: 0 0 0 3px;

    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a6b1bc;

    cursor: inherit;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.typing {
	color: var(--gaawk);
}

/*.conversation_time {*/
/*    width: fit-content;*/
/*    height: 100%;*/

/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    justify-content: flex-end;*/
/*    align-content: flex-start;*/
/*    align-items: center;*/
/*}*/

.unread_count {
	width: 18px;
	height: 18px;
	margin: 1px 0 3px;
	background-color: var(--gaawk);
	border-radius: 50%;
	display: flex;
	align-content: center;
	justify-content: center;
	font-size: 12px;
	font-weight: var(--font-weight-bold);
	color: #fff;
	cursor: inherit;
}
