.container {
  margin-top: 117px;
  height: 440px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.rightTile {
  flex-direction: row-reverse;
}

.side1 {
  width: 465px;
  height: 100%;
}

.side2 {
  width: 420px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.subTitleIcon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 12px;
}

.subTitleText {
  flex: 1;
  color: #388B96;
  font-family: Poppins;
  font-size: 20px;
}

.titleView {
  margin-top: 10px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  color: #000;
  font-family: "Poppins-SemiBold";
  font-size: 35px;
  font-style: normal;
}

.description {
  margin-top: 10px;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  line-height: 29.6px;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
  .container {
    margin-top: 24px;

    width: 380px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  .side1 {
    width: 100%;
    height: 280px;
  }

  .side2 {
    width: 100%;
    height: 158px;
    align-items: center;
    text-align: center;
  }

  .subTitle {
    justify-content: center;
  }

  .subTitleIcon {
    display: none;
  }

  .subTitleText {
    font-size: 12px;
  }

  .titleView {
    margin-top: 4px;
    height: 40px;
    justify-content: center;
  }

  .title {
    font-size: 26px;
  }

  .description {
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;

  }
}

/*mobile*/
@media screen and (min-width: 360px) and (max-width: 743.98px) {
  .container {
    margin-top: 8px;

    width: 303px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
  }
  .side1 {
    width: 100%;
    height: 203px;
  }

  .side2 {
    width: 100%;
    height: 158px;
    align-items: center;
    text-align: center;
  }

  .subTitle {
    justify-content: center;
  }

  .subTitleIcon {
    display: none;
  }

  .subTitleText {
    font-size: 12px;
  }

  .titleView {
    height: 40px;
    margin-top: 4px;
    justify-content: center;
  }

  .title {

    font-size: 26px;
  }

  .description {
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;

  }
}