.emptyBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}

.emptyBtn button {
	width: 320px;
	height: 35px;
	font-weight: var(--font-weight-bold);
	font-size: 14px;
	text-align: center;
	color: var(--gray80);

	background-color: #dbdfe4;
	border-radius: 5px;
	padding: 8px 14px;
}

.container h4 {
	margin-bottom: 20px;
}

.modal_container {
	padding: 20px;
}

.info_text {
	font-size: 14px;
}

.btn_container {
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;
}

@media screen and (max-width: 639px) {
	.btn {
		width: 100%;
	}
}
