.section {
	padding: 20px;
	border-bottom: 6px solid var(--gray20);
	background-color: #fff;
}

.section:nth-last-child(2) {
	border-bottom: none;
}

.section h3 {
	margin: 0 0 10px;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;

	margin: 15px 0;
}

.textarea_wrapper {
	height: 200px;
}

.form_input_wrapper label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: block;
	margin-bottom: 7px;
}

.form_input_wrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	padding: 20px;
	background-color: #fff;
}

@media screen and (max-width: 639px) {
	.save_btn {
		width: 100%;
	}
}
