.container {
	padding: 20px;
	background-color: #fff;
}

.tab_menu {
	margin-bottom: 20px;
}

.notification_container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.option_icon {
	height: 25px;
	width: 24px;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	img {
		width: initial;
		height: initial;
	}
}

.menu_title {
	margin: 0 0 5px;
	padding: 4px 8px;
}

.icon_wrapper {
	display: flex;
	align-items: center;
}

.menu_item {
	display: flex;
	align-items: center;
	gap: 10px;
}

.empty_icon {
	width: 60px;
}

.no_results {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin: 10px 0;
}

.modal_container {
	padding: 20px;
}

.modal_button_wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	gap: 20px;
}

@media screen and (max-width: 639px) {
	.modal_button_wrapper {
		flex-direction: column-reverse;
		gap: 10px;

		.action_btn {
			width: 100%;
		}
	}
}
