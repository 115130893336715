.search_input_wrapper {
	padding: 10px 20px;
}

.selected_tags_container {
	padding: 20px;
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}

.services_items_container {
	max-height: 400px;
	overflow: auto;
}

.service_item_wrapper {
	width: 100%;
	// cursor: pointer;
	position: relative;
	padding: 10px 20px;

	&:hover {
		background-color: #e7e7e8;
	}

	&:last-of-type {
		.border_bottom {
			border-bottom: none;
		}
	}

	.border_bottom {
		position: absolute;
		border-bottom: 1px solid var(--gray30);
		width: calc(100% - 40px);
		bottom: 0px;
		left: 20px;
	}

	.top {
		display: flex;

		.service_name {
			h4 {
				color: var(--gaawk);
				margin: 0;
			}
			span {
				color: #89929b;
				font-size: 12px;
			}
		}
	}

	.breadcrumb_wrapper {
		margin-top: 5px;
		font-weight: var(--font-weight-bold);
		color: #000;
		font-size: 12px;

		display: flex;
		align-items: center;
		gap: 5px;

		span {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		img {
			width: 14px;
		}
	}
}

.button_wrapper {
	padding: 20px;
	display: flex;
	justify-content: center;

	button {
		width: 100%;
		height: 35px;
		padding: 0;
		font-size: 14px;
	}
}

.custom_loading_spinner {
	border-radius: 0 0 10px 10px;
}
