@use "../shared.module.scss";

.certificate_name {
	width: fit-content;
	border: 1px solid #1a8897;
	background-color: transparent;
	color: #1a8897;
	padding: 2px 6px;
}

.certificates_section {
	width: 100%;
	height: fit-content;
	margin-top: 15px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;

	gap: 19px;
}

.certificate_item {
	width: 100%;
	border-bottom: 0.5px solid #a6b1bc;
	padding-bottom: 20px;
}

.certificate_item:last-of-type {
	border-bottom: none;
	padding-bottom: 0px;
}

.certificate_title {
	font-weight: var(--font-weight-semibold);
	font-size: 20px;
	color: var(--gray80);

	margin: 10px 0;

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.certificate_title span {
	max-width: 1020px;
}

.institute_name {
	font-weight: var(--font-weight-semibold);
	font-size: 18px;
	color: #000;

	margin-bottom: 5px;
}

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

.certificate_controls img {
	height: 22px;
	cursor: pointer;
}

.certificate_controls img:last-of-type {
	margin-left: 20px;
}

.institute_website,
.institute_location {
	font-size: 10px;
	font-weight: var(--font-weight-semibold);
	color: var(--gray70);
}

.institute_location {
	margin-bottom: 20px;
}

.certificate_description {
	font-size: 14px;
	color: #000;

	margin-bottom: 20px;
}

.files_container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
	margin: 20px 0px;
}

div.file_item {
	width: 72px;
	height: 72px;
}

.certificate_score {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #ffffff;

	padding: 8px;

	background-color: #1b8897;
	border-radius: 5px;
	margin-bottom: 10px;
	display: inline-block;
}

.certificate_dates,
.certificate_validity {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: var(--gray70);
}

.certificate_media_item {
	flex: 0 0 90px;
	height: 90px;

	margin-right: 10px;

	background-color: var(--gray20);
}

.certificate_media_item:last-child {
	margin-right: 0;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 800px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 590px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.certificate {
		padding: 16px 10px 10px 4px;
	}
	.certificate:first-child {
		margin-top: 8px;
	}
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 400px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 190px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 450px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}
	.certificate_title span {
		max-width: 190px;
	}
}
