.search_input_wrapper {
	padding: 20px;
	/* background-color: #dbdfe4; */
}

.cities_container {
	padding: 10px;
}

.inner {
	overflow: auto;
	max-height: 500px;
}

.city_item {
	text-align: left;
	width: 100%;
	padding: 10px;
	border-bottom: 1px solid #dbdfe4;
}

.city_item.active {
	background-color: var(--gaawk);
	color: white;
	font-weight: var(--font-weight-bold);
}

.city_item:hover {
	background-color: #dbdfe4;
	color: inherit;
}

.city_item:first-of-type {
	margin-top: 0px;
}
.city_item:last-of-type {
	/* padding-bottom: 0px; */
	border-bottom: none;
}

.loader {
	border-radius: 10px;
}
