.navItem {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 10px;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.2s ease;

	&:hover,
	&.isActive {
		background-color: #f5f5f5;
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		color: #000;
	}

	.label {
		font-size: 12px;
		color: #000;
		font-weight: var(--font-weight-bold);
	}
}
