.search_wrapper {
	padding: 20px;
}

.container {
	padding: 20px;
}

.agencies_container {
	width: 100%;
	max-height: 400px;

	background-color: #ffffff;
	overflow-y: auto;
}

.agency_item {
	/* margin-bottom: 10px; */
	display: flex;
	align-items: center;
	width: 100%;
	/* margin-bottom: 10px; */
	padding: 10px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.agency_item:hover {
	background-color: var(--gray20);
}

.agency_item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.loading_container {
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 0 0 12px 12px;
	width: 100%;
	height: 100%;
	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bulletList_container {
	padding: 0px 20px 10px 0px;
}

.info_text {
	font-size: 14px;
}

.btn_container {
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;
}

.agency_tagLine,
.agency_location {
	width: 100%;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.agency_tagLine {
	font-size: 12px;
	color: #000;
}

.agency_location {
	font-size: 10px;
	color: #a6b1bc;
}

@media screen and (max-width: 639px) {
	.btn {
		width: 100%;
	}
}
