.container {
	// padding: 20px;
}

.search_input_wrapper {
	padding: 20px;
}

.frames_wrapper {
	// margin: 20px 0;
	padding-bottom: 20px;
}
