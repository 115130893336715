.container {
	display: flex;
	flex-direction: column;
	gap: 10px;

	h3 {
		display: -webkit-box;
		line-clamp: 2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.content_wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
