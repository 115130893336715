.otp_container {
	/*background-color: red;*/
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 0;
	margin: 0;
}

.otp_input {
	width: 46px !important;
	height: 46px !important;
	border-radius: 5px;
	border: solid 1px var(--gray50);

	margin: 0 !important;

	font-weight: var(--font-weight-semibold);
	font-size: 24px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
}

.otp_input:focus {
	border: solid 2px var(--gaawk) !important;
}

/*!*360*!*/
@media screen and (max-width: 400px) {
	.otp_input {
		width: 40px !important;
		height: 40px !important;
	}
}

@media screen and (max-width: 340px) {
	.otp_input {
		width: 30px !important;
		height: 30px !important;
	}
}
