@use "../shared.module.scss";

.certificates_section {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
/*
.certificate {
    width: 100%;
    height: fit-content;

    padding: 16px 20px 10px;

    border-top: var(--gray50) 0.5px solid;
} */

/* .certificate:first-child {
    margin-top: 12px;
}

.certificate:last-child {
    border-bottom: var(--gray50) 0.5px solid;
} */

.certificate_item {
	width: 100%;
	border-bottom: 0.5px solid var(--gray30);
	/* padding: 20px 0px; */
	padding-bottom: 10px;
}

.certificate_item:first-of-type {
	padding-top: 0px;
}
.certificate_item:last-of-type {
	border-bottom: none;
	padding-bottom: 0px;
}

.certificate_wrapper {
	display: flex;
	gap: 4px;
	align-items: center;
}

.certificate_name {
	width: fit-content;
	border: 1px solid #1a8897;
	background-color: transparent;
	color: #1a8897;
	padding: 2px 6px;
}

.certificate_title {
	font-weight: var(--font-weight-semibold);
	font-size: 20px;
	color: var(--gray80);

	margin: 10px 0;

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.certificate_title span {
	max-width: 1020px;
}

.institute_name {
	font-weight: var(--font-weight-semibold);
	font-size: 18px;
	color: #000;

	margin-bottom: 5px;
}

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

.institute_website,
.institute_location {
	font-weight: var(--font-weight-semibold);
	font-size: 10px;
	color: var(--gray70);
}

.institute_location {
	margin-bottom: 20px;
}

.certificate_description {
	font-size: 14px;
	color: #000;

	margin-bottom: 20px;
}

.files_container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
	margin: 20px 0px;
}

.file_item {
	width: 72px;
	height: 72px;
}

.certificate_score {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	text-align: center;
	color: #ffffff;

	padding: 8px;

	background-color: #1b8897;
	border-radius: 5px;
	margin-bottom: 10px;
	display: inline-block;
}

.certificate_dates,
.certificate_validity {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: var(--gray70);
}

/* .certificate_header {
    width: 100%;
    height: fit-content;



    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
}

.certificate_info_wrapper {
    flex: 1;
    height: fit-content;
}

.certificate_info_wrapper p {
    width: 100%;
    height: fit-content;
    font-weight: var(--font-weight-semibold);
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.certificate_name {
    font-weight: var(--font-weight-semibold);
    font-size: 15px;
}

.certificate_date {

    font-size: 14px;
    font-weight: normal !important;
}

.certificate_country {
    font-weight: var(--font-weight-extralight);
    font-size: 14px;
    font-weight: 300 !important;
}

.edit_button {
    flex: 0 0 25px;
    width: 25px;
    height: 25px;
}

.certificate_description {
    width: 100%;
    height: fit-content;
    margin: 10px 0 0 0;

    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 17px;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.certificate_media {
    margin-top: 10px;

    width: 100%;
    height: fit-content;

    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;

    overflow-x: auto;

    scrollbar-color: var(--gray30) transparent !important;
    scrollbar-width: 5px !important;

    padding-bottom: 5px !important;
}

.certificate_media::-webkit-scrollbar {
    width: 5px;
    height: 8px !important;

    border-radius: 5px !important;
    cursor: pointer !important;
}

.certificate_media::-webkit-scrollbar-track {
    background-color: transparent !important;
}

.certificate_media::-webkit-scrollbar-thumb {
    background-color: var(--gray30);
    cursor: pointer !important;
}*/

.certificate_media_item {
	flex: 0 0 90px;
	height: 90px;

	margin-right: 10px;

	background-color: var(--gray20);
}

.certificate_media_item:last-child {
	margin-right: 0;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .container {
        padding: 20px 20px 30px;
    } */

	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 800px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .container {
        margin-top: 15px;
        padding: 20px 20px 30px;
    } */
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 590px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .container {
        margin-top: 15px;
        padding: 20px 20px 30px;
    } */
	.certificate {
		padding: 16px 10px 10px 4px;
	}
	.certificate:first-child {
		margin-top: 8px;
	}
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}
	.certificate_title span {
		max-width: 400px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .container {
        margin-top: 10px;
        padding: 10px 10px 40px;
    } */

	/* .header button {
        width: 24px;
        height: 24px;

        justify-content: center;
    } */

	/* .header button label {
        display: none !important;
    }

    .certificate {
        padding: 10px 0 15px;
    }

    .certificate:first-child {
        margin-top: 8px;
    } */
	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}

	.certificate_title span {
		max-width: 190px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	/* .container {
        margin-top: 15px;
        padding: 22px 20px 30px;
    } */
	/* .certificate:first-child {
        margin-top: 8px;
    }

    .certificate {
        padding: 16px 10px 15px;
    }

    .certificate_name {
        font-weight: var(--font-weight-semibold);
        font-size: 14px;
    }

    .certificate_date {

        font-size: 13px;
        font-weight: normal !important;
    }

    .certificate_country {
        font-weight: var(--font-weight-extralight);
        font-size: 13px;
        font-weight: 300 !important;
    }

    .certificate_description {
        font-size: 14px;
        line-height: 16px;
    } */

	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}
	.certificate_title span {
		max-width: 450px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .container {
        margin-top: 10px;
        padding: 10px 10px 40px;
    } */
	/* .wrapper {
      padding: 0 10px;
  }

    .header button {
        width: 24px;
        height: 24px;

        justify-content: center;
    }

    .header button label {
        display: none !important;
    }

    .certificate {
        padding: 16px 0 15px;
    }

    .certificate:first-child {
        margin-top: 8px;
    }

    .certificate_name {
        font-weight: var(--font-weight-semibold);
        font-size: 14px;
    }

    .certificate_date {

        font-size: 13px;
        font-weight: normal !important;
    }

    .certificate_country {
        font-weight: var(--font-weight-extralight);
        font-size: 13px;
        font-weight: 300 !important;
    }

    .certificate_description {
        font-size: 14px;
        line-height: 16px;
    } */

	.certificate_media_item {
		flex: 0 0 70px;
		height: 70px;

		margin-right: 6px;
	}
	.certificate_title span {
		max-width: 190px;
	}
}
