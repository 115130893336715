@use "../../shared.module.scss";

.leftside_header {
	display: flex;
	gap: 10px;
}

.item_icon img {
	width: 24px;
	height: 24px;
}

.right_side {
	overflow: hidden;
}

.right_side p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.item_description {
	display: flex;
	align-items: center;
	gap: 10px;
}

.item_description label {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.visibility_icon {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.map {
	width: 100%;
	height: 360px;
	margin-top: 15px;
}

.modal_map_container {
	padding: 20px;
}

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

.addresses_section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	margin-top: 15px;
}

/*
item from EducationItem.jsx
 */

.visibility_wrapper {
	margin-top: 15px;
	width: 20%;
	height: fit-content;
}

.visibility_label {
	display: inline-block;
	margin-bottom: 7px;
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

/*
Add From
 */

.add_form {
	padding: 20px;
	height: 100%;
}

.form_input_wrapper {
	margin: 15px 0;

	width: 100%;
	height: fit-content;

	position: relative;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

#street_wrapper {
	margin: 15px 0;
	width: 100%;
	height: fit-content;
	display: flex;
}

#street_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

#street {
	width: 70%;
	margin-right: 15px;
}

#street label,
#zip label {
	margin-bottom: 7px;
	display: block;
}

#zip {
	width: 30%;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	margin-bottom: 7px;
	display: block;
}

.description_wrapper {
	width: 100%;
	height: 35px;
	margin: 7px 0 0;
	border: solid 1px var(--gray50);
	border-radius: 5px;
}

.react_select_wrapper {
	margin-top: 15px;

	width: 100%;
	height: fit-content;
}

.react_select_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: inline-block;
	margin-bottom: 7px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.react_select_wrapper p.error_message,
.form_input_wrapper p.error_message,
#street_wrapper p.error_message {
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.addresses_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.visibility_wrapper {
		width: 25%;
	}

	.addresses_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.visibility_wrapper {
		width: 35%;
	}

	.addresses_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.text_wrapper {
		padding-top: 10px;
	}

	.visibility_wrapper {
		width: 100%;
	}

	.addresses_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.visibility_wrapper {
		width: 35%;
	}

	.addresses_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.text_wrapper {
		padding-top: 10px;
	}

	.visibility_wrapper {
		width: 100%;
	}

	.addresses_section {
		grid-template-columns: repeat(1, 1fr);
	}

	.button_container button {
		width: 100%;
	}
}
