.button_container {
	display: flex;
	align-items: center;
	justify-content: center;
	/* background-color: #fff; */
	padding: 20px;
}

/* .loadbtn {
    padding: 0 15px;
    height: 35px;
    font-size: 14px;
} */
