.container {
    position: relative;
    background-color: #fff;
    padding: 20px;
}

.options {
    position: absolute;
    right: 0;
    top: 0;
}
