.info_text {
	text-align: center;
}

.btn_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.warning_wrapper {
	padding: 20px 20px 0;
}

.custom_btn {
	width: 320px;
}

.search_input {
	background-color: var(--gray20);
}

.no_results_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.has_intro {
	padding: 0px;
}

.service_header_wrapper {
	position: relative;
	min-height: 30px;
}

.service_tab_container {
	background-color: #fff;
	border-top: 10px solid var(--gray20);
	flex-grow: 1;
	height: 100%;
}
.search_container {
	padding: 20px 20px 0;
}

.service_title_wrapper {
	background-color: rgba(114, 122, 130, 0.8);
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 5px 20px;
	color: white;
	text-transform: uppercase;
	font-weight: var(--font-weight-bold);
}

.service_title_wrapper h3,
h3.services_section {
	margin: 0;
	font-size: 14px;
}

.description_wrapper {
	padding: 20px;
}

.no_padding {
	padding: 0;
}

.profile_wrapper {
	display: flex;
	align-items: center;
	padding: 20px 20px 0;
}

.service_full_view_item {
	color: unset;
	display: flex;
}

.service_full_view_item:focus,
.service_full_view_item:hover {
	text-decoration: unset;
	color: unset;
}

.services_container {
	min-height: 20px;
}

.services_container > *:not(:first-child) {
	border-top: 6px solid var(--gray20);
}

.services_container.is_stale {
	opacity: 0.5;
	transition: opacity 0.2s 0.2s linear;
}

@media screen and (max-width: 748px) {
	.service_full_view_item:first-of-type > div {
		padding-top: 0px;
	}
}
