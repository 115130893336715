.radio {
	display: flex; //! switched from block to flex
	cursor: pointer;
	user-select: none;
	text-align: left;
	position: relative;

	&.disabled {
		color: #a8abae;
	}

	// & + .radio {
	//     margin-top: 12px;
	// }
	input {
		// display: none;
		opacity: 0;
		position: absolute;
		// top: 50%;
		// transform: translateY(-50%);
		// width: 14px;
		// height: 14px;
		// visibility: hidden;
		// left: 39px;
		& + span {
			// display: inline-block; //! Commenting > check if no break
			position: relative;
			padding-left: 30px;
			line-height: 14px;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0px;
				left: 0px;
				border-radius: 50%;
				margin-right: 5px;
				width: 14px;
				height: 14px;
				border: 1px solid #b6b6b6;
				background: #fff;
			}

			&.centered::before {
				top: 50%;
				transform: translateY(-50%);
			}

			&.standalone::before {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				margin-right: 0px;
			}

			&:after {
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				background: #fff;
				position: absolute;
				border-radius: 50%;
				top: 4px;
				left: 4px;
				opacity: 0;
				transform: scale(0, 0);
				transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
			}

			&.centered:after {
				top: 50%;
			}

			&.standalone:after {
				transform: translate(-50%, -50%);
				top: 50%;
				left: 50%;
			}
		}
		&:checked + span:after {
			opacity: 1;
			transform: scale(1, 1);
		}
		&:checked + span.centered:after {
			transform: scale(1, 1) translateY(-50%);
		}
		&:checked + span.standalone:after {
			transform: scale(1, 1) translate(-50%, -50%);
		}
		&:checked + span::before {
			background: var(--gaawk);
			border: solid 1px var(--light-gaawk);
		}

		&:focus + span::before {
			box-shadow: 0 0 0 2px rgba(217, 240, 243, 0.4);
		}
	}
}
