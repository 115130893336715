.scroll_container {
	height: 100%;
	overflow: hidden !important;
	overflow-x: scroll !important;

	/* padding: 20px 0; */
	/* margin-top: 24px; */
}

.scroll_container::-webkit-scrollbar {
	width: 8px;
	background-color: transparent;
}

/**  STYLE 11 */
.scroll_container::-webkit-scrollbar-track {
	border-radius: 8px;
	background: transparent;
}

.scroll_container::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background: transparent;
}

.scroll_container::-webkit-scrollbar-thumb:hover {
	background: transparent;
}

.scroll_container::-webkit-scrollbar-thumb:active {
	background: transparent;
}

.tabs_wrapper {
	display: flex;
	/* overflow-x: scroll; */
	/* -ms-overflow-style: none; IE and Edge */
	/* scrollbar-width: none; Firefox */

	/* align-content: flex-end; */
	align-items: center;
	gap: 20px;
}

/* .tabs_wrapper::-webkit-scrollbar {
	display: none;
} */

.menuBtn {
	width: fit-content;
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: #000;
	white-space: nowrap;
}

.menuBtn.selected {
	padding: 3px 10px;
	border-radius: 4px;
	background-color: var(--gaawk);
	color: #ffffff !important;
}
