.container {
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	gap: 52px;
	row-gap: 20px;
	margin-top: 20px;
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.container {
		gap: 50px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.container {
		gap: 42px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.container {
		gap: 43px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.container {
		gap: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.container {
		gap: 65px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.container {
		// gap: 20px;
		grid-template-columns: repeat(auto-fill, 130px);
		justify-content: space-between;
	}
}

@media screen and (max-width: 367px) {
	.container {
		justify-content: center;
	}
}
