.header {

	font-size: 18px;
	font-weight: var(--font-weight-bold);
	text-align: left;
	color: #fff;
}

.post_actions_wrapper {
	display: flex;
	align-items: center;
	gap: 30px;
}

.fullscreen_icon {
	width: auto;
	height: 20px;
	cursor: pointer;
}

.mobile_post_container {
	display: none;
}

@media screen and (max-width: 973.98px) {
	.post_actions_wrapper {
		gap: 20px;
	}

	@media screen and (max-width: 747.98px) {
		.mobile_post_container {
			display: block;
		}
	}
}
