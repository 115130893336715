.groupInfo_container {
	width: 100%;
	/* height: calc(100dvh - 55px); */
	height: 100%;
	background-color: #ffffff;
	padding: 0 20px 20px;
	overflow: auto;
	scrollbar-width: none;
	/* -ms-overflow-style: none; IE and Edge */
}

/* .groupInfo_container::-webkit-scrollbar {
    display: none;
} */

.upper_block,
.lower_block {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background-color: #ffffff;
}

.lower_block {
	margin-top: 30px;
	/* gap: 60px; */
}

.lower_block > div:last-child {
	border-bottom: none;
}

.group_header {
	width: 570px;
	border-radius: 0 0 16px 16px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	padding: 0 8px 8px;

	display: flex;
	align-items: flex-start;
}

.img_wrapper {
	/* width: 100%; */
	width: 320px;
	min-width: 320px;
	height: 320px;
	background-color: lightgray;
	border-radius: 0 0 10px 10px;
}

.img_wrapper img {
	object-fit: cover;
	border-radius: 0 0 10px 10px;
}

.group_controls {
	width: 100%;

	margin-left: 18px;
	margin-bottom: 12px;
}

.control_item {
	cursor: pointer;
	margin: 15px 0px;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	font-weight: var(--font-weight-semibold);
	font-size: 16px;
}

.control_item:last-of-type {
	cursor: pointer;
	margin: 15px 0 0 0;
}

.control_item img {
	width: 24px;
	height: auto;
	margin-right: 10px;
}

.group_description {
	width: 570px;
	margin: 20px 0;
}

.group_description h1 {
	font-size: 28px;

	color: #000000;
	/* margin: 20px 0 10px 0; */
	margin: 0 0 10px;
}

.group_description p {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.group_description .group_creation {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;

	text-align: right;
	color: #a6b1bc;
	margin: 30px 0;
}

.group_members {
	/* width: 100%; */
	/* flex: 0 0 50%; */
	width: 545px;
	/* flex: 1 0 auto; */
}

.group_members.full_width {
	width: 100%;
}

.group_members h3 {
	margin: 10px 0px;
	color: var(--gaawk);
}

.group_members_header {
	margin-bottom: 20px;
}

.group_members_header h2 {
	font-weight: var(--font-weight-semibold);
	font-size: 20px;

	margin: 0;
}

.group_members_header span {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;

	color: #a6b1bc;
}

/* ADD PEOPLE TO GROUP CHAT MODAL */
.add_group_container {
	width: 100%;
	height: fit-content;
	padding: 20px 20px 40px;
}

.group_participants_wrapper {
	display: flex;
	flex-wrap: wrap;
	/* margin: 10px 0; */
	margin-bottom: 10px;
	gap: 10px;
}

.group_participants_wrapper .participant {
	display: flex;
	justify-content: space-between;
	background-color: #dbdfe4;
	border-radius: 6px;
	border: solid 1px #a6b1bc;
	padding: 5px 10px;
	/* margin-right: 10px; */
	/* margin-top: 10px; */
}

.group_participants_wrapper .participant span {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: var(--gray80);
	white-space: nowrap;
	cursor: default;
}

.group_participants_wrapper .participant img {
	margin-left: 14px;
	width: 17px;
	cursor: pointer;
}

.group_search_wrapper {
	padding: 12px 20px;
	background-color: #dbdfe3;
}

.search_wrapper {
	height: 60px;
	background-color: #dbdfe4;
	display: flex;
	align-content: center;
	padding: 0 20px;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;
	/* flex: auto; */

	position: relative;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 13px;

	color: #000000;
	display: inline-block;
}

.form_input_wrapper input {
	width: 100%;
	height: 40px;
	margin: 10px 0 20px 0px;
	object-fit: contain;
	border-radius: 5px;
	border: solid 1px var(--gray50);
	padding: 5px 10px;
	font-size: 14px;
}

.form_input_wrapper input.search_contact {
	width: 100%;
	height: 40px;
	margin: 10px 0px 0px 0px;
	object-fit: contain;
	border-radius: 5px;
	border: solid 1px var(--gray50);
	padding: 5px 10px 5px 40px;
	font-size: 14px;
}

.form_input_wrapper input:focus {
	border: #6cc5d1 solid 2px !important;
}

.form_input_wrapper textarea {
	width: 100%;
	min-height: 96px;
	margin: 10px 0 20px 0px;
	padding: 5px 10px;
	object-fit: contain;
	border-radius: 5px;
	border: solid 1px var(--gray50);
	resize: vertical;
	overflow: hidden;

	font-size: 14px;
}

.form_input_wrapper textarea:focus {
	border: #6cc5d1 solid 2px !important;
}

.form_input_wrapper .img_preview_container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.form_input_wrapper .img_preview_container img {
	object-fit: cover;
}
.form_input_wrapper .img_preview_container img.img_placeholder {
	width: 60px;
	height: auto;
}

.form_input_wrapper img.search_icon {
	width: 20px;
	height: auto;
	position: absolute;
	top: 41px;
	left: 10px;
}

.custom_upload_button {
	position: relative;
}

.custom_upload_button input[type="file"] {
	opacity: 0;
	z-index: 1;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 35px;
	margin: 0;

	cursor: pointer;
}

.custom_upload_button button {
	position: absolute;
	background-color: #a6b1bc;
	border-radius: 5px;
	top: 0px;
	width: 100%;
	height: 35px;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	text-align: center;
	color: white;
}

.img_preview_container {
	width: 100%;
	height: 160px;
	margin: 10px 0 20px 0px;
	object-fit: contain;
	background-color: #dbdfe4;
}

.form_controls {
	margin-top: 85px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.form_controls.second_step_modal {
	margin-top: 20px;
}

.form_controls button {
	width: 96px;
	height: 35px;
	border: solid 1px var(--gray50);
	border-radius: 5px;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	text-align: center;

	color: var(--gray70);
}

.form_controls button.cancel {
	margin-right: 30px;
}

.form_controls button.next {
	background-color: var(--gaawk);
	color: white;
	border: none;
}

.form_controls button.create {
	width: 148px;
	background-color: var(--gaawk);
	color: white;
	border: none;
}

.contact_item {
	width: 100%;
	border-bottom: 1px var(--gray30) solid;
	padding: 5px 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.contact_item:last-of-type {
	border-bottom: none;
}

.contact_item:hover {
	background-color: var(--gray30);
	cursor: pointer;
}

/* .contact_item_wrapper {
    width: 100%;


    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
} */

.contact_list_wrapper {
	max-height: 300px;
	overflow: auto;
}

.direct_msg_icon {
	width: 24px;
	height: auto;
	cursor: pointer;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.group_members {
		width: 455px;
	}

	/* .groupInfo_container {
		width: 1000px;
	} */

	.group_header,
	.group_description {
		width: 100%;
	}

	.group_description h1 {
		font-size: 20px;
	}

	.group_header {
		border-radius: 0;
		box-shadow: none;
		padding: 0;
	}

	.upper_block {
		flex-direction: column;
	}
	.lower_block {
		margin-top: 0px;
		/* gap: 70px; */
	}

	.group_description .group_creation {
		text-align: left;
		margin: 20px 0 40px 0;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.group_members {
		width: 350px;
	}

	/* .groupInfo_container {
		width: 766px;
	} */

	.group_header,
	.group_description {
		width: 100%;
	}

	.group_description h1 {
		font-size: 20px;
	}

	.group_header {
		border-radius: 0;
		box-shadow: none;
		padding: 0;
	}

	.img_wrapper {
		width: 343px;
		min-width: 343px;
		height: 343px;
	}

	.upper_block {
		flex-direction: column;
	}

	.lower_block {
		margin-top: 0px;
	}

	.group_controls {
		margin-left: 40px;
		margin-bottom: 0px;
	}

	.group_description .group_creation {
		text-align: left;
		margin: 20px 0 40px 0;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.group_members {
		width: 290px;
	}

	/* .groupInfo_container {
		width: 599px;
	} */

	.group_header,
	.group_description {
		width: 100%;
	}

	.group_header {
		border-radius: 0;
		box-shadow: none;
		padding: 0;
	}

	.group_description h1 {
		font-size: 20px;
	}

	.img_wrapper {
		width: 270px;
		min-width: 270px;
		height: 270px;
		align-self: flex-start;
	}

	.upper_block {
		flex-direction: column;
	}

	.lower_block {
		margin-top: 0px;
	}

	.group_controls {
		margin-left: 22px;
		margin-bottom: 0px;
	}

	.group_description .group_creation {
		text-align: left;
		margin: 20px 0 30px 0;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.group_members {
		width: 100%;
	}

	.groupInfo_container {
		/* width: 360px; */
		padding: 0px 0px 60px;
	}

	.group_header,
	.group_description {
		width: 100%;
	}

	.group_description {
		margin-bottom: 0;
		padding: 0 20px 20px;
		border-bottom: 6px solid var(--gray20);
	}

	.group_header {
		border-radius: 0;
		box-shadow: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 20px;
	}

	.group_description h1 {
		font-size: 20px;
		/* margin: 15px 0 10px 0; */
	}

	.upper_block {
		flex-direction: column;
	}

	.group_controls {
		margin-top: 20px;
		margin-left: 0px;
		margin-bottom: 0px;
		padding: 0 20px 20px;
		border-bottom: 6px solid var(--gray20);
	}

	.group_description .group_creation {
		text-align: left;
		margin: 20px 0;
	}

	.lower_block {
		flex-direction: column;
		margin-top: 0px;
		gap: 0px;
	}

	.lower_block > div {
		padding: 20px;
		border-bottom: 6px solid var(--gray20);
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.group_members {
		width: 320px;
	}

	/* .groupInfo_container {
		width: 640px;
	} */

	.group_header,
	.group_description {
		width: 100%;
	}

	.group_header {
		border-radius: 0;
		box-shadow: none;
		padding: 0;
	}

	.group_description h1 {
		font-size: 20px;
	}

	.img_wrapper {
		width: 290px;
		min-width: 290px;
		height: 290px;
	}

	.upper_block {
		flex-direction: column;
	}

	.lower_block {
		margin-top: 0px;
	}

	.group_controls {
		margin-left: 22px;
		margin-bottom: 0px;
	}

	.group_description .group_creation {
		text-align: left;
		margin: 20px 0 30px 0;
	}

	.lower_block {
		/* gap: 25px; */
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	.group_members {
		width: 100%;
	}

	.groupInfo_container {
		/* width: 360px; */
		padding: 0px 0px 60px;
	}

	.group_header,
	.group_description {
		width: 100%;
	}

	.group_header {
		border-radius: 0;
		box-shadow: none;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 20px;
	}

	.group_description {
		margin-bottom: 0;
		padding: 0 20px 20px;
		border-bottom: 6px solid var(--gray20);
	}

	.group_description h1 {
		font-size: 20px;
		/* margin: 15px 0 10px 0; */
	}

	.upper_block {
		flex-direction: column;
	}

	.group_controls {
		margin-top: 20px;
		margin-left: 0px;
		margin-bottom: 0px;
		padding: 0 20px 20px;
		border-bottom: 6px solid var(--gray20);
	}

	.group_description .group_creation {
		text-align: left;
		margin: 20px 0;
	}

	.lower_block {
		flex-direction: column;
		margin-top: 0px;
	}

	.lower_block {
		/* gap: 25px; */
		gap: 0px;
	}
	.lower_block > div {
		padding: 20px;
		border-bottom: 6px solid var(--gray20);
	}
}
