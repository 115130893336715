.dropped_file_item {
	width: 96px;
	height: fit-content;
	position: relative;
}

/* .dropped_file_item.isChat {
	width: 96px;
	height: 96px;
}

.dropped_file_item .icon.isChat {
	height: 96px;
} */

.dropped_file_item .icon {
	width: 100%;
	height: 96px;
	border-radius: 12px;
	background-color: var(--gray20);
	position: relative;

	/* border: solid 1px var(--gray70); */
}

.dropped_file_item .icon .play_icon {
	position: absolute;
	width: 40px;
	height: 40px;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* .dropped_file_item .icon.video_container {
	position: relative;
}
.dropped_file_item .icon.video_container .play_button {
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
} */

/* .dropped_file_item .icon img {
	object-fit: cover;
	border-radius: 11px;
}

.dropped_file_item .icon.full_size {
	display: flex;
	justify-content: center;
	align-items: center;
}

.dropped_file_item .icon.full_size img {
	width: 40px;
	height: auto;
	border-radius: 0px;
} */

.dropped_file_item .fileIcon {
	object-fit: fill;
	width: 50px;
	height: 50px;
	border-radius: 0px;
}

.dropped_file_item .imgOrVideo {
	object-fit: cover;
	border-radius: 11px;
}

.dropped_file_item .close {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 4px;
	right: 4px;
	z-index: 1;
	background-color: var(--gray20);
	border-radius: 50%;
}

.dropped_file_item .name {
	margin-top: 8px;

	width: 100%;
	height: fit-content;
	font-weight: var(--font-weight-semibold);
	font-size: 12px;

	color: #000;

	text-overflow: ellipsis;
	overflow-wrap: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.dropped_file_item .type {
	width: 100%;
	height: 17px;
	font-weight: var(--font-weight-extralight);
	font-size: 12px;

	color: #000;

	text-overflow: ellipsis;
	overflow-wrap: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .dropped_file_item {
		width: 96px;
	}

	.dropped_file_item .icon {
		height: 96px;
	} */
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .dropped_file_item {
		width: 96px;
	}

	.dropped_file_item .icon {
		height: 96px;
	} */
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.dropped_file_item {
		width: 72px;
	}

	.dropped_file_item .icon {
		height: 72px;
	}

	.dropped_file_item.isChat {
		width: 72px;
		height: 72px;
	}

	.dropped_file_item .icon.isChat {
		height: 72px;
	}

	.dropped_file_item.isChat.p2p {
		width: 96px;
		height: 96px;
	}

	.dropped_file_item .icon.isChat.p2p {
		height: 96px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .dropped_file_item {
		width: 96px;
	}

	.dropped_file_item .icon {
		height: 96px;
	} */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.dropped_file_item {
		width: 72px;
	}

	.dropped_file_item .icon {
		height: 72px;
	}

	.dropped_file_item.isChat {
		width: 72px;
		height: 72px;
	}

	.dropped_file_item .icon.isChat {
		height: 72px;
	}

	.dropped_file_item.isChat.p2p {
		width: 96px;
		height: 96px;
	}

	.dropped_file_item .icon.isChat.p2p {
		height: 96px;
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	/* .dropped_file_item {
		width: 96px;
	}

	.dropped_file_item .icon {
		height: 96px;
	} */
}
