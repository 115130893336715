.item_container {
	margin-top: 20px;
	border-bottom: 1px solid #dbdfe4;
	padding-bottom: 20px;
}

.item_container:last-of-type {
	border-bottom: none;
	padding-bottom: 0px;
}

.container {
	display: flex;
	align-items: center;
}

.company_icon {
	width: 48px;
	height: 48px;
	background-color: #dbdfe4;
	/* border: 1px solid #a6b1bc; */
	border-radius: 8px;
	margin-right: 20px;
}

.details_container span {
	display: block;
}

.position {
	font-size: 18px;
	color: #000000;
}

.company_name {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: #000;
}

.position_location {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--gray70);
}

.checkbox_container {
	margin-top: 15px;
	display: flex;
	align-items: center;
}

.checkbox_container span {
	font-size: 14px;
	color: #000000;
}
