.container {
	width: 100%;
	margin-top: 150px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.iconsView {
	height: 31px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.headerIcon {
	width: auto;
	height: 100%;
}

.title {
	width: 586px;
	margin-top: 18px;
	color: #000000;
	text-align: center;
	font-family: "Poppins-SemiBold";
	font-size: 45px;
	line-height: 50px;
}

.description {
	width: 807px;
	margin-top: 40px;
	color: #000000;
	text-align: center;
	font-family: "Poppins";
	font-size: 16px;
	line-height: 24px;
}

.image {
	width: 807px;
	height: auto;
	margin-top: 8px;
}

.features {
	width: 1026px;
	margin-top: 100px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;

	row-gap: 100px;
}

.feature {
	width: 303px;
	height: 252px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.featureImg {
	width: 52px;
	height: auto;
}

.featureSubtitle {
	color: #2fadbe;
	margin-top: 16px;
	text-align: center;
	font-family: "Poppins-Regular";
	font-size: 16px;
}

.featureTitle {
	color: #000;
	text-align: center;
	font-family: "Poppins";
	font-size: 28px;
}

.featureDescription {
	color: #000;
	text-align: center;
	font-family: "Poppins";
	font-size: 16px;
	line-height: 24px;
}

.download_img_wrapper {
	width: 100%;
	height: 100%;
}

.downloadView {
	margin-top: 124px;
	margin-bottom: 142px;

	width: 876px;
	height: 475px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 40px;
}

.downloadViewImg {
	width: auto;
	height: 100%;
	object-fit: cover;
}

.downloadViewContainer {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.downloadViewTitle {
	width: 100%;
	color: #000000;
	font-family: "Poppins-SemiBold";
	font-size: 40px;
	line-height: 50px;
}

.downloadViewSubtitle {
	margin-top: 5px;
	width: 100%;
	color: #000;
	font-family: "Poppins";
	font-size: 16px;
	line-height: 24px;
}

.downloadButtons {
	margin-top: 15px;
	width: 100%;
	height: 45px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 12px;
}

.downloadButton {
	width: 150px;
	height: 100%;

	border-radius: 5.918px;
	border: 0.741px solid #dfdfdf;
	background: #fff;

	padding: 7px 11px;
}

.downloadButtonImg {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
	.iconsView {
		height: 27px;
	}
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
	.container {
		margin-top: 113px;
	}
	.iconsView {
		height: 18px;
	}
	.title {
		width: 347px;
		margin-top: 10px;
		font-size: 26px;
		line-height: 30px;
	}
	.description {
		width: 476px;
		margin-top: 25px;
		font-size: 12px;
		line-height: 18px;
	}
	.image {
		width: 476px;
		height: auto;
		margin-top: 24px;
	}
	.features {
		width: 100%;
		margin-top: 80px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;

		row-gap: 24px;
	}

	.feature {
		width: 390px;
		height: 195px;
	}

	.featureSubtitle {
		margin-top: 8px;
		font-size: 12px;
	}

	.featureTitle {
		font-size: 28px;
	}

	.featureDescription {
		font-size: 12px;
		line-height: 18px;
	}

	.downloadView {
		margin-top: 62px;
		margin-bottom: 76px;

		width: 368px;
		height: 553px;

		flex-direction: column;
		gap: 50px;
	}

	.downloadViewImg {
		width: 100%;
		height: 100%;
	}

	.downloadViewTitle {
		font-size: 28px;
		line-height: 10px;
		text-align: center;
	}

	.downloadViewSubtitle {
		margin-top: 12px;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
	}

	.downloadButtons {
		margin-top: 18px;
		width: 100%;
		height: 45px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 12px;
	}
}

/*mobile*/
@media screen and (max-width: 743.98px) {
	.container {
		margin-top: 82px;
	}
	.iconsView {
		height: 18px;
	}
	.title {
		width: 310px;
		margin-top: 10px;
		font-size: 26px;
		line-height: 30px;
	}
	.description {
		width: 310px;
		margin-top: 14px;
		font-size: 12px;
		line-height: 18px;
	}
	.image {
		width: 310px;
		height: auto;
		margin-top: 18px;
	}
	.features {
		width: 100%;
		margin-top: 80px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;

		row-gap: 24px;
	}

	.feature {
		width: 300px;
		height: 195px;
	}

	.featureSubtitle {
		margin-top: 8px;
		font-size: 12px;
	}

	.featureTitle {
		font-size: 28px;
	}

	.featureDescription {
		font-size: 12px;
		line-height: 18px;
	}

	.downloadView {
		margin-top: 62px;
		margin-bottom: 76px;

		width: 296px;
		height: 488px;

		flex-direction: column;
		gap: 34px;
	}

	.downloadViewImg {
		width: 100%;
		height: 100%;
	}

	.downloadViewTitle {
		font-size: 28px;
		line-height: 10px;
		text-align: center;
	}

	.downloadViewSubtitle {
		margin-top: 12px;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
	}

	.downloadButtons {
		margin-top: 18px;
		width: 100%;
		height: 45px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
}
