@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@font-face {
	font-family: "Nexa-Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Nexa-Bold"), url(./fonts/Nexa-Bold.otf) format("opentype");
}

@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: normal;
	src: local("Poppins"), url(./fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
	font-family: "Poppins-SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("Poppins-SemiBold"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
	font-family: "Poppins-Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Poppins-Regular"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
	font-family: "Poppins-Thin";
	font-style: normal;
	font-weight: normal;

	src: local("Poppins-Thin"), url(./fonts/Poppins-Thin.ttf) format("truetype");
}

:root {
	border-radius: 12px;

	/* //* FONT-WEIGHTS */
	--font-weight-thin: 100;
	--font-weight-extralight: 200;
	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;
	--font-weight-extrabold: 800;
	--font-weight-black: 900;

	/* //* GAAWK COLORS */
	--gaawk: #6cc5d1;
	--dark-gaawk: #3c96a3;
	--light-gaawk: #d4f3f7;

	/* //* GAAWK COLORS */
	--primary100: #e2f3f6;
	--primary200: #c4e8ed;
	--primary300: #a7dce3;
	--primary400: #89d1da;
	--primary500: #50bac8;
	--primary600: #3aabba;
	--primary700: #32919e;
	--primary800: #297882;
	--primary900: #205e67;
	--primary1000: #17454b;

	/* //* RED COLORS */
	--error100: #ffd5d5;
	--error200: #ff4e4e;
	--error300: #e70000;

	/* //* GRAY COLORS */
	--light-grey: #f8f9fa; /* CUSTOM GRAY ONLY FOR JOBS DETAILS PAGE */

	--gray20: #f2f3f5; /* this is the app background color */
	--gray30: #eaeaea;
	--gray50: #c4ccd3;
	--gray70: #a6b1bc;
	--gray80: #727a82;

	--white: #fff;
	--scrollbarWidth: 8px;
	--scrollbarHeight: 4px;
}

* {
	/* Same properties as above */
	&::-webkit-scrollbar {
		width: 8px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 4px;
		transition: background-color 0.2s ease-in-out;
	}

	&:hover::-webkit-scrollbar-thumb,
	&:focus::-webkit-scrollbar-thumb,
	&:active::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;

	&:hover {
		scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
	}
}

body {
	/* margin: 0; */
	max-width: 100vw;
	/* margin: auto !important; */

	display: flex;
	justify-content: center;
	background-color: #f2f3f5;
	/* width: 100vw; */
	position: relative;
	font-family: "Inter", sans-serif;
	font-weight: var(--font-weight-regular);
	color: #000;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

header {
	height: 55px;
}

p {
	margin: 0;
	padding: 0;
	font-weight: var(--font-weight-regular);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: var(--font-weight-semibold);
	margin: 0;
}

/* //TODO >> rules for h1 h2 h5 h6 */
/* h1  >> 20px */
/* h2 >> 18px */
/* h5 >> 12px */
/* h6 >> 10px */

h3 {
	font-size: 16px;
}
h4 {
	font-size: 14px;
}

a {
	text-decoration: none;
	color: unset;
}

/* a:hover,
a:focus {
	color: #000;
} */

iframe#webpack-dev-server-client-overlay {
	display: none !important;
}

button {
	border: none;
	background: none;
	cursor: pointer;
	padding: 0;
	font-weight: var(--font-weight-semibold);
}

div {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */
}

input {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */

	border: none;
	outline: none;
}

.customInput {
	/* Your custom styles here */
	outline: solid 1px var(--gray50);
	border-radius: 5px;
	border: none;
	height: 35px;
	font-size: 14px;
}

.customInput:enabled:focus {
	outline: solid 2px var(--gaawk);
}

span {
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */
}

.required {
	margin-left: 2px;
	font-weight: var(--font-weight-semibold);
	/* font-weight: var(--font-weight-semibold); */
	font-size: 13px;
	line-height: 16px;
	letter-spacing: normal;
	text-align: left;
	color: var(--gaawk);
}

label {
	padding: 0;
	margin: 0;
}

.gaawk-text {
	font-size: inherit;
	font-family: Nexa-Bold, serif;
}

textarea {
	outline: none;
}

.img-fluid {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.lazy-load-image-background {
	border-radius: inherit !important;
}

::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: var(--gray70);
}
:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: var(--gray70);
	opacity: 1;
}
::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: var(--gray70);
	opacity: 1;
}
:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--gray70);
}
::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--gray70);
}

::placeholder {
	/* Most modern browsers support this now. */
	color: var(--gray70);
}

::selection {
	background: var(--gaawk);
}

.toast_container {
	top: 60px !important;
}

/* .pac-container is the dropdown for google-map autocomplete feature. Z-index needed to have the results appearing in a modal */
.pac-container {
	z-index: 10000 !important;
}
