.delete_confirmation_modal {
	padding: 26px 20px 20px;
}

.delete_confirmation_modal p {
	width: 100%;

	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
}

.delete_confirmation_modal p.warning {
	margin-top: 20px;
	color: var(--error200);
}

.delete_confirmation_modal .buttons {
	margin-top: 32px;

	width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.delete_confirmation_modal .buttons button {
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	line-height: 19px;
	letter-spacing: 0.65px;
	text-align: center;
	text-transform: uppercase;
	color: #000000;
}

.delete_confirmation_modal .buttons button.danger {
	width: 96px;
	height: 25px;
	padding: 3px 8px;
	border-radius: 5px;
	border: solid 1px var(--error200);
	background-color: var(--error200);
	color: #ffffff;
}
