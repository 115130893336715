.scrollableMenu_wrapper {
	padding: 10px 20px 0;
	border-bottom: 6px solid var(--gray20);
}

.counter {
	padding: 20px 20px 0;
	margin-bottom: 20px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

.search_container {
	padding: 0 20px;
	margin-top: 20px;
}
