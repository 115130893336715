.modal_container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.section_wrapper {
}

.title {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	display: block;
	margin: 0px 0px 10px 0px;
}

.contacts_wrapper {
	height: 220px;
	overflow: auto;
}

.contacts_wrapper.hasError {
	height: fit-content;
}

.btn_wrapper {
	display: flex;
	justify-content: flex-end;
}
