.profile_container {
	flex-basis: 33.333333%;
}

.url_container {
	margin-top: 10px;

	font-size: 14px;
	text-align: center;
	color: var(--gray70);
}

.button_container {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.button_container button {
	display: flex;
	align-items: center;
}

.button_container button.delete {
	color: red;
	border: 1px solid red;
}

.edit img,
.delete img {
	width: 12px !important;
	height: auto !important;
	margin-right: 10px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .data_section {
        grid-template-columns: 1fr 1fr;
    } */
	.profile_container {
		flex-basis: 50%;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .data_section {
        grid-template-columns: 1fr 1fr;
    } */
	.profile_container {
		flex-basis: 50%;
	}
}

@media screen and (max-width: 1165.98px) {
	/* .data_section {
        grid-template-columns: 1fr;
    } */
	.profile_container {
		flex-basis: 100%;
	}
}
