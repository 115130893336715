@use "variables" as *;

.container {
	width: 1200px;
	margin: auto;
	background-color: #fff;
	flex-grow: 1;
	height: 100dvh;
}

.back_container {
	display: flex;
	align-items: center;
	gap: 10px;

	p {
		font-weight: var(--font-weight-bold);
		font-size: 14px;
	}
}

.container.with_padding {
	padding: 20px;
}

.background {
	height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	background-color: var(--gray20);
	overflow: auto;
	display: flex;
	flex-direction: column;
}

/*1608*/
// @media screen and (min-width: 1608px) and (max-width: 1999.98px) {
// }

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.container {
		width: 992px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.container {
		width: 992px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.container {
		width: 768px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.container {
		width: 620px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.container {
		width: 620px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.container {
		width: 100vw;
	}
}
