.container {
	border-bottom: 6px solid var(--gray20);
}

.container:last-of-type {
	border-bottom: none;
}

.section_wrapper {
	padding: 20px;
}

.section_wrapper {
	& > h3 {
		margin: 0 0 20px;
	}
}

.service_item {
	display: block;
	color: unset;
}

.service_item:focus,
.service_item:hover {
	text-decoration: unset;
	color: unset;
}

.services_container {
	display: flex;
	margin-left: -20px; /* gutter size offset */
	width: auto;
}

.services_container_grid_columns {
	padding-left: 20px; /* gutter size */
	background-clip: padding-box;

	// .service_item {
	//     margin-bottom: 15px;
	// }
}

.button_wrapper {
	display: flex;
	justify-content: center;
}

.no_padding {
	padding: 0px;
}
