.form {
	padding: 40px;

	h2 {
		font-size: 18px;
		margin: 20px 0;
	}

	.arrow_btn {
		width: fit-content;
		height: 20px;
		display: flex;
		align-items: center;
		img {
			width: 20px;
		}
	}

	.info {
		font-size: 10px;
		color: var(--gray80);
		display: block;
		margin-top: 25px;
	}

	.custom_padding {
		input {
			padding-right: 40px;
		}
	}

	.next_btn {
		width: 100%;
		margin-top: 20px;
	}
}
