/* application details */
.applicant {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 0;
}

.application_details {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.application_details .sub_section {
	font-size: 12px;
	color: var(--black);
	/* margin-bottom: 20px; */
}

.application_details h4 {
	margin-top: 0;
	font-size: 16px;
	margin-bottom: 10px;
}

.application_details .sub_section .file {
	color: var(--gaawk);
}
.application_details .sub_section .file:hover {
	text-decoration: underline;
	cursor: pointer;
}

/* button container */

.button_container {
	/* padding: 0 20px; */
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

.application_item_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

.item_btn {
	/* border: 1px solid var(--gray30);
	color: var(--gray80);
	text-transform: none;
	letter-spacing: normal; */
	white-space: nowrap;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.button_container {
		flex-direction: column-reverse;
		gap: 10px;
	}
	.btn {
		width: 100%;
	}
}

@media screen and (max-width: 639.98px) {
	.button_container {
		flex-direction: column-reverse;
		gap: 10px;
	}
	.btn {
		width: 100%;
	}
}
