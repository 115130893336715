.container {
  width: 100%;
  height: 250px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  text-align: left;
}

.title {
  color: #000000;
  font-family: "Poppins-SemiBold";
  font-size: 27px;
}

.items {
  margin-top: 22px;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  flex: 1;
  flex-wrap: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;

  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.items::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {

  .container {
    height: 145px;
  }

  .title {
    font-size: 16px;
  }

  .items {
    margin-top: 12px;
  }
}

/*mobile*/
@media screen and (min-width: 320px) and (max-width: 743.98px) {

  .container {
    height: 145px;
    padding: 0 30px;
  }

  .title {
    font-size: 16px;
  }

  .items {
    margin-top: 12px;
  }
}