.post_header {
	width: 100%;
	height: fit-content;
	min-height: 45px;

	display: flex;

	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
	overflow: hidden;
}

.post_header.align_center {
	align-items: center;
	padding: 10px 0;
}

.emotion_icon {
	width: 20px;
	height: 20px;

	position: relative;

	margin-right: 25px;
}

.location_wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.link {
	color: var(--gaawk);
}

.emotion_icon img {
	position: absolute;
	top: 2px;
	right: -20px;

	width: inherit !important;
	height: inherit !important;

	object-fit: contain;
}

.tagged_names {
	color: var(--gaawk);
}

.tagged_names:hover {
	color: var(--gaawk);
	text-decoration: underline;
	cursor: pointer;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.emotion_icon {
		width: 16px;
		height: 16px;

		margin-right: 20px;
	}

	.emotion_icon img {
		position: absolute;
		top: 2px;
		right: -16px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

@media screen and (max-width: 639.98px) {
	.post_header {
		min-height: 30px;
		height: fit-content;
	}

	.emotion_icon {
		width: 16px;
		height: 16px;

		margin-right: 20px;
	}

	.emotion_icon img {
		position: absolute;
		top: 2px;
		right: -16px;
	}
}
