.calendar_form {
	padding: 20px;
	background-color: #fff;
}

.form_input_wrapper {
	margin-bottom: 20px;
	width: 100%;
	height: fit-content;

	display: flex;
	flex-direction: column;
}

.input_label,
.datePickerItem label,
.textarea_container label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: #000000;
	margin-bottom: 8px;
}

.form_input_wrapper input {
	width: 100%;
	height: 35px;
	border-radius: 5px;
}

.form_input_wrapper p.error_message,
.datePickerWrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
}

.button_wrapper {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.option_item {
	display: flex;
	align-items: center;
	gap: 10px;
}

.color_pin {
	display: block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
}

.color_pin.control {
	margin-left: 7px;
}

/* DATEPICKER */

.datePickerWrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 20px;
	gap: 40px;
}

.datePickerItem {
	width: 100%;
	display: flex;
	flex-direction: column;
}

/* .datePickerWrapper input {
    margin: 0;
} */

.details_section {
	margin-top: 20px;

	width: 100%;
	height: fit-content;
}

/* TEXT AREA */
.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

@media screen and (max-width: 639px) {
	.button {
		width: 100%;
	}
}
