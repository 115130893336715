.form {
	padding: 20px;
}

.form > div:first-child {
	margin-top: 0px;
}

.member_item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.member_item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
}

@media screen and (max-width: 639px) {
	.button_container button {
		width: 100%;
	}
}
