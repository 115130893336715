.scroll_container {
	height: 100%;
	overflow: hidden !important;
	overflow-x: scroll !important;

	display: flex;
	gap: 8px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.loader {
	width: 50px;
	height: 50px;
	border: 5px solid #ccc;
	border-top: 5px solid #1a8897;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}
.forward_icon {
	width: 12px;
	height: 12px;
	margin-left: 12px;
}
.backdrop {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.forward_icon {
	width: 8px;
	height: 8px;
}
.container_product_image {
	color: unset;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.container_product_image:hover,
.container_product_image:focus {
	text-decoration: none;
	color: unset;
}
.container_link {
	color: unset;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
}

.container_link:hover,
.container_link:focus {
	text-decoration: none;
	color: unset;
}

.product_img {
	border-radius: 8px;
	width: 88px;
	height: 88px;
}
.service_products_container {
	padding: 20px;
}

.section {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.items_wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	gap: 50px;
	row-gap: 20px;
}

.view_all_container {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.notFirst {
	margin-top: 50px;
}
.name_image_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 22px;
}
.see_all_container {
	display: flex;
	align-items: flex-end;
	align-self: flex-end;
}
.service_name {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #000;
}
.see_all {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: var(--gray80);
}

.product_name {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: #000;
	margin-top: 8px;
	max-width: 99px;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper {
		gap: 50px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper {
		gap: 42px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		gap: 43px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		gap: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		gap: 65px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.items_wrapper {
		gap: 20px;
	}
}

@media screen and (max-width: 367px) {
	.items_wrapper {
		grid-template-columns: repeat(auto-fill, 130px);
		gap: 5px;
		justify-content: space-between;
	}
}
