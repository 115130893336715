.project_dates {
	display: flex;
	align-items: center;

	& p:not(:last-child):after {
		content: "-";
		display: inline-block;
		margin: 0 5px;
	}

	p span {
		font-weight: var(--font-weight-semibold);
	}
}
