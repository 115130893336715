.container {
	display: flex;
	align-items: center;
	gap: 10px;
	width: fit-content;
	position: relative;
}

.stroke {
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: #a6b1bc;
}

.step_item {
	width: 12px;
	height: 12px;
	border: solid 1px #a6b1bc;
	border-radius: 50%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.step_item.active {
	border: solid 1px var(--gaawk);
}

.step_item.active span {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--gaawk);
}

.step_item.validated span {
	background-color: #a6b1bc;
	width: 8px;
	height: 8px;
	border-radius: 50%;
}
