@use "variables" as *;

.container {
	flex: 0 0 360px !important;
	position: relative;
	z-index: 2;
	background-color: var(--gray20);
	display: flex;

	// height: 100%;

	&.isMobile {
		flex-direction: column;
	}
}

.left_panel_main_wrapper {
	/* width: 100%; */
	width: 360px;

	&.navBarEnabled:not(.isMobile) {
		width: 310px;
	}

	height: 100dvh;
	height: calc(100dvh - $topNavBarHeight);

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;

	background-color: transparent;

	z-index: 2;

	position: relative;
}
.left_panel_main_wrapper.isMobile {
	height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
}

.middle_container {
	width: 100%;
	height: 100%;

	/* position: absolute;
    top: 55px;
    left: 0;
    bottom: 56px; */

	background-color: #ffffff;

	overflow-y: auto;

	/*padding: 15px 5px 15px 10px;*/
}

.middle_container.hybridNav {
	overflow-y: hidden !important;
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.container:not(.hybridNav) {
		display: none;
	}

	.container.hide {
		display: none;
	}

	// .hybridNav.right_priority {
	// 	flex: 0 !important;
	// }

	.left_panel_main_wrapper {
		width: 100%;
		left: 50%;
		top: 0 !important;
		transform: translateX(-50%);
	}

	.middle_container {
		width: 100%;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.container:not(.hybridNav) {
		display: none;
	}

	/* .container.hybridNav {
		flex: 1 !important;
	} */

	.container.hide {
		display: none;
	}

	// .hybridNav.right_priority {
	// 	flex: 0 !important;
	// }

	.left_panel_main_wrapper {
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}

	.middle_container {
		width: 100%;
	}
}

@media screen and (max-width: 747.98px) {
	.container.hybridNav {
		width: 100vw;
		flex: none !important;
	}
}
