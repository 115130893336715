@use "variables" as *;

.container {
	padding: 20px;
}

.margin_top {
	margin-top: 10px;
}

.mb_8 {
	margin-bottom: 8px;
}

.multicheckbox_container {
	margin-bottom: 20px;

	label {
		font-weight: var(--font-weight-semibold);
		font-size: 14px;
		// color: #000000;
		// margin-bottom: 8px;
		display: block;
	}
}

.multicheckbox_container:last-of-type {
	margin-bottom: 0px;
}

.button_container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
	// padding: 20px;
	.btn {
		width: 100%;
		height: 35px;
		font-size: 14px;

		.clear {
			background-color: #a6b1bc;
		}
	}
}

.loading_spinner {
	height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	align-items: center;
}

.input_wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;

	input {
		height: 35px;
		cursor: text;
		flex-basis: 33.33%;
	}
}

.dropdown_container {
	width: 100%;
	height: 37px;
	// flex-basis: 33.33%;
}
