.form {
	padding: 20px;
	background-color: #fff;
}

.subsection h3 {
	margin: 0 0 10px;
}

.subsection .info_text {
	object-fit: contain;

	font-size: 12px;
	font-style: italic;
	color: var(--gray80);
}

.thumbs_container {
	/* display: flex;
	flex-wrap: wrap;
	margin: 10px 0px;
	width: 100%;
	gap: 20px; */
	display: grid;
	grid-template-columns: repeat(auto-fill, 80px);
	gap: 20px;
	justify-content: space-between;
}

.custom_upload_button {
	margin: 15px 0 30px;
}

.custom_upload_button input[type="file"] {
	opacity: 0;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 100%;

	cursor: inherit;
}

.img_option button {
	position: relative;

	width: 100%;
	height: 35px;
	background-color: var(--light-gaawk);
	border-radius: 5px;

	font-weight: var(--font-weight-bold);
	font-size: 14px;

	text-align: center;
	color: var(--gaawk);
	margin-top: 10px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

@media screen and (max-width: 639px) {
	.save_btn {
		width: 100%;
	}
}
