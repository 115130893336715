.container {
  width: 100%;
  min-height: 62px;
  height: auto;
  padding: 16px 0;

  border-bottom: #F1F1F1 3px solid;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  cursor: pointer;
}

.title {
  flex: 1;
  text-align: left;
  color: #000;
  font-family: "Poppins";
  font-size: 18px;
}

.close {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.openIcon {
  //padding: 5px;
  width: 15px;
  height: 15px;
}

.closeIcon {
  //padding: 5px;
  width: 13px;
  height: 13px;
}

.descriptionWrapper {
  width: 100%;
  height: auto;

  padding: 16px 24px;
  text-align: left;
}

.description {
  //width: 100%;

  //border-top: #F1F1F1 3px solid;

  color: #000;

  font-family: "Poppins-Regular";
  font-size: 18px;
  line-height: 120%;
}


/*others*/
@media screen and (max-width: 1279.98px) {
  .container {
  }
}
