.container {
	white-space: pre-line;
	word-break: break-all;
}

.special_text {
	color: var(--gaawk);
}

.special_text:hover {
	cursor: pointer;
}
