.keyPerson_item {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}

.keyPerson_item.clickable {
	cursor: pointer;
}

.img_wrapper {
	position: relative;
}

.delete_button {
	background-color: #ffffff;
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	border-radius: 50%;
}

.delete_icon {
	width: 24px;
	height: 24px;
}

.img_container {
	width: 146px;
	height: 146px;
	border-radius: 50%;
	background-color: #a6b1bc;
}

.img_container img {
	border-radius: 50%;
	object-fit: cover;
}

.info_container {
	font-weight: var(--font-weight-semibold);
	font-size: 20px;

	text-align: center;
}

.position {
	font-size: 18px;
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.delete_button {
		top: 0px;
		right: 0px;
	}

	.info_container {
		font-size: 18px;
	}

	.position {
		font-size: 14px;
	}

	.img_container {
		width: 96px;
		height: 96px;
	}
}

@media screen and (max-width: 1165.98px) {
	.delete_button {
		top: 0px;
		right: 0px;
	}

	.info_container {
		font-size: 18px;
	}

	.position {
		font-size: 14px;
	}

	.img_container {
		width: 96px;
		height: 96px;
	}
}
