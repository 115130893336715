.container {
	width: 100%;
	max-height: 450px;
	padding: 0 20px 20px;
	overflow-y: auto;
}

.contact_item {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.contact_item_wrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	border-bottom: 1px var(--gray30) solid;
}

.contact_item:last-of-type .contact_item_wrapper {
	border-bottom: none;
}

.user_item > a > div {
	padding: 20px 0;
}

.tabs {
	position: relative;
}

.tabs hr {
	position: absolute;
	left: 20px;
	right: 20px;
	margin: 0;
	bottom: 14px;
	height: 3px;

	background-color: #dbdfe4;
	border: none;
}

.tabs_container {
	padding: 20px 0;
	margin: 0 20px;

	display: flex;
	align-items: center;
	gap: 20px;
	overflow: auto !important;
}

.tab_button_wrapper {
	height: 25px;
	position: relative;
	z-index: 2;
}

.tab_button_wrapper img {
	width: 20px;
}

.button {
	display: flex;
	align-items: center;
	gap: 5px;

	font-size: 14px;
	font-weight: var(--font-weight-semibold);

	line-height: 1.29;

	text-align: left;
	color: var(--gray80);

	cursor: pointer;
}

.button h4 {
	margin: 0;
}

.selected_tab {
	position: absolute;

	width: 100%;
	height: 3px;

	bottom: -6px;
	left: 0;

	background-color: var(--gaawk);
}
