.container {
  margin-top: 95px;

  width: 1503px;
  height: 900px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 75px;
  position: relative;
}

.img {
  width: 578px;
  height: 100%;
  object-fit: cover;
}

.forCreators {
  //flex: 1;
  height: 100%;
  //max-width: ;
  width: 850px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  gap: 50px;

}

.section {
  width: 100%;
  height: 250px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {

  .container {
    margin-top: 66px;

    width: 1280px;
    height: 900px;
    gap: 36px;
  }

  .img {
    width: 289px;
    object-position: 85% 10%;
  }

}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
  .container {
    margin-top: 27px;

    width: 744px;
    height: 498px;
    gap: 45px;
  }

  .img {
    width: 158px;
    object-position: 85% 10%;
  }

  .forCreators {
    width: 485px;
    height: auto;
    gap: 30px;
  }

  .section {
    height: 145px;
  }
}

/*mobile*/
@media screen and (min-width: 320px) and (max-width: 743.98px) {
  .container {
    margin-top: 12px;

    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 12px;
  }

  .img {
    width: auto;
    height: 400px;
    object-fit: cover;
  }

  .forCreators {
    flex: auto;
    width: 100%;
    height: auto;
    gap: 30px;
  }

  .section {
    height: 145px;
  }
}