.container {
	width: 100%;
	/* height: 100%; */
	padding: 20px;
	background-color: #ffffff;
}

.button_wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.image_options_container {
	width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;

	position: relative;
}

.img_canvas {
	width: 184px;
	height: 184px;
	border-radius: 50%;
	background: var(--gray50);

	display: block;
	margin-left: auto;
	margin-right: auto;

	position: relative;
}

.img_canvas img {
	object-fit: cover;
	border-radius: 50%;
}

.temp_custom_date_wrapper {
	position: absolute;
	bottom: -10px;
	left: calc(50% - (110px / 2));
	/* left: calc(50%); */

	width: 110px;
	height: 26px;

	/*padding: 8px 18px 7px 18px;*/
	border-radius: 5px;
	/* border: solid 2px #ffffff; */
	background-color: var(--gray80);

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.temp_custom_date_wrapper img {
	width: 18px;
	height: auto;
}
.temp_custom_date_wrapper label {
	/*width: 96px;*/
	height: 20px;
	margin-left: 5px;

	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	/* line-height: 1.14; */
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.frame_wrapper {
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;

	background-color: transparent;
}

.frame_wrapper img {
	width: 100%;
	height: 100%;
	object-fit: contain;

	border: none !important;

	background-color: transparent;
}

.img_options {
	padding-bottom: 10px;
	/* border-bottom: 1px solid var(--gray30); */
}

.feed_checkbox_container {
	border-top: 1px solid var(--gray30);
}

.img_options button {
	display: flex;
	align-items: center;
	margin: 20px 0;
	gap: 10px;
}

.icon {
	width: 20px;
	height: 20px;
	fill: #000;
}

.public_info {
	width: 100%;
	/* height: 35px; */
	margin: 20px 0;
	/* border-radius: 5px; */
	/* background-color: var(--gaawk);; */

	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.public_info.fixed_mt {
	margin: 20px 0 !important;
}

.public_info img {
	width: 16px;
	height: 16px;
	margin: 0 10px 0 0;
	object-fit: contain;
}

.public_info label {
	font-size: 14px;
	font-style: italic;
	color: var(--gray70);
}

.caption_canvas {
	margin-top: 10px;

	width: 100%;
	/* height: 100px;
    padding: 8px 5px 11px 14px;
    border-radius: 5px;
    border: solid 1px var(--gray50); */
}

.textarea_wrapper {
	height: 200px;
}

.share_canvas {
	width: 100%;
	margin: 20px 0 0 0;
	display: flex;
	align-items: center;
	/* height: 60px; */
	/* background-color: var(--gray20); */
	/* flex-wrap: wrap; */
	/* justify-content: center; */
	/* align-content: center; */
}

.share_canvas label {
	/* margin: 0 30px 0 0; */

	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #000000;
}

.share_canvas .checkbox {
	background-color: #1a8897;

	margin: 0 10px !important;
}

/* .save_button_container {
	margin-top: 30px;

	width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
}

.save_button {
	width: 130px;
	height: 35px;
	padding: 0px 25px;
	border-radius: 5px;
	border: solid 1px #6cc5d1;
	background-color: var(--gaawk);

	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
}

.save_button > div {
	display: flex;

	justify-content: center;
	align-content: center;
} */

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .container {
		padding: 40px 20px 30px;
	} */

	/* .empty_container {
        width: 150px;
    } */
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .container {
		padding: 40px 20px 30px;
	} */

	/* .img_option label {
        display: none !important;
    }

    .empty_container {
        width: 60px;
    } */
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .container {
		padding: 30px 20px 30px;
	} */

	.public_info {
		height: 30px;
		margin: 0 0 0 0;
	}

	.public_info.fixed_mt {
		margin: 20px 0 0 0 !important;
	}

	.public_info label {
		font-size: 12px;
	}

	.share_canvas label {
		margin: 0 10px 0 0;
	}

	.save_button_container {
		margin-top: 40px;
	}

	.temp_custom_date_wrapper {
		left: calc(50% - (135px / 2));

		width: 135px;
		height: 29px;
	}

	.temp_custom_date_wrapper label {
		/*width: 96px;*/
		height: 19px;
		margin: 0 0 0 10px;
		font-size: 13px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	/* .container {
		padding: 20px 20px 30px;
	} */

	.public_info {
		height: 30px;
		margin: 0 0 0 0;
	}
	.public_info.fixed_mt {
		margin: 20px 0 0 0 !important;
	}

	.public_info label {
		font-size: 12px;
	}

	.caption_canvas textarea {
		font-size: 14px;
	}

	.save_button_container {
		margin-top: 40px;
	}

	.temp_custom_date_wrapper {
		left: calc(50% - (135px / 2));

		width: 135px;
		height: 29px;
	}

	.temp_custom_date_wrapper label {
		/*width: 96px;*/
		height: 19px;
		margin: 0 0 0 10px;
		font-size: 13px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .container {
		padding: 20px 20px 30px;
	} */

	.public_info {
		height: 30px;
		margin: 0 0 0 0;
	}

	.public_info.fixed_mt {
		margin: 20px 0 0 0 !important;
	}

	.public_info label {
		font-size: 12px;
	}

	.caption_canvas textarea {
		font-size: 14px;
	}

	.share_canvas label {
		margin: 0 10px 0 0;
	}

	.save_button_container {
		margin-top: 40px;
	}

	.temp_custom_date_wrapper {
		left: calc(50% - (135px / 2));

		width: 135px;
		height: 29px;
	}

	.temp_custom_date_wrapper label {
		/*width: 96px;*/
		height: 19px;
		margin: 0 0 0 10px;
		font-size: 13px;
	}

	.button_wrapper button {
		width: 100%;
	}
}
