.container {
    width: 100%;
    height: 226px;
    background-color: #f2f5f5;

    padding-top: 25px;
    padding-bottom: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.links {
    width: auto;
    height: 32px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}
.linkItem {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: unset;
}

.links span {
    color: #000;
    text-align: center;
    font-family: "Poppins-SemiBold";
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    cursor: pointer;
}

.copyrightView {
    width: auto;
    height: 50px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.copyrightText {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    line-height: 29.6px;
}

.evoIcon {
    width: 78px;
    height: 20px;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
    .container {
        padding-top: 22px;
    }
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
    .container {
        height: 396px;
        padding-top: 47px;
    }

    .links {
        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
    }

    .links img {
        margin-bottom: 38px;
    }
}

/*mobile*/
@media screen and (max-width: 743.98px) {
    .container {
        height: 396px;
        padding-top: 25px;
    }

    .links {
        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
    }

    .links img {
        margin-bottom: 38px;
    }

    .copyrightText {
        width: 180px;
        line-height: 12px;
        margin-bottom: 10px;
    }
}
