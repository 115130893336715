.container {
	width: 100%;
	height: fit-content;
	/*overflow: hidden;*/
	background-color: #000000;
	flex-grow: 1;
}

.top_header {
	width: 100%;
	height: 175px;
	background-color: #000000;

	display: flex;
	flex-wrap: wrap;
	align-content: center;
	/*align-items: center;*/
	justify-content: center;

	/*position: relative;*/

	/* position: fixed;
    top: 0;
    left: 0;
    right: 0; */

	z-index: 10;
}

.top_header_wrapper {
	width: 100%;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	align-content: center;
	/*align-items: center;*/
	justify-content: center;

	position: relative;
}

.logo {
}

.logo img {
	width: 300px;
}

.header_option {
	position: absolute;
	top: 20px;
	right: 20px;
}

.option_icon {
	width: 100%;
	height: 100%;
}

.middle_container {
	width: 100%;

	margin-top: 5px;
	/* margin-bottom: 60px; */

	height: calc(100dvh - (180px + 56px + 55px));

	overflow-y: auto;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
}

.middle_wrapper {
	width: 100%;
	height: 100%;

	/* overflow-y: scroll; */
}

.services_section {
	width: 100%;
	height: 100%;

	/*padding: 25px 0 0 0;*/

	background-color: #000;

	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
}

.search_panel {
	margin-top: 20px;

	width: 100%;

	display: flex;
	align-content: center;
	justify-content: center;
}

.search_input_wrapper {
	width: 699px;
	height: 45px;
}

.service_panel {
	display: grid;
	grid-template-columns: repeat(6, auto);
	gap: 40px;
	margin: 30px 0;
}

.profile_wrapper {
	width: 100%;
	background-color: #ffffff;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.profile_container {
	width: 795px;
	height: fit-content;
}

.board_wrapper {
	width: 100%;
	height: fit-content;
	background-color: var(--gray20);

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	padding-bottom: 50px;
}

.board_title {
	width: 100%;

	margin: 20px 0;

	font-size: 28px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 0.79;
	letter-spacing: normal;
	text-align: center;
	color: #000;
}

.board_container {
	width: 1420px;
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-around;
}

.board_title span {
	font-family: "Nexa-Bold", "Inter", serif;
	font-weight: var(--font-weight-bold);
}

/* POPOVER CSS */

.sys_popover_items {
	/*min-width: ;*/
	min-width: 247px;
	background-color: #ffffff;
	border-radius: 10px;
	padding: 10px 0;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
}

.sys_popover_item {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-start;

	padding: 7px 15px;
}

.sys_popover_item:hover {
	background-color: #e7e7e8;
	cursor: pointer;
}

.sys_popover_item span {
	width: 25px;
	height: 25px;
	margin-right: 15px;
	line-height: 1;
}

.sys_popover_item span.img-sm {
	padding: 3px;
}

.sys_popover_item span.pink {
	border-radius: 5px;
	background-color: #ff00e6;
}

.sys_popover_item button {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	/*line-height: 20px;*/
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	line-height: 1;

	position: relative;
}

/* .service {
    width: 113px;
    height: 117px;
    margin: 0px 10px 20px 10px;


    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    color: #ffffff;

    transition: 0.3s;
}

.service:hover {
    transform: scale(1.1);
    color: var(--gaawk);;
    font-weight: var(--font-weight-semibold);
    font-size: 18px;
}

.service .service_icon {
    width: 90px;
    height: 90px;
    border: solid 1px #ffffff;
    border-radius: 20px;
    background-image: linear-gradient(to bottom, #292929, #000000);

    cursor: pointer;
}

.service label {
    height: 20px;
    margin-top: 7px;

    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: inherit;

    cursor: pointer;
} */

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	/* .services_section {
        height: 455px;
    } */

	/* .service_panel {
        width: 800px;
    } */
	.board_container {
		width: 1420px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .services_section {
        height: 455px;
    } */

	/* .service_panel {
        width: 800px;
    } */
	.board_container {
		width: 1240px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .services_section {
        height: 455px;
    } */

	/* .service_panel {
        width: 800px;
    } */
	.board_container {
		width: 1120px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	/* .services_section {
        height: 455px;
    } */

	/* .service_panel {
        width: 800px;
    } */
	.board_container {
		width: 740px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.top_header {
		height: 120px;
	}

	.more_page_popover {
		/* position: inherit !important; */
		top: -80px !important;
	}

	.logo img {
		width: 200px;
	}

	.header_option {
		top: 10px;
		right: 10px;
	}

	.middle_container {
		/* margin-top: 125px; */

		height: calc(100dvh - (125px + 60px + 56px));
	}

	/* .services_section {
        height: 363px;
    } */

	.search_input_wrapper {
		width: 500px;
		height: 45px;
	}

	.service_panel {
		gap: 30px;
	}

	.profile_container {
		width: 650px;
		height: fit-content;
	}

	.board_container {
		width: 673px;
	}

	/* .service {
        width: 80px;
        height: 97px;
    }

    .service .service_icon {
        width: 70px;
        height: 70px;
    }

    .service label {
        font-size: 13px;
    } */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.top_header {
		height: 120px;
	}

	.more_page_popover {
		/* position: inherit !important; */
		top: -80px !important;
	}

	.logo img {
		width: 200px;
	}

	.header_option {
		top: 10px;
		right: 10px;
	}

	.middle_container {
		/* margin-top: 125px; */

		height: calc(100dvh - (125px + 60px + 56px));
	}

	/* .services_section {
        height: 363px;
    } */

	.search_input_wrapper {
		width: 500px;
		height: 45px;
	}

	.service_panel {
		gap: 30px;
	}

	.profile_container {
		width: 626px;
		height: fit-content;
	}

	.board_container {
		width: 610px;
	}

	/* .service {
        width: 90px;
        height: 97px;
        margin: 0 0 20px 0;
    }

    .service .service_icon {
        width: 70px;
        height: 70px;
    }

    .service label {
        font-size: 13px;
    } */
}

/*360*/
@media screen and (max-width: 639.98px) {
	.top_header {
		height: 100px;
	}

	.more_page_popover {
		/* position: inherit !important; */
		top: -60px !important;
	}

	.logo img {
		width: 150px;
	}

	.header_option {
		top: 10px;
		right: 10px;
	}

	.middle_container {
		/* margin-top: 105px; */

		height: calc(100dvh - (105px + 60px + 56px));
	}

	/* .services_section {
        height: fit-content;
        padding-bottom: 20px;
    } */

	.search_panel {
		margin: 20px 0px;
	}

	.search_input_wrapper {
		width: 281px;
		height: 35px;
		margin: 20px;
	}

	.service_panel {
		/* width: 330px; */
		/* justify-content: space-around; */
		gap: 30px;
		grid-template-columns: repeat(3, auto);
	}

	.profile_container {
		width: 360px;
		height: fit-content;
	}

	.board_container {
		width: 340px;
	}

	/* .service {
        width: 90px;
        height: 97px;
        margin: 0 0 15px 0;
    }

    .service .service_icon {
        width: 70px;
        height: 70px;
    }

    .service label {
        font-size: 13px;
    } */
}
