.container {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	height: 100%;
	display: flex;
	align-items: center;
}

/* Hide the browser's default checkbox */
.container input {
	/* position: absolute; */
	/* top: calc(50% - 10px); */
	/*top: 0;*/
	opacity: 0;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 2px;
	z-index: 100;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	/* top: calc(50% - 10px); */
	left: 0;
	border-radius: 2px;
	border: 1px var(--gray50) solid;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	/*background-color: var(--gray30);*/
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark.black {
	background-color: #000000 !important;
	border: none;
}

.container input:checked ~ .checkmark.gaawk {
	background-color: var(--gaawk) !important;
	border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 5px;
	top: 2px;
	width: 5px;
	height: 9px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(40deg);
	-ms-transform: rotate(40deg);
	transform: rotate(40deg);
}
