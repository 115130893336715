.container {
	padding: 20px;
}

.users_container {
	margin-top: 20px;
	overflow: auto;
	max-height: 400px;

	&.no_margin_top {
		margin-top: 0px;
	}

	.users_list {
		margin: 0 10px;
	}
}

.confirm_btn {
	margin-top: 20px;
	width: 100%;
}

.tags_container {
	max-height: 110px;
	overflow: auto;
	margin-bottom: 20px;
	margin-top: 20px;
}
