.container {
	display: flex;
	align-items: flex-start;

	.job_details {
		width: 100%;
		overflow: hidden;

		.title_wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;

			h3 {
				display: -webkit-box;
				line-clamp: 1;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			button {
				display: flex;
				align-items: center;
			}
		}

		.company_name,
		.job_type {
			color: var(--gray80);
		}

		.job_type {
			display: flex;
			align-items: center;
			justify-content: space-between;

			p {
				font-weight: var(--font-weight-semibold);

				span:first-child::after {
					content: "/";
					display: inline-block;
					margin: 0 4px;
				}
				span:nth-child(2)::after {
					content: "-";
					display: inline-block;
					margin: 0 4px;
				}
				span:last-child {
				}
			}

			.applied {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}

		.connections_wrapper {
			display: flex;
			align-items: center;
			margin-top: 10px;

			p {
				font-size: 10px;
				font-weight: var(--font-weight-semibold);
				color: var(--gray80);
			}
		}

		.thumbnail_wrapper {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
		}

		.thumbnail_wrapper > div {
			border: 1px solid white;
		}

		.thumbnail_wrapper > div:nth-of-type(1) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}

		.thumbnail_wrapper > div:nth-of-type(2) {
			grid-column: 1 / 3;
			grid-row: 1 / 2;
			place-self: center;
			margin-left: 4px;
		}

		.thumbnail_wrapper > div:nth-of-type(3) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
			place-self: center;
			margin-left: 4px;
		}

		.thumbnail_wrapper > div:nth-of-type(4) {
			grid-column: 2 / 4;
			grid-row: 1 / 2;
			place-self: center;
			margin-left: 4px;
		}

		.thumbnail_wrapper > div:nth-of-type(5) {
			grid-column: 3 / 4;
			grid-row: 1 / 2;
			place-self: center;
			margin-left: 4px;
		}

		.thumbnail_wrapper > div.more_profile {
			grid-column: 3 / 5;
			grid-row: 1 / 2;
			place-self: center;
			margin-left: 4px;
			z-index: 1;
			width: 24px;
			height: 24px;
			border-radius: 50%;
			background-color: var(--gray80);
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;

			font-weight: var(--font-weight-semibold);
			font-size: 10px;
		}

		.project_wrapper {
			display: flex;
			align-items: flex-start;
			background-color: var(--gray20);
			border-radius: 5px;
			padding: 10px;
			margin-top: 10px;
			gap: 10px;
			overflow: hidden;

			.project_tag {
				background-color: var(--gray50);
				font-weight: var(--font-weight-bold);
				color: #fff;
				border: none;
				padding: 2px 6px;
				font-size: 12px;
				width: fit-content;
				flex-shrink: 0;
			}

			p {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
