@use "../shared.module.scss";

.language_wrapper {
	margin-top: 0;
}

/* .container {
	width: 100%;
	padding: 20px;
	background-color: #ffffff;

	display: flex;
	flex-direction: column;
	gap: 20px;
} */

.container.new {
	height: auto;
}

/* .button_wrapper {
	display: flex;
	justify-content: center;
} */

.form_wrapper {
	width: 100%;
}

.languages_section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	/* width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;

	gap: 20px; */
}

.language_item {
	width: 367px;
	margin-bottom: 17px;
}

.language_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.language_controls {
	display: flex;
	column-gap: 20px;
	margin-right: 3%;
}

.language_controls img {
	height: 24px;
	cursor: pointer;
}

.language_name {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--gray80);
	display: block;
	margin-bottom: 10px;
}

.proficiency_label {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: var(--black);

	display: block;
	margin-bottom: 7px;
}

.proficiency_grade {
	display: block;

	margin-top: 25px;

	font-weight: var(--font-weight-semibold);
	font-size: 12px;

	color: var(--gray70);
}

.language_slider_wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.controls {
	margin-top: 10px;
	display: flex;
	gap: 20px;
}

/*
Form Add
 */

.form {
	width: 100%;
	height: fit-content;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;

	margin-bottom: 15px;
}

.form_input_wrapper label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000000;

	display: block;
	margin-bottom: 7px;
}

.form_input_wrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.description_section {
	margin-top: 20px;

	width: 100%;
	height: fit-content;

	font-size: 12px;

	color: #a6b1bc;
}

.title {
	font-weight: var(--font-weight-semibold);
	display: block;
	margin-bottom: 20px;
	font-size: 14px;
}

.pro_title {
	margin-top: 20px;
	font-weight: var(--font-weight-semibold);
}

.description {
	margin-top: 4px;

	margin-bottom: 20px;
}

.description:last-of-type {
	margin-bottom: 0px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.delete_button {
		margin-right: 40px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.delete_button {
		margin-right: 40px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.delete_button {
		margin-right: 30px;
	}

	.language_item {
		width: 466px;
	}

	.languages_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.delete_button {
		margin-right: 30px;
	}

	.language_item {
		width: 354px;
	}

	.languages_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.delete_button {
		margin-right: 30px;
	}
	.language_item {
		width: 100%;
	}

	.languages_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.language_item {
		width: 100%;
	}

	.languages_section {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.language_item {
		width: 100%;
	}

	.languages_section {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.language_item {
		width: 100%;
	}

	.languages_section {
		grid-template-columns: repeat(1, 1fr);
	}

	.button_container button {
		width: 100%;
	}
}
