.container {
	margin: 15px 0;
	&.disabled {
		color: #a8abae;
		cursor: not-allowed;
	}
}

.tags_container {
	margin-top: 15px;
}

.info_text {
	margin-top: 10px;

	font-size: 12px;
	color: var(--black);
}
.info_text_italic {
	margin-top: 5px;

	font-style: italic;
	font-size: 12px;
	color: var(--black);
}
