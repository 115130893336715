.title_wrapper {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	color: var(--black);
	padding: 20px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title_wrapper.no_bottom_padding {
	padding: 20px 20px 0;
}

.title_wrapper button {
	width: 24px;
	height: 24px;
	background-color: #a6b1bc;
	border-radius: 4px;
	padding: 4px;
}

.title_wrapper button img {
	display: flex;
	align-items: center;
}

.active_filters {
	background-color: var(--gray20);
	padding: 10px 20px;
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}

.pending_btn {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: var(--gaawk);
	padding: 10px 20px;
}

.pending_btn:hover {
	text-decoration: underline;
}

.talent_container {
	padding: 20px;
	background-color: #fff;
}
