.container {
	width: 100%;
	height: 100%;
	margin: 0;
	display: flex;
	align-items: center;
	// border-right: 1px solid #dbdfe4;
	// padding-right: 10px;
	padding-right: 5px;
}

.profile_wrapper {
	width: 32px;
	height: auto;
}

.spinner_wrapper {
	width: 100%;
	padding: 0;
}
