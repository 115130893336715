.container {
	padding-bottom: 20px;
	margin-bottom: 20px;

	width: 100%;
	height: fit-content;

	background-color: #ffffff;

	/* display: flex; */
	/* flex-wrap: wrap; */
	/* justify-content: flex-start; */
	/* align-content: flex-start; */
	border-bottom: 6px solid var(--gray20);
}

.container:last-of-type {
	margin-bottom: 0px;
	border-bottom: none;
}

.item_header {
	/* flex: auto; */
	justify-content: space-between;
	display: flex;
}

.item_header img {
	width: 20px;
	cursor: pointer;
}

/* .emptyContainer {
	padding-bottom: 20px;
	margin-bottom: 20px;
	width: 100%;

	background-color: #ffffff;
	border-bottom: 6px solid var(--gray20);
}

.emptyContainer:last-of-type {
	margin-bottom: 0px;
	border-bottom: none;
} */

.emptyText {
	font-size: 14px;
	color: var(--gray80);

	margin: 20px 0px;
}

.emptyBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.custom_btn {
	width: 330px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.header_txt {
		height: 22px;
		font-size: 16px;
		line-height: 18px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.header_txt {
		height: 18px;
		font-size: 15px;
		line-height: 18px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.header_txt {
		height: 19px;
		font-size: 14px;
		line-height: 16px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.emptyBtn button {
		width: 100%;
	}

	.header_txt {
		height: 19px;
		font-size: 14px;
		line-height: 16px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.header_txt {
		height: 19px;
		font-size: 14px;
		line-height: 18px;
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	.emptyBtn button {
		width: 100%;
	}

	.header_txt {
		height: 19px;
		font-size: 14px;
		line-height: 16px;
	}
}
