.talent_item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray20);
}

.talent_link {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	text-decoration: none;
	overflow: hidden;
}

.talent_item:last-of-type {
	margin-bottom: 0px;
	padding-bottom: 0px;
	border-bottom: none;
}

.controls {
	display: flex;
	align-items: center;
	gap: 20px;
}

.controls button img {
	width: 24px;
	height: 24px;
}
