.reply_bubble_wrapper {
	background-color: #dbdfe4;
	max-width: 720px;
	padding: 10px;
	border-radius: 12px 12px 0 0;

	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 10px;
}

.left_side {
	/* min-width: 0; */
}

.right_side {
	display: flex;
	gap: 10px;
}

.reply_sent_by {

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.message {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.media_container {
	width: 58px;
	height: 58px;
	border-radius: 12px;
	background-color: #ffffff;
}

.media_container img {
	object-fit: cover;
	border-radius: 12px;
}

.cancel_icon {
	width: 18px;
	height: 18px;
	cursor: pointer;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.reply_bubble_wrapper {
		max-width: 595px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.reply_bubble_wrapper {
		max-width: 460px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.reply_bubble_wrapper {
		max-width: 400px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.reply_bubble_wrapper {
		max-width: 334px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.reply_bubble_wrapper {
		max-width: 400px;
	}
}

@media screen and (max-width: 639.98px) {
	.reply_bubble_wrapper {
		max-width: 315px;
	}
}
