.progress_bar_wrapper {
	margin: 20px 0;
}

.progress_bar {
	width: 100%;
	height: 1px;
	background-color: var(--gaawk);
	position: relative;
}

.achieved {
	position: absolute;
	height: 5px;
	border-radius: 3px;
	top: -2px;
	background-color: var(--gaawk);
}

.progress_label {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: right;
	color: var(--gaawk);
}
