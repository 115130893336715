.modal_container {
	padding: 20px;
}

.content_wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
}

.text {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
}

.red {
	color: var(--error200);
}

.btn_container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	margin-top: 20px;
}

.icon {
	width: 36px;
	height: auto;
}

@media screen and (max-width: 639.98px) {
	.btn_container {
		display: flex;
		flex-direction: column-reverse;
		gap: 10px;
	}

	.modal_btn {
		width: 100%;
	}
}
