.container {
	width: 100%;
	padding: 20px;
	background-color: #ffffff;

	display: flex;
	flex-direction: column;
	gap: 20px;
}

.button_wrapper {
	display: flex;
	justify-content: center;
}
