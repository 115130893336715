.comment_item {
	width: 100%;
	height: fit-content;
	padding: 10px 0 5px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

.comment_item:last-child {
	padding-bottom: 10px;
}

.comment_flex {
	flex: 1;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

.comment {
	width: 100%;
	height: fit-content;
	min-height: 55px;
	padding: 10px 10px 15px;
	border-radius: 5px;
	background-color: var(--gray20);

	display: flex;

	flex-direction: column;
	align-items: flex-start;
	/* flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start; */
}

.comment .owner {
	width: fit-content;
	height: 22px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000;
	cursor: pointer;
}

.comment .owner:hover {
	text-decoration: underline;
}

.comment .time {
	width: fit-content;
	height: 22px;

	margin-left: 5px;

	font-size: 12px;

	color: #000;
}

.comment_header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
}

.reply_text {
	width: 100%;
	height: fit-content;
	/*margin: 17.6px 0 0;*/

	font-size: 12px;
	/* line-height: 17px; */
	color: #000;
	overflow-wrap: anywhere;
}

/* .comment_reactions_wrapper {
    width: 100%;
    height: 16px;

    margin-top: 5px;



    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    position: relative;
}

.comment_reactions_wrapper label {
    width: fit-content;
    height: fit-content;

    margin: 0 5px 0 0;

    font-weight: var(--font-weight-semibold);
    font-size: 13px;
    color: #000;

    cursor: pointer;
}

.comment_reactions_wrapper button {
    font-weight: var(--font-weight-semibold);
    font-size: 13px;
    color: #000;
} */

/* .comment_reactions_wrapper img {
} */

.seperator {
	width: 1px;
	height: 70%;
	margin: 0 10px;
	background-color: var(--gray30);
}

.reply_icon {
	width: 12px !important;
	height: 12px !important;
	margin-right: 5px;
	margin-top: 2px;
}

/* 3 dots popover */
.option_icon {
	width: 15px;
	height: 15px;
	transform: rotate(90deg);
	cursor: pointer;
	display: flex;
}

/* .option_icon img {
	width: unset;
	height: unset;
	object-fit: none;
} */

.sys_popover_items {
	min-width: 123px;
	background-color: #ffffff;
	border-radius: 10px;
	padding: 10px 0;
	box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.16);
	/* margin-top: 5px; */
}

.sys_popover_item {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;

	padding: 4px 8px;
}

.sys_popover_item:hover {
	background-color: #e7e7e8;
	cursor: pointer;
}

.sys_popover_item img {
	width: 14px;
	height: auto;
	margin-right: 10px;
	line-height: 1;
}

.sys_popover_item button {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;

	position: relative;
}

/* end of 3 dots popover */

/* .reactions_container {
    position: absolute;
    left: 0px;
    bottom: 25px;
}

.reactions_container.reply {
    left: -50px;
}

.reactions_container > div {
    justify-content: space-around;
} */

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .reactions_container > div {
        width: 415px !important;
    } */
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .reactions_container {
        left: -50px;
    }

    .reactions_container.reply {
        left: -100px;
    }

    .reactions_container > div {
        width: 340px !important;
    } */
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

@media screen and (max-width: 639.98px) {
	/* .reactions_container {
        left: -50px;
    }

    .reactions_container.reply {
        left: -100px;
    }
    .reactions_container > div {
        width: 340px !important;
    } */
}
