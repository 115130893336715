.items_wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	gap: 52px;
	row-gap: 20px;
	&.list {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}

.title_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.filter_button {
		width: 28px;
		height: 28px;
		background-color: #a6b1bc;
		border-radius: 4px;
		padding: 4px;

		img {
			display: flex;
			align-items: center;
		}
	}

	.controls {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}

.fullWidth {
	width: 100%;
}

.button_container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.active_filters {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper {
		gap: 50px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper {
		gap: 42px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		gap: 42px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		gap: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		gap: 65px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.items_wrapper {
		gap: 20px;
	}
}

@media screen and (max-width: 367px) {
	.items_wrapper {
		grid-template-columns: repeat(auto-fill, 130px);
		gap: 12px;
		justify-content: space-between;
	}
}
