.info_text {
	text-align: center;
	margin-bottom: 20px;
}
.text_uncategorized_conatiner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 22px;
}
.empty_image {
	width: 45px;
	height: 45px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.btn_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.warning_wrapper {
	padding: 20px 20px 0;
}

//select all .service_products_container after the 1st one
.products_container ~ .products_container {
	border-top: 6px solid var(--gray20);
}

.products_container.is_stale {
	opacity: 0.5;
	transition: opacity 0.2s 0.2s linear;
}

.custom_btn {
	width: 320px;
}

.see_all {
	font-weight: var(--font-weight-bold);
	font-size: 14px;
	color: var(--gray80);
}
.forward_icon {
	width: 8px;
	height: 8px;
	margin-left: 10px;
}
.product_tab_container {
	background-color: #fff;
	border-top: 10px solid var(--gray20);
	flex-grow: 1;
	height: 100%;
	// min-height: calc(100dvh - 553px);
	// padding-top: 20px;
}
.search_container {
	// padding-left: 20px;
	// padding-right: 20px;
	padding: 20px 20px 0;
}
.no_result_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.forward_icon {
	width: 8px;
	height: 8px;
}

.items_wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	gap: 20px;
}

.search_input {
	background-color: var(--gray20);
}

.section_title {
	margin: 0 0 20px;
}

.button_wrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.product_category_wrapper {
	margin-top: 20px;

	.section_title {
		margin: 0 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.is_closed {
			margin: 0;
		}
	}
}

.expand_button {
	padding: 0 10px;
}

.button_text {
	display: flex;
	align-items: center;
	gap: 10px;

	.button_icon {
		width: 15px;
	}
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper {
		gap: 12px;
		row-gap: 20px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper {
		gap: 46px;
		row-gap: 20px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		gap: 48px;
		row-gap: 20px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		gap: 30px;
		row-gap: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		gap: 70px;
		row-gap: 20px;
	}
}

@media screen and (max-width: 639.98px) {
	.items_wrapper {
		gap: 30px;
		row-gap: 20px;
	}
}
