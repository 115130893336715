.container {
	background-color: #fff;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	text-align: center;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	p {
		color: var(--gray80);
	}
}
