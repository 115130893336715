.container {
  width: 100%;
  height: 536px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
}

.wrapper {
  width: 667px;
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.line1Text {
  color: #000;
  font-family: "Poppins";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 69.811px;
}

.line1Img {
  margin-left: 1px;
  margin-right: 1px;
}

.line2{
  margin-top: 16px;
  padding-left: 33px;
  padding-right: 33px;
  text-align: center;
}

.line2Text {
  width: 100%;
  color: #000;
  font-family: "Poppins-SemiBold";
  font-size: 50.695px;
}

.line3 {
  margin-top: 22px;
  width: 100%;
  text-align: center;
}

.line3Text {
  width: 100%;
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 29.6px;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
  .container {
    height: 317px;
  }
  .wrapper {
    width: 342px;
    height: 100%;
  }

  .line1Text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 44.481px;
  }

  .line1Img {
    width: 32px;
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .line2{
    margin-top: 18px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .line2Text {
    font-size: 26px;
    line-height: 36.916px;
  }

  .line3 {
    margin-top: 18px;
  }

  .line3Text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.86px;
  }
}

/*mobile*/
@media screen and (min-width: 360px) and (max-width: 743.98px) {
  .container {
    height: 317px;
  }
  .wrapper {
    width: 342px;
    height: 100%;
  }

  .line1Text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 44.481px;
  }

  .line1Img {
    width: 32px;
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .line2{
    margin-top: 18px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .line2Text {
    font-size: 26px;
    line-height: 36.916px;
  }

  .line3 {
    margin-top: 18px;
  }

  .line3Text {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18.86px;
  }
}