.section {
	border-bottom: 6px solid var(--gray20);
	background-color: #fff;
	&:last-child {
		border-bottom: none;
	}
	.sub_section:not(.posts_container) {
		padding: 20px;

		h4 {
			margin: 0 0 20px;
		}
	}

	.posts_container {
		padding: 0px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.section {
		.sub_section {
			padding: 10px;
		}
		.posts_container {
			padding: 0px;
		}
	}
}

@media screen and (max-width: 639.98px) {
	.section {
		.sub_section {
			padding: 10px;
		}
		.posts_container {
			padding: 0px;
		}
	}
}
