.participant {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	background-color: #dbdfe4;
	border-radius: 6px;
	border: solid 1px #a6b1bc;
	padding: 5px 10px;
}

.participant span {

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: var(--gray80);
	white-space: nowrap;
	cursor: default;
}

.participant img {
	/* margin-left: 14px; */
	width: 16px;
	height: auto;
	cursor: pointer;
}
