.image_container {
	padding: 20px;
}

.base_info {
	border-top: 6px solid var(--gray20);
	padding: 20px 20px 0;
}

.base_info_wrapper {
	padding-bottom: 20px;
	// border-bottom: 2px solid var(--gray20);
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 10px;
}

.share_section {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	border-top: 6px solid var(--gray20);
}

.share_item {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	gap: 5px;
}

.share_item span {
	font-size: 14px;
	text-align: center;
	color: var(--gaawk);
}

.icon {
	width: 48px;
	height: 48px;
	border-radius: 6px;
	padding: 10px;
	border: 1px solid var(--light-gaawk);
}

.warning_info {
	margin: 20px 20px 0;
	padding: 10px;
	background-color: rgb(255, 180, 0, 0.2);
	border-radius: 4px;
	display: flex;
	align-items: flex-start;
	gap: 8px;

	img {
		width: 14px;
		margin-top: 4px;
	}
}
