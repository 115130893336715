@use "variables" as *;

.main {
	display: flex;
	flex-direction: column;
	height: calc(100vh - $topNavBarHeight);
}

/* .container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;

	position: relative;
	margin: auto;
	max-width: 2000px;
	width: 100vw;
	height: calc(100vh - 55px);
} */

.container {
	display: flex;
	justify-content: space-between;
	position: relative;
	width: 100vw;
	// max-width: 2000px;
}

.right_side_container {
	display: flex;
	flex-flow: column;
	flex-grow: 1;
	/* height: 100%; */
}

.bottomNavBar {
	display: none;
}

@media screen and (max-width: 747.98px) {
	.bottomNavBar {
		display: flex;
		flex-grow: 1;
	}
}
