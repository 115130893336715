$free-color: #ffca0f;
$premium-color: var(--error200);
$aaa-color: var(--gaawk);
$custom-color: #1cc019;

.container {
	width: 259px;
	border: 1px solid #d9d9d9;
	border-radius: 12px;
	padding: 15px;
	flex-shrink: 0;

	&.highlighted {
		animation: highlightBorder 2s ease-in-out;
	}

	@keyframes highlightBorder {
		0% {
			box-shadow: 0 0 0 0 rgba(108, 197, 209, 0.7); /* #6cc5d1 at 70% opacity */
		}
		70% {
			box-shadow: 0 0 0 10px rgba(108, 197, 209, 0); /* #6cc5d1 at 0% opacity */
		}
		100% {
			box-shadow: 0 0 0 0 rgba(108, 197, 209, 0); /* #6cc5d1 at 0% opacity */
		}
	}

	&.currentPlan {
		border: 2px solid #878787;
	}

	.tag_container {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.tag {
			display: flex;
			justify-content: center;
			width: 91px;
			height: 28px;
			&.basic {
				color: $free-color;
				background-color: #fff4cb;
			}
			&.premium {
				color: $premium-color;
				background-color: #ffd8d8;
			}
			&.aaa {
				color: $aaa-color;
				background-color: var(--light-gaawk);
			}
			&.custom {
				color: $custom-color;
				background-color: #c5fac4;
			}
		}

		.most_popular {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 10px;

			img {
				height: 14px;
				width: auto;
			}
		}
	}
	.price_container {
		margin-top: 15px;
		font-weight: var(--font-weight-bold);
		font-size: 32px;

		span {
			color: #b9b9b9;
			font-size: 12px;
		}
	}

	.description {
		margin-top: 10px;
		margin-right: 10px;
	}

	.offer {
		white-space: pre-line;
		color: #999999;
		font-size: 12px;
		margin-top: 5px;
		min-height: 35px;
	}

	.button {
		margin-top: 15px;
		width: 100%;

		&.currentPlan {
			cursor: default;
		}
	}

	.separator {
		width: 20px;
		border: 1px solid #cecece;
		margin: 20px 0;
	}

	.features_container {
		h5 {
			margin: 15px 0;
			font-size: 12px;
		}

		.features_list {
			list-style-type: none;
			padding: 0;

			li {
				display: flex;
				align-items: flex-start;
				gap: 10px;
				margin: 10px 0;

				svg {
					flex-shrink: 0;
					margin-top: 2px;
				}

				.basic_color {
					fill: $free-color;
				}
				.premium_color {
					fill: $premium-color;
				}
				.aaa_color {
					fill: $aaa-color;
				}
				.custom_color {
					fill: $custom-color;
				}
			}
		}
	}
}
