.connections {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.title {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	margin: 0;
}

.contact_item_wrapper {
	padding: 10px 0;
	border-bottom: 1px solid var(--gray30);
}

.contact_item_wrapper:first-of-type {
	padding-top: 0;
}
.contact_item_wrapper:last-of-type {
	border-bottom: none;
	padding-bottom: 0;
}

.see_all_btn {
	display: flex;
	justify-content: center;
}

.modal_container {
	padding: 20px;
}

.modal_container .company_container {
	margin-bottom: 25px;
}

.modal_container .company_container > p {
	font-weight: var(--font-weight-semibold);
	margin-bottom: 10px;
}

.connections_container {
	width: 100%;
	max-height: 400px;
	background-color: #ffffff;
	overflow-y: auto;
}

.connections_container .mr_10 {
	margin: 0 10px;
}

/* .see_all_container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}
.see_all_btn {
	width: 320px;
	height: auto;
	font-size: 14px;
	background-color: var(--gray80);
	height: 35px;
} */
