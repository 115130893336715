.container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.container > div:last-child {
	flex-grow: 1;
}

.items_wrapper_grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	margin-top: 15px;
}

/* * added this to prevent style from <SocialItem /> component  */
.items_wrapper_grid > div {
	border: 0px;
	padding: 0px;
}

.item {
	display: flex;
	align-content: center;
	gap: 10px;
}

.item_icon {
	width: 24px;
	height: 24px;
}

.empty_icon {
	border: dashed 1px #6cc5d1;
	border-radius: 50%;
}

.item_description {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
}

.item_description label {
	margin-right: 10px;
}

.item_description .visibility_icon {
	display: flex;
	align-items: center;
}

.item_description .visibility_icon span {
	display: flex;
	align-items: center;
}

.item_description .visibility_icon span img {
	width: 18px;
	height: 18px;
	margin-right: 8px;
}

.item_description .visibility_icon span:last-child img {
	margin-right: 0px;
}

.item_data {
	font-size: 14px;
	color: #000;
}

a.item_data {
	color: var(--gaawk);
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper_grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper_grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper_grid {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper_grid {
		grid-template-columns: repeat(1, 1fr);
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper_grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.items_wrapper_grid {
		grid-template-columns: repeat(1, 1fr);
	}
}
