.container {
	/* padding: 0 15px; */
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.container:first-child {
	margin-top: 0px;
}

.see_more {
	margin-top: 8px;
}
.see_more > label {
	margin-bottom: 0px !important;
}

.form_input_wrapper {
	/* margin: 15px 0; */

	width: 100%;
	height: fit-content;

	position: relative;
}

.section label,
.form_input_wrapper label,
.status_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: inline-block;
	margin-bottom: 7px;
}

.checkbox_labels div > label {
	margin-bottom: 0px;
}

.form_input_wrapper input {
	width: 100%;
	height: 35px;
	/* margin: 7px 0 0; */
	border-radius: 5px;
	padding: 7px;

	font-size: 14px;
	color: #000000;
}

.inline_input {
	width: 100%;
	display: flex;
	gap: 30px;
}

.inline_input .item,
.inline_input .item input {
	width: 100%;
}

.switch_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;

	font-size: 14px;
	color: var(--black);
}
