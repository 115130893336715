.container {
	padding: 0 20px;
}

.form_input_wrapper,
.gender_wrapper {
	margin-top: 15px;
}

.form_input_wrapper label,
.datepicker_wrapper label,
.gender_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	display: inline-block;
	margin-bottom: 7px;
}

.form_input_wrapper input {
	width: 100%;
	height: 35px;
	border-radius: 5px;
	padding: 7px;

	font-size: 14px;
	color: #000000;
}

.datepicker_wrapper {
	margin-top: 15px;
}

.form_input_wrapper p.error_message,
.gender_wrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.gender_item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	width: 100%;
	margin-bottom: 10px;
}

.gender_item label {
	font-size: 14px;
	margin: 0;
}

.hide {
	display: none;
}

@media screen and (max-width: 639px) {
	.button_container button {
		width: 100%;
	}
}
