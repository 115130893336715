.spec_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--gray20);
	padding: 10px 0;
}

.spec_item.has_tags {
	display: block;
}
.spec_item.has_gap {
	gap: 10px;
	padding: 7px 0px;
}

.spec_item:last-of-type {
	border-bottom: none;
	padding-bottom: 0;
}
.spec_title_style {
	display: flex;
	align-items: center;
	gap: 5px;

	font-size: 14px;
	color: #000;
}
.spec_title_style img {
	width: 20px;
	height: 20px;
}
.content_span {
	font-size: 14px;
	color: #000;
	font-weight: var(--font-weight-bold);
}
.title {
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

.title img {
	width: 20px;
	height: 20px;
}

.margin {
	& > div {
		margin-top: 10px;
	}
}
