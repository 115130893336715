.form {
	width: 100%;
	height: fit-content;
	padding: 20px;
}

.form.no_padding {
	padding: 0px;
}

.section {
	padding: 20px;
	border-bottom: 6px solid var(--gray20);
}

.section:nth-last-child(2) {
	border-bottom: none;
}
.services_accordion_wrapper {
	margin-top: 20px;
}
.gaawk {
	color: var(--gaawk);
	font-style: normal;
	font-family: "Nexa-Bold";
}

.progress_wrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.section_title_wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.title_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 20px;
}

.section_title_wrapper button img,
.title_wrapper button img {
	width: 18px;
	height: 18px;
}

.section_title {
	font-size: 14px;
	margin: 0;
}

.title {
	font-size: 16px;
	margin: 0;
}

.input_wrapper {
	position: relative;
	height: 100%;
}

.input_wrapper span {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-left: 10px;
	display: flex;
	align-items: center;
	z-index: 1;
}

.input_wrapper.left input {
	padding-left: 20px;
}
.mt_20 {
	margin-top: 20px;
}

.form_input_wrapper,
.custom_upload_button {
	margin-top: 15px;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper label,
.custom_upload_button label {
	width: 100%;
	/* height: 18px; */
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000000;
	/* display: inline-block; */
	/* margin-bottom: 7px; */
}

.form_input_wrapper input {
	height: 35px;
	cursor: text;
}

.form_input_wrapper p.error_message,
p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.form_input_wrapper textarea {
	outline: 1px solid var(--gray50);
	border-radius: 5px;
	min-height: 140px;
	padding: 10px;
	resize: vertical;
}
.form_input_wrapper textarea:focus {
	outline: 2px solid #6cc5d1;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

/* .description_watcher {
	font-weight: var(--font-weight-bold);
	font-size: 12px;
	color: var(--gaawk);
}
.description_counter {
	display: flex;
	justify-content: flex-end;
}

.textarea_wrapper {
	display: flex;
	flex-direction: column;
}

.textarea_wrapper.error textarea {
	outline: solid 2px red;
} */

.option_item_wrapper {
	width: 100%;
	text-align: left;
}

.option_item_wrapper:disabled {
	color: #a8abae;
}

.custom_upload_button .option_item_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	/* height: 70px; */
	width: 100%;
	/* padding: 10px; */
}

.custom_upload_button .option_item_wrapper img {
	width: 90px;
	height: auto;
}

.option_item_left {
	display: flex;
	gap: 10px;
	align-items: center;
}

.option_item_left .radiolabel label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
	display: block;
	margin-bottom: 5px;
	cursor: pointer;
	text-align: left;
}
.line_top {
	border-top: 2px solid var(--gray20);
	margin-bottom: 15px;
}
.m_top {
	margin-top: 20px;
}

.option_item_left .radiolabel p {
	font-style: italic;
}

.radio_button_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.radio_button_wrapper.disabled {
	color: #a8abae;
}
.radio_button_wrapper.disabled label {
	cursor: not-allowed;
}

.radio_button_wrapper button {
	margin-left: auto;
}
.radio_button_wrapper:not(.disabled) button {
	color: var(--gaawk);
}

.radio_button_wrapper.disabled button {
	cursor: not-allowed;
}

.info_text {
	font-size: 14px;
	font-style: italic;
}

.button_container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	gap: 20px;
}

.button_container.padding {
	padding: 0 20px 20px;
	margin-top: 0;
}

.suggestion_btn {
	color: var(--gaawk);
	margin-top: 20px;
}

.suggestion_btn:hover {
	text-decoration: underline;
}

.suggestion_btn span {
	font-weight: var(--font-weight-semibold);
}

.checkbox_wrapper {
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.checkbox_wrapper.left {
	padding-left: 20px;
}

.img_option button {
	position: relative;

	width: 100%;
	height: 35px;
	background-color: var(--light-gaawk);
	border-radius: 5px;

	font-weight: var(--font-weight-bold);
	font-size: 14px;
	/* font-weight: var(--font-weight-bold); */
	font-stretch: normal;
	font-style: normal;

	letter-spacing: 0.7px;
	text-align: center;
	color: var(--gaawk);
	margin-top: 20px;
}
.img_option button:disabled {
	background-color: var(--gray20);
	color: #dbdfe4;
}

.img_option input[type="file"] {
	opacity: 0;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 100%;

	cursor: inherit;
}
.img_option input[type="file"].disabled {
	/* Your disabled styles here, for example: */
	cursor: not-allowed;
	opacity: 0.5;
	opacity: 0;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 100%;
}

.thumb {
	border-radius: 5px;
	width: 360px;

	margin: 10px auto 0;
}

.thumb.feed_image {
	width: 152px;
	height: 152px;
}

.thumb_inner {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 100%;
	height: 100%;
	border-radius: 5px;

	position: relative;
}

.thumb_inner .edit_button {
	position: absolute;
	top: 10px;
	left: 10px;

	font-size: 12px;
	height: 30px;
	padding: 6px 13px;
	letter-spacing: 0.6px;
}

.thumb_inner .close_button {
	position: absolute;
	top: 10px;
	right: 10px;
}

.thumb_inner img {
	display: block;
	object-fit: cover;
}

.react_switch {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.react_switch label {
	display: flex;
	align-items: center;
}

.react_switch span {
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000000;

	display: block;
	margin-left: 20px;
}

.preview_wrapper {
	border: solid 1px var(--gray50);
	padding: 10px;
	border-radius: 6px;
	margin-top: 10px;
}

.preview_wrapper.horizontal {
	display: flex;
	gap: 10px;
}
.options_container {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	gap: 20px;
}
.preview_wrapper.horizontal .image_wrapper {
	flex-shrink: 0;
}
.preview_wrapper.horizontal p {
	margin-top: 0;
}

.preview_wrapper p {
	margin-top: 10px;
	font-size: 12px;
}

.image_wrapper {
	position: relative;
}

.preview_title_wrapper {
	background-color: rgba(114, 122, 130, 0.4);
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 5px 15px;
	color: white;
	text-transform: uppercase;
	font-weight: var(--font-weight-bold);
	height: 27px;
}

.preview_title_wrapper.text_only {
	background-color: #b3b8bf;
	position: static;
}

.preview_title_wrapper h3 {
	font-size: 12px;
}

.radio_item {
	margin-bottom: 20px;
}

.label_title {
	margin: 0 0 5px;
	font-weight: var(--font-weight-semibold);
}

.label_text {
	font-style: italic;
}

.no_margin {
	margin: 0;
}
.no_error {
	display: none;
}

/* .error {
	background-color: red;
} */

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.thumb {
		width: 320px;
	}
}

@media screen and (max-width: 639.98px) {
	.thumb {
		width: 320px;
	}
	.row {
		flex-direction: column;
		gap: 0px !important;
	}
}
@media screen and (max-width: 660.98px) {
	.options_container {
		flex-direction: column;
	}
}
.row {
	display: flex;
	gap: 80px;
}

.tag_checkbox {
	margin-top: 20px;
}
