@use "variables" as *;

.vault_root_container {
	width: 100%;
	height: fit-content;

	background-color: white;
	position: relative;
	padding: 20px;
}

.fileviewer_container {
	height: calc(100dvh - $bottomNavbarHeight);
	padding: 0px 15px;
}

.header {
	/* height: 96px; */
	padding: 20px;

	background-color: var(--gray20);

	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

/* .parent_details {
    flex: 1;
    height: fit-content;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
} */

.icon_container {
	width: 56px;
	height: 56px;
	border-radius: 10px;
	background-color: var(--gaawk);

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 20px;
}

.header_icon {
	width: 30px;
	height: auto;
}

.folder_controls {
	/* margin-top: 30px; */

	/* width: 100%; */
	/* height: 36px; */

	display: flex;
	justify-content: space-between;
	/* align-content: center; */
	align-items: center;
	/* padding: 0px 20px; */
}

.folder_controls.select_all {
	justify-content: space-between;
}

.checkbox_container {
	display: flex;
	align-items: center;
	color: #a6b1bc;

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
}

.checkbox_container label {
	margin-left: 10px;
}

.breadcrumb {
	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.parent_folder {
	color: #000;
}
.parent_folder:hover {
	color: #000;
	text-decoration: underline;
}

.folder_info {
	font-size: 12px;
}

.add_new_items_container {
	flex: 1;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	align-content: center;
}

.create_folder_button {
	border-radius: 5px;
	border: solid 1px #ffffff;
	margin-left: 20px;
}

.upload_new_file_button {
	border-radius: 5px;
	background-color: #ffffff;
	margin-left: 40px;
}

.items_container {
	width: 100%;
	height: fit-content;

	/* margin-top: 26px; */
}

.no_top_margin {
	margin-top: 0px !important;
}

.items_wrapper {
	width: 100%;
	height: fit-content;

	padding: 0 20px 20px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: space-evenly;
}

.items_wrapper_list {
	width: 100%;
	height: fit-content;

	padding: 0 15px 15px;
}

.create_folder_container {
	width: 100%;
	padding: 20px;
}

.create_folder_container label {
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.create_folder_container .text {
	margin-top: 7px;
	width: 100%;
	height: 35px;
}

.create_folder_container .buttons {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
}

// .create_folder_container .buttons .cancel_button,
// .create_folder_container .buttons .create_button {
// 	font-size: 14px;
// 	font-weight: var(--font-weight-bold);
// 	font-stretch: normal;
// 	font-style: normal;
// 	letter-spacing: 0.7px;
// 	text-align: center;
// 	height: fit-content;

// 	padding: 8px 14px;
// }

// .create_folder_container .buttons .cancel_button {
// 	border: solid 1px #a6b1bc;
// 	color: #a6b1bc;
// }

// .create_folder_container .buttons .create_button {
// 	margin-left: 30px;
// 	background-color: var(--gaawk);
// }

/* DETAILS MODAL CSS */
.modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
	padding: 20px 30px;
}

.details_modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
}
.details_modal_header {
	height: 100px;
	background-color: var(--gray20);
	display: flex;
	align-items: center;
	padding: 14px 20px;
}

.details_modal_body {
	padding: 10px 20px 30px;
}

.details_info span {
	display: block;

	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	/* line-height: 1.5; */
	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.details_icon_container {
	width: 72px;
	height: 72px;
	border-radius: 12px;
	border: solid 1px var(--gray70);
	display: flex;
	align-items: center;
	justify-content: center;
}

.details_icon_container .folder_icon {
	width: 26px;
	height: auto;
}

.details_icon_container .file_img {
	object-fit: cover;
	border-radius: 11px;
}

.details_title_container {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
}

.details_title_container span {
	font-size: 16px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 0.81;
	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.details_title_container span:last-child {
	font-weight: var(--font-weight-extralight);
	font-size: 14px;
	font-weight: 300;
	margin-top: 10px;
}
.sharedby_container {
	margin-top: 10px;
}

.separator {
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.16);
}

.sharedby_container h3 {
	margin: 10px 0px;
	display: block;
}

.sharedby {
	margin-top: 10px;

	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.sharedby:last-child {
	border-bottom: none;
}

.parentFolderName {
	font-size: 18px;
	font-weight: var(--font-weight-bold);
	text-align: left;
	color: #fff;
}

.loading_container {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin: 30px 0;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.items_wrapper {
		padding: 0 15px 15px;
	}
	/* .folder_controls {
		padding: 0px 15px;
	} */
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .items_container {
        margin-top: 0px;
    } */
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper {
		padding: 0 14px 14px;
	}
	/* .folder_controls {
		margin-top: 20px;
		padding: 0 14px;
	} */
	/* .items_container {
        margin-top: 20px;
    } */
	.create_folder_button {
		margin-left: 0px;
	}

	.upload_new_file_button {
		margin-left: 20px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		padding: 0 14px 14px;
	}
	/* .items_container {
        margin-top: 20px;
    } */
	/* .folder_controls {
		margin-top: 20px;
		padding: 0 14px;
	} */
	.create_folder_button {
		margin-left: 0px;
	}

	.upload_new_file_button {
		margin-left: 20px;
	}
	.add_new_items_container {
		flex: none;
	}
	.header {
		/* height: 71px; */
		padding: 15px;
	}

	.breadcrumb {
		font-size: 16px;
	}
	.folder_info {
		font-size: 8px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		padding: 0 20px 20px;
	}
	/* .items_container {
        margin-top: 3px;
    } */
	/* .folder_controls {
		margin-top: 20px;
	} */
	.create_folder_button {
		margin-left: 0px;
	}

	.upload_new_file_button {
		margin-left: 20px;
	}
	.add_new_items_container {
		/* flex: none; */
		justify-content: flex-start;
	}
	.header {
		/* height: 117px; */
		padding: 10px;
		/* flex-direction: column; */
		/* flex-wrap: nowrap; */
		/* align-items: flex-start; */
		/* justify-content: center; */
	}
	/* .parent_details, */
	.add_new_items_container {
		flex: none;
	}
	.add_new_items_container {
		margin-top: 19px;
	}

	.breadcrumb {
		font-size: 16px;
	}
	.folder_info {
		font-size: 8px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		padding: 0 10px 10px;
	}
	/* .items_container {
        margin-top: 20px;
    } */
	/* .folder_controls {
		margin-top: 20px;
		padding: 0 10px;
	} */
	.create_folder_button {
		margin-left: 0px;
	}

	.upload_new_file_button {
		margin-left: 20px;
	}
	.add_new_items_container {
		flex: none;
	}
	.header {
		/* height: 71px; */
		padding: 10px;
	}

	.breadcrumb {
		font-size: 16px;
	}
	.folder_info {
		font-size: 8px;
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	/* .folder_controls {
		margin-top: 16px;
	} */
	/* .items_container {
        margin-top: 3px;
    } */
	.create_folder_button {
		margin-left: 0px;
	}

	.upload_new_file_button {
		margin-left: 20px;
	}
	.add_new_items_container {
		/* flex: none; */
		justify-content: flex-start;
	}
	.header {
		/* height: 117px; */
		padding: 10px;
		/* flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: center; */
	}
	/* .parent_details, */
	.add_new_items_container {
		flex: none;
	}
	.add_new_items_container {
		margin-top: 19px;
	}

	.breadcrumb {
		font-size: 16px;
	}
	.folder_info {
		font-size: 8px;
	}
}

@media screen and (max-width: 639px) {
	.create_folder_container {
		.buttons {
			flex-direction: column-reverse;
			gap: 10px;
			button {
				width: 100%;
			}
		}
	}
}
