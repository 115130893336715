.frame_item {
	width: 100%;
	height: 90px;
	padding: 10px;

	border-bottom: 1px #dbdfe4 solid;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;

	position: relative;
}

.frame_item:hover {
	cursor: pointer;
	background-color: var(--gray30);
}

.frame_item:last-child {
	border-bottom: none;
}

.out_frame {
	width: 70px;
	height: 70px;
	object-fit: contain;
	position: absolute;
	left: 10;
}

.frame_img {
	width: 70px;
	height: 70px;
	background: var(--gray50);
	border-radius: 50%;
	object-fit: cover;
}

.frame_details_wrapper {
	flex: 1;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.frame_details_wrapper p {
	width: 100%;
	-webkit-text-stroke: 1px rgba(0, 0, 0, 0);
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
}

.frame_details_wrapper p.title {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	color: #000000;
}

.frame_details_wrapper p.description {
	font-size: 14px;
	color: #a6b1bc;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.frame_details_wrapper p.description {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	.frame_details_wrapper p.description {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;
	}
}

@media screen and (max-width: 319px) {
	.frame_details_wrapper p.description {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		-webkit-box-orient: vertical;
	}
}
