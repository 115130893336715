.container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.section_container:last-of-type {
	border-bottom: none;
}

.section_title {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	/* font-weight: var(--font-weight-semibold); */
	/* text-align: left; */
	color: #000;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.section_title > div {
	display: flex;
	align-items: center;
}

.section_title img {
	width: 17px;
	height: auto;
	margin-right: 10px;
}

.contact_item {
	border-bottom: 1px solid #dbdfe4;
	padding: 10px 0px;
}

.contact_item:first-of-type {
	padding: 0px 0px 10px 0px;
}

.contact_item:last-of-type {
	border-bottom: none;
	padding-bottom: 0;
}

.button_wrapper {
	margin: 20px 0;
	text-align: center;
}

.button {
	width: 320px;
	height: 35px;
	font-size: 14px;
	padding: 0;
}

.subtitle {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
	margin: 20px 0;
}

.more_section {
	padding: 20px 0 0 0;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

/* .more_section.counter {
	border-top: none;
} */

.more_info {
	margin-bottom: 10px;
}

.more_section span,
.subtitle span,
.view_all {
	color: var(--gaawk);
	cursor: pointer;
}

.more_section span:hover,
.subtitle span:hover,
.view_all:hover {
	text-decoration: underline;
}
