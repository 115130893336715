.container {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.privacy_item {
		padding-bottom: 20px;
		border-bottom: 1px solid var(--gray20);

		&:last-child {
			border-bottom: none;
		}
	}
}
