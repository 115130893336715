.tile_wrapper {
	transition: 0.3s;
}

.tile_wrapper:hover {
	transform: scale(1.1);
	color: var(--gaawk);
	font-weight: var(--font-weight-semibold);
	font-size: 18px;
	cursor: pointer;
}

.tile_item {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.tile_item.inline {
	flex-direction: row;
	gap: 20px;
}

.icon {
	width: 90px;
	height: 90px;
	border: solid 1px #fff;
	background-image: linear-gradient(to bottom, #292929, #000);
	border-radius: 15px;
	padding: 20px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.label {
	width: 100%;
	height: 20px;
	margin: 8px 0 0 0;

	font-size: 15px;
	display: flex;
	justify-content: center;
	color: #ffffff;
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (max-width: 973.98px) {
	.tile_item .icon {
		width: 70px;
		height: 70px;
	}
}
