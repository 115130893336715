@use "variables" as *;

.loading_container {
	background-color: rgba(255, 255, 255, 0.8);

	/* width: 100vw; */
	height: calc(100dvh - $topNavBarHeight - 35px); /* 35px being the footer */
	z-index: 1;

	display: flex;
	align-items: center;
	justify-content: center;
}

.loading_container.fullPage {
	height: 100dvh;
	width: 100vw;
}

@media screen and (max-width: 747.98px) {
	.loading_container {
		height: calc(
			100dvh - $topNavBarHeight - 35px - $bottomNavbarHeight
		); /* 35px being the footer */
	}
}
