.container {
	padding: 20px;
}

.info_text {
	font-size: 14px;
	font-style: italic;
	color: #000;
	margin-bottom: 20px;
}

.warning_container {
	display: flex;
	align-items: flex-start;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;
}

.talent_container {
	width: 100%;
	max-height: 400px;
	background-color: #ffffff;
	overflow-y: auto;
}

.talent_container .mr_10 {
	margin: 0 10px;
}

.controls {
	display: flex;
	align-items: center;
	gap: 20px;
}

.controls button img {
	width: 24px;
	height: 24px;
}

.button_container {
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

/* .btn {
	height: 35px;
	padding: 0 15px;
	font-size: 14px;
}

.btn.cancel {
	color: #a6b1bc;
	border: 1px solid #a6b1bc;
}

.btn.decline {
	background-color: var(--error200);
} */

.input_wrapper {
	margin-top: 20px;
}

.input_wrapper input[type="text"] {
	width: 100%;
	height: 35px;
	margin: 7px 0 0;
	border-radius: 5px;
	/* border: solid 1px var(--gray50); */
	padding: 7px;

	font-size: 14px;

	text-align: left;
	color: #000000;
}

.input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.input_wrapper p {
	margin-top: 10px;

	font-size: 10px;
	font-style: italic;
	color: #000;
}

@media screen and (max-width: 639px) {
	.button_container {
		flex-direction: column-reverse;
		gap: 10px;
	}
	.button_container button {
		width: 100%;
	}
}
