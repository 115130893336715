.section {
	border-bottom: 6px solid var(--gray20);
	&:last-child {
		border-bottom: none;
	}
}

.search_input_wrapper {
	padding: 20px;
}

.tab_menu_wrapper {
	padding: 0 20px 7px;
}

.no_padding {
	padding: 0;
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.search_input_wrapper {
		padding: 10px;
	}

	.tab_menu_wrapper {
		padding: 0 10px 7px;
	}
}

@media screen and (max-width: 639.98px) {
	.search_input_wrapper {
		padding: 10px;
	}

	.tab_menu_wrapper {
		padding: 0 10px 7px;
	}
}
