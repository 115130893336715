.container {
	// height: fit-content;
	// height: 100%;
	margin: 0 auto;
	width: 100%;

	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

@media screen and (min-width: 2000px) {
	.fixed_container {
		width: 758px;
	}
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
	.fixed_container {
		width: 758px;
	}
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
	.fixed_container {
		width: 620px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
	.fixed_container {
		width: 758px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
	.fixed_container {
		width: 576px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.fixed_container {
		width: 100%;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
	.fixed_container {
		width: 100%;
	}
}

@media screen and (max-width: 639px) {
	.fixed_container {
		width: 100%;
	}
}

// @media screen and (min-width: 2000px) {
// 	.container {
// 		width: 758px;
// 	}
// }

// @media screen and (min-width: 1608px) and (max-width: 1999px) {
// 	.container {
// 		width: 758px;
// 	}
// }

// @media screen and (min-width: 1400px) and (max-width: 1607px) {
// 	.container {
// 		width: 620px;
// 	}
// }

// @media screen and (min-width: 1166px) and (max-width: 1399px) {
// 	.container {
// 		width: 483px;
// 	}
// }

// @media screen and (min-width: 974px) and (max-width: 1165px) {
// 	.container {
// 		width: 576px;
// 	}
// }

// @media screen and (min-width: 748px) and (max-width: 973px) {
// 	.container {
// 		width: 100%;
// 	}
// }

// @media screen and (min-width: 640px) and (max-width: 747px) {
// 	.container {
// 		width: 100%;
// 	}
// }

// @media screen and (max-width: 639px) {
// 	.container {
// 		width: 100%;
// 	}
// }
