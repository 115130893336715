.container {
	padding: 40px;

	.info {
		color: #000;
		margin: 20px 0 12px 0;
	}

	.otp_error {
		font-size: 12px;
		margin-top: 13px;
		color: red;
	}

	.confirm_btn {
		width: 100%;
		margin-top: 20px;
	}

	.other_btn {
		width: 100%;
		margin-top: 15px;
	}

	.gray_btn {
		width: 100%;
		margin-top: 15px;
	}
}
