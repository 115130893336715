@use "variables" as *;
/* CLEAR CHAT MODAL */

.modal_container {
	width: 100%;
	height: fit-content;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.info_text {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	padding-right: 30px;
}

.info_text,
.radio_container p {

	font-size: 14px;
	margin-bottom: 20px;
}

.muteIcon {
	width: 32px;
	height: auto;
}

.radio_container p {
	margin-bottom: 0px;
}

.tickbox_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.radio_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin-bottom: 20px;
	padding: 0 50px;
}

.tickbox_wrapper {
	display: flex;
	gap: 10px;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;
}

.radio_wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 5px;
	cursor: pointer;
}

.btn_container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.fileviewer_container {
	height: calc(100dvh - $topNavBarHeight);
}

.fullscreen_icon {
	height: 20px;
	width: auto;
}
