.option_btn {
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		transform: rotate(90deg);
	}

	&.vertical {
		svg {
			transform: none;
		}
	}
}
