.pin_icon {
	height: 20px;
	width: auto;
}

.more_button {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
}

.more_button img {
	width: 100%;
	height: 100%;
	object-fit: none;
}
