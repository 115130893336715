.container {
	/*margin-top: 5px;*/
	margin-bottom: 10px;

	/* width: 100%; */
	height: fit-content;
	padding: 0 20px;
}

.container.selectActive {
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
}

.container.selectActive:hover {
	background-color: #e3e6e9;
}

.container.sender {
	justify-content: space-between;
}

.container.receiver {
	justify-content: flex-start;
}

.container.isInfoModal {
	padding: 0px;
}
