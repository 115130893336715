.navBar {
	display: flex;
	flex-direction: column;
	// width: 280px;
	height: 100%;
	// height: 100vh;
	background-color: white;
	padding: 20px;
	transition: width 0.3s ease;
	position: relative;
	// border-right: 1px solid #eee;

	&.collapsed {
		width: 50px;
		padding: 20px 0;
		align-items: center;

		// .collapseButton {
		// 	right: -12px;
		// }
	}
}

.header {
	margin-bottom: 24px;
	padding: 0px 12px;
	// &.collapsed {
	// 	padding-left: 0;
	// }
}

.navigation {
	flex: 1;
	display: flex;
	flex-direction: column;
	// gap: 8px;
}

.footer {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	// gap: 8px;
	padding-top: 16px;
	// border-top: 1px solid #eee;
	flex-grow: 1;
	justify-content: flex-end;
}

// .collapseButton {
// 	position: absolute;
// 	right: -12px;
// 	top: 24px;
// 	width: 24px;
// 	height: 24px;
// 	background-color: white;
// 	border: 1px solid #eee;
// 	border-radius: 50%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	cursor: pointer;
// 	transition: all 0.3s ease;

// 	&:hover {
// 		background-color: #f5f5f5;
// 	}

// 	.rotated {
// 		transform: rotate(180deg);
// 	}
// }
