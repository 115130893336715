.info {
	margin-bottom: 20px;
}

.form_input_wrapper {
	position: relative;

	label {
		font-weight: var(--font-weight-semibold);
		font-size: 14px;
		font-weight: var(--font-weight-semibold);
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: left;
		color: #000000;
		margin-bottom: 7px;
		display: block;
	}
	p {
		margin-left: auto;
		text-align: right;
		font-style: italic;
		color: red;
	}
}
.phone_input_wrapper {
	display: flex;
	justify-content: flex-start;
	align-content: center;
	gap: 20px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 25px;

	button {
		height: 35px;
		padding: 0 15px;
		font-size: 14px;
	}
}

.dialCode_input {
	margin: 0;
	flex: 0 0 130px;
}

.hide {
	display: none;
}

.error_message {
	font-size: 12px;
	color: red;
	font-style: italic;
	position: absolute;
	bottom: -20px;

	&.mobile {
		right: 0px;
	}

	&.countryCode {
		left: 0px;
	}
}
