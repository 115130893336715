// .items_wrapper {
// 	display: flex;
// 	flex-direction: column;
// 	gap: 20px;
// 	background-color: #fff;
// }

.title_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.filter_button {
		width: 28px;
		height: 28px;
		background-color: #a6b1bc;
		border-radius: 4px;
		padding: 4px;

		img {
			display: flex;
			align-items: center;
		}
	}
}

.button_container {
	display: flex;
	justify-content: center;
}

.active_filters {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.services_container {
	display: flex;
	margin-left: -20px; /* gutter size offset */
	width: auto;
}

.services_container_grid_columns {
	padding-left: 20px; /* gutter size */
	background-clip: padding-box;
}

.service_item {
	display: block;
	color: unset;
}
.service_item:focus,
.service_item:hover {
	text-decoration: unset;
	color: unset;
}
