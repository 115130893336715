.container h3 {
	margin: 0 0 20px 0;
}

.container .pending_btn {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: var(--gaawk);
}

.container .pending_btn:hover {
	text-decoration: underline;
}

.container .loadbtn {
	padding: 0 15px;
	height: 35px;
	font-size: 14px;
}

.button_container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
}

/*  TALENT CATEGORIES  */
.categories_container {
	display: grid;
	grid-template-columns: repeat(3, auto);
	gap: 20px;
}

.category_item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.category_item:hover,
.category_item:focus {
	text-decoration: none;
}

.category_item img {
	width: 96px;
	height: 96px;
}

.category_item h4 {
	font-size: 12px;
	font-weight: var(--font-weight-semibold);
	text-align: center;
	color: var(--gaawk);
	margin: 5px 0 0 0;
}

/* TALENTS CONTAINER */
.talent_container {
	margin-top: 20px;
}

.talent_container.info_text {
	font-style: italic;
}

@media screen and (min-width: 2000px) {
	.categories_container {
		grid-template-columns: repeat(6, auto);
	}
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.categories_container {
		grid-template-columns: repeat(5, auto);
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.categories_container {
		grid-template-columns: repeat(4, auto);
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

@media screen and (max-width: 639.98px) {
}
