.header {
	font-weight: var(--font-weight-bold);
	font-size: 18px;

	display: flex;
	align-items: center;
	gap: 20px;
}

.filter_tag {
	padding: 5px;
	font-size: 12px;
	background-color: #fff;
	border: 1px solid #dbdfe4;
}

.service_accordion_header_name {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #000;
	margin: 20px 20px 0;
	/* margin-bottom: 10px; */
	/* margin-top: 20px; */
}

.accordion_wrapper {
	padding: 20px;
}
