.container {
	width: 100%;
	height: 100%;
	background-color: var(--gray20);

	/*height: 1200px;*/

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;

	/* padding: 5px 0 0 0; */
}

.title_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 20px;
}

.title_wrapper h4 {
	padding: 0px !important;
	margin: 0px !important;
}

.title_wrapper button {
	width: 28px;
	height: 28px;
	background-color: var(--gray70);
	border-radius: 4px;
	padding: 4px;
}

.title_wrapper button img {
	display: flex;
	align-items: center;
}

.button_container {
	display: flex;
	justify-content: center;
	padding: 20px 0;
}

.active_filters {
	padding: 0 20px;
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.active_filters {
		padding: 0 10px;
	}

	.title_wrapper {
		padding: 10px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.active_filters {
		padding: 0 10px;
	}

	.title_wrapper {
		padding: 10px;
	}
}
