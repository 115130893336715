.items_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.section_container {
    margin-top: 6px;
    min-height: fit-content;
    padding: 15px;

    &.no_padding {
        padding: 0;
    }
}
