.board_item {
	margin: 0;
	padding: 0;
}

.cover_wrapper {
	aspect-ratio: 2/1;
	img {
		aspect-ratio: 2/1;
		object-fit: cover;
	}
}

.boards_info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.section {
	padding: 20px;
	background-color: #fff;
}

.pending_wrapper {
	margin-top: 20px;
	width: 100%;

	button {
		color: var(--dark-gaawk);
		display: flex;
		align-items: center;
		gap: 5px;

		svg {
			width: 15px;
			fill: var(--dark-gaawk);
		}
	}
}

.description {
	margin-top: 20px;
}

.feed_section {
	padding-top: 12px;
	background-color: var(--gray20);
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.search_wrapper {
	background-color: #fff;
	h3 {
		margin: 20px 20px 0;
	}
}

.button_container {
	display: flex;
	gap: 20px;

	.board_controls {
		width: 160px;

		// &.secondary {
		// 	border: solid 2px var(--gray70);
		// 	color: var(--gray70);
		// 	padding: 0px 7px;
		// }
	}
}

.modal_container {
	padding: 20px;
}

.modal_button_wrapper {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	gap: 20px;
}

// .feed_container {
// 	height: fit-content;
// 	margin: 0 auto;
// }

@media screen and (max-width: 1399px) {
	.board_item {
		align-self: flex-start;
	}

	.boards_info {
		flex-direction: column;
		gap: 20px;
	}

	.button_container {
		// display: flex;
		// flex-direction: column;
		// gap: 20px;
		width: 100%;

		.board_controls {
			width: 100%;
		}
	}
}

// @media screen and (min-width: 2000px) {
// 	.feed_container {
// 		width: 758px;
// 	}
// }

// @media screen and (min-width: 1608px) and (max-width: 1999px) {
// 	.feed_container {
// 		width: 758px;
// 	}
// }

// @media screen and (min-width: 1400px) and (max-width: 1607px) {
// 	.feed_container {
// 		width: 620px;
// 	}
// }

// @media screen and (min-width: 1166px) and (max-width: 1399px) {
// 	.feed_container {
// 		width: 483px;
// 	}
// }

// @media screen and (min-width: 974px) and (max-width: 1165px) {
// 	.feed_container {
// 		width: 576px;
// 	}
// }

// @media screen and (min-width: 748px) and (max-width: 973px) {
// 	.feed_container {
// 		width: 100%;
// 	}
// }

// @media screen and (min-width: 640px) and (max-width: 747px) {
// 	.feed_container {
// 		width: 100%;
// 	}
// }

// @media screen and (max-width: 639px) {
// 	.feed_container {
// 		width: 100%;
// 	}
// }
