.container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #fff;
}

.container > div {
    display: flex;
    align-items: center;
}
