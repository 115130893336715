.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.btn_wrapper {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: flex-end;
}

.btn {
	display: flex;
	align-items: center;
	gap: 10px;
}

.add_icon {
	width: 15px;
	height: 15px;
}

.btn.delete {
	color: #d20707;
	border: 1px solid #d20707;
}

.body {
	margin: 30px 0;
	display: flex;
	justify-content: center;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.entry_caption_container {
	display: flex;
	justify-content: center;
	gap: 40px;
}

.entry_caption_item {
	display: flex;
	align-items: center;
	gap: 5px;
}
.entry_caption_item p {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000;
}

.color_pin {
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	flex-shrink: 0;
}

.color_pin.booked {
	background-color: #ffad5c;
}
.color_pin.penciled {
	background-color: #83d1ad;
}
.color_pin.blacked_out {
	background-color: #a6b1bc;
}

.switch_container,
.info_message {
	display: flex;
	align-items: center;
	gap: 20px;

	font-size: 14px;
	color: #000;
}

.info_message {
	font-style: italic;
	margin-top: 10px;
}

/* ======== THE ENTRY MODAL ======== */

.entry_details_container {
	padding: 20px;
}

.entry_details {
	display: flex;
	gap: 20px;
	margin-bottom: 10px;
}

.entry_details img {
	width: 50px;
	height: auto;
}

.entry_status {
	display: flex;
	flex-direction: column;
}

.entry_type {
	font-weight: var(--font-weight-semibold);
	display: flex;
	align-items: center;
	gap: 5px;
}

.color_pin {
	display: block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

.entry_dates {
	margin-left: 15px;
}

.entry_details_container .label {
	font-weight: var(--font-weight-semibold);
}

.entry_details_container .notes {
	margin-bottom: 20px;
}
