.wrapper {
	width: 100%;
	height: 100%;
	/* height: calc(100dvh - 55px); */
	background-color: #fff;

	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	align-items: flex-start;
	flex-grow: 1;
}

.container {
	width: 100%;
	padding: 20px;
}

.other_companies {
	background-color: var(--gray20);
	border-radius: 20px 20px 0 0;
	padding: 30px 20px;
	display: flex;
	gap: 40px;

	width: 100%;
	flex-grow: 1;
	/* width: 1000px; */
	/* margin: auto; */
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	/* .other_companies {
		width: 100%;
	} */
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .other_companies {
		width: 100%;
	} */
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.other_companies {
		/* width: 100%; */
		flex-direction: column;
		gap: 20px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.other_companies {
		/* width: 100%; */
		flex-direction: column;
		gap: 20px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.other_companies {
		/* width: 100%; */
		flex-direction: column;
		gap: 20px;
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	.other_companies {
		/* width: 100%; */
		flex-direction: column;
		gap: 20px;
	}
}
