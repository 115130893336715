.container {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fill, 96px);
	gap: 37px;
	row-gap: 20px;
}

.container.list {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.container.list.m0 {
	margin-top: 5px;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
	.container {
		gap: 26px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
	.container {
		gap: 20px 30px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
	.container {
		gap: 20px 50px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.container {
		gap: 20px 16px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
	.container {
		gap: 20px 25px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639px) {
	.container {
		gap: 20px 16px;
	}
}

@media screen and (min-width: 355px) and (max-width: 367px) {
	.container {
		justify-content: space-between;
		gap: 20px 10px;
	}
}

@media screen and (max-width: 354px) {
	.container {
		justify-content: center;
		gap: 20px 20px;
	}
}
