.container {
	padding: 20px;
	background-color: #fff;

	.header {
		display: flex;
		justify-content: flex-end;
	}

	.projects_container {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}
