/* PROVIDED CLASSES BY THE LIBRARY (needs to be put first) */
.mentions {
	/* margin: 20px 0; */
	height: 100%;
}

.mentions > div:nth-child(2) {
	border-radius: 5px;
	z-index: 10 !important;
}

/* CHAT CASE */

.container.isChat .mentions__control,
.container.isComment .mentions__control {
	width: 100%;
	height: 100%;
}

.container.isChat .mentions__control .mentions__highlighter {
	padding: 5px;
	height: 100%;
	max-height: 120px;
}

.container.isChat .mentions__control .mentions__input {
	padding: 5px;
	height: 100%;
	background-color: #fff !important;
	max-height: 120px;
	overflow: auto !important;
}

/* COMMENT CASE */

.container.isComment .mentions__control .mentions__highlighter {
	/* padding: 5px; */
	height: 100%;
	max-height: 120px;

	padding: 7px 39px 6px 5px;
}

.container.isComment .mentions__control .mentions__input {
	/* padding: 5px; */
	height: 100%;
	background-color: var(--gray20) !important;
	max-height: 120px;
	overflow: auto !important;
	border: none;
	padding: 8px 40px 7px 6px;
}

/* ======= */

.mentions--singleLine .mentions__control {
	display: inline-block;
	/* width: 130px; */
	width: 91%;
	/* height: 100%; */
}
.mentions--singleLine .mentions__highlighter {
	padding: 7px 35px 7px 5px;
	/* padding: 7px 5px; */
	/* border: 2px inset transparent; */
	border-radius: 5px;
	/* height: 35px; */
}
.mentions--singleLine .mentions__input {
	/* padding: 1px;
    border: 2px inset; */
	border: 1px solid var(--gray50);
	/* padding: 9px; */
	/* outline: 0; */
	border-radius: 5px;
	/* height: 35px; */
	/* padding: 5px; */
	padding: 5px 35px 5px 5px;
}

.mentions--singleLine .mentions__input:focus {
	outline: 2px solid var(--gaawk);
	outline-offset: -1px;
}

.mentions--multiLine .mentions__control {

	font-size: 14px;
	word-break: break-word;
}
.mentions--multiLine .mentions__highlighter {
	/* border: 2px solid transparent; */
	/* padding: 9px; */
	padding: 8px 35px 7px 6px;
	overflow: hidden;
	min-height: 35px;
}

.mentions--multiLine .mentions__input {
	border: 1px solid var(--gray50);
	/* padding: 9px; */
	padding: 8px 35px 7px 6px;

	/* outline: 0; */
	border-radius: 5px;

	overflow-wrap: break-word;
}
.mentions--multiLine .mentions__input:focus {
	/* border: 2px solid var(--gaawk); */
	outline: 2px solid var(--gaawk);
	outline-offset: -1px;
}

.mentions__suggestions__list {
	background-color: white;
	/* border: 1px solid rgba(0, 0, 0, 0.15); */
	/* font-size: 10pt; */
	border-radius: 5px;

	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	max-height: 300px;
	overflow-y: auto;
}

.mentions__suggestions__item {
	padding: 5px 15px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
	background-color: #f4f4f4;
	/* background-color: #cee4e5; */
}

/* disabled due to overscroll desync issues on safari and firefox */
.mentions__mention {
	/* position: relative; */
	/* z-index: 1; */
	/* color: var(--gaawk); */

	/* text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, */
	/* -1px -1px 1px white; */
	/* text-decoration: underline; */
	/* pointer-events: none; */
}

/* ============ OWN CSS ============ */

.container {
	position: relative;
}

/* .container.singleLine {
    height: 35px;
} */

/* .container {
    min-height: 35px;
} */

.emoji_button_wrapper {
	position: absolute;
	right: 10px;
	bottom: 10px;
	width: 25px;
	height: 25px;
}

.emoji_button_wrapper.isComment {
	width: 20px;
	height: 20px;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
}

/* .emoji_button_wrapper.singleLine {
    right: 5px;
} */

.emoji_container {
	position: absolute;
	right: 0;
	z-index: 9000;
}

/* .emoji_container.singleLine {
    top: 35px;
} */

.suggestion_item {
	display: flex;
	align-items: center;

}

.suggestion_item .name {
	color: var(--gaawk);
	font-weight: 14px;
}

/* .suggestions_container {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
} */

.suggestions_container > li:first-of-type {
	border-radius: 5px 5px 0 0;
}

.suggestions_container > li:last-of-type {
	border-radius: 0 0 5px 5px;
	border-bottom: none;
}
