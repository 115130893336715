.msg_container {
	flex: 1;
	height: 100%;
	max-width: 230px;
	margin: 0 0 0 3px;

	font-size: 12px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #a6b1bc;

	cursor: inherit;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
}

.smaller_icon {
	height: 14px !important;
	width: auto !important;
}
