@use "variables" as *;

.container {
	background-color: #fff;
}
.item_container {
	width: 69px;
	height: 69px;
	border-radius: 8px;
}
.services_tag_wrapper {
	margin-top: 8px;
}
.left_wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.description_product {
	font-size: 14px;
	color: #000;
}
.content_span {
	font-size: 14px;
	color: #000;
	font-weight: var(--font-weight-bold);
	overflow: hidden;
}
.content_span_size {
	font-size: 14px;
	color: #000;
	font-weight: var(--font-weight-bold);
	overflow: hidden;
}
.options_conatiner {
	display: flex;
	gap: 20px;
}
.item_container img:first-of-type:not(.file_icon) {
	object-fit: cover;
	border-radius: 8px;
}

.option_white_style {
	rotate: 90deg;
}
.info_conatiner {
	width: 50%;
	display: flex;
	flex-direction: column;
}
.spec_txt {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	color: #000;
	margin-bottom: 12px;
	margin-top: 10px;
}
.scroll_container {
	max-width: 300px;
	gap: 10px;
	height: auto;
}
.scroll_wrapper {
	padding: 0px;
}
.scroll_wrapper_media {
	padding: 0;
}

.spec_title_style {
	display: flex;
	align-items: center;
	gap: 5px;

	font-size: 14px;
	color: #000;
}
.services_tag_conatiner {
	background-color: #e8e8e8;
	border: none;
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: #494949;
	cursor: pointer;
}
.fileviewer_container {
	height: calc(100dvh - $topNavBarHeight);
}
.locations_wrapper {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	color: var(--gray80);
}
.product_name {
	color: #000;
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	margin-bottom: 5px;
}
.product_name_up {
	color: #000;
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	display: none;
	padding: 10px;
}
.truncated {
	color: #000;
	font-size: 14px;
}
.product_wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 14px;
	padding: 20px;
}
.main_image_wrapper {
	width: 526px;
	height: 526px;
	object-fit: cover;
}

.button_container {
	display: flex;
	justify-content: center;
	gap: 20px;
	// margin-top: 10px;
}

.truncated_mobile {
	display: none !important;
}
//  border-bottom: 1px solid #e5e5e5;
.tag {
	background-color: transparent;
	// padding: 5px;
	padding-top: 2px;
	padding-bottom: 2px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: var(--gray80);
}

.specs_wrapper {
	margin-bottom: 20px;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}
.specs_container {
	display: none;
}
.specs_conatiner_row {
	display: block;
}

.tag_container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
	gap: 10px;
	margin-top: 10px;
}

.gallery_container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 52px;
	margin-bottom: 20px;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.gallery_container {
		gap: 50px;
	}
	.main_image_wrapper {
		width: 463px;
		height: 463px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.main_image_wrapper {
		width: 333px;
		height: 316px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.main_image_wrapper {
		width: 293px;
		height: 278px;
	}

	.gallery_container {
		gap: 43px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.main_image_wrapper {
		width: 335px;
		height: 320px;
	}
	.product_wrapper {
		flex-direction: column;
		align-items: center;
		padding-top: 0px;
	}
	.content_span_size {
		width: 50%;
	}
	.product_name {
		display: none;
	}
	.product_name_up {
		display: block;
	}
	.info_conatiner {
		width: 100%;
	}

	.truncated {
		display: none !important;
	}

	.truncated_mobile {
		display: block !important;
		margin-bottom: 20px;
	}

	.gallery_container {
		gap: 20px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.main_image_wrapper {
		width: 293px;
		height: 278px;
	}

	.truncated {
		display: none !important;
	}

	.truncated_mobile {
		display: block !important;
		margin-bottom: 20px;
	}

	.gallery_container {
		gap: 65px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.main_image_wrapper {
		width: 335px;
		height: 320px;
	}
	.product_wrapper {
		flex-direction: column;
		align-items: center;
		padding-top: 0px;
	}
	.content_span_size {
		width: 50%;
	}
	.product_name_up {
		display: block;
	}
	.product_name {
		display: none;
	}
	.info_conatiner {
		width: 100%;
	}
	.truncated {
		display: none !important;
	}

	.truncated_mobile {
		display: block !important;
		margin-bottom: 20px;
	}

	.gallery_container {
		gap: 20px;
	}
}

@media screen and (max-width: 367px) {
	.gallery_container {
		gap: 12px;
		justify-content: space-between;
	}
	.product_name_up {
		display: block;
	}
	.content_span_size {
		width: 50%;
	}
	.product_wrapper {
		flex-direction: column;
		align-items: center;
		padding-top: 0px;
	}
	.product_name {
		display: none;
	}
	.info_conatiner {
		width: 100%;
	}
	.main_image_wrapper {
		width: 100%;
		height: 100%;
	}
}

@media screen and (max-width: 1166px) {
	.specs_container {
		display: block;
		padding-left: 20px;
		padding-right: 20px;
	}
	.specs_conatiner_row {
		display: none;
	}
}
