.container {
	width: 100vw;
	height: 100dvh;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #ffffff;
	position: relative;
}

.titleContainer {
	width: 548px;

	margin: 100px auto 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.title {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
}

.titleText1 {
	color: #000;

	font-family: "Poppins";
	font-size: 68px;
	line-height: 68px;
}

.titleText2 {
	color: #000;

	font-family: "Poppins-Thin";
	font-size: 68px;
	font-style: italic;
	line-height: 68px;

	margin: 0 8px 0 2px;
}

.subTitle {
	margin-top: 36px;
	color: #000;
	font-family: "Poppins";
	font-size: 14px;
}

.contentWrapper {
	margin: 90px auto 100px;
	width: 548px;

	& > div:nth-child(-n + 4):not(:first-child) {
		margin-top: 30px;
	}
}

.fileUploadedView {
	width: 100%;
	height: auto;
	background: rgba(108, 197, 209, 0.26);
	border-radius: 12px;
	border: 1px solid #6cc5d1;
	padding: 15px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	margin-top: 30px;
}

.icon1 {
	width: 21px;
	height: 21px;
}

.text {
	flex: 1;
	text-align: left;
	color: #000;

	font-family: "Poppins";
	font-size: 12px;
	// line-height: 160%;
	letter-spacing: 0.5px;
	word-break: break-all;
}

.icon2 {
	width: 21px;
	height: 21px;
	cursor: pointer;
}

.button {
	margin-top: 40px;

	width: 100%;
	height: 61px;
	border-radius: 8px;
	background: #6cc5d1;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	color: #fff;
	text-align: center;
	font-family: "Poppins";
	font-size: 18px;
	line-height: 160%; /* 28.8px */
	letter-spacing: 0.5px;
}

.button:hover {
	background: #54b2be;
}

.successContainer {
	width: 380px;
	height: 424px;

	margin: 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	gap: 18px;
}

.successContainer img {
	width: 37px;
	height: 37px;
}

.successContainer span {
	color: #000;

	text-align: center;
	font-family: "Poppins-Regular";
	font-size: 14px;
	line-height: 160%;
	letter-spacing: 0.5px;
}

/*others*/
@media screen and (max-width: 1279.98px) {
	.container {
	}

	.titleContainer {
		width: 100%;

		margin: 60px auto 0;
	}

	.title {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.titleText1 {
		font-size: 45px;
		line-height: 45px;
	}

	.titleText2 {
		font-size: 45px;
		line-height: 45px;
		margin: 8px 0 0 0;
	}

	.subTitle {
		width: 300px;
		padding: 0;
		font-family: "Poppins";
		font-size: 12px;
	}

	.contentWrapper {
		margin: 60px auto;
		width: 300px;
	}

	.successContainer {
		width: 275px;
		height: 400px;
		gap: 34px;
	}
}
