.items_wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: #fff;

	& > div {
		padding-bottom: 10px;
		border-bottom: 1px solid var(--gray30);
	}
	& > div:last-of-type {
		padding-bottom: 0px;
		border-bottom: none;
	}
}

.title_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.filter_button {
		width: 28px;
		height: 28px;
		background-color: #a6b1bc;
		border-radius: 4px;
		padding: 4px;

		img {
			display: flex;
			align-items: center;
		}
	}
}

.button_container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.active_filters {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	margin-bottom: 10px;
}
