.container {
	max-width: 336px;
}

.active_profile_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 20px 20px 0;

	.disable_overflow {
		overflow: visible;
	}
}

.button_container {
	margin: 20px;

	.manage_companies_btn {
		width: 100%;
	}
}

.info_text {
	/* margin-bottom: 20px; */
	padding: 16px;
}

.info_text p {
	margin-top: 12px;
	font-size: 14px;
}

/* .company_item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
} */

.profiles_container {
	/* margin: 20px 0px; */
	padding: 0 10px;
	max-height: 30vh;
	overflow-y: auto;
}

.profiles_container button:last-of-type {
	border-bottom: none;
}

.loading_container {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navigation {
	flex: 1;
	display: flex;
	flex-direction: column;
	// gap: 8px;
	padding: 16px;

	.navItemLabel {
		font-size: 12px;
		font-weight: var(--font-weight-semibold);
	}
}

@media screen and (min-width: 768px) {
	.navigation {
		display: none;
	}

	.profiles_container {
		max-height: 42vh;
	}
}

@media screen and (max-width: 767px) {
	.button_container {
		display: none;
	}
}
