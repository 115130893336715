.container {
	display: flex;
	gap: 10px;
	align-items: center;
}

.no_access {
	height: 38px;
	min-width: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	border: 1px solid var(--gray30);
	cursor: default;
}

.btn {
	width: 150px;
	min-width: 90px;

	.btn_label {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.icon {
		fill: #000;
		transition: color 0.3s ease;
	}

	&:hover {
		.icon {
			fill: #fff;
		}
	}
}

.more_btn {
	width: 38px;
	height: 38px;
	flex-shrink: 0;
	min-width: unset;

	border: 1px solid black;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	.btn_label {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.icon {
		transform: rotate(90deg);
		fill: #000;
		transition: color 0.3s ease;
	}

	&:hover {
		.icon {
			fill: #fff;
		}
	}
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.btn {
		font-size: 12px;
		.btn_label {
			gap: 6px;
		}
	}
	.container {
		width: 100%;
		justify-content: space-between;
	}

	.container.block_btn {
		width: auto;
	}

	.container.centered {
		justify-content: center;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (min-width: 360px) and (max-width: 639px) {
	.container {
		flex-direction: column;
		width: 100%;
	}

	.container.centered {
		justify-content: center;
	}
	.more_btn {
		width: 150px;
		min-width: 90px;
	}
}

@media screen and (max-width: 359px) {
	.container {
		flex-direction: column;
		width: 100%;
	}

	.more_btn {
		width: 150px;
		min-width: 90px;
	}
}
