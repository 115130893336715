.container {
	display: flex;
	align-items: center;
	gap: 30px;
}

.headerIcons {
	width: auto;
	height: 20px;
	cursor: pointer;
}

.icon {
	width: 25px;
	height: 25px;
}

@media screen and (max-width: 973.98px) {
	.container {
		gap: 20px;
	}
}
