.tab_container {
	flex-grow: 1;
	height: 100%;
}

.button_container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 20px;
}
.add_button {
	width: 320px;
}

.btn_container {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}
