.form {
	background-color: #fff;
	padding: 20px;
}
/* .form > div {
	border-bottom: 6px solid var(--gray20);
} */

/* .form .search_container,
.form > div:last-of-type,
.form > div:nth-last-of-type(2) {
	border-bottom: none;
} */

.loading_spinner {
	height: 100%;
}

.search_container {
	/* background-color: #dbdfe4; */
	padding: 20px 10px 0px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	/* padding: 15px; */
	gap: 10px;
	margin-top: 15px;
}
