.job_details {
	display: flex;
	flex-direction: column;
	gap: 5px;
	.job_detail_item {
		display: flex;
		align-items: flex-start;

		p:first-of-type {
			width: 100px;
			color: var(--gray80);
			flex-shrink: 0;
		}
		p:last-of-type {
			font-weight: var(--font-weight-semibold);

			span:not(:last-child):after {
				content: "-";
				display: inline-block;
				margin: 0 5px;
			}
		}
	}
}
