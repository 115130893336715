.add_group_container {
	width: 100%;
	height: fit-content;
	padding: 20px 20px 40px;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;
	/* flex: auto; */

	position: relative;
	margin-top: 15px;
}

.form_input_wrapper label {
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
	display: inline-block;
}

.form_input_wrapper {
	margin-top: 15px;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper input {
	height: 35px;
	cursor: text;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: #000000;
	display: inline-block;
	margin-bottom: 7px;
}

.form_input_wrapper .error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

.form_input_wrapper .img_preview_container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.form_input_wrapper .img_preview_container img {
	object-fit: cover;
}
.form_input_wrapper .img_preview_container .img_placeholder {
	width: 60px;
	height: auto;
}

.custom_upload_button {
	position: relative;

	width: 100%;
	height: 35px;
	background-color: var(--light-gaawk);
	border-radius: 5px;

	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;

	letter-spacing: 0.7px;
	text-align: center;
	color: var(--gaawk);
	margin-top: 10px;
}

.custom_upload_button input[type="file"] {
	opacity: 0;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 100%;

	cursor: inherit;
}

.img_preview_container {
	width: 100%;
	height: 160px;
	margin: 7px 0 15px 0px;
	object-fit: contain;
	background-color: #dbdfe4;
}

.form_controls {
	margin-top: 30px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.form_controls button {
	width: 96px;
	height: 35px;
	border: solid 1px var(--gray50);
	border-radius: 5px;

	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: 0.7px;
	text-align: center;
	cursor: pointer;
	color: var(--gray70);
}

.form_controls .cancel {
	margin-right: 30px;
}

.form_controls .next {
	background-color: var(--gaawk);
	color: white;
	border: none;
}
