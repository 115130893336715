.profile_image_wrapper {
	display: flex;
	align-items: center;
	height: 100%;
	overflow: hidden;
	flex-shrink: 0;

	/* width: -webkit-fill-available;
	width: -moz-available; */
}

.fullWidth {
	width: 100%;
}

.profile_pic {
	width: 40px;
	height: 40px;
	margin-right: 10px;
	border-radius: 2px;

	flex-shrink: 0;

	position: relative;
}

.profile_pic.applyBgColor {
	background-color: var(--gaawk);
}

.initial_letter {
	font-weight: var(--font-weight-semibold);
	font-size: 18px;
	text-transform: uppercase;
	/* display: inline-block; */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	color: #fff;
}

.profile_pic .thumbnail_icon {
	position: absolute;
	top: 55%;
	right: -15%;
	height: 30px;
	width: auto;
	border-radius: 0 !important;
}

.profile_pic .thumbnail_icon.user {
	border-radius: 50% !important;
}

.profile_pic .thumbnail_icon.company {
	border-radius: 2px !important;
}

/* .profile_pic.isClickable {
    cursor: pointer;
} */

.isClickable {
	cursor: pointer;
}

.profile_pic.company {
	border-radius: 20%;
	/* border-radius: 8px; */
}

.profile_pic.user {
	border-radius: 50%;
}

.profile_pic.company img {
	border-radius: 20%;
	/* border-radius: 8px; */
	object-fit: cover;
}

.profile_pic.user img {
	border-radius: 50%;
	object-fit: cover;
}

.profile_pic.border .profile_pic.user img {
	border: 1px #fff solid;
}

.header_profile_info_wrapper {
	flex: 1;
	/* height: 100%; */

	/*margin-right: 10px;*/

	display: flex;
	flex-direction: column;
	/* flex-wrap: wrap; */
	justify-content: flex-start;
	align-content: center;
	align-items: flex-start;
	overflow: hidden;
}

.header_text_wrapper {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	flex-direction: column;
	/*word-break: break-all;*/
}

.post_owner_name {
	/* height: fit-content; */
	/* min-height: 20px; */
	line-height: normal;

	font-size: 14px;
	width: 100%;
	text-align: left;
}

.post_owner_name .bold {
	font-weight: var(--font-weight-semibold);

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	display: block;
	width: 100%;
}

.board_header {
	font-weight: var(--font-weight-semibold);
	font-size: 10px;
}

.custom_font_color {
	color: #fff !important;
}

/* .isClickable:hover {
    text-decoration: underline;
    cursor: pointer;
} */

.emotion_icon {
	width: 20px;
	height: 20px;

	position: relative;

	margin-right: 25px;
}

.emotion_icon img {
	position: absolute;
	top: 2px;
	right: -20px;

	width: inherit !important;
	height: inherit !important;

	object-fit: contain;
}

.post_header_info {
	width: 100%;
	height: 20px;
	/*background-color: var(--gaawk);;*/

	display: flex;
	align-items: center;
	gap: 5px;
	/* flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start; */
}

.visibility_wrapper {
	display: flex;
	align-items: center;
}

.post_header_info span {
	width: 16px;
	height: 16px;
}

.post_header_info_date {
	/*height: 100%;*/
	font-weight: var(--font-weight-extralight);
	font-size: 13px;
}

.subdata {
	font-weight: var(--font-weight-semibold);
	font-size: 10px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.prime_icon {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	z-index: 99;
	pointer-events: auto;
	visibility: visible;

	/* width: 47px;
	height: 20px;

	border-radius: 5px;
	background-color: #cfc6a3;
	position: absolute;
	right: -5px;
	bottom: 0;
	color: #fff;
	text-align: center;
	font-weight: var(--font-weight-bold); */
}

.gaawk_board {
	width: 17px;
	height: 17px;
	background-color: var(--gaawk);
	border-radius: 5px;

	position: absolute;
	left: 8%;
	bottom: 8%;
	display: flex;
	align-items: center;
	justify-content: center;

	color: #fff;
	font-family: Nexa-Bold;
}

.gaawk_board .letter {
	position: absolute;
	line-height: normal;
	top: 0px;
}
