.modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
	padding: 20px 30px;
}

.delete_icon_modal {
	height: 36px;
	margin-bottom: 10px;
}

.delete_msg_modal {
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: center;
	color: var(--gray80);

	margin-bottom: 30px;
}

.delete_msg_modal span {
	color: var(--error200);
}

.delete_msg_modal strong {
	font-weight: var(--font-weight-bold);
	font-weight: var(--font-weight-bold);
}

.delete_modal_buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
}

/* .delete_modal_buttons .cancel_button,
.delete_modal_buttons .delete_button {
	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: 0.7px;
	text-align: center;
	height: fit-content;

	padding: 8px 14px;
}

.delete_modal_buttons .cancel_button {
	border: solid 1px #a6b1bc;
	color: #a6b1bc;
}

.delete_modal_buttons .delete_button {
	margin-left: 30px;
	background-color: var(--error200);
} */

@media screen and (max-width: 639px) {
	.delete_modal_buttons {
		flex-direction: column-reverse;
		gap: 10px;
	}
	.delete_modal_buttons button {
		width: 100%;
	}
}
