.work_availability_container {
	margin-top: 10px;
	width: 100%;
	height: 56px;
	padding: 20px;
	background-color: var(--gaawk);

	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;

	font-size: 16px;
	font-weight: var(--font-weight-semibold);
	color: #fff;
}

.work_availability_container img {
	width: 24px;
	height: auto;
}

.work_availability_container.unavailable {
	background-color: var(--gray80);
}

.separator {
	position: absolute;
	width: 100%;
	height: 6px;
	background-color: var(--gray20);
	left: 0;
	right: 0;
	margin: 20px 0px;
}

.btn_wrapper {
	display: flex;
	justify-content: center;
}

.btn_wrapper button {
	width: 320px;
}

.work_status_container,
.calendar_container {
	flex-grow: 1;
}

.work_status_container > div:last-child {
	padding-bottom: 0;
}

.work_status_container:not(:has(.btn_wrapper)) > div:nth-child(1) {
	margin-top: 0;
}

.disabled {
	opacity: 0.6;
}
