.header_container {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #ff00e6; */
}

.leftSide {
    display: flex;
    align-items: center;
    gap: 16px;
}
