/* .menu {
	background-color: #000;
	padding: 5px;

	display: flex;
	justify-content: center;
}

.menu_button {
	color: #fff;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
} */

.loader_container {
	padding: 20px;
	& > div {
		border-bottom: 1px solid var(--gray30);
		margin-bottom: 20px;
		padding-bottom: 10px;
		&:last-child {
			border-bottom: none;
		}
	}
}

.loading_item_container {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--gray30);
}

.recommended_jobs {
	padding: 20px;
	background-color: #fff;
}

.title {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #000;
	margin: 0 0 20px;
}

.jobs_wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.jobs_wrapper > div {
	padding-bottom: 10px;
	border-bottom: 1px solid var(--gray30);
}
.jobs_wrapper > div:last-of-type {
	padding-bottom: 0px;
	border-bottom: none;
}

/* .btn_container {
	background-color: #fff;
	padding: 20px;
	text-align: center;
} */
