.group_search_wrapper {
	padding: 20px;
}

.add_group_container {
	width: 100%;
	height: fit-content;
	padding: 0 20px 20px;
}

.group_participants_wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	gap: 10px;
}

.form_controls {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	gap: 20px;
}

.contact_list_wrapper {
	height: 463px;
	overflow: auto;
}
