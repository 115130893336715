.container {
	width: 100%;
	padding: 20px;
	background-color: #ffffff;

	display: flex;
	flex-direction: column;
	gap: 20px;
}

.button_wrapper {
	display: flex;
	justify-content: center;
}

/* .wrapper {
	width: 100%;
}

.container {
	width: 100%;
	padding: 20px;
	background-color: #ffffff;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: center;
}

.container > button {
	border-radius: 5px;
	border: solid 1px #a6b1bc;

	margin: 20px auto 40px;
	padding: 8px 14px;


	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: 0.7px;
	text-align: center;
	color: var(--gray80);
}

.header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;
	align-items: center;

	width: 100%;
}

.header label {

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: var(--black);
} */

/* .data_section {
    width: 100%;
    height: fit-content;
    margin-top: 15px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 40px;
} */

.key_profile {
	flex-basis: 33.333333%;
}

.key_special_profiles {
	width: 100%;
	height: fit-content;
	margin-top: 20px;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	row-gap: 40px;
}

.key_special_profiles.align_left {
	justify-content: left;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.key_profile {
		flex-basis: 50%;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.key_profile {
		flex-basis: 50%;
	}
}

@media screen and (max-width: 1165.98px) {
	.key_profile {
		flex-basis: 100%;
	}
}
