.container {
	width: 100%;
	padding: 20px;
	background-color: #ffffff;
}

.button_wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.cover_container {
	width: 100%;
	height: 333px;
	border-radius: 0 0 10px 10px;
}

.cover_container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0 0 10px 10px;
}

.controllers {
	margin-top: 20px;
	width: 100%;
	height: fit-content;

	display: flex;
	justify-content: center;
	align-content: flex-start;
	align-items: center;
}

input[type="button"] {
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
}

.img_options {
	padding-bottom: 10px;
	/* border-bottom: 1px solid var(--gray30); */
}

.feed_checkbox_container {
	border-top: 1px solid var(--gray30);
}

.img_options button {
	display: flex;
	align-items: center;
	margin: 20px 0;
	gap: 10px;
	text-align: left;
}

.icon {
	width: 20px;
	height: 20px;
	fill: #000;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.cover_container {
		height: 283px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.cover_container {
		height: 217px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.cover_container {
		height: 161px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.cover_container {
		height: 89px;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.cover_container {
		height: 173px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.cover_container {
		height: 89px;
	}

	.button_wrapper button {
		width: 100%;
	}
}
