.container {
	display: flex;
	flex-direction: column;
	gap: 7px;
	justify-content: space-between;
	margin-bottom: 20px;
	position: relative;

	.label_wrapper {
		display: flex;
		flex-direction: column;

		label {
			font-weight: var(--font-weight-semibold);
			font-size: 14px;
			margin-bottom: 0 !important;
		}

		.info {
			font-style: italic;
			font-size: 12px;
		}
	}

	.error_message {
		font-size: 12px;
		color: var(--error200);
		font-style: italic;
		position: absolute;
		bottom: -20px;
		right: 0px;
		text-align: right;
	}

	.textarea_wrapper {
		width: 100%;
		height: 250px;

		// margin-top: 10px;
		padding: 10px 5px 30px 10px;

		border: solid 1px var(--gray50);
		border-radius: 5px;

		position: relative;

		&.focused {
			outline: solid 2px var(--gaawk);
			outline-offset: -1px;
		}

		&.error {
			outline: solid 2px var(--error200);
			outline-offset: -1px;
		}
	}

	textarea {
		resize: none;

		width: 100%;
		height: 100%;

		padding: 0 10px 0 0;

		border: none;

		font-size: 14px;

		color: #000000;

		scrollbar-color: #a6b1bc var(--gray20);
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 5px;
			cursor: pointer;
			border-radius: 5px !important;
		}

		&::-webkit-scrollbar-track {
			background-color: var(--gray20);
		}

		&::-webkit-scrollbar-thumb {
			background-color: #a6b1bc;
			cursor: pointer !important;
		}
	}

	.word_count_wrapper {
		width: fit-content;
		height: fit-content;

		position: absolute;
		right: 10px;
		bottom: 6px;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;

		font-weight: var(--font-weight-semibold);
		font-size: 11px;
		color: #1a8897;
	}
}
