.container {
	display: flex;
	flex-direction: column;
	height: 100%;

	.loading_container {
		height: 100%;
	}

	.header {
		margin: 30px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 15px;

		h1 {
			margin: 0;
			font-size: 23px;
			font-weight: var(--font-weight-bold);
		}

		.description {
			color: var(--gray80);
			font-size: 12px;
			font-weight: var(--font-weight-semibold);
			max-width: 500px;
		}

		.trial_offer {
			background-color: var(--light-gaawk);
			border-radius: 12px;
			padding: 10px;
			display: flex;
			align-items: center;
			gap: 15px;

			text-align: left;

			svg {
				flex-shrink: 0;
			}
		}
	}

	.main {
		// margin: 20px 0;
		flex-grow: 1;
		button {
			margin-top: 20px;
			color: var(--gray80);
			font-size: 12px;
			font-weight: var(--font-weight-bold);
		}

		.plan_section {
			padding: 12px 0;
			border-bottom: 1px solid var(--gray30);

			&:last-of-type {
				border-bottom: none;
			}

			h6 {
				margin: 0 0 5px;
				font-size: 12px;
				color: var(--gray80);
			}

			p {
				font-size: 12px;
				font-weight: var(--font-weight-bold);
			}
		}
	}

	.button_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		.upgrade_button {
			max-width: 312px;
			width: 100%;
		}

		.compare_button {
			max-width: 312px;
			width: 100%;
			height: 35px;
			color: var(--gray80);
			font-size: 12px;
			font-weight: var(--font-weight-bold);
		}
	}
}
