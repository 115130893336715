.option_btn {
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: auto;
		// height: 20px;
	}
}

// span.img-sm {
// 	display: flex;
// }
