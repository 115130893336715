.system_message {
	width: 100%;

	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
}

.system_message_line {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;

	width: 90%;
	height: 1px;
	background-color: var(--gray80);
}

.system_message_text {
	z-index: 2;
	width: fit-content;
	height: fit-content;
	padding: 4px 10px;
	border-radius: 12px;
	background-color: var(--gray80);

	display: flex;
	justify-content: center;
	align-items: center;
}

.system_message_text span {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	text-align: center;
	color: var(--white);
}
