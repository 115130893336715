.offer_container {
	border: 1px solid #dbdfe4;
	border-radius: 12px;
	padding: 15px;
	color: var(--gray80);
	width: 100%;

	.button_container {
		display: flex;
		justify-content: flex-end;
		margin-top: 20px;
		font-weight: var(--font-weight-bold);
		gap: 30px;

		.offer_btn {
			color: var(--error200);
		}
	}

	.info {
		margin-top: 6px;
		font-weight: var(--font-weight-semibold);
		font-size: 12px;

		margin-top: 0;
		font-size: 14px;
	}
}
