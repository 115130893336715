.is_first_day {
	border-radius: 50% 0 0 50% !important;
}

.is_in_range {
	border-radius: 0;
}

.is_last_day {
	border-radius: 0 50% 50% 0 !important;
}

.is_first_day.is_in_range.is_last_day {
	border-radius: 50% !important;
}

.rdp_days {
	font-size: 16px;
	font-weight: var(--font-weight-semibold);
	color: #000;
	width: 100%;
	height: 100%;
}

.head_cell {
	font-size: 14px;
	color: rgba(60, 60, 67, 0.3);
}

.Penciled {
	background-color: #83d1ad;
}

.Booked {
	background-color: #ffad5c;
}

.Blacked_Out {
	background-color: #a6b1bc;
}

.selected {
	/* color: #ffffff !important; */
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
}

.root {
	--rdp-cell-size: 40px;
	/* --rdp-accent-color: #0000ff; */
	/* --rdp-background-color: #e7edff; */
	/* --rdp-accent-color-dark: #3003e1; */
	/* --rdp-background-color-dark: #180270; */
	/* --rdp-outline: 2px solid var(--rdp-accent-color);  */
	/* --rdp-outline-selected: 3px solid var(--rdp-accent-color);  */
	width: 350px;
	border-radius: 12px;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);

	margin: 1em;
}

.month,
.table {
	width: 100%;
	max-width: none;
}

.table {
	border-collapse: separate;
	border-spacing: 0 5px;
	padding: 0 10px;
}

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 40px;
	background-color: var(--gray20);
	padding: 0 20px;

	box-shadow: 0px 3px 3px -3px rgb(0 0 0 / 20%);

	border-radius: 12px 12px 0 0;
}

.nav h3 {
	letter-spacing: 0.26px;
}

.weekend {
	color: #dbdfe4;
}

.today {
	border: 1px solid black;
	border-radius: 100%;
	margin: auto;
	vertical-align: middle;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.root {
		width: unset;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (min-width: 320px) and (max-width: 639px) {
	.root {
		width: unset;
	}
}

@media screen and (max-width: 319px) {
	.root {
		width: unset;
	}
}

/* .rdp-day_selected.penciled {
    background-color: #83d1ad;
    font-weight: var(--font-weight-bold);
}

.rdp-day_selected.booked {
    background-color: #ffad5c;
    font-weight: var(--font-weight-bold);
}

.rdp-day_selected.blacked_out {
    background-color: #a6b1bc;
    font-weight: var(--font-weight-bold);
} */

/* .rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: #0000ff;
    --rdp-background-color: #e7edff;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);

    margin: 1em;
}

.rdp-vhidden {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
}

.rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: inherit;
    background: none;
    font: inherit;

    -moz-appearance: none;
    -webkit-appearance: none;
}

.rdp-button_reset:focus-visible {
    outline: none;
}

.rdp-button {
    border: 2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: 0.25;
}

.rdp-button:not([disabled]) {
    cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
    color: inherit;
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: var(--rdp-background-color);
}

.rdp-months {
    display: flex;
}

.rdp-month {
    margin: 0 1em;
}

.rdp-month:first-child {
    margin-left: 0;
}

.rdp-month:last-child {
    margin-right: 0;
}

.rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
}

.rdp-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
}

.rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
}

.rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
}

.rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: 140%;
    font-weight: var(--font-weight-bold);
}

.rdp-nav {
    white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
}

.rdp-dropdown_year,
.rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
    border-radius: 6px;
}

.rdp-dropdown_icon {
    margin: 0 0 0 5px;
}

.rdp-head {
    border: 0;
}

.rdp-head_row,
.rdp-row {
    height: 100%;
}

.rdp-head_cell {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 700;
    text-align: center;
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
}

.rdp-tbody {
    border: 0;
}

.rdp-tfoot {
    margin: 0.5em;
}

.rdp-cell {
    width: var(--rdp-cell-size);
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-align: center;
}

.rdp-weeknumber {
    font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 0;
    border: 2px solid transparent;
    border-radius: 100%;
}

.rdp-day.first-day-from-range {
    border-radius: 100% 0 0 100% !important;
}

.rdp-day.already-in-range {
    border-radius: 0;
}

.rdp-day.last-day-from-range {
    border-radius: 0 100% 100% 0;
}

.rdp-day_today:not(.rdp-day_outside) {
    font-weight: var(--font-weight-bold);
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    color: white;
    opacity: 1;
    background-color: var(--rdp-accent-color);
}

.rdp-day_selected:focus-visible {
    outline: var(--rdp-outline);
    outline-offset: 2px;
    z-index: 1;
}

.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
    border-radius: 100%;
}

.rdp-day_range_middle {
    border-radius: 0;
} */
