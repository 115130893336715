.add_group_container {
	width: 100%;
	height: fit-content;
	padding: 20px 20px 40px;
}

.form_input_wrapper {
	width: 100%;
	height: fit-content;
	/* flex: auto; */

	position: relative;
	margin-top: 15px;
}

.form_input_wrapper label {
	width: 100%;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
	display: inline-block;
}

.form_input_wrapper {
	margin-top: 15px;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper input {
	height: 35px;
	cursor: text;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: #000000;
	display: inline-block;
	margin-bottom: 7px;
}

.form_input_wrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.form_input_wrapper textarea {
	outline: 1px solid var(--gray50);
	border-radius: 5px;
	min-height: 140px;
	padding: 10px;
	resize: vertical;
}

.form_input_wrapper textarea:focus {
	outline: 2px solid #6cc5d1;
}

.form_input_wrapper .img_preview_container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.form_input_wrapper .img_preview_container img {
	object-fit: cover;
}
.form_input_wrapper .img_preview_container img.img_placeholder {
	width: 60px;
	height: auto;
}

.custom_upload_button {
	position: relative;
}

.custom_upload_button input[type="file"] {
	opacity: 0;
	z-index: 1;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 35px;
	margin: 0;

	cursor: pointer;
}

.custom_upload_button button {
	position: absolute;
	background-color: #a6b1bc;
	border-radius: 5px;
	top: 0px;
	width: 100%;
	height: 35px;

	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: 0.7px;
	text-align: center;
	color: white;
}

.img_preview_container {
	width: 100%;
	height: 160px;
	margin: 7px 0 15px 0px;
	object-fit: contain;
	background-color: #dbdfe4;
}

.form_controls {
	margin-top: 85px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.form_controls button {
	width: 96px;
	height: 35px;
	border: solid 1px var(--gray50);
	border-radius: 5px;

	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: 0.7px;
	text-align: center;
	cursor: pointer;
	color: var(--gray70);
}

.form_controls button.cancel {
	margin-right: 30px;
}

.form_controls button.next {
	background-color: var(--gaawk);
	color: white;
	border: none;
}

// !== contact item ==

.contact_item {
	width: 100%;
	padding: 0 10px;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.contact_item:hover {
	background-color: var(--gray30);
	cursor: pointer;
}

.contact_item:last-child {
	border-bottom: none;
}

.contact_item_wrapper {
	width: 100%;
	/* height: 45px; */

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	padding: 5px 0;
	border-bottom: 1px var(--gray30) solid;
}

.contact_item:last-of-type .contact_item_wrapper {
	border-bottom: none;
}
