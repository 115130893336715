.container {
	padding: 20px;

	h4 {
		margin: 0 0 20px;
	}

	.items_wrapper {
		margin-top: 20px;
		overflow: auto;
		max-height: 500px;
	}

	.contact_item {
		padding: 5px 10px 10px 0;
	}

	.add_btn {
		width: 100%;
	}

	.new_board_container {
		width: 100%;
		padding: 20px;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 20px;
		color: var(--gray80);
	}
}
