.container {
	z-index: 9999;
	border-radius: 10px;
	box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 16%);
	min-width: 123px;
	background-color: #ffffff;
}

.container:focus {
	outline: none;
}

#arrow,
#arrow_shadow {
	position: absolute;
	width: 15px;
	height: 15px;
	transform: rotate(45deg);
	z-index: -1;
}

#arrow {
	background: #fff;
}

#arrow_shadow {
	box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 16%);
}

/* ==== */

.popover_container {
	background-color: #ffffff;
	padding: 10px 0;
	border-radius: 10px;
}

.popover_item {
	display: flex;
	/* flex-wrap: wrap; */
	align-items: center;
	justify-content: flex-start;
	gap: 10px;

	width: 100%;

	padding: 4px 8px;
}

.popover_item:hover {
	background-color: #e7e7e8;
	cursor: pointer;
}

.popover_item:focus {
	background-color: #e7e7e8;
	outline: none;
	/* cursor: pointer; */
}

.popover_item img {
	width: 14px;
	height: auto;
	/* margin-right: 10px; */
	line-height: 1;
}

.popover_item span {
	font-weight: var(--font-weight-semibold);
	font-size: 12px;
	display: flex;
	align-items: center;
	position: relative;
	white-space: nowrap;
}
