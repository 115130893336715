.no_data {
	font-size: 14px;

	color: var(--gray80);

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
}

.message_btn {
	width: 320px;
}
