.member_wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.member_wrapper:focus,
.member_wrapper:hover {
	text-decoration: none;
}

.member_wrapper:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.link_item {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	color: #000000;
	overflow: hidden;
}

.link_item .item {
	flex-shrink: 1;
}

/* .member_wrapper .direct_msg_icon {
	width: 24px;
	height: auto;
	cursor: pointer;
} */

.member_wrapper button img {
	width: auto;
	height: 16px;
	margin-left: 20px;
}

.userInfo_container {

	display: flex;
	flex-direction: column;
	width: 100%;
}

.tagline {
	font-size: 12px;
	color: #000;

	/* max-width: 1050px; */

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

/* .tagline.chatLeftSide {
	max-width: 210px;
} */

.connection_container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}

.connection_wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 3px;
}

.connection_wrapper img {
	width: 10px;
	height: auto;
}

.connection_wrapper p {
	font-size: 10px;
	color: var(--gray70);
}

.disabled {
	opacity: 0.5;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.disabled .link_item {
	cursor: not-allowed;
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.member_wrapper .direct_msg_icon {
		width: 17px;
	}
	.member_wrapper button img {
		margin-left: 10px;
	}
	/* .tagline {
		max-width: 850px;
	} */
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.member_wrapper .direct_msg_icon {
		width: 17px;
	}
	.member_wrapper button img {
		margin-left: 10px;
	}
	/* .tagline {
		max-width: 620px;
	} */
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.member_wrapper .direct_msg_icon {
		width: 17px;
	}
	.member_wrapper button img {
		margin-left: 10px;
	}
	/* .tagline {
		max-width: 430px;
	} */
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.member_wrapper .direct_msg_icon {
		width: 17px;
	}
	.member_wrapper button img {
		margin-left: 10px;
	}
	/* .tagline {
		max-width: 210px;
	} */
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.member_wrapper .direct_msg_icon {
		width: 17px;
	}
	.member_wrapper button img {
		margin-left: 10px;
	}
	/* .tagline {
		max-width: 460px;
	} */
}

@media screen and (min-width: 360px) and (max-width: 639px) {
	.member_wrapper .direct_msg_icon {
		width: 17px;
	}
	.member_wrapper button img {
		margin-left: 10px;
	}
	/* .tagline {
		max-width: 210px;
	} */
}

@media screen and (max-width: 359px) {
	/* .tagline {
		max-width: 175px;
	} */
}
