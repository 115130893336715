.container {
	.active_profile_container {
		padding: 40px 20px 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;

		.user_info {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.disable_overflow {
			overflow: visible;
		}
	}

	.nav_group {
		padding: 10px 20px;
		h3 {
			margin-bottom: 20px;
		}
		.navigation {
			flex: 1;
			display: flex;
			flex-direction: column;

			.label {
				font-weight: var(--font-weight-semibold);
			}
		}
	}
}
