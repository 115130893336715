.container {
	width: 100vw;
	height: 100dvh;
	background-color: #fff;
	position: relative;

	& > span > img {
		object-fit: cover;
		filter: brightness(85%);
	}

	.form_wrapper {
		background-color: #fff;
		width: 400px;
		height: 687px;
		position: absolute;
		top: 50%;
		left: 70%;
		transform: translate(-50%, -50%);

		border-radius: 8px;
		box-shadow: 0 33px 46px 0 rgba(0, 0, 0, 0.25);

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.form_footer {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;

			img {
				width: 36px;
			}

			span {
				font-size: 9px;
				color: #a6b1bc;
				line-height: normal;
			}

			text-align: center;
			padding-bottom: 20px;
			color: #000;

			& > button {
				font-weight: var(--font-weight-bold);
				color: var(--gaawk);
			}
		}
	}
}

@media screen and (min-width: 2000px) {
	.container img {
		mask-image: linear-gradient(to right, transparent 0%, gray 20%, gray 80%, transparent 100%);
	}
}

@media screen and (max-width: 991px) {
	.container {
		.form_wrapper {
			left: 50%;
		}
	}
}

@media screen and (max-width: 575px) {
	.container {
		.hide {
			display: none !important;
		}

		.form_wrapper {
			background-color: #fff;
			position: unset;
			width: 100vw;
			height: 100dvh;
			transform: none;
			border-radius: 0%;
			box-shadow: none;
		}
	}
}
