.container {
	background-color: #000;
	height: 100%;
	padding: 50px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.tile {
	width: 58px !important;
	height: 58px !important;
	padding: 16px !important;
}

.label {
	margin: 0 !important;
	justify-content: flex-start !important;
	font-weight: var(--font-weight-semibold) !important;
}

.tile_item {
	padding: 8px;
	border-radius: 20px;
	&:hover {
		background-color: rgba(255, 255, 255, 0.2);
	}
}
