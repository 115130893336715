.container {
	padding: 20px;
}

.profile_wrapper {
	margin: 20px 0;
}

.button_container {
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: flex-end;
}

@media screen and (max-width: 639px) {
	.button_container {
		flex-direction: column-reverse;
		gap: 10px;

		button {
			width: 100%;
		}
	}
}
