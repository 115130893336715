.header_container {
	display: flex;
	align-items: center;

	font-size: 18px;
	font-weight: var(--font-weight-bold);
	color: #fff;
	gap: 20px;
}

.container {
	/* height: 100%; */

	background-color: #ffffff;
	flex-grow: 1;

	/* width: 100%; */

	/* margin-bottom: 5px; */

	/* height: calc(100dvh - 55px); */
}

.company_item {
	background-color: var(--gray20);
	padding: 20px;
}

.company_item:last-child {
	padding-bottom: 0px;
}

.agency_message {
	display: block;
	margin-top: 20px;
	text-align: center;
	font-weight: var(--font-weight-semibold);
	font-size: 18px;
}

.owner_details_wrapper {
	padding: 20px;
}

.owner_details_wrapper h4 {
	margin: 0 0 10px;

	font-size: 18px;
	font-weight: var(--font-weight-bold);
	color: var(--black);
}

.owner_details_wrapper.bordered {
	border-bottom: 6px solid var(--gray20);
}

/* .subsection {
    padding: 20px;
    border-bottom: 6px solid var(--gray20);
}

.subsection h4 {
    margin: 0px;


    font-size: 18px;
    font-weight: var(--font-weight-bold);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
} */

.owner_info {
	width: 100%;
	max-width: 952px;

	margin-bottom: 20px;

	font-size: 14px;
	color: var(--black);
}

.sub_title {
	object-fit: contain;

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: var(--black);
	display: block;
	margin-bottom: 10px;
}

/* .btn_wrapper {
    display: flex;
    justify-content: center;
} */

.w310 {
	width: 310px;
}

.transfer_buttons_container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.member_item {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.member_item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.close_icon {
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	padding: 20px;
}

/* REACT-SELECT INPUT */

.react_select_wrapper {
	margin-top: 20px;

	width: 100%;
	height: fit-content;
}

/* MODAL */

.modal_container {
	width: 100%;
	height: fit-content;
	padding: 20px;
}

.warning_wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 20px;
}

.image_wrapper {
	margin-right: 10px;
}

.warning_text {
	font-weight: var(--font-weight-semibold);
	font-size: 13px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000;
}
.modal_controls {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
}

/* .cancel_btn {
	margin-right: 20px;
	border: solid 1px var(--gray50);
	color: var(--gray70);
	height: 35px;
	font-size: 14px;
	padding: 0px 15px;
}

.confirm_btn {
	background-color: #d20707;
	height: 35px;
	font-size: 14px;
	padding: 0px 15px;
} */

.transfer_section {
	padding: 20px;
}

/* CONFIRM PROMPT CSS */

.confirm_prompt_container {
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

/* .cancel_transfer_btn {
	border: solid 1px var(--gray50);
	color: var(--gray70);
	font-size: 14px;
	width: 310px;
	height: 35px;
	margin-top: 20px;
} */

.fullwidth_info {
	margin: 40px 0;

	font-size: 14px;
	color: var(--black);
}

@media screen and (max-width: 747.98px) {
	.container {
		padding-bottom: 56px;
	}
}

@media screen and (max-width: 639px) {
	.modal_controls {
		flex-direction: column-reverse;
		gap: 10px;

		button {
			width: 100%;
		}
	}
}
