.container {
	padding: 20px;
}

.container .info_text {

	font-size: 14px;
	font-style: italic;
	color: #000;
}

.container .info_text.margin_top {
	margin-top: 20px;
}

.employees_container {
	width: 100%;
	max-height: 400px;
	background-color: #ffffff;
	overflow-y: auto;
}

.employees_container .mr_10 {
	margin: 0 10px;
}
