.search_wrapper {
	display: flex;
	gap: 10px;
	align-items: center;
}

.backArrow {
	width: 20px;
	height: auto;
	cursor: pointer;
}

.filters_wrapper {
	display: flex;
	align-items: center;
}

.filterIcons {
	width: auto;
	height: 28px;
	cursor: pointer;
}

.search_input {
	background-color: var(--gray20);
}
