.form_container {
	padding: 20px;
}

.option_item_wrapper {
	width: 100%;
	text-align: left;
	margin: 20px 0;
}
.radio_button_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}
.radio_button_wrapper label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}
.info_text {
	font-size: 14px;
	font-style: italic;
}

.form_input_wrapper {
	margin-top: 15px;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
	display: inline-block;
	margin-bottom: 7px;
}

.form_input_wrapper input {
	height: 35px;
	cursor: text;
}

.form_input_wrapper p.error_message,
p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.input_wrapper {
	position: relative;
	height: 100%;
}

.input_wrapper span {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-left: 10px;
	display: flex;
	align-items: center;
	z-index: 2;
}

.input_wrapper input {
	padding-left: 20px;
}

.form_input_wrapper textarea {
	outline: 1px solid var(--gray50);
	border-radius: 5px;
	min-height: 140px;
	padding: 10px;
	resize: vertical;
}
.form_input_wrapper textarea:focus {
	outline: 2px solid #6cc5d1;
}

.textarea_wrapper.error textarea {
	outline: solid 2px red;
}

.send_btn {
	margin-top: 30px;
	width: 100%;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}
