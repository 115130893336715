.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header a {
	color: unset;
}

/* .header.active {
	background-color: var(--light-gaawk);

	color: var(--gray80);
} */

.header.disabled a {
	text-decoration: none;
}

.header .arrow {
	width: 10px;
}

.header .right_side {
	display: flex;
	align-items: center;
	gap: 10px;
}
.product_number {
	font-size: 12px;
	color: #fff;
}

/* .header .right_side input[type="radio"] {
    background-color: #fff;
}
.header .right_side input[type="radio"]:checked {
    background-color: var(--gaawk);;
} */

.arrow {
	transform: rotate(0deg);
	transition: transform 0.3s ease;
}
.arrow.rotate {
	transform: rotate(180deg);
	transition: transform 0.3s ease;
}

.white_radio {
	border: 2px solid #fff !important;
}

.white_radio[type="radio"]:checked {
	background-color: #fff !important;
	/* border: 2px solid #6cc5d1; */
}

/* .header.active .right_side .white_radio {
	border: solid 2px var(--gaawk) !important;
} */

.right_side button {
	display: flex;
	align-content: center;
}
