.switcher_wrapper {
	overflow: hidden;
	height: 35px;
	display: flex;
	align-items: center;
	gap: 5px;
	min-width: 70px;

	p {
		font-size: 16px;
		font-weight: var(--font-weight-bold);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.forward_icon {
		width: 24px;
		fill: #000;
		transform: rotate(90deg);
		flex-shrink: 0;
	}
}

.modal_container {
	.info {
		margin-bottom: 20px;
	}

	.project_container {
		width: 100%;
		max-height: 400px;
		overflow-y: auto;
	}

	padding: 20px;

	.project_item {
		&.active {
			background-color: var(--gray20);
		}

		p {
			font-weight: var(--font-weight-semibold);
			font-size: 16px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 10px;
		border-radius: 8px;
	}

	.addProjectBtn {
		width: 100%;
		margin-top: 20px;
	}

	.manage_projects_btn {
		width: 100%;
		justify-content: center;
		margin-top: 20px;
		label {
			font-weight: var(--font-weight-semibold);
			font-size: 12px;
		}
	}
}
