.wrapper {
	margin-bottom: 20px;
}

.date_wrapper {
	width: 100%;
	outline: 1px solid var(--gray50);
	border-radius: 5px;

	&:not([class~="p-calendar-disabled"])[class~="p-focus"] {
		outline: 2px solid var(--gaawk);
	}

	&[class~="p-calendar-disabled"] {
		.input_wrapper {
			background-color: var(--gray20);
		}
	}

	&.invalid {
		outline: 2px solid red !important;
	}
}

.label {
	font-weight: var(--font-weight-semibold);
	margin-bottom: 8px;
	display: block;
}

.error_wrapper {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.input_wrapper {
	font-size: 14px;
	color: #000;

	&::placeholder {
		color: var(--gray70);
	}
}

.panel_wrapper {
	font-size: 14px;

	& button:focus {
		box-shadow: none;
		outline: 2px solid var(--gaawk);
	}

	& [class~="p-datepicker-month"] {
		padding: 0.5rem;
		margin-right: 0.5rem;
		&:hover {
			color: var(--gaawk);
		}
	}
	& [class~="p-datepicker-year"] {
		padding: 0.5rem;
		&:hover {
			color: var(--gaawk);
		}
	}

	& [class~="p-datepicker-calendar"] {
		font-size: 14px;
	}

	& [class~="p-datepicker-buttonbar"] button {
		padding: 0.5rem;
	}

	&[class~="p-datepicker"]:not([class~="p-disabled"])
		table
		td
		span:not([class~="p-highlight"]):not([class~="p-disabled"]):focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--gaawk);
	}

	&[class~="p-datepicker"]:not([class~="p-disabled"])
		[class~="p-yearpicker"]
		[class~="p-yearpicker-year"]:not([class~="p-disabled"]):focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--gaawk);
	}

	&[class~="p-datepicker"]:not([class~="p-disabled"])
		[class~="p-monthpicker"]
		[class~="p-monthpicker-month"]:not([class~="p-disabled"]):focus {
		outline: 0 none;
		outline-offset: 0;
		box-shadow: 0 0 0 0.2rem var(--gaawk);
	}
}
