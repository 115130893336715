.container {
	padding: 20px;
	position: relative;
	background-color: #ffffff;
}

.form_input_wrapper {
	margin-bottom: 20px;
	width: 100%;
	height: fit-content;

	display: flex;
	flex-direction: column;
}

.input_label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: #000000;
	margin-bottom: 8px;
}

.checkbox_options {
	display: flex;
	align-items: center;
	gap: 40px;
}

.checkbox_wrapper {
	display: flex;
	align-items: center;

	font-size: 14px;
	color: #000;
}

.checkbox_wrapper span {
	cursor: pointer;
}

.inline_checkbox_container {
	width: 500px;
}

.info_container {
	margin: 20px 0px;
	display: flex;
	align-items: center;
	gap: 10px;

	font-size: 14px;
	font-style: italic;
	color: var(--gray70);
	position: relative;
}

.info_container img {
	width: 18px;
}

.separator {
	height: 6px;

	background-color: var(--gray20);
	position: absolute;
	left: -20px;
	bottom: -20px;
	right: -20px;
}

/* button */
.button_wrapper {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.info_text {
	margin-top: 10px;

	font-size: 12px;
	color: var(--black);
}

.roles_container {
	margin: 20px 0 0 !important;
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.inline_checkbox_container {
		width: 100%;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.inline_checkbox_container {
		width: 100%;
	}
}

@media screen and (max-width: 639.98px) {
	.inline_checkbox_container {
		width: 100%;
	}

	.button {
		width: 100%;
	}
}
