.option_icon {
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: initial;
		height: initial;
	}
}

.filter_icon {
	height: 24px;
	width: auto;
}

.icon_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 19px;
	/* height: 19px; */
}

img.more_icon {
	width: auto;
	height: 19px;
	margin-right: 0px;
}

.add_gap {
	gap: 10px;
}

.header {
	font-size: 18px;
	font-weight: var(--font-weight-bold);

	display: flex;
	align-items: center;
	gap: 20px;
}
