.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;

	.overflow_hidden {
		overflow: hidden;
		color: #000;
		&:hover {
			text-decoration: none;
		}
	}

	& > h4 {
		margin-bottom: 0 !important;
	}

	a {
		font-size: 11px;
		color: #a6b1bc;
		display: flex;
		align-items: center;
		gap: 10px;

		img {
			// margin-top: 2px;
			width: 5px;
			opacity: 0.4;
		}
	}
}

.items_container {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.section_container {
	// margin-top: 0px;
	min-height: fit-content;
	padding: 15px;

	// &.no_padding {
	// 	padding: 0;
	// }
}
