// //* NEW CSS =========== //

.item_container {
	border-radius: 8px;
	border: solid 0.5px #9ea7b3;
	padding: 10px;
	width: 100%;
	position: relative;
	margin-bottom: 20px;
}

.item_image {
	width: 256px;
	height: 205px;
	object-fit: cover;
	margin-right: 16px;
}

.image_container {
	height: 205px;
}

.item_content_container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 100%;
}

.item_service_name {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	text-align: left;
	color: #000;
	text-transform: uppercase;
}
.content_wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 0;
}

.description {
	padding-top: 2px;

	font-size: 14px;
	color: #000;
	word-break: break-word;
}

.flex_row_between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.serviceOwnerPicture {
	margin-bottom: 10px;
}

.serviceOwner {
	font-size: 10px;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399px) {
}

@media screen and (min-width: 974px) and (max-width: 1165px) {
}

@media screen and (min-width: 748px) and (max-width: 973px) {
	.item_content_container {
		flex-direction: column;
	}

	.item_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		margin-right: 0px;
	}
	.image_container {
		margin-bottom: 10px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747px) {
}

@media screen and (max-width: 639px) {
	.item_content_container {
		flex-direction: column;
	}

	.item_image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		margin-right: 0px;
	}
	.image_container {
		margin-bottom: 10px;
	}
}
