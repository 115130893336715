.img_option {
	margin-top: 15px;

	button {
		position: relative;

		width: 100%;
		height: 35px;
		background-color: var(--light-gaawk);
		border-radius: 5px;

		font-size: 14px;
		font-weight: var(--font-weight-bold);
		font-stretch: normal;
		font-style: normal;

		letter-spacing: 0.7px;
		text-align: center;
		color: var(--gaawk);
		// margin-top: 20px;

		&.disabled {
			background-color: var(--gray20);
			color: #dbdfe4;

			cursor: no-drop;
		}
	}

	label {
		width: 100%;
		height: 18px;
		font-weight: var(--font-weight-semibold);
		font-size: 14px;
		font-weight: var(--font-weight-semibold);
		color: #000000;
		display: inline-block;
		margin-bottom: 7px;
	}
	.extraMargin {
		margin-bottom: 20px;
	}

	&.has_title button {
		margin-top: 0px;
	}

	input[type="file"] {
		opacity: 0;

		position: absolute;
		right: 0;
		top: 0;

		width: 100%;
		height: 100%;

		cursor: inherit;
	}

	p.error_message {
		margin-left: auto;
		font-style: italic;
		font-size: 12px;
		color: red;
		text-align: right;
	}

	.thumb_container {
		margin: 0 0 20px !important;
	}
}
