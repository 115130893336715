.location_item {
	font-weight: var(--font-weight-semibold);
	padding: 10px 0;
	border-bottom: solid 1px var(--gray30);
	margin-right: 20px;
	text-align: left;

	&:last-child {
		border-bottom: none;
	}
}

.search_wrapper {
	padding: 20px;
}

.add_container {
	width: 100%;
	height: fit-content;
	padding: 0 20px 20px;
}

.tags_container {
	max-height: 110px;
	overflow: auto;
	margin-bottom: 20px;
}

// .group_participants_wrapper {
// 	display: flex;
// 	flex-wrap: wrap;
// 	margin-bottom: 20px;
// 	gap: 10px;
// }

.form_controls {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	gap: 20px;
}

.cancel_btn {
	border: solid 1px var(--gray50);
	color: var(--gray70);
}

.create_btn {
	width: 100%;
}

.items_list_wrapper {
	height: 463px;
	overflow: auto;
	display: flex;
	flex-direction: column;
}
