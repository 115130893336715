@use "variables" as *;

.container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
}

.tab_menu_wrapper {
	padding: 20px;
}

.separator {
	margin: 0px;
	border: 1px solid var(--gray20);
}

.fileviewer_container {
	height: calc(100dvh - $bottomNavbarHeight);
	padding: 0px 15px;
}

.vault_gaawk_container {
	margin-top: 40px;

	width: 100%;
	height: 597px;

	background-color: #f6db71;
}

/* DETAILS MODAL CSS */

.modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
	padding: 20px 30px;
}

.unshare_modal_container {
	padding: 20px;
	font-weight: var(--font-weight-semibold);
}

.button_container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 20px;
}

.details_modal_container {
	width: 100%;
	height: fit-content;

	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
}
.details_modal_header {
	height: 100px;
	/* background-color: var(--gray20); */
	display: flex;
	align-items: center;
	padding: 14px 20px;
}

.details_modal_body {
	padding: 0px 20px 20px;
}

.details_info {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.details_info > div {
	display: flex;
	align-items: flex-start;
	font-size: 12px;
}

.details_info > div > p {
	color: #000;
}

.details_info > div > span {
	color: var(--gray70);
	width: 85px;
	flex-shrink: 0;
}

.details_icon_container {
	width: 72px;
	height: 72px;
	border-radius: 12px;
	background-color: var(--gray20);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.details_icon_container .folder_icon {
	width: 26px;
	height: auto;
}

.details_icon_container .play_icon {
	position: absolute;
	width: 36px;
	z-index: 2;
}

.details_icon_container .file_img {
	object-fit: cover;
	border-radius: 11px;
}

.details_icon_container .file_icon {
	border-radius: 0px;
	width: 35px;
	height: 35px;
}

.details_title_container {
	margin-left: 20px;
	display: flex;
	flex-direction: column;
}

.details_title_container span {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	line-height: 0.81;
	color: #000;
}

.details_title_container span:last-child {
	font-weight: var(--font-weight-extralight);
	font-size: 14px;
	margin-top: 10px;
}

.sharedby_container {
	margin-top: 10px;
}

/* .separator {
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.16);
} */

.sharedby_container h3 {
	margin: 10px 0px;
	display: block;
}

.sharedby {
	margin-top: 10px;

	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.sharedby button {
	display: flex;
	align-items: center;
}

.sharedby:last-child {
	border-bottom: none;
}

.loader_view {
	margin-top: 10px;
}

.parentFolderName {
	font-weight: var(--font-weight-bold);
	font-size: 18px;
	text-align: left;
	color: #fff;
}

.subdata {
	font-size: 10px;
	color: var(--gray70);
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.recent_container {
		height: 198px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.recent_container {
		height: 182px;
		border-radius: 16px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.recent_container {
		height: 182px;
		border-radius: 16px;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.recent_container {
		height: 231px;
		border-radius: 0;
		box-shadow: none;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.recent_container {
		height: 182px;
		border-radius: 16px;
	}
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	.recent_container {
		height: 231px;
		border-radius: 0;
		box-shadow: none;
	}

	.button_container {
		flex-direction: column-reverse;
		gap: 10px;

		button {
			width: 100%;
		}
	}
}
