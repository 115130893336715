.container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: #fff;

	& > div {
		padding-bottom: 20px;
		border-bottom: 1px solid var(--gray30);
	}
	& > div:last-of-type {
		padding-bottom: 0px;
		border-bottom: none;
	}
}
.error_container {
	padding: 20px;
}

.loader_container {
	padding: 20px;
	& > div {
		border-bottom: 1px solid var(--gray30);
		margin-bottom: 20px;
		padding-bottom: 10px;
		&:last-child {
			border-bottom: none;
		}
	}
}

.loading_item_container {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--gray30);
}

.title_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 20px 0;
}

.title_wrapper button {
	width: 24px;
	height: 24px;
	background-color: #a6b1bc;
	border-radius: 4px;
	padding: 4px;
}

.title_wrapper button img {
	display: flex;
	align-items: center;
}

.no_results_container {
	background-color: #1a8897;
	padding: 20px;
	color: #fff;

	font-size: 14px;
}

.menu {
	padding: 0 20px;
	display: flex;
	justify-content: flex-start;
}

.menu_button {
	color: var(--gray70);
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.info_text {
	padding: 20px;

	font-size: 14px;
	font-style: italic;
	color: var(--gray80);
}
