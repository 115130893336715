.projects_wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
}

.project_wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
}
