.container {
	display: flex;
	flex-direction: column;
	height: 100%;

	.tab_menu {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px 0px 20px;
		border-bottom: var(--gray30) 0.5px solid;
	}
	.menu_button {
		padding: 2px 7px 3px 6px;
		box-sizing: border-box;
		border-radius: 5px;
		font-weight: var(--font-weight-semibold);
		font-size: 14px;
	}
}
