.accordion_item {
	margin: 5px 0;

	display: flex;
	align-items: center;

	cursor: pointer;

	width: 100%;
	height: 35px;

	border-radius: 8px;
	background-color: var(--gray80);

	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	letter-spacing: 0.75px;

	color: #ffffff;

	padding: 0 15px;
}
/* .accordion_item_product_count {

	font-size: 12px;
	color: #fff;
} */
.accordion_item.active {
	background-color: var(--gaawk);
}
/* .accordion_item_level2_product_count {

	font-size: 12px;
	color: var(--gray80);
} */
.accordion_item.level_2 {
	color: #000;
	background-color: var(--gray20);

	width: 95%;
	margin-left: auto;
}
.accordion_item.level_2.details_view {
	width: 100%;
}
/* .accordion_item.level_2_sub {
	color: #000;
	background-color: var(--gray20);

	width: 100%;
} */

.accordion_item.level_2.active {
	background-color: var(--gaawk);
	color: #ffffff;
}

/* .accordion_item.level_2.w100 {
	width: 100%;
}
.accordion_item.level_3_sub {
	color: #000;
	background-color: #fff;
	font-size: 13px;
	outline: 2px solid var(--gray20);
	outline-offset: -2px;

	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
} */

.accordion_item.level_3 {
	color: #000;
	background-color: #fff;
	font-size: 13px;
	outline: 2px solid var(--gray20);
	outline-offset: -2px;

	width: 90%;
	margin-left: auto;

	display: flex;
	align-items: center;
	justify-content: space-between;
}
.accordion_item.level_3.details_view {
	width: 95%;
}

.accordion_item.level_3.details_view.level_3_fullwidth {
	width: 100%;
}
.accordion_item.level_3.active {
	background-color: var(--gaawk);
	color: #ffffff;
	outline: 0px;
}
/* .accordion_item.level_3.w95 {
	width: 95%;
}
.accordion_item.accordion_item.level_3_sub a {
	color: unset;
} */
.accordion_item.level_3 a {
	color: unset;
}
.accordion_text {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 15px;
}
.services_txt {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #000;
}
.collapse_txt {
	font-size: 12px;
	color: var(--gray80);

	font-weight: var(--font-weight-semibold);
}
