.login_form {
	padding: 40px;

	.login_btn {
		width: 100%;
	}
}

.logo_wrapper {
	display: flex;
	justify-content: center;
	margin: 53px 0 75px 0;
	img {
		width: 110px;
	}
}

.login_extra_field {
	display: flex;

	align-items: center;
	margin: 20px 0;

	label {
		font-size: 14px;
		margin: 0;
		color: #000;
	}
}

.link_container {
	text-align: center;
	margin-top: 10px;

	a {
		color: #000;
	}
}
