.container {
	background-color: #1a8897;
	height: 100%;
	padding: 20px;
}
.content {
	padding-top: 80px;
	/* text-align: center; */
	/* color: #fff; */
}

.title {
	font-weight: var(--font-weight-bold);
	font-size: 22px;
	margin: 0;
	text-align: center;
	color: #fff;
}

.text_info {
	font-weight: var(--font-weight-semibold);
	font-size: 22px;
	margin-top: 10px;
	text-align: center;
	color: #fff;
}

.job_card {
	padding: 20px;
	background-color: #fff;
	border-radius: 12px;
	max-width: 400px;
	margin: 60px auto 0;
}

.no_padding {
	padding: 0;
}

.tag_container {
	width: 60%;
	margin-top: 10px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 5px;
}

.tag {
	font-size: 12px;
	padding: 1px 5px;
	border: none;
	color: var(--gray80);
}

.button_container {
	display: flex;
	justify-content: center;
	margin-top: 60px;
}
