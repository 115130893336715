@use "variables" as *;

.header {
	font-size: 18px;
	font-weight: var(--font-weight-bold);

	display: flex;
	align-items: center;
	gap: 20px;
}

.filter_tag {
	padding: 5px;
	font-size: 12px;
	background-color: #fff;
	border: 1px solid #dbdfe4;
}

.icon_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 14px;
	height: 14px;
}

img.more_icon {
	margin-right: 0px;
}

.add_gap {
	gap: 10px;
}

.container {
	width: 100%;
	height: calc(100dvh - $topNavBarHeight);

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;
	background-color: var(--gray20);
}

.center_section {
	height: fit-content;
	margin: 0 auto;
}

.right_section {
	height: 700px;

	background-color: goldenrod;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.center_section {
		width: 758px;
	}

	.right_section {
		width: 422px;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.center_section {
		width: 758px;
	}

	.right_section {
		width: 422px;
	}
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.center_section {
		width: 620px;
	}

	.right_section {
		width: 347px;
	}
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.center_section {
		width: 483px;
	}

	.right_section {
		width: 269px;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.center_section {
		width: 576px;
	}

	.right_section {
		display: none !important;
	}
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.center_section {
		width: 100%;
	}

	.right_section {
		display: none !important;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.center_section {
		width: 100%;
	}

	.right_section {
		display: none !important;
	}

	.container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
}

/*360*/
@media screen and (max-width: 639.98px) {
	.center_section {
		width: 100%;
	}

	.right_section {
		display: none !important;
	}

	.container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);
	}
}
