@mixin error-warning {
	font-size: 12px;
	color: red;
	font-style: italic;
	position: absolute;
	bottom: -20px;
	right: 0px;
}

.container {
	padding: 40px 40px 0;

	h3 {
		font-size: 18px;
		font-weight: var(--font-weight-bold);
	}

	.no_margin {
		margin: 0;
	}

	.next_btn {
		width: 100%;
		margin-top: 37px;
	}
}

.arrow_btn {
	width: fit-content;
	height: 20px;
	display: flex;
	align-items: center;
	img {
		width: 20px;
	}
}

.logo_wrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 37px;
	img {
		width: 110px;
	}
}

.dob_wrapper {
	// width: 320px;
	height: 35px;

	display: flex;
	gap: 20px;
	// flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	align-content: center;
}

.dob_item_wrapper {
	// width: 100px;
	flex: 1;
	height: 100%;
}

// .form_input_wrapper {
//     display: flex;
//     flex-direction: column;
//     gap: 7px;
//     justify-content: space-between;
//     margin-top: 20px;
//     position: relative;

//     label {
//         font-size: 14px;
//         font-weight: var(--font-weight-semibold);
//         color: #000;
//         margin-bottom: 0 !important;
//     }

//     p.error_message {
//         @include error-warning;
//     }
// }

.no_error {
	display: none;
}
