.container {
    width: 100%;
    height: 1110px;

    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #ffffff;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(65%);
    z-index: 2;

    border: none !important;

    border-radius: 100% / 10%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.menuContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 219px;
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.25);
    z-index: 4;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.menuWrapper {
    width: 320px;
    height: 100%;
    //border: #f6db71 2px solid;
    padding: 48px 42px 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.menuIcons {
    width: 100%;
    height: 25px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 25px;
}

.menuIconsGaawk {
    width: 78px;
    height: 25px;
}

.menuIconsClose {
    width: 12px;
    height: 12px;
    cursor: pointer;
}
.menuItem {
    width: 100%;
    height: 34px;
    border-bottom: #e9e9e9 1px solid;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
}

.menuItemText {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
}

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 1440px;
    height: 100%;

    z-index: 3;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    padding: 78px 0 70px 0;
}

.topSection {
    width: 100%;
    height: 46px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gaawkIcon {
    width: 115px;
    height: 36px;
    cursor: pointer;
}

.buttonView {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    height: 100%;
}

.loginText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 21.182px;
    font-style: normal;
    font-weight: 500;
    //line-height: 59.627px;

    //   cursor: pointer;
}

.button {
    margin-left: 30px;
    width: 195px;
    height: 100%;

    border-radius: 8px;
    background: #fff;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.buttonText {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 21.182px;
    font-style: normal;
    font-weight: 500;
}

.menuImg {
    width: 16px;
    height: 12px;
    cursor: pointer;
    display: none;
}

.bottomSection {
    width: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.bottomSectionTitle {
    width: 500px;
    height: 140px;
    margin-bottom: 12px;
}

.bottomSectionSubTitle {
    width: 382.893px;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.bottomSectionIcon {
    margin-top: 112px;
    width: 38px;
    height: 38px;
    align-self: center;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
    .container {
        height: 976.563px;
    }

    .wrapper {
        width: 1280px;
        padding: 73px 0 52px 0;
    }
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
    .container {
        height: 567.627px;
    }

    .wrapper {
        width: 744px;
        padding: 50px 0 22px 0;
    }

    .topSection {
        height: 26px;
    }

    .gaawkIcon {
        width: 78px;
        height: 25px;
    }

    .loginText {
        font-size: 12px;
    }

    .button {
        margin-left: 8px;
        width: 114px;

        border-radius: 4px;
    }

    .buttonText {
        font-size: 11.899px;
    }

    .bottomSectionTitle {
        width: 250px;
        height: auto;
        margin-bottom: 4px;
    }

    .bottomSectionSubTitle {
        width: 220px;
        font-size: 10px;
    }

    .bottomSectionIcon {
        margin-top: 62px;
        width: 24px;
        height: 24px;
    }
}

/*mobile*/
@media screen and (max-width: 743.98px) {
    .container {
        height: 568px;
    }

    .wrapper {
        width: 320px;
        padding: 48px 0 18px 0;
    }

    .topSection {
        height: 26px;
        //padding: 0 40px;
    }

    .gaawkIcon {
        width: 78px;
        height: 25px;
    }

    .loginText {
        display: none;
    }

    .button {
        display: none;
    }

    .menuImg {
        display: block;
    }

    .bottomSectionTitle {
        width: 250px;
        height: auto;
        margin-bottom: 4px;
    }

    .bottomSectionSubTitle {
        width: 220px;
        font-size: 10px;
    }

    .bottomSectionIcon {
        margin-top: 32px;
        width: 24px;
        height: 24px;
    }
}
