.container {
	padding: 20px;

	.items_wrapper {
		overflow: auto;
		max-height: 500px;
	}

	.contact_item {
		padding: 5px 10px 10px 0;
	}
}
