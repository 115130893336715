@use "variables" as *;

.container {
	position: relative;
	height: calc(100dvh - $topNavBarHeight);
	width: 100%;

	.background {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		p {
			z-index: 1;
			color: #fff;
			font-family: "Poppins-Thin";
			font-size: 28vw;
			// transform: rotate(180deg);
			// writing-mode: vertical-lr;
			opacity: 0.7;

			-webkit-user-select: none; /* Safari */
			-ms-user-select: none; /* IE 10 and IE 11 */
			user-select: none; /* Standard syntax */
		}
	}

	.content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		gap: 20px;
		h1 {
			font-family: "Poppins-SemiBold";
			margin: 0;
		}

		p {
			font-family: "Poppins";
		}

		.link {
			font-family: "Poppins-SemiBold";
			font-size: 11px;
		}
	}
}

@media screen and (max-width: 747px) {
	.container {
		height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);

		.background {
			p {
				font-size: 50vh;
				transform: rotate(180deg);
				writing-mode: vertical-lr;
			}
		}
	}
}
