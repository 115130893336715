.option_item {
	width: 100%;
	cursor: pointer;
	/* margin: 15px 0px; */
	padding: 10px 0px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-bottom: 1px solid #dbdfe4;
}

.controls:last-child .option_item:last-of-type {
	border-bottom: none;
}

.option_item img {
	width: 19px;
	height: auto;
	max-height: 21px;
}

.option_item span {
	font-size: 16px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	margin-left: 11px;
}

@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .option_items {
        margin-top: 22px;
    } */
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.controls.p2p:first-child .option_item:last-of-type {
		border-bottom: none;
	}

	.option_items.p2p {
		/* margin-top: 5px; */
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: 20px;
	}
	.option_items.p2p > div {
		width: 100%;
	}
}

@media screen and (max-width: 639.98px) {
}
