.container {
	background-color: white;
}

.search_input_wrapper {
	margin-top: 20px;
	padding: 0 20px;
}

.search_input {
	background-color: var(--gray20);
}

.contact_item {
	border-bottom: 1px solid #dbdfe4;
	padding: 10px 0px;

	&:first-of-type {
		padding-top: 0px;
	}

	&:last-of-type {
		border-bottom: none;
		padding-bottom: 0px;
	}
}

.section_wrapper {
	padding: 20px;
}

.section_wrapper {
	& > h3 {
		margin: 0 0 20px;
		color: #000;
	}
}
