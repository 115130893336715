.container {
	width: 100%;
	padding: 20px;

	background-color: #ffffff;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: center;
}

.container > div {
	margin: 0;
	width: 100%;
}
.container > div > div:first-of-type {
	margin-top: 0;
}

.container .button_wrapper {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

@media screen and (max-width: 639px) {
	.container .button_wrapper button {
		width: 100%;
	}
}

/* .no_margin {
	margin: 0;
	width: 100%;
} */

/* .creatable_input {
	width: 100%;
	margin: 0;
} */

/* .roles_container {
	margin: 20px 0 0 !important;
} */

/* .info_text {
	margin-top: 10px;

	font-size: 12px;
	color: var(--black);
} */

/* .selected_roles {

    font-size: 14px;
    font-weight: var(--font-weight-semibold);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    display: block;
    margin-bottom: 6px;
}

.role_wrapper {
    border: 1px solid var(--gray50);
    border-radius: 5px;
    min-height: 120px;
    padding: 10px;
}

.role_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 5px;
}

.role_item {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: #dbdfe4;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #a6b1bc;
}

.role_item img {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
} */

/*
  Add From
   */

/* .add_form {
    width: 100%;
    height: fit-content;
}

.form_input_wrapper {
    width: 100%;
    height: fit-content;

    margin-bottom: 48px;
}

.form_input_wrapper label {
    font-weight: var(--font-weight-semibold);
    font-size: 13px;
    font-weight: var(--font-weight-semibold);
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.info_max {

    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;

    display: block;
    margin-top: 10px;
}

.customAutocomplete_wrapper {
    width: 100%;
    height: 35px;
    margin: 7px 0 0;
} */
