.container {
	background-color: #fff;

	min-height: fit-content;
}
.search_input {
	background-color: var(--gray20);
}
.search_container {
	padding: 20px;
	border-bottom: 5px solid var(--gray20);
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
}
.service_name {
	font-weight: var(--font-weight-semibold);
	font-size: 16px;
	color: #000;
}
.btn_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.items_wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fill, 204px);
	gap: 35px;
	row-gap: 20px;
}
.product_img {
	border-radius: 8px;
	width: 204px;
	height: 204px;
}
@media screen and (min-width: 2000px) {
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.items_wrapper {
		gap: 13px;
		grid-template-columns: repeat(auto-fill, 180px);
	}
	.product_img {
		width: 180px;
		height: 180px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.items_wrapper {
		gap: 8px;
		grid-template-columns: repeat(auto-fill, 176px);
	}
	.product_img {
		width: 176px;
		height: 176px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.items_wrapper {
		gap: 5px;
		grid-template-columns: repeat(auto-fill, 175px);
	}
	.product_img {
		width: 175px;
		height: 175px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.items_wrapper {
		gap: 10px;
		grid-template-columns: repeat(auto-fill, 155px);
	}
	.product_img {
		width: 155px;
		height: 155px;
	}
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.items_wrapper {
		gap: 12px;
		grid-template-columns: repeat(auto-fill, 185px);
	}
	.product_img {
		width: 185px;
		height: 185px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	.items_wrapper {
		gap: 10px;
		grid-template-columns: repeat(auto-fill, 155px);
	}
	.product_img {
		width: 155px;
		height: 155px;
	}
}

@media screen and (max-width: 367px) {
	.items_wrapper {
		grid-template-columns: repeat(auto-fill, 130px);
		gap: 34px;
	}
	.row {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	.btn_wrapper {
		width: 100%;
		button {
			width: 100%;
		}
	}
	.product_img {
		width: 130px;
		height: 130px;
	}
}
.accordion_container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.service_accordion_header_name {
	font-weight: var(--font-weight-bold);
	font-size: 16px;
	color: #000;
	margin-bottom: 10px;
}
.empty_image {
	width: 45px;
	height: 45px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.no_result_conatiner {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
