.container {
	&.closed > * {
		color: var(--gray80);
	}

	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 56px;
	border-radius: 8px;
	background-color: var(--gray20);
	padding: 10px;

	.job_details {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
	}

	h3 {
		// margin: 0;
		font-size: 14px;
		font-weight: var(--font-weight-bold);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}

	p {
		font-size: 10px;
		font-weight: var(--font-weight-bold);
		span {
		}
	}

	.job_controls {
		display: flex;
		align-items: center;

		.new_applicants {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--gaawk);
			border-radius: 8px;
			width: 32px;
			height: 32px;

			font-weight: var(--font-weight-bold);
			font-size: 18px;
			color: #fff;
		}

		.option_btn {
			width: 32px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: auto;
			}
		}
	}
}
