.tabs_buttons {
	width: fit-content;
	height: 100%;

	display: flex;
	align-items: center;
	gap: 10px;
}

.tabs_buttons button {
	width: 28px;
	height: 28px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.sort_button img {
	width: 20px;
}
