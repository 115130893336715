.container {
	padding: 20px;

	.header {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
	}

	.menu {
		margin: 25px 0;

		.menu_button {
			color: #000;
			font-weight: var(--font-weight-semibold);
			font-size: 12px;
		}
	}

	.jobs_container {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
}
