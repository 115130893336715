.container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.statusIcon {
	fill: #000;
	width: 15px;
	height: 15px;
}
