.storageBar {
	width: 100%;

	p {
		font-weight: var(--font-weight-bold);
		gap: 10px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
}

.barContainer {
	height: 6px;
	background-color: #f0f0f0;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
}

.barSegment {
	height: 100%;
	transition: width 0.3s ease-in-out;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 10px;
}

.vault {
	background-color: #ffd900;
	z-index: 1;
}

.gallery {
	background-color: #ffa200;
	z-index: 2;
}

.system {
	background-color: #f44336;
	z-index: 3;
}

.legend {
	display: flex;
	font-weight: var(--font-weight-semibold);
	color: var(--gray80);
	// justify-content: space-between;
	gap: 20px;
	margin-top: 10px;
	font-size: 10px;
}

.systemLegend::before,
.galleryLegend::before,
.vaultLegend::before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 5px;
	border-radius: 50%;
}

.vaultLegend::before {
	background-color: #ffd900;
}

.galleryLegend::before {
	background-color: #ffa200;
}

.systemLegend::before {
	background-color: #f44336;
}
