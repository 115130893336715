.input {
	width: 100%;
	height: 100%;
	/* border: solid 1px var(--gray50); */
	outline: solid 1px var(--gray50);
	outline-offset: "-1px";
	padding: 0 10px;

	font-size: inherit;
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	border-radius: 5px;
}

.input_wrapper {
	width: 100%;
	height: 35px;
	position: relative;

	.visibility_button {
		display: flex;
		align-items: center;
		position: absolute;
		width: 20px;
		height: auto;
		right: 10px;
		top: 9px;
	}

	// .left_side_item {
	// 	position: absolute;
	// 	left: 2px;
	// 	top: 50%;
	// 	display: flex;
	// 	align-items: center;
	// 	transform: translateY(-50%);
	// 	padding: 0 5px;
	// 	border-radius: 4px 0 0 4px;
	// 	height: 31px;
	// 	background-color: #d9edf1;
	// 	font-weight: var(--font-weight-semibold);
	// }
}

.input:focus,
.input:focus.unfocused {
	outline: solid 2px var(--gaawk) !important;
}

.focused {
	outline: solid 2px var(--gaawk) !important;
}

.unfocused {
	outline: solid 1px var(--gray50) !important;
}

.input.disabled {
	background-color: var(--gray20) !important;
	outline: none;
	cursor: default !important;
}

.input.error,
.input.focused.error,
.input.unfocused.error {
	outline: solid 2px red !important;
}
