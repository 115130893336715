.form {
	width: 100%;
	height: fit-content;
	// padding: 10px 10px 20px;
}

// .form.no_padding {
// 	padding: 0px;
// }
.character_max {
	font-style: italic;
	font-size: 12px;
	color: #000000;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

.section {
	padding: 20px;
	border-bottom: 6px solid var(--gray20);
}

.section:nth-last-child(2) {
	border-bottom: none;
}

.form_input_wrapper {
	.inline_wrapper {
		display: flex;
		align-items: center;
		gap: 30px;
	}
}

.form_input_wrapper,
.custom_upload_button {
	margin-top: 15px;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.form_input_wrapper label,
.custom_upload_button label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	color: #000000;
	display: inline-block;
	margin-bottom: 7px;
}

.form_input_wrapper input {
	height: 35px;
	cursor: text;
}

.form_input_wrapper p.error_message,
p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.form_input_wrapper textarea {
	outline: 1px solid var(--gray50);
	border-radius: 5px;
	min-height: 140px;
	padding: 10px;
	resize: vertical;
}

.form_input_wrapper textarea:focus {
	outline: 2px solid #6cc5d1;
}
.description_watcher {
	font-weight: var(--font-weight-bold);
	font-size: 12px;
	color: var(--gaawk);
}
.description_counter {
	display: flex;
	justify-content: flex-end;
}
.button_container {
	display: flex;
	justify-content: center;
	padding: 20px;

	button {
		min-width: 160px;
		height: 35px;
		font-size: 14px;
		padding: 0 10px;
	}
}

.dropdown_container {
	width: 100%;
	height: 37px;

	// display: flex;
	// flex-wrap: wrap;
	// justify-content: flex-start;
	// align-content: flex-start;
	/*align-items: center;*/
}

.m_20 {
	margin: 20px 0;
}

.radio_button_wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.info_text {
	font-size: 14px;
	font-style: italic;
}

.radio_button_wrapper.disabled {
	color: #a8abae;
}
.radio_button_wrapper.disabled label {
	cursor: not-allowed;
}

.radio_button_wrapper button {
	margin-left: auto;
}
.radio_button_wrapper:not(.disabled) button {
	color: var(--gaawk);
}

.radio_button_wrapper.disabled button {
	cursor: not-allowed;
}

// .img_option button {
//     position: relative;

//     width: 100%;
//     height: 35px;
//     background-color: var(--light-gaawk);
//     border-radius: 5px;

//
//     font-size: 14px;
//     font-weight: var(--font-weight-bold);
//     font-stretch: normal;
//     font-style: normal;

//     letter-spacing: 0.7px;
//     text-align: center;
//     color: var(--gaawk);
//     margin-top: 10px;
// }

// .img_option input[type="file"] {
//     opacity: 0;

//     position: absolute;
//     right: 0;
//     top: 0;

//     width: 100%;
//     height: 100%;

//     cursor: inherit;
// }
