.container {
  width: 100%;
  height: 570px;

  background-color: #ffffff;
}

.swiper {
  width: 100% !important;
  height: 100% !important;
}

.swiperSlide {
  width: 380px !important;
  height: 100%;
}

.sliderImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
  .container {
    height: 300px;
  }
  .swiperSlide {
    width: 200px !important;
  }
}

/*mobile*/
@media screen and (max-width: 743.98px) {

  .container {
    height: 136px;
  }

  .swiperSlide {
    width: 92px !important;
  }
}