.section {
	padding: 20px;
	border-bottom: 6px solid var(--gray20);
}

.section:nth-last-child(2) {
	border-bottom: none;
}

.button_container {
	display: flex;
	justify-content: center;
	padding: 20px;

	button {
		min-width: 160px;
		height: 35px;
		font-size: 14px;
		padding: 0 10px;
	}
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

.no_margin {
	margin: 0;
}

.react_switch {
	display: flex;
	align-items: center;
	gap: 20px;

	label {
	}
}

.character_max {
	font-style: italic;
	font-size: 12px;
	color: #000000;
}

.description_watcher {
	font-weight: var(--font-weight-bold);
	font-size: 12px;
	color: var(--gaawk);
}
.description_counter {
	display: flex;
	justify-content: flex-end;
}
