.job_form {
	padding: 20px;
	background-color: #fff;
}

.form_input_wrapper {
	margin-top: 20px;

	width: 100%;
	height: fit-content;

	position: relative;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

/* .form_input_wrapper input[type="text"],
.form_input_wrapper input[type="number"] {
    width: 100%;
    height: 35px;
    margin: 7px 0 0;
    border-radius: 5px;
    padding: 7px;

    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
} */

/* .form_input_wrapper > label {
    width: 100%;
    height: 18px;
    font-weight: var(--font-weight-semibold);
    font-size: 14px;
    font-weight: var(--font-weight-semibold);

    color: #000000;
} */

.react_select_wrapper {
	margin-top: 20px;

	width: 100%;
	height: fit-content;

	display: flex;
	flex-direction: column;
}

.form_input_wrapper > label,
.react_select_wrapper label,
.inline_input .item label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);

	color: #000000;
	margin-bottom: 7px;
	display: block;
}

.form_input_wrapper p.error_message,
.gender_wrapper p.error_message,
.react_select_wrapper p.error_message,
p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

/* CREATE JOB BUTTON */

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

/* REACT SWITCH */

.react_switch {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.react_switch span {
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000000;

	display: block;
	margin-left: 20px;
}

.address {
	font-size: 13px;
	color: #000000;
	margin-top: 20px;
}

/* TEXT AREAS */

.text_input {
	margin-top: 20px;

	width: 100%;
	height: fit-content;
}

.text_input label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

.inline_input {
	width: 100%;
	display: flex;
	gap: 30px;
	margin-top: 20px;
}

.inline_input.age_wrapper {
	margin-top: 0px;
}

.inline_input.gender_wrapper {
	margin-top: 7px;
}

.add_margin {
	margin-top: 7px;
}

.inline_input .item,
.inline_input .item input {
	width: 100%;
}

.gender_item {
	display: flex;
	align-items: center;
	gap: 5px;
}

/* MAP MODAL */
.map_container {
	padding: 20px;
}

.map {
	width: 100%;
	height: 360px;
}

.margin_20 {
	margin: 20px 0;
}

/* TIME PICKER */
.timepicker {
	width: 100%;
}

/* .timepicker :global(.react-time-picker__wrapper) {
	outline: solid 1px var(--gray50);
	border-radius: 5px;
	border: none;
	height: 35px;
} */

/* .timepicker :global(.react-time-picker__inputGroup) {
	padding: 7px;
}
:global(.react-time-picker--open) {
	outline: solid 2px var(--gaawk);
	border-radius: 5px;
} */

/* project input custom input */

.form_input_wrapper input[type="file"] {
	opacity: 0;

	position: absolute;
	right: 0;
	top: 0;

	width: 100%;
	height: 100%;

	cursor: inherit;
}

.img_option button {
	position: relative;

	width: 100%;
	height: 35px;
	background-color: var(--light-gaawk);
	border-radius: 5px;

	font-size: 14px;
	font-weight: var(--font-weight-bold);
	font-stretch: normal;
	font-style: normal;

	letter-spacing: 0.7px;
	text-align: center;
	color: var(--gaawk);
	margin-top: 7px;
}

.thumbs_container {
	aspect-ratio: 2 / 1;
	width: 320px;
	margin: 15px auto 0;
}

@media screen and (max-width: 639px) {
	.save_btn {
		width: 100%;
	}
}
