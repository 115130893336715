@use "variables" as *;

.loading_container {
	width: 100%;
	margin: auto;
	text-align: center;
}

.media_container_title h2 {

	font-size: 20px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	margin: 0;
}

.media_container_title span {

	font-size: 12px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #a6b1bc;
}

.media_container {
	/* width: 100%; */
	/* flex: 0 0 50%; */

	width: 100%;
}

.media_container.p2p {
	width: 100%;
}

.media_container_title {
	margin-bottom: 20px;
}

.grid_container {
	display: grid;
	grid-template-columns: repeat(auto-fill, 96px);
	row-gap: 20px;
	column-gap: 22px;
}

.grid_container.groupPage {
	column-gap: 16px;
}

/* .media_files_container {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 10px;
} */

.media_files_item {
	width: 96px;
	height: 96px;

	display: flex;
	justify-content: space-around;
	align-items: center;
	cursor: pointer;
}

.no_media {
	background-color: var(--gray20);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.no_media p {

	font-size: 12px;
	/* font-style: italic; */
}

/* .media_files_container::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.media_files_container::-webkit-scrollbar-track {
    margin-top: 5px;
    background: var(--gray50);
} */

.img_item {
	object-fit: cover;
	border-radius: 11px;
}

.fileviewer_container {
	height: calc(100dvh - $topNavBarHeight);
}

.fullscreen_icon {
	height: 20px;
	width: auto;
}

@media screen and (min-width: 2000px) {
	.media_container {
		width: 545px;
	}
}

@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
	.media_container {
		width: 545px;
	}
}

@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.media_container {
		width: 429px;
	}

	.grid_container {
		column-gap: 26px;
	}

	.grid_container.groupPage {
		column-gap: 15px;
	}
}

@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	/* .media_files_container {
        gap: 10px;
    } */

	.media_container {
		width: 318px;
	}

	.grid_container {
		column-gap: 30px;
	}

	.grid_container.groupPage {
		column-gap: 15px;
	}
}

@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.media_container {
		width: 230px;
	}

	/* .media_container {
        padding: 0px;
    } */
	/* .media_files_container {
        gap: 10px;
    } */

	.grid_container {
		column-gap: 14px;
	}

	.grid_container.groupPage {
		column-gap: 7px;
	}

	.grid_container.groupPage {
		grid-template-columns: repeat(auto-fill, 72px);
	}

	.media_files_item {
		width: 72px;
		height: 72px;
	}

	.media_files_item.p2p {
		width: 96px;
		height: 96px;
	}
}

@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .media_container.p2p {
        padding: 0px;
    }

    .media_container {
        padding: 0 20px;
    } */

	.grid_container,
	.grid_container.groupPage {
		column-gap: 16px;
	}

	.media_files_item {
		width: 96px;
		height: 96px;
	}

	/* .media_files_container {
        gap: 10px;
    } */
}

@media screen and (min-width: 640px) and (max-width: 747.98px) {
	/* .media_container {
        padding: 0px;
    } */

	.media_container {
		width: 230px;
	}

	.grid_container {
		grid-template-columns: repeat(auto-fill, 96px);
		column-gap: 25px;
	}

	.grid_container.groupPage {
		grid-template-columns: repeat(auto-fill, 72px);
		column-gap: 7px;
	}

	.media_files_item {
		width: 72px;
		height: 72px;
	}

	.media_files_item.p2p {
		width: 96px;
		height: 96px;
	}

	/* .media_files_container {
        row-gap: 10px;
        column-gap: 25px;
    } */
}

@media screen and (min-width: 360px) and (max-width: 639.98px) {
	/* .media_container.p2p {
        padding: 0px;
    }

    .media_container {
        padding: 0 20px;
    } */

	/* .media_files_container {
        gap: 10px;
    } */

	.grid_container,
	.grid_container.groupPage {
		column-gap: 16px;
	}

	.media_files_item {
		width: 96px;
		height: 96px;
	}
}

@media screen and (max-width: 359px) {
	.grid_container {
		justify-content: space-between;
	}
}
