/* The container */
.checkbox-container {
	/*display: block;*/
	position: relative;
	/*padding-left: 20px;*/
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/*width: 100%;*/
	/* height: 100%; */

	height: 20px;
	width: 20px;

	flex-shrink: 0;

	/*height: 100%;*/

	/*margin-bottom: 5px;*/

	/*background-color: red;*/
}

/* The container */
.checkbox-container.small {
	height: 14px;
	width: 14px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	/* top: calc(50% - 10px); */
	top: 0;
	opacity: 0;
	border: none !important;
	outline: none;
	cursor: pointer;
	height: 20px;
	width: 20px;
	border-radius: 2px;
	z-index: 100;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	/* top: calc(50% - 10px); */
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border-radius: 2px;
	border: 1px var(--gray50) solid;
}

.checkmark.circle {
	border-radius: 50%;
}
.checkmark.box {
	border-radius: 5px;
}
.checkmark.disabled {
	background-color: var(--gray30);
}

.checkbox-container.disabled:hover input {
	cursor: not-allowed;
	background-color: var(--gray30);
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
	background-color: var(--gray30);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark.black {
	background-color: #000000;
	border: none;
}

.checkbox-container input:checked ~ .checkmark.gaawk {
	background-color: var(--gaawk);
	border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
	left: 7px;
	top: 2px;
	width: 6px;
	height: 13px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(40deg);
	-ms-transform: rotate(40deg);
	transform: rotate(40deg);
}

.checkbox-container.small input {
	height: 14px;
	width: 14px;
}

.checkbox-container.small .checkmark {
	height: 14px;
	width: 14px;
}

.checkbox-container.small .checkmark:after {
	left: 5px;
	top: 1px;
	width: 5px;
	height: 10px;
}
