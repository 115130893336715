.container {
	background-color: #fff;
}

.sub_container {
	padding: 20px 15px;
}

.separator {
	display: block;
	width: 100%;
	height: 5px;
	background-color: var(--gray20);
}
