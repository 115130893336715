@use "variables" as *;

.right_column_wrapper {
	height: calc(100dvh - $topNavBarHeight);
	overflow-y: auto;
	overflow-x: hidden;
	flex: 0 0 350px !important;
	width: 350px;
	position: relative;
	z-index: 2;
	background-color: #fff;
	padding-right: 10px;
	padding-top: 10px;
}

@media screen and (max-width: 1399.98px) {
	.right_column_wrapper {
		display: none;
	}
}
