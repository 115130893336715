.gaawk_button {
	padding: 1rem;
	font-weight: var(--font-weight-bold);
	border-radius: 8px;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	box-shadow: 0 0 0 0 transparent;
	min-width: 150px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;

	&.primary {
		background-color: var(--gaawk);
		color: white;

		&:not(.disabled):not(.loading):hover {
			background-color: var(--dark-gaawk);
			box-shadow: 0 0 0 5px var(--primary100);
		}
	}

	&.secondary {
		background-color: var(--primary100);
		color: var(--dark-gaawk);

		&:not(.disabled):not(.loading):hover {
			background-color: var(--primary200);
			box-shadow: 0 0 0 5px var(--primary100);
		}
	}

	&.tertiary {
		background-color: #fff;
		color: var(--gray80);
		outline: 1px solid var(--gray30);
		outline-offset: -1px;

		&:not(.disabled):not(.loading):hover {
			background-color: var(--gray20);
			box-shadow: 0 0 0 5px var(--gray30);
		}
	}

	&.quaternary {
		background-color: #fff;
		color: var(--gray80);

		&:not(.disabled):not(.loading):hover {
			background-color: var(--gray20);
			box-shadow: 0 0 0 5px var(--gray30);
		}
	}

	&.black {
		background-color: #000;
		color: #fff;

		&:not(.disabled):not(.loading):hover {
			box-shadow: 0 0 0 5px var(--gray30);
		}
	}

	&.blackSecondary {
		background-color: #fff;
		color: #000;
		outline: 1px solid #000;
		outline-offset: -1px;

		&:not(.disabled):not(.loading):hover {
			background-color: #000;
			color: #fff;
			box-shadow: 0 0 0 5px var(--gray30);
		}
	}

	&.danger {
		background-color: var(--error200);
		color: #fff;

		&:not(.disabled):not(.loading):hover {
			background-color: var(--error300);
			box-shadow: 0 0 0 5px var(--error100);
		}
	}

	&.small {
		padding: 0.5rem 1rem;
		font-weight: var(--font-weight-semibold);
		font-size: 12px;
		height: fit-content;
		min-width: auto;
	}

	&.disabled {
		opacity: 0.3;
		cursor: not-allowed !important;
	}

	.custom_loader {
		background-color: transparent !important;
		padding: 0 !important;
	}
}
