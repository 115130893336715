.datePickerWrapper {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 20px;
	gap: 40px;
}

.thumbs_container {
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0px;

	width: 100%;
	gap: 20px;
}

.datePickerItem {
	width: 240px;
	display: flex;
	flex-direction: column;
}

.datePickerItem label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	color: #000000;
	display: inline-block;

	margin-bottom: 7px;
}

.container {
	width: 100%;
	padding: 20px;
	background-color: #ffffff;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: center;
}

.form {
	width: 100%;
	height: fit-content;
}

.form_input_wrapper {
	margin-top: 15px;

	width: 100%;
	height: fit-content;

	position: relative;
}

.m_15 {
	margin: 15px 0;
}

.form_input_wrapper p.error_message,
.react_select_wrapper p.error_message,
.datePickerWrapper p.error_message {
	margin-left: auto;
	font-style: italic;
	font-size: 12px;
	color: red;
	text-align: right;
}

.form_input_wrapper:first-child {
	margin-top: 0px;
}

.grade_section {
	width: 100%;
	display: flex;
}

.grade_section .score_item {
	width: 240px;
}

.grade_section .score_item:first-of-type {
	margin-right: 40px;
}

.grade_section .score_item input {
	width: 100%;
}

.form_input_wrapper label {
	width: 100%;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;

	margin-bottom: 7px;
	display: inline-block;
}

.react_select_wrapper {
	margin-top: 15px;

	width: 100%;
	height: fit-content;
}

.react_select_wrapper label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	margin-bottom: 7px;
	display: inline-block;
}

#titleInput:focus,
#scoreInput:focus {
	border: #6cc5d1 solid 2px !important;
}

.date_range_section {
	margin: 7px 0 0;
	width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: flex-start;
}

.date_range {
	margin-right: 10px;
	width: 190px !important;
	height: 35px !important;
}

.date_range:last-child {
	margin-right: 0;
}

.add_files_section {
	margin-top: 15px;

	width: 100%;
	height: fit-content;
}

.add_files_section label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
}

.add_files_section .subLabel {
	display: block;

	font-size: 12px;
	font-style: italic;
	color: var(--gray80);
}

.file_uploader_wrapper {
	margin-top: 7px;
	width: 100%;
}

.comments_section {
	margin-top: 15px;

	width: 100%;
	height: fit-content;
}

.comments_section label {
	width: 100%;
	height: 18px;
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	margin-bottom: 7px;
	display: inline-block;
}

.textarea_wrapper {
	margin-top: 20px;
	height: 200px;
}

.button_container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.react_switch {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.react_switch span {
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000000;

	display: block;
	margin-left: 20px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.date_wrapper {
		width: fit-content;
	}
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.date_range_section {
		justify-content: space-between;
	}

	.date_range {
		margin-right: 0;
		width: 160px !important;
	}

	.grade_section .score_item:first-of-type {
		margin-right: 30px;
	}

	.datePickerItem,
	.grade_section .score_item {
		width: 145px;
	}

	.datePickerWrapper {
		justify-content: space-between;
	}
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
}

/*/*360*/
@media screen and (max-width: 639.98px) {
	.date_range_section {
		justify-content: space-between;
	}

	.date_range {
		margin-right: 0;
		width: 155px !important;
	}

	.grade_section .score_item:first-of-type {
		margin-right: 30px;
	}

	.datePickerItem,
	.grade_section .score_item {
		width: 145px;
	}

	.datePickerWrapper {
		justify-content: space-between;
	}

	.button_container button {
		width: 100%;
	}
}
