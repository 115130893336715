.counter_container {
	width: 100%;
	height: 30px;

	position: absolute;
	bottom: 0;

	border-radius: 0 0 12px 12px;
	background-image: linear-gradient(
		to bottom,
		rgb(55, 59, 67, 0.6),
		rgb(13, 13, 13, 0.6)
	);

	display: flex;
	align-items: center;
	padding: 10px;
	gap: 9px;
}

.counter_item {
	display: flex;
	align-items: center;
	gap: 3px;
}

.counter_item .icon {
	width: 12px;
	height: 12px;
	fill: #fff;
}

.counter_item p {
	margin-top: 0;
	font-size: 12px;
	color: #fff;
}
