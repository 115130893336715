.round_button {
	border-radius: 50%;
	transition: background-color 0.3s ease, box-shadow 0.3s ease;
	box-shadow: 0 0 0 0 transparent;

	width: 28px;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:not(.disabled):not(.loading):hover {
		background-color: var(--gray20);
		box-shadow: 0 0 0 2px var(--gray30);
	}
}
