.member_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.shrink {
	flex-shrink: 1;
}

.member_item:last-child {
	border-bottom: none;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.close_icon {
	width: 18px;
	height: 18px;
	cursor: pointer;
}

.sub_title {
	object-fit: contain;

	font-size: 14px;
	font-weight: var(--font-weight-semibold);

	color: var(--black);
	display: block;
	margin-bottom: 10px;
}

.container {
	border-bottom: 6px solid var(--gray20);
}

.wrapper {
	padding: 20px;
}

.container h4 {
	margin: 0px;


	font-size: 18px;
	font-weight: var(--font-weight-bold);
	color: var(--black);
}

.other_info {
	margin: 10px 0px 20px;

	font-size: 14px;
	color: var(--black);
}

.no_bottom_margin {
	margin-bottom: 0;
}
