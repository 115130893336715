.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	height: 100%;

	h3 {
		font-weight: var(--font-weight-bold);
	}

	.plan_container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: var(--font-weight-bold);
		font-size: 20px;
		margin-top: 25px;

		p {
			span {
			}
		}
	}

	.charging_info {
		p {
			color: var(--error200);
			font-weight: var(--font-weight-semibold);
			font-size: 12px;
		}
	}

	.card {
		margin-top: 10px;
		background-color: var(--gray20);
		padding: 12px;
		border-radius: 8px;
		font-weight: var(--font-weight-semibold);
		font-size: 12px;

		p:first-of-type {
			padding-bottom: 10px;
			border-bottom: 1px solid var(--gray50);
		}

		p:last-of-type {
			padding-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.button_container {
		display: flex;
		justify-content: center;
	}
}
