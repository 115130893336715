.container {
	background-color: #fff;
	width: 100vw;
	height: 56px;
	// flex-grow: 1;
	display: flex;
	align-items: center;
	padding: 0 30px;
	justify-content: space-between;
	border-top: 1px solid var(--gray20);
}

@media screen and (min-width: 748px) {
	.container {
		justify-content: center;
		gap: 50px;
	}
}
