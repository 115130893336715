.search_wrapper {
	height: 35px;
	flex-grow: 1;
	position: relative;
	/* width: 100%; */
	/* margin: auto; */
	/* display: flex; */
}

.input {
	background-color: var(--gray20);
}

.clear_wrapper {
	position: absolute;
	top: 6.5px;
	right: 20px;
	width: 14px;
	height: 14px;
	cursor: pointer;
}
.search_wrapper_icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	/* top: 6.5px; */
	left: 20px;
	width: 14px;
	height: 14px;
	cursor: pointer;

	display: flex;
	align-items: center;
}

/* .search_wrapper.chat {
	width: 100%;
	height: 35px;
	margin: auto;
	position: relative;
} */

.search_wrapper input {
	width: 100%;
	flex-grow: 1;
	height: 100%;
	border: 1px solid var(--gray50);
	border-radius: 20px;
	padding: 10px 20px;

	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;

	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.search_wrapper.with_controls input {
	padding: 10px 45px 10px 38px;
}

.search_wrapper label {
	position: absolute;
	top: 10.5px;
	left: 21px;

	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000;
}

.search_wrapper label span {
	font-family: "Nexa-Bold", "Inter", serif;
	font-weight: var(--font-weight-bold);
}

/* .search_controls {
	width: 142.7px;
	height: 25.5px;
	position: absolute;
	top: 10.5px;
	right: 20px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.search_wrapper.chat .search_controls {
	width: 56px;
	height: 20px;
	position: absolute;
	top: 8.5px;
	right: 20px;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.search_controls .clear_icon {
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.search_controls .flag {
	width: 46px;
	height: 25px;
	cursor: pointer;
}

.search_controls .search_icon {
	width: 25px;
	height: 25px;
	cursor: pointer;
}

.search_wrapper.chat .search_controls .search_icon {
	width: 17.9px;
	height: 19px;
	cursor: pointer;
}

.search_controls .separator {
	width: 1px;
	height: 25px;
	background-color: #a6b1bc;
} */

/* .advanced_search {
	width: 100%;
	margin-top: 55px;

	font-size: 14px;
	font-weight: var(--font-weight-semibold);
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: center;
	color: #a6b1bc;
	cursor: pointer;
} */

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	.search_wrapper label {
		top: 7.5px;
		font-size: 14px;
	}

	/* .search_controls {
		width: 117.8px;
		height: 20px;
		top: 7px;
	}

	.search_controls .flag {
		width: 37.8px;
		height: 20px;
		cursor: pointer;
	}

	.search_controls .separator {
		height: 20px;
	}

	.search_controls .search_icon {
		width: 17.9px;
		height: 19px;
	}

    .advanced_search {
		margin-top: 36px;
		font-size: 13px;
	} */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.search_wrapper label {
		top: 7.5px;
		left: 21px;
		font-size: 14px;
	}

	/* .search_controls {
		width: 117.8px;
		height: 20px;
		top: 7px;
	}

	.search_controls .flag {
		width: 37.8px;
		height: 20px;
		cursor: pointer;
	}

	.search_controls .separator {
		height: 20px;
	}

	.search_controls .search_icon {
		width: 17.9px;
		height: 19px;
	}

	.advanced_search {
		margin-top: 36px;
		font-size: 13px;
	} */
}

/* 360 */
@media screen and (max-width: 639.98px) {
	.search_wrapper label {
		top: 7.5px;
		left: 21px;
		font-size: 14px;
	}

	/* .search_controls .search_icon {
		width: 17.9px;
		height: 19px;
	}
	.search_controls {
		width: 56px;
		height: 20px;
		top: 8px;
		right: 15px;
	}
	.search_controls .separator {
		height: 20px;
	}

	.advanced_search {
		margin-top: 36px;
		font-size: 13px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.advanced_search .separator {
		width: 1px;
		height: 20px;
		background-color: #a6b1bc;
		margin: 0 14px;
	}

	.advanced_search .flag {
		width: 28.5px;
		height: 15px;
	} */
}

@media screen and (max-width: 359px) {
	/* .search_wrapper {
		width: 100vw;
	} */
}
