.container {
	background-color: #f7f7f7;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
}

.smallTitleView {
	margin-top: 75px;
	width: 100%;
	height: 32px;

	display: flex;
	justify-content: center;
	align-items: center;

	text-align: center;
}

.smallTitle {
	color: var(--gaawk);
	font-family: "Poppins";
	font-size: 16px;
	font-style: normal;
	line-height: 53.398px;
}

.titleView {
	margin-top: 7px;
	width: 100%;
	height: 51px;

	display: flex;
	justify-content: center;
	align-items: center;

	text-align: center;
}

.title {
	color: #131723;
	font-family: "Poppins-SemiBold";
	font-size: 45px;
	font-style: normal;
	line-height: 50px;
}

.extendedView {
	width: 100%;
	height: auto;
	background-color: #ffffff;
}

.marquee {
	width: 100%;

	color: #000;
	text-align: center;
	font-family: "Poppins";
	font-size: 101.226px;
}

/*big screens*/
@media screen and (min-width: 1440px) {
}

/*laptop*/
@media screen and (min-width: 1280px) and (max-width: 1439.98px) {
	.smallTitleView {
		margin-top: 112px;
	}
}

/*tablet*/
@media screen and (min-width: 744px) and (max-width: 1279.98px) {
	.smallTitleView {
		margin-top: 72.3px;
		height: 18px;
	}

	.smallTitle {
		font-size: 12px;
		line-height: 48.885px;
	}

	.titleView {
		margin-top: 6px;
		height: 37px;
	}

	.title {
		font-size: 26px;
		line-height: 36.916px;
	}

	.marquee {
		font-size: 49px;
	}
}

/*mobile*/
@media screen and (min-width: 360px) and (max-width: 743.98px) {
	.smallTitleView {
		margin-top: 47.12px;
		height: 18px;
	}

	.smallTitle {
		font-size: 12px;
		line-height: 48.885px;
	}

	.titleView {
		margin-top: 6px;
		height: 37px;
	}

	.title {
		font-size: 26px;
		line-height: 36.916px;
	}

	.marquee {
		font-size: 42px;
	}
}
