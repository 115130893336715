.container {
	/* margin-bottom: 15px; */
	width: 100%;
}

.label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
	display: block;
	margin-bottom: 7px;
}

.tag_container {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}

.tag_container.has_border {
	border: 1px solid var(--gray50);
	border-radius: 5px;
	padding: 10px;
	min-height: 120px;
	align-content: flex-start;
}
