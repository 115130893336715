.search_container {
	padding: 20px;
}

.add_container {
	width: 100%;
	height: fit-content;
	padding: 0 20px 20px;
}

.contact_item {
	font-weight: var(--font-weight-semibold);

	padding: 10px 0;
	border-bottom: solid 1px var(--gray30);
	margin-right: 20px;
	text-align: left;

	&:last-child {
		border-bottom: none;
	}
}

.items_list_wrapper {
	max-height: 463px;

	overflow: auto;
	display: flex;
	flex-direction: column;
}

.tags_container {
	max-height: 110px;
	overflow: auto;
	margin-bottom: 20px;
}

.form_controls {
	margin-top: 20px;

	button {
		width: 100%;
	}
}
