.vault_root_container {
	/* margin-top: 25px; */

	width: 100%;
	height: fit-content;

	background-color: white;
	position: relative;
	padding: 20px;
}

.gaawk_section {
	margin-top: 50px;
}

.upload_container {
	padding: 20px;
	flex-grow: 1;
	background-color: #fff;
}

.upload_header {
	font-weight: var(--font-weight-semibold);
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	gap: 20px;
}

.upload_header h4 {
	margin: 0 0 5px;
}
.upload_header p {
	font-size: 12px;
	color: var(--gray80);
}

.header {
	width: 100%;
	height: fit-content;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

.header_text {
	flex: 1;

	height: 30px;
	font-weight: var(--font-weight-semibold);
	font-size: 22px;

	color: #000;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
}

.header_text span {
	width: 16px;
	height: 16px;
	margin-left: 10px;
	position: relative;
	cursor: pointer;
}

.header_text .info_text {
	position: absolute;
	top: -5px;
	left: 21px;

	width: 232px;
	z-index: 1;

	padding: 8px;
	background-color: var(--gray20);
	border-radius: 7px;

	font-size: 10px;

	color: #000;
}

.header_text span img {
	width: 16px;
	height: 16px;
	object-fit: cover;
}

.right_side_buttons {
	display: flex;
	gap: 20px;
	align-items: center;
	margin-right: auto;
}

.right_side_buttons button img {
	width: 28px;
}

.upload_folder {
	margin-right: 40px;
}

.header_container {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 35px;
}

.header_container.select_commands {
	justify-content: flex-end;
}

/* .vault_root_container hr {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 56px;
	border: 4px solid var(--gray20);
} */

/* .items_container {


	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(auto-fill, 96px);
	gap: 37px;
	row-gap: 20px;
}

.items_container.list {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.items_container.list.m0 {
	margin-top: 5px;
} */

.see_more {
	margin-top: 20px;
	width: 100%;
	height: 19px;

	border-top: 0.8px #000 dashed;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	align-items: center;

	padding: 10px 0 0 0;

	cursor: pointer;
}

.see_more label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000;

	cursor: inherit;
}

.see_more img {
	margin-left: 5px;
	width: 15px;
	height: 9px;
}

.sort_ment {
	width: 106px;
	height: 83px;

	padding: 5px 4px 10px 2px;
	background-color: var(--gray70);
}

/* NEW FOLDER MODAL */

.create_folder_container {
	width: 100%;

	padding: 20px;
}

.create_folder_container label {
	font-weight: var(--font-weight-semibold);
	font-size: 14px;
	color: #000000;
}

.create_folder_container .text {
	margin-top: 7px;
	width: 100%;
	height: 35px;
}

.create_folder_container .buttons {
	margin-top: 20px;
	gap: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

/* .create_folder_container .buttons .cancel_button,
.create_folder_container .buttons .create_button {
	font-weight: var(--font-weight-bold);
	font-size: 14px;
}

.create_folder_container .buttons .cancel_button {
	border: solid 1px #a6b1bc;
	color: #a6b1bc;
}

.create_folder_container .buttons .create_button {
	margin-left: 30px;
	background-color: var(--gaawk);
} */

.nocontent_container {
	margin-top: 50px;
}

.empty_info_text {
	font-size: 12px;
	color: var(--gray80);
}

.empty_info_text span {
	color: var(--gaawk);
}

.empty_info_text span:hover {
	cursor: pointer;
	text-decoration: underline;
}

/* GAAWK FOLDER CONTAINER */

.header_container.gaawkfolder {
	justify-content: flex-end;
}

/* .loading_container {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	margin: 30px 0;
} */

.centered_div {
	margin: auto;
}

.view_more_container {
	text-align: center;
	margin-top: 20px;
}

.empty_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.empty_container p {
	font-size: 14px;
	font-style: italic;
	text-align: center;
	color: #000;
}

.empty_container img {
	margin-top: 20px;
	width: 200px;
}

/*2000*/
@media screen and (min-width: 2000px) {
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
	.upload_folder {
		height: 30px !important;
		padding: 6px 14px !important;
		font-size: 12px !important;
		line-height: 12px !important;
	}

	.upload_file {
		height: 30px !important;
		padding: 6px 14px !important;
		font-size: 12px !important;
	}

	.item {
		margin-top: 30px;
		/*margin-right: 20px !important;*/
	}

	.see_more label {
		font-size: 12px;
	}

	/* .items_container {

		gap: 26px;
	} */
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
	.header_text {
		height: 26px;
		font-size: 20px;
	}

	.upload_folder {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 12px !important;
		margin-right: 20px;
	}

	.upload_file {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 12px !important;
	}
	/*
	.vault_root_container hr {
		left: -15px;
		right: -15px;
	} */

	.item {
		margin-top: 25px;
		/*margin-right: 20px !important;*/
	}

	.see_more label {
		font-size: 12px;
	}

	/* .items_container {

		gap: 20px 30px;
	} */
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
	.header_text {
		height: 26px;
		font-size: 20px;
	}

	.upload_folder {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 12px !important;
		margin-right: 20px;
	}

	.upload_file {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 12px !important;
	}

	/* .vault_root_container hr {
		left: -15px;
		right: -15px;
	} */

	.item {
		margin-top: 25px;
		/*margin-right: 18px !important;*/
	}

	.see_more label {
		font-size: 12px;
	}

	/* .items_container {

		gap: 20px 50px;
	} */
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
	/* .vault_root_container {
		margin-top: 0;

	} */

	.header_text {
		width: 100%;

		height: 27px;
		font-size: 20px;
	}

	.header_text .info_text {
		width: 170px;
	}

	.right_side_buttons {
		/* margin-top: 10px;
        width: 100%; */
		justify-content: flex-start;
	}

	.upload_folder {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 10px !important;
		margin-right: 10px;
	}

	.upload_file {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 10px !important;
	}

	/* .vault_root_container hr {
		left: 0px;
		right: 0px;
	} */

	.item {
		margin-top: 20px;
		/*margin-right: 15px !important;*/
	}

	.see_more label {
		font-size: 12px;
	}

	/* .items_container {

		gap: 20px 16px;
	} */
}

/*640*/
@media screen and (min-width: 640px) and (max-width: 747.98px) {
	.header_text {
		height: 26px;
		font-size: 20px;
	}

	.upload_folder {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 12px !important;
		margin-right: 20px;
	}

	.upload_file {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 12px !important;
	}

	/* .vault_root_container hr {
		left: -10px;
		right: -10px;
	} */

	.item {
		margin-top: 25px;
		/*margin-right: 28px !important;*/
	}

	.see_more label {
		font-size: 12px;
	}

	/* .items_container {

		gap: 20px 25px;
	} */
}

/*!*360*!*/
@media screen and (max-width: 639.98px) {
	/* .vault_root_container {
		margin-top: 0;

	} */

	.header_text {
		width: 100%;

		height: 27px;
		font-size: 20px;
	}

	.header_text .info_text {
		width: 170px;
	}

	.right_side_buttons {
		/* margin-top: 10px;
        width: 100%; */
		justify-content: flex-start;
	}

	.upload_folder {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 10px !important;
		margin-right: 10px;
	}

	.upload_file {
		height: 26px !important;
		padding: 6px 10px !important;
		font-size: 10px !important;
		letter-spacing: 0.5px !important;
		line-height: 10px !important;
	}

	/* .vault_root_container hr {
		left: 0px;
		right: 0px;
	} */

	.item {
		margin-top: 20px;
		/*margin-right: 15px !important;*/
	}

	.see_more label {
		font-size: 12px;
	}
}

@media screen and (min-width: 368px) and (max-width: 639.98px) {
	/* .items_container {
		gap: 20px 16px;
	} */
}

@media screen and (min-width: 355px) and (max-width: 367px) {
	/* .items_container {
		justify-content: space-between;
		gap: 20px 10px;
	} */
}

@media screen and (max-width: 354px) {
	/* .items_container {
		justify-content: center;
		gap: 20px 20px;
	} */
}

@media screen and (max-width: 639px) {
	.create_folder_container .buttons {
		flex-direction: column-reverse;
		gap: 10px;
	}

	.create_folder_container .buttons button {
		width: 100%;
	}
}
