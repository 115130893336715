.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	height: 100%;
	.invoice_container {
		max-width: 400px;
		margin: 80px auto 0;
		padding: 15px;
		background-color: #f5f5f5;
		border-radius: 8px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		text-align: center;
		position: relative;

		.icon_container {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 53px;
			height: 53px;
			background-color: #f5f5f5;
			border-radius: 100%;

			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 25px;
				height: 25px;
			}
		}

		.title {
			font-weight: var(--font-weight-bold);
			font-size: 20px;
			margin: 15px 0 0;
		}

		.subtitle {
			color: var(--gray70);
			font-weight: var(--font-weight-semibold);
			font-size: 14px;
			margin-bottom: 22px;
		}

		.totalPayment {
			.subtitle {
				margin-bottom: 0px;
			}
			font-size: 14px;
			font-weight: var(--font-weight-semibold);
			margin-bottom: 25px;
		}

		.amount {
			font-weight: var(--font-weight-bold);
			font-size: 22px;
			margin-right: 0.25rem;
		}

		.currency {
			font-size: 22px;
		}

		.detailsGrid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 10px;
			margin-bottom: 1.5rem;
		}

		.detailItem {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border: 1.5px solid var(--gray70);
			border-radius: 6px;
			padding: 10px;
			text-align: left;
			min-height: 80px;
		}

		.detailLabel {
			font-size: 12px;
			font-weight: var(--font-weight-semibold);
			color: var(--gray70);
			margin-bottom: 0.25rem;
		}

		.detailValue {
			font-weight: var(--font-weight-bold);
			font-size: 12px;
		}
	}

	.button_container {
		display: flex;
		justify-content: center;
	}
}
