.profile_button {
	position: relative;
	background-color: var(--gray50);
	width: 32px;
	height: 32px;
	border-radius: 50%;

	&.active.user {
		border-radius: 100%;
	}

	&.active {
		border: 1px solid #000;
		border-radius: 7px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
	}

	.notification_counter {
		position: absolute;
	}

	&.active .indicator::after {
		content: "•";
		color: #000;
		position: absolute;
		bottom: -20px;
		left: 0;
		right: 0;

		z-index: 3;
	}

	.notification_counter {
		position: absolute;
		bottom: -5px;
		right: -5px;

		p {
			line-height: normal;
			font-size: 9px;
			font-weight: var(--font-weight-semibold);
			color: #fff;
			background-color: var(--gaawk);
			padding: 0px 4px;
			border-radius: 7px;
		}
	}
}
