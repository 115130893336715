.container {
	padding: 20px;

	.no_margin {
		margin: 0;
	}

	.button_container {
		display: flex;
		justify-content: center;
	}

	.textarea_wrapper {
		margin-top: 20px;
		height: 200px;
	}
}

@media screen and (max-width: 639px) {
	.container {
		.button_container {
			button {
				width: 100%;
			}
		}
	}
}
