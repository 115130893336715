.container {
	width: 100%;
	height: 104px;

	border: #737b7d 1px dashed;

	// cursor: pointer;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 24px;

	margin-top: 30px;
	border-radius: 12px;
}

.container:hover {
	border: #6cc5d1 1px solid;
}

.icon {
	width: 24px;
	height: 24px;
}
.text {
	color: #8e8e8e;
	font-family: "Poppins";
	font-size: 14px;
	line-height: 160%;
	letter-spacing: 0.5px;
}

.errorMessage {
	color: #d40d0d;
	font-family: "Poppins-Regular";
	font-size: 10px;
	line-height: 160%;
	letter-spacing: 0.5px;
}
.uploadNote {
	margin-top: 12px;
	color: #8e8e8e;
	font-family: Poppins;
	font-size: 10px;
	line-height: 160%;
	letter-spacing: 0.5px;
}

.hidden {
	display: none;
}
