.container {
	/* position: absolute; */
	/* left: 0; */
	width: calc(100vw - 360px);
	background-color: #000;
	min-height: 105px;
	padding: 20px;
	/* z-index: 200; */
}

.container .inner_wrapper {
	margin: auto;
	width: 100%;
	max-width: 1200px;
}

.blackBg {
	background-color: #000;
	padding: 0;
}

.vault_details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	font-weight: var(--font-weight-bold);
	margin-bottom: 10px;
}

.vault_details p {
	display: flex;
	align-items: center;
	gap: 10px;
}

.storage_indicator {
	width: 100%;

	position: relative;

	height: 6px;
	border-radius: 4px;
	border: none;
	background-color: #373b43;
	/* border-bottom: 1px solid #ffffff; */
}

.used_container {
	height: 100%;

	border-radius: 4px;

	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;

	background-color: var(--gaawk);
}

.used_container.critical {
	background-color: var(--error300);
}

.detailed_size {
	color: #dbdfe4;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 10px;
	font-size: 12px;
}

.detailed_size p:not(:last-child):after {
	content: "•";
	display: inline-block;
	margin: 0 8px;
}

.container .storage_warning {
	margin: auto;
	max-width: 1200px;
	border: none;
	padding: 0;
	margin-top: 20px;
	color: #fff;
}

.container .storage_warning button {
	color: #fff !important;
}

@media screen and (max-width: 747px) {
	.container {
		width: 100vw;
	}
}
