.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.status_wrapper {
		display: flex;
		align-items: center;
		gap: 10px;

		p span {
			font-weight: var(--font-weight-semibold);
		}

		.status_indicator {
			width: 10px;
			height: 10px;
			border-radius: 100%;
			background-color: var(--gray70);
			&.active {
				background-color: #40ac7a;
			}
		}
	}

	.toggleStatusBtn {
		flex-shrink: 0;
	}
}
