@use "variables" as *;

.backdrop {
	opacity: 0.8;
}

.modal_container {
	/* max-width: 1639px; */
	width: calc(100% - 361px);

	position: absolute;
	/* top: 0; */
	/* right: 0; */
	left: auto !important;
	bottom: auto !important;
}

.dialog {
	width: 100% !important;
	height: 100dvh !important;
	margin: 0 !important;
}

.content {
	border: none !important;
	border-radius: 0 !important;

	width: 100%;
	height: 100%;
}

.header {
	width: 100%;
	height: 55px;
	background-color: #000;

	padding: 10px 20px;
	border: none !important;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
}

// .header_left_container {
// 	flex: 1;
// 	/*width: fit-content;*/
// 	height: 100%;

// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: flex-start;
// 	align-content: center;
// 	align-items: center;
// }

// .back_arrow_img {
// 	width: 30px;
// 	height: 25px;
// 	cursor: pointer;
// 	margin-right: 15px;
// }

// .header_right_container {
// 	flex: 1;
// 	height: 100%;

// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: flex-end;
// 	align-content: center;
// 	align-items: center;
// }

// .close_img {
// 	width: 20px;
// 	height: 20px;
// 	cursor: pointer;
// }

// .option_img {
// 	margin-left: 10%;
// 	width: 6px;
// 	/*height: 20px;*/
// 	cursor: pointer;
// }

/* .header_middle_container {
    flex: 1;
    width: fit-content;

    height: 100%;



    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.middle_action_button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--gray20);
} */

// .body {
// 	width: 100%;
// 	height: calc(100dvh - $topNavBarHeight - $bottomNavbarHeight);

// 	padding: 5px 0;

// 	background-color: var(--gray20);
// }

// .body_container {
// 	width: 100%;
// 	height: 100%;
// }

// .footer {
// 	width: 100%;
// 	height: 56px;
// }

.full_width_modal {
	width: 100%;
	max-width: none;
}

/*2000*/
@media screen and (min-width: 2000px) {
	.modal_container {
		width: 100vw;
	}
}

/*1608*/
@media screen and (min-width: 1608px) and (max-width: 1999.98px) {
}

/*1400*/
@media screen and (min-width: 1400px) and (max-width: 1607.98px) {
}

/*1166*/
@media screen and (min-width: 1166px) and (max-width: 1399.98px) {
}

/*974*/
@media screen and (min-width: 974px) and (max-width: 1165.98px) {
}

/*748*/
@media screen and (min-width: 748px) and (max-width: 973.98px) {
}

/*640*/
@media screen and (max-width: 747.98px) {
	.dialog {
		width: 100vw !important;
	}

	.modal_container {
		width: 100%;
	}
}

/*!*360*!*/
/* @media screen and (max-width: 639.98px) {
} */
