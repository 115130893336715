.react-calendar {
	width: 350px;
	max-width: 100%;
	background: white;
	border: none;
	box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
	border-radius: 5px;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
}

/* .react-calendar__tile abbr {
    display: none;
} */

.react-calendar.no-shadow {
	box-shadow: none;
}

.react-calendar--doubleView {
	width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}
.react-calendar button:enabled:hover {
	cursor: pointer;
}
.react-calendar__navigation {
	display: flex;
	height: 44px;
	margin-bottom: 1em;
}
.react-calendar__navigation button {
	min-width: 44px;
	background: none;
}
.react-calendar__navigation button:disabled {
	background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: var(--font-weight-bold);
	font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	font-weight: var(--font-weight-bold);
}
.react-calendar__month-view__days__day--weekend {
	color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}
.react-calendar__tile {
	max-width: 100%;
	padding: 10px 6.6667px;
	background: none;
	text-align: center;
	line-height: 16px;
	border-radius: 5px;
}
.react-calendar__tile:disabled {
	background-color: #f0f0f0;
	border-radius: 0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #e6e6e6;
}
.react-calendar__tile--now {
	background: #ffff76;
	border-radius: 0;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #ffffa9;
}
.react-calendar__tile--hasActive {
	background: var(--gaawk);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: var(--gaawk);
}
.react-calendar__tile--active {
	background: var(--gaawk);

	color: white;
	border-radius: 5px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: var(--gaawk);
}
.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}
